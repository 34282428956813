import React from 'react'
import './JobSeekerItem.scss'
import CommonLink from '../CommonLink/CommonLink'
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage'

const JobSeekerItem = ({ image, header, description, link }) => {
  return (
    <div className='jobSeekerItem'>
        <div className='jobSeekerItem_image'>
            <LazyLoadImage src={image} alt='jobSeekerItem_image'/>
        </div>
        <div className='jobSeekerItem_content'>
            <div className='jobSeekerItem_header'>
                <h4>
                    {header}
                </h4>
            </div>
            <div className='jobSeekerItem_description'>
                <p>
                    {description}
                </p>
            </div>
            <div className='jobSeekerItem_link'>
                <CommonLink text={'Learn more'} path={link} blank={false}/>
            </div>
        </div>
    </div>
  )
}

export default JobSeekerItem