import React, { useState, useRef } from 'react'
import './MobileFilters.scss'

import CompaniesAlphabeticalFilter from '../CompaniesAlphabeticalFilter/CompaniesAlphabeticalFilter'
import CompaniesRatingFilter from '../CompaniesRatingFilter/CompaniesRatingFilter'
import ArrowDown from 'assets/images/ArrowDown'
import useOutsideClickListener from 'hooks/useClickOutsideListener'


const MobileFilters = () => {
  const componentRef = useRef()
  const [byLetter, setByLetter] = useState(false)
  const [byRating, setByRating] = useState(false)

  useOutsideClickListener(componentRef, () => {
    setByLetter(false)
    setByRating(false)
  })

  const toggleFilters = (e) => {
    const { id } = e.target
    if(id === 'alphabetFilter') {
      setByLetter(!byLetter)
      setByRating(false)
    } else if (id === 'ratingFilter') {
      setByRating(!byRating)
      setByLetter(false)
    } else {
      setByLetter(false)
      setByRating(false)
    }
  }

  return (
    <div className='mobileFilters' ref={componentRef}>
      <div className='letterFilter'>
        <button id='alphabetFilter' className={`filterButton ${byLetter ? 'active' : ''}`} onClick={e => toggleFilters(e)}>
          A-Z
          <ArrowDown/>
        </button>
        { byLetter &&      
          <div className='letterFilter_container' onClick={toggleFilters}>
            <CompaniesAlphabeticalFilter mobile={true}/>
          </div>
        }
      </div>
      <div className='ratingFilter'>
        <button id='ratingFilter' className={`filterButton ${byRating ? 'active' : ''}`} onClick={e => toggleFilters(e)}>
          Rating
          <ArrowDown/>
        </button>
        { byRating && 
          <div className='ratingFilter_container' >
            <CompaniesRatingFilter mobile={true}/>
          </div>
        }
      </div>
    </div>
  )
}

export default MobileFilters