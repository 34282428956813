import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
// const Footer = React.lazy(() => import('components/Footer/Footer'))
import Footer from 'components/Footer/Footer'
// const Header = React.lazy(() => import('components/Header/Header'))
import Header from 'components/Header/Header'

const Page = ({ component, withFooter = true}) => {
  const [currentURL, setCurrentUrl] = useState(window.location.href)
  const location = useLocation();

  useEffect(() => {
      setCurrentUrl(window.location.origin + location.pathname)
  }, [location]);

  return (
    <>
        <Helmet>
          <link rel="canonical" href={currentURL} />
        </Helmet>
        <Header/>
        {component}
        {withFooter && <Footer/>}
    </>
  )
}

export default Page