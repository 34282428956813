import React, { useEffect } from 'react'
import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'
import SEO from 'components/SEOComponent/SEO'
import HTMLRenderer from 'components/HTMLRenderer/HTMLRenderer'
import { useGetPrivacyQuery } from 'services/api/termsAndPolicyApi'
import { scrollToTop } from "services/helpers";

import '../TermsPage/styles.scss'

const PrivacyPage = () => {
    const { data } = useGetPrivacyQuery()

    useEffect(() => {
        scrollToTop()
    }, [])
    return (
        <>
            <SEO title={'Privacy Policy'} description={'Privacy Policy'} keywords={'Privacy Policy'}/>
            <ContentWrapper>
                <div className='infoPages_content'>
                    <h2 className='title-orange'>
                        Privacy Policy
                    </h2>
                    <p>
                        <HTMLRenderer htmlString={data?.content}/>
                    </p>
                </div>
            </ContentWrapper>
        </>
    )
}

export default PrivacyPage