/* eslint-disable */
import React, { Fragment, useState } from "react";
import "./AdvicesFilter.scss";
import RadioButton from "components/RadioButton/RadioButton";
import Dropdown from "components/Dropdown/Dropdown";
import { useGetCareerAdviceArticlesCategoriesQuery } from "services/api/careerAdviceApi";

const AdvicesFilter = ({ setCategory }) => {
  const [selectedOption, setSelectedOption] = useState({
    value: "",
    label: "All",
  });

  const { data: categories } = useGetCareerAdviceArticlesCategoriesQuery();

  const onHandleChange = (e) => {
    const selectedOption = categories?.categories.find((item) =>
      e.value ? item?.id == e.value : ""
    );
    setSelectedOption({
      value: selectedOption?.id,
      label: selectedOption?.name,
    });
    setCategory(selectedOption?.id);
  };

  const onHandleDropdown = (value) => {
    setCategory(value);
  };

  return (
    <>
      <div className="advicesFilter">
        <h5 className="advicesFilter_header">Categories</h5>
        <div className="advicesFilter_inputs">
          <RadioButton
            label={"All"}
            value={""}
            name={"advicesFilter"}
            checked={!selectedOption.value}
            onOptionChange={onHandleChange}
          />
          {categories?.categories.map((item) => (
            <Fragment key={item.id}>
              <RadioButton
                label={item.name}
                value={item.id}
                name={"advicesFilter"}
                onOptionChange={onHandleChange}
                checked={selectedOption.value === item.id}
              />
            </Fragment>
          ))}
        </div>
      </div>
      <div className="advicesFilter_mobile">
        <Dropdown
          options={categories?.categories}
          selectedByDefault={selectedOption.label}
          onOptionHandle={onHandleDropdown}
        />
      </div>
    </>
  );
};

export default AdvicesFilter;
