import React, { useState } from 'react';
import icon from '../../assets/images/DDarrow.svg'
import './Dropdown.scss';

const Dropdown = ({ options, selectedByDefault, onOptionHandle }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(selectedByDefault ? selectedByDefault : '');

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelectOption = (id, option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onOptionHandle(id)
  };

  return (
    <div className="customDropdown">
      <div className={`dropdownHeader ${isOpen ? 'open' : ''}`} onClick={handleToggleDropdown}>
        {selectedOption 
        ? <span>Category: <span className='selectedCategory'>{selectedOption}</span></span> 
        : <span>Select an option</span>
        }
        <img className={`dropdownHeader_icon ${isOpen ? 'active' : ''}`} src={icon} alt='arrow'/>
      </div>
      {isOpen && (
        <ul className="dropdownOptions">
            <li id={''} onClick={(e) => handleSelectOption('', 'All')}>
              All
            </li>
          {options.map(option => (
            <li key={option.id} id={option.id} onClick={(e) => handleSelectOption(option.id, option.name)}>
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
