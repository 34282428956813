import React from 'react'
import './ReviewsFilters.scss'
import CompaniesRatingFilter from './_components/CompaniesRatingFilter/CompaniesRatingFilter'
import CompaniesAlphabeticalFilter from './_components/CompaniesAlphabeticalFilter/CompaniesAlphabeticalFilter'

const ReviewsFilters = ({ resultPage = false }) => {
  return (
    <div className={`reviewsFilters ${resultPage ? 'reverse' : ''}`}>
        <CompaniesRatingFilter/>
        <CompaniesAlphabeticalFilter/>
    </div>
  )
}

export default ReviewsFilters