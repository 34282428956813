import React, { Fragment } from 'react'
import RadioButton from 'components/RadioButton/RadioButton'

const RadiosList = ({ data, name, handler }) => {
  return (
    <div className='radios_list'>
        {data.map(item => 
          <Fragment key={item.value}>
            <RadioButton 
              value={item.value} 
              label={item.label} 
              name={name} 
              onOptionChange={handler}
            />
          </Fragment>
        )}
    </div>
  )
}

export default RadiosList