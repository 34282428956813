/* eslint-disable */
import React from 'react'
import './FooterBlue.scss'

import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'
import logo from 'assets/images/AF-footer-logo.svg'
import CommonLink from '../../../CommonLink/CommonLink'
import twIcon from 'assets/images/twitter-icon.svg'
import fbIcon from 'assets/images/facebook-icon.svg'
import liIcon from 'assets/images/linkedin-icon.svg'
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage'


const FooterBlue = () => {

  const getCurrentYear = () => new Date().getFullYear()

  return (
    <div className='footerBlue'>
        <ContentWrapper>
            <div className='footerBlue_container'>
                <div className='footerBlue_leftside'>
                  <div className='footerBlue_logo__container'>
                    <LazyLoadImage src={logo} alt='footer-logo' width='193px' height='15px'/>
                    <address>
                      <p>204 2nd Ave., Waltham, MA 02451</p>
                    </address>
                  </div>

                  <div className='footer_links'>
                    <CommonLink path={'https://search.retirementjobs.com/employer-offers'} textDecoration={true} text={'Employers'}/>
                    <CommonLink path={'https://maturecaregivers.com/'} textDecoration={true} text={'Mature Caregivers'} blank={true}/>
                    <CommonLink path={'https://institute.agefriendly.com/initiatives/certified-age-friendly-employer-program/'} textDecoration={true} text={'Certified Age Friendly Employer (CAFE Program)'} blank={true}/>
                    <CommonLink path={'/privacy-policy'} textDecoration={true} text={'Privacy Policy'}/>
                    <CommonLink path={'/terms-of-service'} textDecoration={true} text={'Terms of Service'}/>
                  </div>
                </div>
                <div className='footerBlue_rightside'>
                  <div className='contactUs'>
                    <h4>Contact Us:</h4>
                    <CommonLink path={'/about/contact-us'} textDecoration={true} text={'Get In Touch'}/>
                    <a className='contactUs_phone' href="tel:781-890-5050">781-890-5050</a>
                  </div>

                  <div className='contactSocials'>
                    <h4>Follow us:</h4>
                    <div className='socialsLinks'>
                      <a href='https://twitter.com/retirementjobs' target='_blank' rel="noreferrer">
                        <LazyLoadImage src={twIcon} alt='twitter-icon' width='26px' height='21px'/>
                      </a>
                      <a href='https://www.linkedin.com/company/retirementjobs-com/about/' target='_blank' rel="noreferrer">
                        <LazyLoadImage src={liIcon} alt='linkedin-icon' width='23px' height='21px'/>
                      </a>
                      <a href='https://www.facebook.com/RetirementJobs' target='_blank' rel="noreferrer">
                        <LazyLoadImage src={fbIcon} alt='facebook-icon' width='12px' height='21px'/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            <p className='footerCopyright'>	&copy; Copyright {getCurrentYear()} RetirementJobs.com, Inc. All rights reserved. RetirementJobs.com is an operating business of <a rel="noreferrer" href='https://www.agefriendlyventures.com/' target='_blank'>Age Friendly Ventures, Inc.</a></p>
        </ContentWrapper>
    </div>
  )
}

export default FooterBlue