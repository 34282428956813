import React from 'react'
import './SuccessRequestPopup.scss'
import CommonButton from 'components/CommonButton/CommonButton'
import closeShape from 'assets/images/close-shape.svg'

const SuccessRequestPopup = ({ title, description, buttonText, onClose }) => {
  return (
    <div className='successPopup'>
        <div className='successPopup_header'>
          <p>{title}</p>

          <span className='closeShape' onClick={onClose}>
            <img src={closeShape} alt='shape'/>
          </span>

          <div className='closeBtn'></div>
        </div>
        <div className='successPopup_content'>
          <p>{description}</p>
          <div className='successPopup_button'>
            <CommonButton addColor={'orange'} text={buttonText} onClick={onClose}/>
          </div>
        </div>
    </div>
  )
}

export default SuccessRequestPopup