import { isRejectedWithValue } from '@reduxjs/toolkit'

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if(action.payload.originalStatus === 404) {
        window.location.replace('/not-found')
    } else if (action.payload.originalStatus === 500) {
        window.location.replace('/error')
    }
  }
  return next(action)
}