/* eslint-disable */
import React, { Fragment } from 'react'
import './MoreResources.scss'
import BorderedSidebar from 'components/BorderedSidebar/BorderedSidebar'

const ResourcesItem = ({ title, urlTitle, url }) => {
    return(
        <div className='moreResources_item'>
            <span>{title}</span>
            <a href={url} target='_blank' rel="noreferrer">{urlTitle}</a>
        </div>
    )
}

const MoreResources = ({ state, moreRecources }) => {
    const renderItems = moreRecources?.map((item, i) => <Fragment key={i+1}><ResourcesItem title={item.title} url={item.link} urlTitle={item.linkTitle}/></Fragment>)
    return (
        <div className='moreResources'>
            <BorderedSidebar title={`More Resources for Seniors in ${state}`}>
                <div className='moreResources_list'>
                    {renderItems}
                </div>
            </BorderedSidebar>
        </div>
    )
}

export default MoreResources