/* eslint-disable */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseURL } from 'constants/api'

export const searchReviewsApi = createApi({
    reducerPath: 'searchReviewsApi',
    baseQuery: fetchBaseQuery({ baseUrl: baseURL}),
    tagTypes: ['Review'],
    endpoints: (build) => ({
        getReviewsSearchResults: build.query({
            query: ({company}) => ({
                url: `typeahead_companies?query=${company}`,
                method: 'GET',
            })
        }),
        getReviewsCompanies: build.query({
            query: () => ({ 
                url: `companies`,
                method: 'GET',
            })
        }),
        getReviewsCompany: build.query({
            query: ({companyName}) => ({ 
                url: `companies/${companyName}`,
                method: 'GET',
            })
        }),
        getFilteredCompanies: build.query({
            query: ({letter, rating, page, companyName, perPage}) => ({
                url: `filter_companies`,
                method: 'GET',
                params: {
                    search: companyName,
                    letter: letter,
                    rating: rating,
                    page: page,
                    perPage: perPage
                },
            })
        }),
        addReview: build.mutation({
            query: (body) => ({
                url: `/reviews`,
                method: 'POST',
                body
            }),
        })
    })
})

export const { 
    useGetReviewsSearchResultsQuery, 
    useGetReviewsCompaniesQuery, 
    useGetFilteredCompaniesQuery, 
    useGetReviewsCompanyQuery, 
    useAddReviewMutation 
} = searchReviewsApi;
