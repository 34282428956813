import React from 'react'
import './FormError.scss'

const FormError = () => {
  return (
    <div className='formError'>
      <p>
        Request not sent! Please, resubmit your information.
      </p>
    </div>
  )
}

export default FormError