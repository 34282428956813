/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import CommonLink from "components/CommonLink/CommonLink";
import { useLocation } from "react-router-dom";

const RenderFooterLinks = ({
  data,
  linksType,
  jobsByState,
  state,
  category,
}) => {
  const [columnSizes, setColumnSize] = useState(
    linksType === "category" ? [4, 4, 4, 3, 3] : [10, 10, 10, 10, 10]
  );
  const [result, setResult] = useState([]);
  const location = useLocation();

  const windowWidthRef = useRef(window.innerWidth);

  const resizeFunction = () => {
    if (windowWidthRef.current < 450) {
      setColumnSize(linksType === "category" ? [18] : [25, 25]);
    } else if (windowWidthRef.current < 695) {
      setColumnSize(linksType === "category" ? [9, 9] : [25, 25]);
    } else if (windowWidthRef.current < 1080 || jobsByState) {
      setColumnSize(linksType === "category" ? [6, 6, 6] : [17, 17, 17]);
    } else {
      setColumnSize(
        linksType === "category" ? [4, 4, 4, 3, 3] : [10, 10, 10, 10, 10]
      );
    }
  };

  const handleResize = () => {
    const newWidth = window.innerWidth;
    windowWidthRef.current = newWidth;
    resizeFunction();
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    resizeFunction();
  }, [windowWidthRef.current]);

  function replaceCityStateZip(link, replacement) {
    const regex = /city_state_zip=/g;
    const modifiedUrl = link?.replace(regex, `city_state_zip=${replacement}`);
    return modifiedUrl;
  }

  function categoryLink(link, state, category) {
    if (
      location.pathname.split("/")[1] != "" &&
      !location.pathname.split("/").includes("category") &&
      !location.pathname.split("/").includes("jobs-by-categories") &&
      !location.pathname.split("/").includes(category)
    ) {
      const stateName = location.pathname.split("/")[2];

      return `/jobs-for-seniors/${stateName}/${category}`;
    } else if (location.pathname.startsWith("/jobs-for-seniors")) {
      return replaceCityStateZip(link, state);
    } else {
      return link;
    }
  }
  function stateLink(modifiedLink) {
    if (
      linksType === "state" &&
      !location.pathname.split("/").includes("category") &&
      !location.pathname.split("/").includes(category)
    ) {
      return `/jobs-for-seniors/${modifiedLink}`;
    } else {
      return `/jobs-for-seniors/${modifiedLink}/${category}`;
    }
  }

  useEffect(() => {
    const subarrays = [];
    let index = 0;
    columnSizes.forEach((columnSize) => {
      const subarray = data.slice(index, index + columnSize);
      subarrays.push(subarray);
      index += columnSize;
    });

    setResult(subarrays);
  }, [data, columnSizes]);

  const links = result.map((subarray, index) => (
    <div className="linksColumn" key={index}>
      {subarray.map(({ abbrev, name, link }) => {
        const modifiedLink =
          name === "Louisiana"
            ? "louisiana"
            : name.replace(/\//g, "").replace(/\s+/g, "-").toLowerCase();

        return (
          <span key={abbrev}>
            <CommonLink
              path={
                linksType === "state"
                  ? stateLink(modifiedLink)
                  : categoryLink(
                      link,
                      state,
                      name.replace(/\//g, "").replace(/\s+/g, "-")
                    )
              }
              text={name}
              state={name}
            />
          </span>
        );
      })}
    </div>
  ));

  return <>{links}</>;
};

export default RenderFooterLinks;
