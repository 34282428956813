/* eslint-disable */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseURL } from 'constants/api'

export const searchApi = createApi({
    reducerPath: 'searchApi',
    baseQuery: fetchBaseQuery({ baseUrl: baseURL}),
    endpoints: (build) => ({
        getSearchResults: build.query({
            query: ({locations}) => ({
                url: `locations`,
                method: 'GET',
                params: {
                    query: locations
                }
            })
        })
    })
})

export const { useGetSearchResultsQuery } = searchApi;
