import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { scrollToTop, capitalizeWords } from 'services/helpers'

import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'
import SEO from 'components/SEOComponent/SEO'
import CategoryJobInfo from './components/CategoryJobInfo/CategoryJobInfo'

const JobsInCategory = () => {

    const location = useLocation()
    const state = location.pathname.split('/')[3];
    useEffect(() => {
        scrollToTop()
    }, [location])

    return (
        <ContentWrapper>
            <SEO title={`Age-Friendly ${capitalizeWords(state)} Jobs by State`} description={`Find age-friendly ${capitalizeWords(state)} jobs in your state for older workers and seniors.`} keywords={''}/>
            <CategoryJobInfo state={state}/>
        </ContentWrapper>
    )
}

export default JobsInCategory