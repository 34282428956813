import React from 'react'
import './ReviewBlock.scss'
import quotations from 'assets/images/quotations.svg'
import { Link } from 'react-router-dom'

const ReviewBlock = () => {
  return (
    <div className='reviewBlock'>
        <div className='reviewBlock_content'>
            <div className='reviewBlock_header'>
                <h4>Feedback:</h4>
            </div>
            <div className='reviewBlock_feedback'>
                <div className='reviewBlock_feedback__quotations'>
                    <img src={quotations} alt='quotations'/>
                </div>
                <div className='reviewBlock_feedback__text'>
                    <h4>Marie Moore, AARP</h4>
                    <p>After reading the comments by those who express their perspective of age related discriminating practices by more than a few employers, I felt very concerned for older workers that need/desire employment. I wonder if these employers understand that it is not just the revenue from young adults that keep them in business. I wonder how they would survive if the older generation would stop supporting their businesses. I also wonder if these employers understand the principle of sowing and reaping? I am grateful to your agency for providing the results of your survey and your commitment to provide older workers with reliable job leads.</p>
                </div>
            </div>
            <div className='reviewBlock_btn'>
                <Link to={'/about/contact-us'}>Send us feedback</Link>
            </div>
        </div>
    </div>
  )
}

export default ReviewBlock