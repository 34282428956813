export const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
  });
};

export function generateURL(string) {
    return string.replace(/[^a-zA-Z0-9]+/g, '-').replace(/-$/, '');
}

export function generateRange(start, end) {
    if (start > end) {
      [start, end] = [end, start];
    }
    const range = [];
    for (let i = start; i <= end; i++) {
      range.push({value: i, label: i});
    }
    return range;
}

export function capitalizeWords(str) {
  if(str.includes('Accounting') || str.includes('Administrative')  || str.includes('Caregiving') || str.includes('Driver')){
    let stringArr = str.split('-')
    stringArr.splice(1, 0, '/')
    let result = stringArr.join('-')

    return result.split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
    
  }else{
    return str.split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  }
  
}

export function capitalizeAndReplace(str) {
  return str.split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('%20');
}