/* eslint-disable */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseURL } from 'constants/api'

export const termsAndPolicyApi = createApi({
    reducerPath: 'termsAndPolicyApi',
    baseQuery: fetchBaseQuery({ baseUrl: baseURL}),
    endpoints: (build) => ({
        getPrivacy: build.query({
            query: () => ({
                url: `privacy_policies`,
                method: 'GET',
            })
        }),
        getTerms: build.query({
            query: () => ({
                url: `terms_of_services`,
                method: 'GET',
            })
        })
    })
})

export const { useGetPrivacyQuery, useGetTermsQuery } = termsAndPolicyApi;
