import React from "react";
import { NavLink } from "react-router-dom";

import { aboutUsSectionNavItems } from "constants/aboutUsContent";

import "./SectionNavigation.scss";

const SectionNavigation = () => {
  return (
    <>
      <ul className="navList">
        {aboutUsSectionNavItems &&
          aboutUsSectionNavItems.map((item, i) => (
            <li key={i}>
              <NavLink
                className={({ isActive }) => (isActive ? "activeItem" : "")}
                to={item.link}
              >
                {item.title}
              </NavLink>
            </li>
          ))}
      </ul>
    </>
  );
};

export default SectionNavigation;
