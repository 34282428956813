import React from 'react'
import './ReviewFormPage.scss'
import EmployerReviewForm from 'components/Forms/EmployerReviewForm/EmployerReviewForm'

const ReviewFormPage = () => {
  return (
    <div className='reviewFormPage'>
        <div className='leftrail'>
          <EmployerReviewForm/>
        </div>
        <div className='rightrail'>
          <amp-ad width='300' height='250'
              type="doubleclick"
              data-slot="/1031329/300x250-ROS2">
          </amp-ad>
        </div>
    </div>
  )
}

export default ReviewFormPage