import React from 'react'
import './TopBar.scss'
import MainLogo from 'assets/images/MainLogo'
import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'
import { Link } from 'react-router-dom'

const TopBar = () => {
  return (
    <ContentWrapper>
      <section className='topBar'>
        <Link to='/'>
          <div className='topBar_logo'>
              <MainLogo/>
          </div>
        </Link>
        <h2 className='title-orange'>Jobs for People over 50</h2>
      </section>
    </ContentWrapper>
  )
}

export default TopBar