import React from 'react'

const DateLabel = ({ date }) => {

const [month, day] = date?.split(' ');
const shortMonth = month.substr(0, 3)

return (
    <div className='dateLabel'>
        <span className='dateLabel_date'>{`${shortMonth} ${day}`}</span>
    </div>
  )
}

export default DateLabel