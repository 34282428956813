import React from 'react'
import TopBar from './_components/TopBar/TopBar'
import TopNav from './_components/TopNav/TopNav'

const Header = () => {
  return (
    <header>
        <TopBar/>
        <TopNav/>
    </header>
  )
}

export default Header