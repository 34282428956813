import React from 'react'
import Preloader200px from 'assets/images/PreloaderL'
import Preloader50px from 'assets/images/PreloaderS'


const Preloader = ({ size }) => {
  return (
    <div className='preloader'>
        {size === 'L' ? <Preloader200px/> : <Preloader50px/>}
    </div>
  )
}

export default Preloader