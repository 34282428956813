import React from 'react'

const MainLogo = () => {
  return (
    <svg width="412" height="28" viewBox="0 0 412 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.821 11.4581H14.5366C16.4333 11.4581 17.8233 11.1387 18.7067 10.5C19.59 9.84823 20.0317 8.81844 20.0317 7.41061C20.0317 5.96369 19.6095 4.92737 18.7651 4.30168C17.9207 3.67598 16.4658 3.36313 14.4002 3.36313H9.821V11.4581ZM9.821 21.6648C9.821 22.7467 9.9639 23.4311 10.2497 23.7179C10.5485 24.0047 11.2955 24.148 12.4906 24.148H14.3613V27.1983H0V24.148H1.55889C2.74105 24.148 3.47502 24.0112 3.76082 23.7374C4.04662 23.4506 4.18951 22.7598 4.18951 21.6648V5.84637C4.18951 4.7514 4.04662 4.06704 3.76082 3.7933C3.47502 3.50652 2.74105 3.36313 1.55889 3.36313H0V0.332402H15.9981C19.3497 0.332402 21.8374 0.860335 23.4613 1.9162C25.0851 2.97207 25.897 4.59497 25.897 6.78492C25.897 8.71415 25.2475 10.2588 23.9484 11.419C22.6623 12.5661 20.7592 13.2896 18.239 13.5894C20.1486 13.8762 21.4802 14.4236 22.2337 15.2318C23.0001 16.027 23.4483 17.4153 23.5782 19.3966L23.7536 21.8017C23.8185 22.7402 23.9354 23.3529 24.1043 23.6397C24.2732 23.9264 24.5525 24.0698 24.9422 24.0698C25.7217 24.0698 26.1374 22.6033 26.1893 19.6704C26.1893 19.6182 26.1893 19.5791 26.1893 19.5531H28.7225C28.7485 19.7225 28.7615 19.905 28.7615 20.1006C28.7745 20.2831 28.781 20.5503 28.781 20.9022C28.781 23.3659 28.3328 25.1648 27.4364 26.2989C26.5531 27.433 25.1501 28 23.2274 28C21.6426 28 20.402 27.5633 19.5056 26.6899C18.6092 25.8166 18.1351 24.6043 18.0831 23.0531L17.9662 19.905C17.8883 17.8063 17.4206 16.3855 16.5632 15.6425C15.7188 14.8864 14.1079 14.5084 11.7306 14.5084H9.821V21.6648Z" fill="#1E398D"/>
        <path d="M44.6622 15.3101C44.5453 13.5633 44.188 12.2858 43.5904 11.4777C43.0058 10.6695 42.142 10.2654 40.9988 10.2654C39.8296 10.2654 38.8748 10.6955 38.1343 11.5559C37.4068 12.4032 36.9262 13.6546 36.6923 15.3101H44.6622ZM46.4744 20.433H49.826C49.5402 22.7533 48.6114 24.5847 47.0395 25.9274C45.4806 27.257 43.48 27.9218 41.0378 27.9218C37.9979 27.9218 35.5751 27.0028 33.7694 25.1648C31.9637 23.3138 31.0609 20.8436 31.0609 17.7542C31.0609 14.7039 31.9702 12.2467 33.7889 10.3827C35.6206 8.50559 38.0369 7.56704 41.0378 7.56704C43.9347 7.56704 46.1431 8.473 47.663 10.2849C49.1829 12.0838 49.9429 14.7039 49.9429 18.1452H36.4975C36.5754 20.2831 37.0236 21.9125 37.842 23.0335C38.6734 24.1546 39.8361 24.7151 41.33 24.7151C42.8629 24.7151 44.0191 24.3892 44.7986 23.7374C45.591 23.0726 46.1496 21.9711 46.4744 20.433Z" fill="#1E398D"/>
        <path d="M55.0288 21.2737V11.067H51.911V8.46648C53.6907 8.16667 55.0028 7.43668 55.8472 6.27654C56.7046 5.10335 57.2112 3.29143 57.3671 0.840782H59.9198V8.2905H65.1226V11.067H59.8419V21.8408C59.8419 22.7663 59.9458 23.392 60.1536 23.7179C60.3615 24.0307 60.7382 24.1871 61.2838 24.1871C61.9593 24.1871 62.427 23.8678 62.6868 23.229C62.9466 22.5903 63.0765 21.2868 63.0765 19.3184V18.2626H65.6682C65.6812 18.4711 65.6877 18.6993 65.6877 18.9469C65.7007 19.1946 65.7072 19.54 65.7072 19.9832C65.7072 22.7598 65.2525 24.7737 64.3431 26.0251C63.4468 27.2635 61.9918 27.8827 59.9783 27.8827C58.2505 27.8827 56.9904 27.3547 56.1979 26.2989C55.4185 25.243 55.0288 23.568 55.0288 21.2737Z" fill="#1E398D"/>
        <path d="M68.3963 27.1983V24.5196H69.8772C70.7866 24.5196 71.3906 24.3762 71.6894 24.0894C72.0012 23.8026 72.1571 23.1443 72.1571 22.1145V13.3743C72.1571 12.3445 72.0012 11.6927 71.6894 11.419C71.3906 11.1322 70.7866 10.9888 69.8772 10.9888H68.3963V8.2905H77.0286V22.1145C77.0286 23.1313 77.178 23.7896 77.4768 24.0894C77.7886 24.3762 78.4056 24.5196 79.328 24.5196H80.5361V27.1983H68.3963ZM71.2802 2.97207C71.2802 2.1378 71.5595 1.43389 72.1181 0.860336C72.6897 0.286779 73.3912 0 74.2226 0C75.067 0 75.775 0.286779 76.3466 0.860336C76.9312 1.43389 77.2235 2.1378 77.2235 2.97207C77.2235 3.80633 76.9312 4.51676 76.3466 5.10335C75.775 5.68994 75.067 5.98324 74.2226 5.98324C73.4042 5.98324 72.7092 5.68994 72.1376 5.10335C71.566 4.51676 71.2802 3.80633 71.2802 2.97207Z" fill="#1E398D"/>
        <path d="M90.8053 22.1145C90.8053 23.1443 90.9547 23.8026 91.2535 24.0894C91.5652 24.3762 92.1758 24.5196 93.0852 24.5196H95.0727V27.1983H82.3873V24.5196H83.7123C84.6217 24.5196 85.2258 24.3762 85.5245 24.0894C85.8233 23.8026 85.9727 23.1443 85.9727 22.1145V13.3743C85.9727 12.3445 85.8233 11.6927 85.5245 11.419C85.2258 11.1322 84.6217 10.9888 83.7123 10.9888H82.3873V8.2905H90.8053V11.1453C91.4288 10.0242 92.1563 9.18342 92.9877 8.6229C93.8191 8.06238 94.7545 7.78212 95.7937 7.78212C97.2227 7.78212 98.3854 8.18622 99.2817 8.99441C100.178 9.78957 100.626 10.8259 100.626 12.1033C100.626 13.1071 100.327 13.9283 99.7299 14.567C99.1323 15.1927 98.3529 15.5056 97.3916 15.5056C96.5732 15.5056 95.9106 15.2775 95.404 14.8212C94.8974 14.352 94.644 13.7523 94.644 13.0223C94.644 12.3575 94.8519 11.797 95.2676 11.3408C95.6833 10.8715 96.2874 10.5261 97.0798 10.3045C96.781 10.1741 96.4887 10.0763 96.2029 10.0112C95.9301 9.93296 95.6768 9.89385 95.443 9.89385C94.1179 9.89385 93.0137 10.5652 92.1303 11.9078C91.247 13.2374 90.8053 14.9451 90.8053 17.0307V22.1145Z" fill="#1E398D"/>
        <path d="M116.02 15.3101C115.903 13.5633 115.546 12.2858 114.949 11.4777C114.364 10.6695 113.5 10.2654 112.357 10.2654C111.188 10.2654 110.233 10.6955 109.492 11.5559C108.765 12.4032 108.284 13.6546 108.05 15.3101H116.02ZM117.833 20.433H121.184C120.898 22.7533 119.969 24.5847 118.398 25.9274C116.839 27.257 114.838 27.9218 112.396 27.9218C109.356 27.9218 106.933 27.0028 105.128 25.1648C103.322 23.3138 102.419 20.8436 102.419 17.7542C102.419 14.7039 103.328 12.2467 105.147 10.3827C106.979 8.50559 109.395 7.56704 112.396 7.56704C115.293 7.56704 117.501 8.473 119.021 10.2849C120.541 12.0838 121.301 14.7039 121.301 18.1452H107.856C107.934 20.2831 108.382 21.9125 109.2 23.0335C110.032 24.1546 111.194 24.7151 112.688 24.7151C114.221 24.7151 115.377 24.3892 116.157 23.7374C116.949 23.0726 117.508 21.9711 117.833 20.433Z" fill="#1E398D"/>
        <path d="M143.535 11.5363C144.73 10.1806 145.847 9.20298 146.886 8.60335C147.926 8.00372 149.017 7.70391 150.16 7.70391C151.017 7.70391 151.81 7.84078 152.537 8.11452C153.265 8.37523 153.856 8.74022 154.311 9.2095C154.791 9.73091 155.129 10.3501 155.324 11.067C155.532 11.7709 155.636 12.9702 155.636 14.6648V22.1145C155.636 23.1443 155.785 23.8026 156.084 24.0894C156.383 24.3762 156.987 24.5196 157.896 24.5196H159.026V27.1983H148.133V24.5196H148.562C149.484 24.5196 150.102 24.3762 150.413 24.0894C150.725 23.7896 150.881 23.1313 150.881 22.1145V14.6648C150.881 13.1266 150.68 12.0968 150.277 11.5754C149.887 11.041 149.186 10.7737 148.172 10.7737C146.951 10.7737 145.906 11.2756 145.035 12.2793C144.165 13.2831 143.73 14.5344 143.73 16.0335V22.1145C143.73 23.1443 143.879 23.8026 144.178 24.0894C144.49 24.3762 145.1 24.5196 146.009 24.5196H146.497V27.1983H136.227V24.5196H136.676C137.598 24.5196 138.215 24.3762 138.527 24.0894C138.839 23.7896 138.994 23.1313 138.994 22.1145V14.6648C138.994 13.1266 138.793 12.0968 138.39 11.5754C137.988 11.041 137.28 10.7737 136.266 10.7737C135.045 10.7737 133.999 11.2756 133.129 12.2793C132.272 13.2831 131.843 14.5344 131.843 16.0335V22.1145C131.843 23.1443 131.992 23.8026 132.291 24.0894C132.603 24.3762 133.214 24.5196 134.123 24.5196H134.591V27.1983H123.503V24.5196H124.828C125.737 24.5196 126.341 24.3762 126.64 24.0894C126.939 23.8026 127.088 23.1443 127.088 22.1145V13.3743C127.088 12.3445 126.939 11.6927 126.64 11.419C126.341 11.1322 125.737 10.9888 124.828 10.9888H123.503V8.2905H131.843V11.4972C132.869 10.1676 133.896 9.20298 134.922 8.60335C135.961 8.00372 137.104 7.70391 138.351 7.70391C139.637 7.70391 140.709 8.02328 141.567 8.66201C142.437 9.30075 143.093 10.2588 143.535 11.5363Z" fill="#1E398D"/>
        <path d="M174.479 15.3101C174.362 13.5633 174.005 12.2858 173.407 11.4777C172.822 10.6695 171.958 10.2654 170.815 10.2654C169.646 10.2654 168.691 10.6955 167.951 11.5559C167.223 12.4032 166.743 13.6546 166.509 15.3101H174.479ZM176.291 20.433H179.642C179.357 22.7533 178.428 24.5847 176.856 25.9274C175.297 27.257 173.296 27.9218 170.854 27.9218C167.814 27.9218 165.392 27.0028 163.586 25.1648C161.78 23.3138 160.877 20.8436 160.877 17.7542C160.877 14.7039 161.787 12.2467 163.605 10.3827C165.437 8.50559 167.853 7.56704 170.854 7.56704C173.751 7.56704 175.96 8.473 177.48 10.2849C178.999 12.0838 179.759 14.7039 179.759 18.1452H166.314C166.392 20.2831 166.84 21.9125 167.659 23.0335C168.49 24.1546 169.653 24.7151 171.147 24.7151C172.679 24.7151 173.836 24.3892 174.615 23.7374C175.408 23.0726 175.966 21.9711 176.291 20.433Z" fill="#1E398D"/>
        <path d="M181.961 27.1983V24.5196H183.286C184.196 24.5196 184.8 24.3762 185.099 24.0894C185.397 23.8026 185.547 23.1443 185.547 22.1145V13.3743C185.547 12.3445 185.397 11.6927 185.099 11.419C184.8 11.1322 184.196 10.9888 183.286 10.9888H181.961V8.2905H190.379V11.6145C191.419 10.311 192.484 9.33333 193.575 8.68156C194.679 8.02979 195.796 7.70391 196.927 7.70391C199.096 7.70391 200.655 8.23836 201.603 9.30726C202.552 10.3762 203.026 12.162 203.026 14.6648V22.1145C203.026 23.1443 203.175 23.8026 203.474 24.0894C203.786 24.3762 204.396 24.5196 205.306 24.5196H206.475V27.1983H195.134V24.5196H195.933C196.842 24.5196 197.446 24.3762 197.745 24.0894C198.057 23.8026 198.213 23.1443 198.213 22.1145V14.6648C198.213 13.27 197.979 12.2728 197.511 11.6732C197.057 11.0736 196.303 10.7737 195.251 10.7737C193.744 10.7737 192.555 11.3473 191.685 12.4944C190.815 13.6415 190.379 15.2188 190.379 17.2263V22.1145C190.379 23.1443 190.529 23.8026 190.827 24.0894C191.139 24.3762 191.75 24.5196 192.659 24.5196H193.458V27.1983H181.961Z" fill="#1E398D"/>
        <path d="M210.996 21.2737V11.067H207.878V8.46648C209.658 8.16667 210.97 7.43668 211.814 6.27654C212.671 5.10335 213.178 3.29143 213.334 0.840782H215.887V8.2905H221.089V11.067H215.809V21.8408C215.809 22.7663 215.913 23.392 216.12 23.7179C216.328 24.0307 216.705 24.1871 217.251 24.1871C217.926 24.1871 218.394 23.8678 218.654 23.229C218.913 22.5903 219.043 21.2868 219.043 19.3184V18.2626H221.635C221.648 18.4711 221.655 18.6993 221.655 18.9469C221.668 19.1946 221.674 19.54 221.674 19.9832C221.674 22.7598 221.219 24.7737 220.31 26.0251C219.414 27.2635 217.959 27.8827 215.945 27.8827C214.217 27.8827 212.957 27.3547 212.165 26.2989C211.385 25.243 210.996 23.568 210.996 21.2737Z" fill="#1E398D"/>
        <path d="M236.825 5.84637C236.825 4.7514 236.682 4.06704 236.396 3.7933C236.11 3.50652 235.376 3.36313 234.194 3.36313H232.323V0.332402H246.412V3.36313H245.204C244.022 3.36313 243.288 3.50652 243.002 3.7933C242.716 4.06704 242.573 4.7514 242.573 5.84637V18.6341C242.573 20.5633 242.417 22.0233 242.105 23.014C241.794 24.0047 241.274 24.878 240.547 25.6341C239.819 26.3901 238.858 26.9702 237.663 27.3743C236.467 27.7914 235.149 28 233.707 28C231.213 28 229.173 27.3743 227.588 26.1229C226.003 24.8585 225.211 23.2551 225.211 21.3128C225.211 20.0615 225.542 19.0838 226.205 18.3799C226.88 17.6629 227.803 17.3045 228.972 17.3045C229.907 17.3045 230.674 17.5978 231.271 18.1844C231.869 18.7709 232.167 19.5205 232.167 20.433C232.167 21.3194 231.94 22.0363 231.485 22.5838C231.044 23.1182 230.375 23.4963 229.478 23.7179C229.985 24.135 230.485 24.4413 230.979 24.6369C231.485 24.8194 232.038 24.9106 232.635 24.9106C234.181 24.9106 235.266 24.5196 235.889 23.7374C236.513 22.9423 236.825 21.4953 236.825 19.3966V5.84637Z" fill="#1E398D"/>
        <path d="M253.875 17.7542C253.875 20.1918 254.304 22.0168 255.161 23.229C256.018 24.4283 257.318 25.0279 259.058 25.0279C260.799 25.0279 262.105 24.4218 262.975 23.2095C263.845 21.9972 264.281 20.1788 264.281 17.7542C264.281 15.3035 263.845 13.4721 262.975 12.2598C262.105 11.0345 260.799 10.4218 259.058 10.4218C257.331 10.4218 256.031 11.0345 255.161 12.2598C254.304 13.4721 253.875 15.3035 253.875 17.7542ZM248.438 17.7542C248.438 14.6778 249.406 12.2142 251.342 10.3631C253.277 8.49907 255.85 7.56704 259.058 7.56704C262.28 7.56704 264.859 8.49907 266.794 10.3631C268.743 12.2272 269.717 14.6909 269.717 17.7542C269.717 20.8045 268.743 23.2551 266.794 25.1061C264.859 26.9572 262.28 27.8827 259.058 27.8827C255.85 27.8827 253.277 26.9572 251.342 25.1061C249.406 23.2551 248.438 20.8045 248.438 17.7542Z" fill="#1E398D"/>
        <path d="M287.859 17.7933C287.859 15.5251 287.482 13.8371 286.729 12.729C285.988 11.621 284.851 11.067 283.319 11.067C281.903 11.067 280.792 11.6601 279.986 12.8464C279.181 14.0326 278.778 15.6816 278.778 17.7933C278.778 19.905 279.181 21.5605 279.986 22.7598C280.805 23.959 281.916 24.5587 283.319 24.5587C284.851 24.5587 285.988 23.9981 286.729 22.8771C287.482 21.7561 287.859 20.0615 287.859 17.7933ZM278.174 24.7542L276.576 27.3939H273.946V5.4162C273.946 4.3864 273.796 3.72812 273.498 3.44134C273.199 3.15456 272.588 3.01117 271.666 3.01117H270.828V0.332402H278.778V11.2626C279.519 9.9851 280.409 9.05307 281.448 8.46648C282.5 7.86685 283.773 7.56704 285.267 7.56704C287.735 7.56704 289.69 8.473 291.132 10.2849C292.574 12.0838 293.295 14.5475 293.295 17.676C293.295 20.8045 292.561 23.2877 291.093 25.1257C289.626 26.9637 287.644 27.8827 285.15 27.8827C283.721 27.8827 282.435 27.6285 281.292 27.1201C280.149 26.6117 279.11 25.8231 278.174 24.7542Z" fill="#1E398D"/>
        <path d="M295.907 27.433V19.7095H298.829C299.206 21.4302 299.94 22.7467 301.031 23.6592C302.136 24.5717 303.519 25.0279 305.182 25.0279C306.195 25.0279 307.007 24.8063 307.618 24.3631C308.228 23.9069 308.534 23.3007 308.534 22.5447C308.534 21.9451 308.287 21.4628 307.793 21.0978C307.299 20.7197 306.429 20.3743 305.182 20.0614L302.22 19.3575C300.155 18.8492 298.622 18.1192 297.621 17.1676C296.621 16.203 296.121 14.9842 296.121 13.5112C296.121 11.7384 296.79 10.3045 298.128 9.2095C299.466 8.11452 301.233 7.56704 303.428 7.56704C304.467 7.56704 305.455 7.72998 306.39 8.05587C307.325 8.36871 308.235 8.85102 309.118 9.50279L309.995 8.01676H312.275V15.0168H309.566C309.164 13.5698 308.449 12.4423 307.423 11.6341C306.397 10.8259 305.169 10.4218 303.74 10.4218C302.792 10.4218 302.045 10.6238 301.499 11.0279C300.966 11.419 300.7 11.9665 300.7 12.6704C300.7 13.1788 300.882 13.6024 301.246 13.9413C301.609 14.2672 302.175 14.5214 302.941 14.7039L306.565 15.5447C308.904 16.1052 310.606 16.8808 311.671 17.8715C312.736 18.8622 313.269 20.1527 313.269 21.743C313.269 23.6462 312.593 25.1453 311.242 26.2402C309.891 27.3352 308.027 27.8827 305.65 27.8827C304.467 27.8827 303.376 27.7132 302.376 27.3743C301.376 27.0484 300.343 26.5074 299.278 25.7514L298.557 27.433H295.907Z" fill="#1E398D"/>
        <path d="M318.048 24.9106C318.048 24.0894 318.334 23.392 318.906 22.8184C319.49 22.2449 320.198 21.9581 321.03 21.9581C321.874 21.9581 322.576 22.2449 323.134 22.8184C323.706 23.379 323.992 24.0763 323.992 24.9106C323.992 25.7579 323.706 26.4683 323.134 27.0419C322.576 27.6024 321.874 27.8827 321.03 27.8827C320.198 27.8827 319.49 27.5959 318.906 27.0223C318.334 26.4488 318.048 25.7449 318.048 24.9106Z" fill="#6380B6"/>
        <path d="M343.4 19.3575H346.479C346.336 22.1862 345.563 24.3175 344.16 25.7514C342.757 27.1722 340.724 27.8827 338.061 27.8827C335.086 27.8827 332.728 26.9702 330.987 25.1452C329.259 23.3203 328.395 20.8566 328.395 17.7542C328.395 14.743 329.357 12.2924 331.279 10.4022C333.215 8.5121 335.729 7.56703 338.82 7.56703C341.12 7.56703 343.004 8.12104 344.471 9.22905C345.939 10.3371 346.673 11.7318 346.673 13.4134C346.673 14.4562 346.381 15.2905 345.797 15.9162C345.225 16.5289 344.452 16.8352 343.478 16.8352C342.607 16.8352 341.912 16.5875 341.393 16.0922C340.873 15.5838 340.613 14.906 340.613 14.0587C340.613 13.433 340.828 12.8985 341.256 12.4553C341.685 11.9991 342.244 11.7318 342.932 11.6536C342.334 11.1583 341.724 10.7998 341.1 10.5782C340.477 10.3436 339.782 10.2263 339.015 10.2263C337.392 10.2263 336.118 10.8845 335.196 12.2011C334.287 13.5177 333.832 15.3557 333.832 17.7151C333.832 19.905 334.248 21.5475 335.079 22.6425C335.911 23.7244 337.158 24.2654 338.82 24.2654C340.172 24.2654 341.224 23.8678 341.977 23.0726C342.731 22.2644 343.205 21.0261 343.4 19.3575Z" fill="#6380B6"/>
        <path d="M354.37 17.7542C354.37 20.1918 354.799 22.0168 355.656 23.229C356.514 24.4283 357.813 25.0279 359.554 25.0279C361.294 25.0279 362.6 24.4218 363.47 23.2095C364.341 21.9972 364.776 20.1788 364.776 17.7542C364.776 15.3035 364.341 13.4721 363.47 12.2598C362.6 11.0344 361.294 10.4218 359.554 10.4218C357.826 10.4218 356.527 11.0344 355.656 12.2598C354.799 13.4721 354.37 15.3035 354.37 17.7542ZM348.934 17.7542C348.934 14.6778 349.902 12.2141 351.837 10.3631C353.773 8.49906 356.345 7.56703 359.554 7.56703C362.775 7.56703 365.354 8.49906 367.29 10.3631C369.238 12.2272 370.213 14.6909 370.213 17.7542C370.213 20.8045 369.238 23.2551 367.29 25.1061C365.354 26.9572 362.775 27.8827 359.554 27.8827C356.345 27.8827 353.773 26.9572 351.837 25.1061C349.902 23.2551 348.934 20.8045 348.934 17.7542Z" fill="#6380B6"/>
        <path d="M392.271 11.5363C393.466 10.1806 394.583 9.20297 395.622 8.60335C396.662 8.00372 397.753 7.70391 398.896 7.70391C399.754 7.70391 400.546 7.84078 401.273 8.11452C402.001 8.37523 402.592 8.74022 403.047 9.20949C403.527 9.73091 403.865 10.3501 404.06 11.067C404.268 11.7709 404.372 12.9702 404.372 14.6648V22.1145C404.372 23.1443 404.521 23.8026 404.82 24.0894C405.119 24.3762 405.723 24.5195 406.632 24.5195H407.762V27.1983H396.87V24.5195H397.298C398.221 24.5195 398.838 24.3762 399.15 24.0894C399.461 23.7896 399.617 23.1313 399.617 22.1145V14.6648C399.617 13.1266 399.416 12.0968 399.013 11.5754C398.623 11.041 397.922 10.7737 396.909 10.7737C395.687 10.7737 394.642 11.2756 393.771 12.2793C392.901 13.283 392.466 14.5344 392.466 16.0335V22.1145C392.466 23.1443 392.615 23.8026 392.914 24.0894C393.226 24.3762 393.836 24.5195 394.746 24.5195H395.233V27.1983H384.964V24.5195H385.412C386.334 24.5195 386.951 24.3762 387.263 24.0894C387.575 23.7896 387.731 23.1313 387.731 22.1145V14.6648C387.731 13.1266 387.529 12.0968 387.127 11.5754C386.724 11.041 386.016 10.7737 385.003 10.7737C383.781 10.7737 382.736 11.2756 381.865 12.2793C381.008 13.283 380.579 14.5344 380.579 16.0335V22.1145C380.579 23.1443 380.729 23.8026 381.027 24.0894C381.339 24.3762 381.95 24.5195 382.859 24.5195H383.327V27.1983H372.239V24.5195H373.564C374.474 24.5195 375.078 24.3762 375.376 24.0894C375.675 23.8026 375.825 23.1443 375.825 22.1145V13.3743C375.825 12.3445 375.675 11.6927 375.376 11.419C375.078 11.1322 374.474 10.9888 373.564 10.9888H372.239V8.2905H380.579V11.4972C381.605 10.1676 382.632 9.20297 383.658 8.60335C384.697 8.00372 385.84 7.70391 387.088 7.70391C388.374 7.70391 389.445 8.02327 390.303 8.66201C391.173 9.30074 391.829 10.2588 392.271 11.5363Z" fill="#6380B6"/>
        <path d="M406.947 10.7176V8.07147H406.005V7.53267H408.527V8.07147H407.587V10.7176H406.947Z" fill="#1E398D"/>
        <path d="M408.934 10.7176V7.53267H409.893L410.469 9.70524L411.039 7.53267H412V10.7176H411.405V8.21051L410.775 10.7176H410.158L409.53 8.21051V10.7176H408.934Z" fill="#1E398D"/>
    </svg>
  )
}

export default MainLogo