import CommonLink from 'components/CommonLink/CommonLink'
import React, { Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import { useGetCitiesInStateQuery } from 'services/api/jobsInParticularStateApi'

const CitiesList = ({state, category}) => {
    const { data } = useGetCitiesInStateQuery({state})
    const location = useLocation();
    const mofifiedState = state.replace(/\//g, '').replace(/\s+/g, '-')
  return (
    <div className='citiesList'>
        <div className='citiesList_container'>
            {data?.cities.map(item => <Fragment key={item.id}><CommonLink text={item.name} path={location.pathname.includes(category) ? item.link +`&keywords=${category}` : item.link}/></Fragment>)}
        </div>
        <div className='citiesList_other'>
            <p>
                If your city is not included here in the top 20, <a href={
                    `https://search.retirementjobs.com/jobs?locale=en&sort=relevance&country=United+States&state=${mofifiedState}&city=&zip=&latitude=&longitude=&keywords=${category}&city_state_zip=${mofifiedState}&job_id=`
                } rel="noreferrer">view all jobs</a>
            </p>
        </div>
    </div>
  )
}

export default CitiesList