import React from 'react'
import './CommonListItem.scss'
import PriceLabel from './_components/PriceLabel'
import TopicLabel from './_components/TopicLabel'
import PopularLabel from './_components/PopularLabel'
import HTMLRenderer from 'components/HTMLRenderer/HTMLRenderer'
import WebinarsTitle from './_components/WebinarsTitle'
import { Link } from 'react-router-dom'

const CommonListItem = ({ topic, linked, path, title, description, price, popular, time, date, shortText, addClass, hover }) => {
  return (
    <>
    {linked
      ? 
        <Link className='listItemLink' to={path}>
          <div className={`сommonListItem ${addClass} ${hover ? 'hover' : ''}`}>
            <div className='сommonListItem_header'>
              {topic && <TopicLabel topic={topic}/>}
              {date && <WebinarsTitle title={title} date={date} time={time}/>}

              {!date && 
                <div className='сommonListItem_header__wrapper'>
                  <div>
                    <h4 className='сommonListItem_header__text'>{title}</h4>
                    {price && <PriceLabel price={price}/>}
                  </div>
                  {popular && <PopularLabel/>}
                </div>
              }

            </div>
            <div className='сommonListItem_content'>
              <p className='сommonListItem_content__text'>
                <HTMLRenderer htmlString={description} />
              </p>
            </div>
          </div>
        </Link>
      
      : <div className={`сommonListItem ${addClass} ${hover ? 'hover' : ''}`}>
          <div className='сommonListItem_header'>
            {topic && <TopicLabel topic={topic}/>}
            {date && <WebinarsTitle title={title} date={date} time={time}/>}

            {!date && 
              <div className='сommonListItem_header__wrapper'>
                <div>
                  <h4 className='сommonListItem_header__text'>{title}</h4>
                  {price && <PriceLabel price={price}/>}
                </div>
                {popular && <PopularLabel/>}
              </div>
            }

          </div>
          <div className='сommonListItem_content'>
            <p className='сommonListItem_content__text'>
              <HTMLRenderer htmlString={description} />
            </p>
          </div>
      </div>
      }
    </>
  )
}

export default CommonListItem