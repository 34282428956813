import React, { useEffect } from 'react'
import { useGetMetaDataQuery } from 'services/api/metaDataApi'
import { scrollToTop } from 'services/helpers'
import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'
import JobByCategories from 'components/JobByCategories/JobByCategories'
import JobsStates from './_components/JobsStates/JobsStates'
import SEO from 'components/SEOComponent/SEO'

const JobsByState = () => {
  const { data } = useGetMetaDataQuery('jobs_by_state')
  useEffect(() => {
    scrollToTop()
  },[data])

  return (
    <ContentWrapper >
        <SEO title={data?.data_attributes?.meta_title} description={data?.data_attributes?.meta_description} keywords={data?.data_attributes?.meta_keywords}/>
        <JobsStates/>
        <JobByCategories/>
    </ContentWrapper>
  )
}

export default JobsByState