import { createSlice } from '@reduxjs/toolkit';

const filterSlice = createSlice({
  name: 'filterReducer',
  initialState: { 
    letterFilter: '',
    ratingFilter: '',
    nameFilter: ''
   },
  reducers: {
    setRatingData: (state, action) => {
      state.ratingFilter = action.payload;
    },
    setLetterData: (state, action) => {
      state.letterFilter = action.payload;
    },
    setNameFilter: (state, action) => {
      state.nameFilter = action.payload;
    }
  },
});

export const { setRatingData, setLetterData, setNameFilter } = filterSlice.actions;
export default filterSlice.reducer;
