import React from "react";
import "./InputStyles.scss";

export const Input = ({
  label = "",
  required,
  disabled,
  placeholder = "",
  type = "text",
  messageText = "",
  error,
  onChange,
  addIcon,
  addClass,
  name,
  value
}) => {
  const Message = () => (
    <p className={`message ${error ? " error" : ""}`}>{messageText}</p>
  );

  return (
    <div className={`input-group ${addClass}`}>
      <label>
        {label}
        {required ? <span>*</span> : ""}
      </label>
      <input
        type={type}
        placeholder={placeholder}
        name={name}
        className={`
					${error ? "error" : ""} 
					${required ? "required" : ""} 
					${disabled ? "disabled" : ""}
				`}
        disabled={disabled}
        onChange={(e) => onChange(e.target)}
        value={value}
      />
      {messageText && <Message />}
      {addIcon && <span className={addIcon}></span>}
    </div>
  );
};

export default Input;
