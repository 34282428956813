/* eslint-disable */
import React, { useEffect, useRef } from "react"
import './Modal.scss'
import closeIcon from 'assets/images/close-shape.svg'

const Modal = ({ onClose, children, header }) => {
  const modalRef = useRef(null);

  const handleOutsideClick = (event) => {
    if(!modalRef.current.contains(event.target)) {
      onClose()
    }
  }

  return (
    <div className="modal-overlay" onClick={e => handleOutsideClick(e)}>
      <div className="modal" ref={modalRef}>
        <div className="modal-content">
          <div className="modal-content--header" onClick={onClose}>
            <h4>{header}</h4>
            <span onClick={onClose}>
              <img src={closeIcon} alt="close"/>
            </span>
          </div>
          <div className="modal-content--body">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
