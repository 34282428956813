import React from 'react'

const RatingStars = ({rating}) => {
    return (
        <svg width="124" height="20" viewBox="0 0 124 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.7208 0.97044C9.12344 -0.323481 10.8766 -0.32348 11.2792 0.970441L12.7178 5.59356C12.8979 6.17221 13.4143 6.564 13.997 6.564H18.6524C19.9554 6.564 20.4971 8.30496 19.443 9.10464L15.6767 11.9619C15.2053 12.3195 15.008 12.9534 15.1881 13.5321L16.6267 18.1552C17.0293 19.4491 15.611 20.5251 14.5569 19.7254L10.7906 16.8682C10.3192 16.5105 9.68083 16.5105 9.20941 16.8682L5.44311 19.7254C4.389 20.5251 2.97069 19.4491 3.37333 18.1552L4.81193 13.5321C4.99199 12.9534 4.79473 12.3195 4.32332 11.9619L0.557018 9.10464C-0.497098 8.30495 0.0446463 6.564 1.3476 6.564H6.00301C6.58571 6.564 7.10214 6.17221 7.28221 5.59356L8.7208 0.97044Z" fill={rating >= 1 ? "#FD8848" : "#D9D9D9"}/>
            <path d="M34.7208 0.97044C35.1234 -0.323481 36.8766 -0.32348 37.2792 0.970441L38.7178 5.59356C38.8979 6.17221 39.4143 6.564 39.997 6.564H44.6524C45.9554 6.564 46.4971 8.30496 45.443 9.10464L41.6767 11.9619C41.2053 12.3195 41.008 12.9534 41.1881 13.5321L42.6267 18.1552C43.0293 19.4491 41.611 20.5251 40.5569 19.7254L36.7906 16.8682C36.3192 16.5105 35.6808 16.5105 35.2094 16.8682L31.4431 19.7254C30.389 20.5251 28.9707 19.4491 29.3733 18.1552L30.8119 13.5321C30.992 12.9534 30.7947 12.3195 30.3233 11.9619L26.557 9.10464C25.5029 8.30495 26.0446 6.564 27.3476 6.564H32.003C32.5857 6.564 33.1021 6.17221 33.2822 5.59356L34.7208 0.97044Z" fill={rating >= 2 ? "#FD8848" : "#D9D9D9"}/>
            <path d="M60.7208 0.97044C61.1234 -0.323481 62.8766 -0.32348 63.2792 0.970441L64.7178 5.59356C64.8979 6.17221 65.4143 6.564 65.997 6.564H70.6524C71.9554 6.564 72.4971 8.30496 71.443 9.10464L67.6767 11.9619C67.2053 12.3195 67.008 12.9534 67.1881 13.5321L68.6267 18.1552C69.0293 19.4491 67.611 20.5251 66.5569 19.7254L62.7906 16.8682C62.3192 16.5105 61.6808 16.5105 61.2094 16.8682L57.4431 19.7254C56.389 20.5251 54.9707 19.4491 55.3733 18.1552L56.8119 13.5321C56.992 12.9534 56.7947 12.3195 56.3233 11.9619L52.557 9.10464C51.5029 8.30495 52.0446 6.564 53.3476 6.564H58.003C58.5857 6.564 59.1021 6.17221 59.2822 5.59356L60.7208 0.97044Z" fill={rating >= 3 ? "#FD8848" : "#D9D9D9"}/>
            <path d="M86.7208 0.97044C87.1234 -0.323481 88.8766 -0.32348 89.2792 0.970441L90.7178 5.59356C90.8979 6.17221 91.4143 6.564 91.997 6.564H96.6524C97.9554 6.564 98.4971 8.30496 97.443 9.10464L93.6767 11.9619C93.2053 12.3195 93.008 12.9534 93.1881 13.5321L94.6267 18.1552C95.0293 19.4491 93.611 20.5251 92.5569 19.7254L88.7906 16.8682C88.3192 16.5105 87.6808 16.5105 87.2094 16.8682L83.4431 19.7254C82.389 20.5251 80.9707 19.4491 81.3733 18.1552L82.8119 13.5321C82.992 12.9534 82.7947 12.3195 82.3233 11.9619L78.557 9.10464C77.5029 8.30495 78.0446 6.564 79.3476 6.564H84.003C84.5857 6.564 85.1021 6.17221 85.2822 5.59356L86.7208 0.97044Z" fill={rating >= 4 ? "#FD8848" : "#D9D9D9"}/>
            <path d="M112.721 0.97044C113.123 -0.323481 114.877 -0.32348 115.279 0.970441L116.718 5.59356C116.898 6.17221 117.414 6.564 117.997 6.564H122.652C123.955 6.564 124.497 8.30496 123.443 9.10464L119.677 11.9619C119.205 12.3195 119.008 12.9534 119.188 13.5321L120.627 18.1552C121.029 19.4491 119.611 20.5251 118.557 19.7254L114.791 16.8682C114.319 16.5105 113.681 16.5105 113.209 16.8682L109.443 19.7254C108.389 20.5251 106.971 19.4491 107.373 18.1552L108.812 13.5321C108.992 12.9534 108.795 12.3195 108.323 11.9619L104.557 9.10464C103.503 8.30495 104.045 6.564 105.348 6.564H110.003C110.586 6.564 111.102 6.17221 111.282 5.59356L112.721 0.97044Z" fill={rating >= 5 ? "#FD8848" : "#D9D9D9"}/>
        </svg>
    )
}

export default RatingStars