import React from 'react'
import './AFCertified.scss'
import AFLogo from 'assets/images/AFSealLogoNew.svg'

const AFCertified = () => {
  return (
    <div className='AFCertified'>
        <div className="AFCertified_title">
            <h3>Certified Age Friendly Employers</h3>
        </div>
        <div className="AFCertified_content">
            <p>
                The Age Friendly Institute Certified Age Friendly Employer (CAFE)™ program is an initiative to identify those organizations that are committed to being the best places to work for employees at or above age 50.
            </p>
        </div>
        <div className="AFCertified_logo">
            <img src={AFLogo} alt='af-seal'/>
        </div>
    </div>
  )
}

export default AFCertified