/* eslint-disable */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseURL } from 'constants/api'

export const trendingTopicsApi = createApi({
    reducerPath: 'trendingTopicsApi',
    baseQuery: fetchBaseQuery({ baseUrl: baseURL}),
    endpoints: (build) => ({
        getTrendingTopics: build.query({
            query: () => ({
                url: `home_page_topic_data`,
                method: 'GET'
            })
        })
    })
})

export const { useGetTrendingTopicsQuery } = trendingTopicsApi