import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'
import SEO from 'components/SEOComponent/SEO'
import React from 'react'
import { useGetMetaDataQuery } from 'services/api/metaDataApi'
import ReviewsList from './_components/ReviewsList/ReviewsList'

const ReviewsPage = () => {
  const { data } = useGetMetaDataQuery('reviews')

  return (
    <>
        <SEO title={data?.data_attributes?.meta_title} description={data?.data_attributes?.meta_description} keywords={data?.data_attributes?.meta_keywords}/>
        <ContentWrapper>
            <ReviewsList/>
        </ContentWrapper>
    </>
  )
}

export default ReviewsPage