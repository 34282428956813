import React from 'react'
import { useDispatch } from 'react-redux';
import { setFeedbackModal, setSelectedCompany } from 'redux/reducers/reviewsReducer';
import RatingStarsHalfed from 'components/RatingStarsHalfed/RatingStarsHalfed';

const CompanyHeaderRating = ({ total_rating, mobile = false, companyName, companyID }) => {
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(setFeedbackModal(true))
    dispatch(setSelectedCompany({ name: companyName, id: companyID }))
  }
  return (
    <div className={`company_rating ${mobile ? 'mobile' : ''}`}>
        <div className='company_rating-rating'>
          <RatingStarsHalfed value={total_rating}/>
          <span>{`${total_rating} of 5`}</span>
        </div>
        <div className='company_add-review'>
            <span onClick={handleClick} className='company_add-review__button'>Add review</span>
        </div>
    </div>
  )
}

export default CompanyHeaderRating