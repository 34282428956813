import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { scrollToTop, capitalizeWords } from 'services/helpers'
import StateJobInfo from './_components/StateJobInfo/StateJobInfo'
import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'
import SEO from 'components/SEOComponent/SEO'

const JobsInState = () => {

    const location = useLocation()
    const state = location.pathname.split('/')[2];
    useEffect(() => {
        scrollToTop()
    }, [location])

    return (
        <ContentWrapper>
            <SEO title={`Jobs for Seniors in ${capitalizeWords(state)} | RetirementJobs.com`} description={`Find jobs for seniors in ${capitalizeWords(state)} by city. The best source for workers over 50 looking for jobs in ${capitalizeWords(state)}. View all openings by city.`} keywords={''}/>
            <StateJobInfo state={state}/>
        </ContentWrapper>
    )
}

export default JobsInState