import React, { useState, useRef } from 'react';
import './WebinarsForm.scss';

import Input from 'components/Input/Input';
import CommonButton from 'components/CommonButton/CommonButton';
import FormError from 'components/FormError/FormError';
import { validateForm } from '../validations';
import API from 'services/api';
import Popup from 'reactjs-popup';
import SuccessRequestPopup from 'components/SuccessRequestPopup/SuccessRequestPopup';
import FormPreloader from 'assets/images/FormPreloader';

const WebinarsForm = ({ title }) => {
  const [formData, setFormData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    zip_code: ''
  });
  const [requestError, setRequestError] = useState(false);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [mailErrors, setMailErrors] = useState('');
  const [firstNameErrors, setFirstNameErrors] = useState('');
  const [lastNameErrors, setLastNameErrors] = useState('');
  const [zipCodeError, setZipCodeError] = useState('');
  const [onLoad, setOnload] = useState(false);
  const formRef = useRef(null);
  

  const validateFormData = (formData) => {
    let hasErrors = false;
    const formDataKeys = Object.keys(formData);
    const errors = {};

    formDataKeys.forEach(key => {
      const error = validateForm(key, formData[key]);
      errors[key] = error;
      if (error) {
        hasErrors = true;
      }
    });

    return { hasErrors, errors };
  };

  const handleChange = (e) => {
    const { name, value } = e;
    const error = validateForm(name, value);

    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));

    switch (name) {
      case 'email':
        setMailErrors(error);
        break;
      case 'first_name':
        setFirstNameErrors(error);
        break;
      case 'last_name':
        setLastNameErrors(error);
        break;
      case 'zip_code':
        setZipCodeError(error);
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { hasErrors, errors } = validateFormData(formData);
    setMailErrors(errors.email);
    setFirstNameErrors(errors.first_name);
    setLastNameErrors(errors.last_name);
    setZipCodeError(errors.zip_code);

    if (!hasErrors) {
      setOnload(true)
      try {
        const response = await API.post('/send_webinars', { member: formData});
        if(response.status === 200) {
          setRequestSuccess(true);
          setOnload(false)
          setTimeout(() => {
            setFormData({
              email: '',
              first_name: '',
              last_name: '',
              zip_code: ''
            });
            setRequestSuccess(false);
          }, 3000);
        }
      } catch (error) {
        console.error(error);
        setRequestError(true);
        if(window.innerWidth < 780) {
          formRef.current.scrollIntoView({ block: 'start' });
        }
        setTimeout(() => {
          setRequestError(false);
        }, 5000);
      }
    }
  };

  return (
    <div className='commonForm' ref={formRef} >
      <Popup position='center center' open={requestSuccess}>
        <SuccessRequestPopup
          onClose={() => setRequestSuccess(false)}
          title='Request sent'
          description='Thank you for submitting your request. You will now receive email alerts about upcoming Webinars.'
          buttonText='Ok'
        />
      </Popup>
      <h3 className='commonForm_header'>{title}</h3>
      {requestError && <FormError />}
      <form onSubmit={handleSubmit}>
        <Input
          type='email'
          label='Email Address'
          addClass='commonFormInput'
          required={true}
          messageText={mailErrors}
          error={!!mailErrors}
          onChange={handleChange}
          name='email'
          value={formData.email}
        />

        <Input
          label='First Name'
          addClass='commonFormInput'
          required={true}
          onChange={handleChange}
          messageText={firstNameErrors}
          error={!!firstNameErrors}
          name='first_name'
          value={formData.first_name}
        />

        <Input
          label='Last Name'
          addClass='commonFormInput'
          required={true}
          onChange={handleChange}
          messageText={lastNameErrors}
          error={!!lastNameErrors}
          name='last_name'
          value={formData.last_name}
        />

        <Input
          label='Zip Code'
          addClass='commonFormInput'
          type='number'
          required={true}
          onChange={handleChange}
          messageText={zipCodeError}
          error={!!zipCodeError}
          name='zip_code'
          value={formData.zip_code}
        />

        <CommonButton type='submit' text={onLoad ? <FormPreloader/> : 'Submit request'} addColor='careerServices' />
      </form>
      <span className='commonForm_agrees'>By submitting this request, you will be opting into Job Alerts, Webinar Invitations, and other Marketing Emails from RetirementJobs.com.</span>
    </div>
  );
};

export default WebinarsForm;
