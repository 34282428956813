export const knowThisEmployer = [
    { label: 'I am currently employed there as a manager or executive', value: 'I am currently employed there as a manager or executive' },
    { label: 'I am currently employed there, not as a manager or executive', value: 'I am currently employed there, not as a manager or executive' },
    { label: 'I was formerly employed there as a manager or executive', value: 'I was formerly employed there as a manager or executive' },
    { label: 'I was formerly employed there, not as a manager or executive', value: 'I was formerly employed there, not as a manager or executive' },
    { label: "I have not worked for this employer, but I'm applying (or have applied) there", value: "I have not worked for this employer, but I'm applying (or have applied) there" },
    { label: 'I have not worked for this employer, but I am (or have been) a customer', value: 'I have not worked for this employer, but I am (or have been) a customer' },
    { label: 'I have not worked for this employer, but I know someone who does/did', value: 'I have not worked for this employer, but I know someone who does/did' },
    { label: 'Other', value: 'Other' }
];

export const haveBeenEmployed = [
    { label: 'Less than 6 months', value: 'Less than 6 months' },
    { label: 'More than 6 months but less than 1 year', value: 'More than 6 months but less than 1 year' },
    { label: '1-3 years', value: '1-3 years' },
    { label: '3-5 years', value: '3-5 years' },
    { label: 'Over 5 years', value: 'Over 5 years' },
    { label: 'Not applicable', value: 'Not applicable' }
];

export const believeAgeBias = [
    { label: 'Declining', value: 'Declining' },
    { label: 'Staying about the same', value: 'Staying about the same' },
    { label: 'Increasing', value: 'Increasing' }
];

export const regardlessOfEmployees = [
    { label: 'Very satisfied', value: 'Very satisfied' },
    { label: 'Satisfied', value: 'Satisfied' },
    { label: 'Neutral', value: 'Neutral' },
    { label: 'Dissatisfied', value: 'Dissatisfied' },
    { label: 'Very dissatisfied', value: 'Very dissatisfied' },
    { label: 'Not applicable', value: 'Not applicable' }
];

export const yesNoList = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 }
];