import React from 'react'
import PaginationArrow from 'assets/images/PaginationArrow';
import './styles.scss'

const PreviousButton = () => {
  return (
    <div className='paginationButtons'>
        <PaginationArrow side={'prev'}/>
        <span>Previous</span>
    </div>
  )
}

export default PreviousButton