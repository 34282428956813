/* eslint-disable */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseURL } from 'constants/api'

export const inTheNewsApi = createApi({
    reducerPath: 'inTheNewsApi',
    baseQuery: fetchBaseQuery({ baseUrl: baseURL}),
    endpoints: (build) => ({
        getInTheNewsArticles: build.query({
            query: ({ page }) => ({
                url: `news_items`,
                method: 'GET',
                params: {
                    page: page
                }
            })
        }),
    })
})

export const { useGetInTheNewsArticlesQuery } = inTheNewsApi;