import React from 'react'

const ArrowDown = () => {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame 12094">
            <path id="Polygon 2" d="M8 11.5L4 7.5L12 7.5L8 11.5Z" fill="#7C7C7D"/>
        </g>
    </svg>
  )
}

export default ArrowDown