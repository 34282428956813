import { React, useState, useEffect } from "react";

import { forJobSeekersList, forEmployersList } from "constants/aboutUsContent";
import { scrollToTop } from "services/helpers";
import RadioButton from "components/RadioButton/RadioButton";
import "./FaqComponent.scss";

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqComponent = () => {
  const [selectedOption, setSelectedOption] = useState("seekers");
  const [faqList, setFaqList] = useState([]);
  
  useEffect(() => {
    scrollToTop()
  }, [])

  useEffect(() => {
    switch (selectedOption) {
      case "seekers":
        setFaqList(forJobSeekersList);
        break;

      case "employers":
        setFaqList(forEmployersList);
        break;

      default:
        break;
    }
  }, [selectedOption]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.value);
  };

  return (
    <>
      <div className="faqComponent">
        <div className="faqComponent_description">
          <h2>FAQs</h2>

          <div className="faqComponent_description_info">
            <p>Please select an option to sort questions</p>

            <div className="faqComponent_description_info_radio">
              <RadioButton
                value="seekers"
                label="For Job Seekers"
                name="radio-faq"
                onOptionChange={handleOptionChange}
                checked={selectedOption === "seekers"}
              />
              <RadioButton
                value="employers"
                label="For Employers"
                name="radio-faq"
                onOptionChange={handleOptionChange}
                checked={selectedOption === "employers"}
              />
            </div>
          </div>
        </div>
        <div className="faqComponent_accordionBlock">
          <Accordion allowZeroExpanded>
            {faqList.map((item, i) => (
              <AccordionItem key={i}>
                <AccordionItemHeading>
                  <AccordionItemButton>{item.question}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>{item.answer}</AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
      </div>
    </>
  );
};

export default FaqComponent;
