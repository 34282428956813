import { Fragment, React, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  aboutUsData,
  productsAndServicesData,
  managementTeamData,
  boardOfDirectorsData,
  advisoryBoardData,
  speakingEngagementsData,
  testimonialsData
} from "constants/aboutUsContent";
import "./TextContent.scss";
import HTMLRenderer from "components/HTMLRenderer/HTMLRenderer";

const locationMappings = {
  "about-us": aboutUsData,
  "products-and-services": productsAndServicesData,
  "management-team": managementTeamData,
  "board-of-directors": boardOfDirectorsData,
  "advisory-board": advisoryBoardData,
  "speaking-engagements": speakingEngagementsData,
  "testimonials": testimonialsData,
};

const TextContent = () => {
  const location = useLocation();
  const [contentObj, setContentObj] = useState(null);
  const [speakingEngagements, setSpeakingEngagements] = useState(false);

  useEffect(() => {
    let locationArr = location.pathname?.split("/");
    window.scrollTo(0, 0);
    setContentObj(locationMappings[locationArr[2]]);
    setSpeakingEngagements(locationArr[2] === "speaking-engagements");
  }, [location]);

  const renderParagraph = (item, i) => (
    <div key={i} className={`textContent_paragraph ${speakingEngagements ? 'speakingEngagements' : ''}`}>
      <h3>{item.title}</h3>
      {item.description && <p>{item.description}</p>}
      {item.expandedDescription && (
        <div>
          {item.expandedDescription.map((item2, j) => (
            <p key={j}>{item2}</p>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <>
      <div className={`textContent ${speakingEngagements ? 'speakingEngagements' : ''}`}>
        <div className="textContent_description">
          <h2>{contentObj?.title}</h2>
          {contentObj?.description.map((item, i) => (
            <p key={i}>{item}</p>
          ))}
          {contentObj?.subtitle && <h3>{contentObj?.subtitle}</h3>}
        </div>

        {contentObj?.basicData &&
          contentObj?.basicData.map(renderParagraph)
        }

        {contentObj?.testimonialsItems &&
          contentObj?.testimonialsItems.map((item, i) => (
            <div key={i} className="textContent_paragraph testimonials">
              <h3>{item.title}</h3>
              {item.description && <p>{item.description}</p>}
              {item.expandedDescription && (
                <div>
                  {item.expandedDescription.map((item2, j) => (
                    <p key={j}>
                      {item2.description}
                      {<p className="textContent_paragraph__author">{item2.author}</p>}
                    </p>
                  ))}
                </div>
              )}
            </div>
          ))
        }

        {contentObj?.expandedData &&
          contentObj?.expandedData.map((item, i) => (
            <Fragment key={i}>
              <h3 className="categoryTitle">{item.sectionTitle}</h3>
              {item.sectionData.map((item2, j) => (
                <div key={j} className="textContent_paragraph">
                  <h4>{item2.title}</h4>
                  {item2.description && <p>{<HTMLRenderer htmlString={item2.description}/>} {item2.linkEmail && <a href={`mailto:${item2.linkEmail}`} target="_blank" rel="noopener noreferrer">{item2.linkEmail}</a>}</p>}
                  {item2.link && <a href={item2.link} target={item2.same ? "_self" : "_blank"} rel="noopener noreferrer">Learn more</a>}
                </div>
              ))}
            </Fragment>
          ))}
        {contentObj?.footerData && (
          <p className="textContent_footer">{contentObj?.footerData}</p>
        )}
      </div>
    </>
  );
};

export default TextContent;
