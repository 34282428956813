import React from 'react'
import PaginationArrow from 'assets/images/PaginationArrow';
import './styles.scss'

const NextButton = () => {
  return (
    <div className='paginationButtons'>
      <span>Next</span>
      <PaginationArrow/>
    </div>
  )
}

export default NextButton