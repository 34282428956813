const emailRegex =
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

const validationMessages = {
  required: "This fiels is required",
  email: "Please enter valid email",
  length: "This field need contain from 2 to 50 symbols",
  messageLength: "This field should contain from 2 to 500 symbols",
  zipCode: "This field need contain 5 symbols",
  phone: "Please enter valid phone",
};
export function emailValidate(email) {
  if (!email.length) {
    return validationMessages.required;
  } else if (!email.match(emailRegex)) {
    return validationMessages.email;
  } else {
    return "";
  }
}

export function nameValidate(name) {
  if (!name.length) {
    return validationMessages.required;
  } else if (name.length < 2 || name.length > 50) {
    return validationMessages.length;
  } else {
    return "";
  }
}

export function messageValidate(message) {
  if (!message.length) {
    return validationMessages.required;
  } else if (message.length < 2 || message.length > 500) {
    return validationMessages.messageLength;
  } else {
    return "";
  }
}

export function zipCodeValidate(code) {
  if(!code?.length) {
    return validationMessages.required;
  } else if(code.length < 5 || code.length > 5 ){
    return validationMessages.zipCode;
  } else {
    return "";
  }
}

export function phoneValidate(phone) {
  if (phone.length === 0) {
    return validationMessages.required
  } else if (!phone.match(/\d/g) && !phone.length >= 10) {
    return validationMessages.phone
  } else {
    return ""
  }
}

export function validateForm(name, value) {
  switch (name) {
    case "email":
      return emailValidate(value);
    case "first_name":
      return nameValidate(value);
    case "last_name":
      return nameValidate(value);
    case "message":
      return messageValidate(value);
    case "zip_code":
      return zipCodeValidate(value);
    case "phone_number":
      return phoneValidate(value)
    default:
      return "";
  }
}
