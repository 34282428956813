/* eslint-disable */
import React, { useEffect, useState } from 'react'
import SEO from 'components/SEOComponent/SEO'
import { useGetMetaDataQuery } from 'services/api/metaDataApi'


const WrapperSEO = ({ location }) => {
    const [selectedLocation, setSelectedLocation] = useState('')
    let pureLocation = location.split("/").pop()

    useEffect(() => {
        switch(pureLocation) {
            case 'about-us': 
                setSelectedLocation('about_us')
                break;
            case 'faq': 
                setSelectedLocation('faqs')
                break;
            case 'contact-us': 
                setSelectedLocation('contact_us')
                break;
            case 'products-and-services': 
                setSelectedLocation('products_and_services')
                break;
            case 'management-team': 
                setSelectedLocation('management_team')
                break;
            case 'board-of-directors': 
                setSelectedLocation('board_of_directors')
                break;
            case 'advisory-board': 
                setSelectedLocation('advisory_board')
                break;
            case 'in-the-news': 
                setSelectedLocation('in_the_news')
                break;
            case 'speaking-engagements': 
                setSelectedLocation('speaking_engagements')
                break;
            case 'testimonials': 
                setSelectedLocation('testimonials')
                break;
            default:
                setSelectedLocation('about_us')
        }
    }, [location])

  const { data } = useGetMetaDataQuery(selectedLocation)

  return (
    <SEO title={data?.data_attributes.meta_title} description={data?.data_attributes.meta_description} keywords={data?.data_attributes.meta_keywords}/>
  )
}

export default WrapperSEO