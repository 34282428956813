export const selectStyles = {
    indicatorSeparator: (styles) => ({
      ...styles,
      display: 'none'
    }),
    control: (styles, state) => ({
      ...styles,
      padding: '5px 12px',
      fontFamily: 'SourceSansPro-Regular',
      fontSize: '18px',
      borderColor: state.isFocused ? 'initial' : styles.borderColor,
      boxShadow: state.isFocused ? 'initial' : styles.boxShadow,
      '&:hover': {
        borderColor: state.isFocused ? 'initial' : styles.borderColor,
      },
    }),
    option: (styles) => ({
      ...styles,
      fontFamily: 'SourceSansPro-Regular',
      fontSize: '18px'
    })
  }