import React, {useEffect} from 'react'
import { useLocation } from 'react-router-dom'

import { useGetCareerAdviceArticleItemQuery } from 'services/api/careerAdviceApi'
import { scrollToTop } from 'services/helpers'
// const IndividualArticle = React.lazy(() => import('./_components/IndividualArticle/IndividualArticle'))
import IndividualArticle from './_components/IndividualArticle/IndividualArticle'
// const ContentWrapper = React.lazy(() => import('HOC/ContentWrapper/ContentWrapper'))
import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'
// const SEO = React.lazy(() => import('components/SEOComponent/SEO'))
import SEO from 'components/SEOComponent/SEO'

const CareerArticlePage = () => {
  const location = useLocation();
  const articleId = location.pathname.split('/')[2]; 
  const { data } = useGetCareerAdviceArticleItemQuery(articleId)
  useEffect(() => {
    scrollToTop()
  },[data])
  
  return (
    <>
      <SEO title={data?.data_attributes.meta_title} description={data?.data_attributes.meta_description} keywords={data?.data_attributes.meta_keywords}/>
      <ContentWrapper>
          <IndividualArticle data={data}/>
      </ContentWrapper>
    </>
  )
}

export default CareerArticlePage