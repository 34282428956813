import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'
import React from 'react'
import ReviewFormPage from './_components/ReviewFormPage'

const ReviewEmployerPage = () => {
  return (
    <ContentWrapper>
        <ReviewFormPage/>
    </ContentWrapper>
  )
}

export default ReviewEmployerPage