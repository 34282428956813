import React, { Fragment } from 'react'
import './JESection.scss'
import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'
import FindJobBlock from 'components/FindJobBlock/FindJobBlock'
import { jobsAndEmpoyersData } from 'constants/textContent'

const JESection = () => {

    const sectionList = jobsAndEmpoyersData.map((item, i) => <Fragment key={i+1}><FindJobBlock logo={item.logo} imgWidth={item.logoW} imgHeight={item.logoH} header={item.header} description={item.description} link={item.link}/></Fragment>
    )
  return (
    <section className='JESection'>
        <ContentWrapper>
            <h2 className='title-orange'>Find Jobs & Employers</h2>
            <div className='JESection_container'>
                {sectionList}
            </div>
        </ContentWrapper>
    </section>
  )
}

export default JESection