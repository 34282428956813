import React from 'react'
import DateLabel from './DateLabel'

const WebinarsTitle = ({ title, date, time }) => {
  return (
    <div className='webinarsTitle'>
        {date && <div className='date'><DateLabel date={date}/></div>}
        {time && <span className='сommonListItem_header__date time'>{time}</span>}
        <h4 className='сommonListItem_header__text title'>{title}</h4>
    </div>
  )
}

export default WebinarsTitle