import React from 'react'
import RatingStars from 'assets/images/RatingStars'
import SharingReviewButtons from '../SharingReviewButtons/SharingReviewButtons'
import ReplyReview from './ReplyReview'

const ReviewItem = ({ author, reviewText, reviewRating, id, companyName, reply }) => {
  return (
    <div  className='reviewItem' id={id}>
        <div className='reviewItem__text'>
          <h5>{author}</h5>
          <p>{reviewText}</p>
          {reply && <ReplyReview replyContent={reply}/>}
        </div>
        <div className='reviewItem__rating'>
          <div className='reviewItem__rating-rating'>
            <RatingStars rating={reviewRating}/>
            <span>{`${reviewRating} of 5`}</span>
          </div>
          <SharingReviewButtons reviewText={reviewText} reviewID={id} companyName={companyName}/>
        </div>
    </div>
  )
}

export default ReviewItem