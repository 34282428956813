import React from 'react'
import './JobsStates.scss'

import { states } from 'constants/textContent'
import LinksList from 'components/Footer/_components/LinksList/LinksList'

const JobsStates = () => {
  return (
    <div className='jobsStates'>
        <LinksList data={states} linksType={'state'} title={'Browse Jobs by State'} divider={false}/>
    </div>
  )
}

export default JobsStates