import React from 'react'

const FormPreloader = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{margin: 'auto', background: 'none', display: 'block', shapeRendering: 'auto'}} width="23px" height="23px" viewBox="0 0 100 100">
        <circle cx="50" cy="50" fill="none" stroke="#ffffff" strokeWidth="7" r="34" strokeDasharray="160.22122533307947 55.40707511102649">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
        </circle>
    </svg>
  )
}

export default FormPreloader