import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { scrollToTop, capitalizeWords } from "services/helpers";

import { statesData } from "constants/jobsByStateContent";
import ContentWrapper from "HOC/ContentWrapper/ContentWrapper";
import SEO from "components/SEOComponent/SEO";
import { categoryStateTest } from "constants/categoryStateText";
import { justForFunByCategory } from "constants/categoryStateJustForFun";
import AFResources from "pages/JobsInState/_components/AFResources/AFResources";
import MoreResources from "pages/JobsInState/_components/MoreResources/MoreResources";
import JustForFun from "pages/JobsInState/_components/JustForFun/JustForFun";
import CitiesList from "pages/JobsInState/_components/CitiesList/CitiesList";
import JobsStates from "pages/JobsCategories/JobsStates/JobsStates";
import JobByCategories from "components/JobByCategories/JobByCategories";

const JobsByCategoryInState = () => {
  const location = useLocation();
  const state = location.pathname.split("/")[2];
  const category = location.pathname.split("/")[3];

  const [textObject, setTextObject] = useState(null);
  const [justForFunObject , setJustForFunObject] = useState(null)
  const [stateText, setStateText] = useState("");
  const [justForFunArr , setJustForFunArr] = useState([])

  const [selectedState, setSelectedState] = useState(statesData.find(item => item.state.toLocaleLowerCase() === capitalizeWords(state).toLocaleLowerCase()))
   
  useEffect(() => {
    setSelectedState(statesData.find(item => item.state.toLocaleLowerCase() === capitalizeWords(state).toLocaleLowerCase()))
}, [state])

  useEffect(() => {
    const pageText = categoryStateTest.filter(
      (el) =>
        el.FIELD1.toLowerCase().replace(/[\s/-]/g, "") ===
        category.toLowerCase().replace(/-/g, "")
       
    );
    const justForFun = justForFunByCategory.filter(
      (el) =>
        el.FIELD1.toLowerCase().replace(/[\s/-]/g, "") ===
        category.toLowerCase().replace(/-/g, "")
    );
    setJustForFunObject(justForFun)
    setTextObject(pageText);
  }, [category]);

  useEffect(() => {
    const formattedState = state.toUpperCase().replace(/-/g, " ");
    if (textObject) {
      const stateStr = textObject[0][formattedState];
      setStateText(stateStr);
    }
    if(justForFunObject){
      let funArr = justForFunObject[0][formattedState].split(/(?<=\.)/)
      funArr = funArr.map(sentence => sentence.trim());
      setJustForFunArr(funArr)
    }
  }, [textObject , justForFunObject, state]);

  useEffect(() => {
    scrollToTop();
  }, [location]);

  return (
    <ContentWrapper>
      <SEO
        title={`Age-Friendly ${capitalizeWords(
          category
        )} Jobs for Seniors in ${capitalizeWords(
          state
        )}`}
        description={`Find Age-Friendly ${capitalizeWords(
          category
        )} jobs in ${capitalizeWords(
          state
        )}. by state and city for older workers and seniors.`}
        keywords={""}
      />

      <section className="stateJobInfo">
        <div className="stateJobInfo_content">
          <div className="stateJobInfo_leftrail">
            <div className="stateJobInfo_header">
              <h2>
                {`Age-Friendly  ${capitalizeWords(
                  state
                )} Jobs for Seniors in ${capitalizeWords(category)}`}
              </h2>
            </div>
            <div className="stateJobInfo_subtitle">
              <p>{stateText}</p>
            </div>
            <div className="stateJobInfo_subtitle">
              <h4>{`${capitalizeWords(category)} Jobs for Senior in ${capitalizeWords(
                state
              )} by City`}</h4>
            </div>
            <div className="stateJobInfo_cities">
              <div className="stateJobInfo_cities__header">
                <p>
                  {`  Select your city or the closest city to you to view the ${capitalizeWords(
                    category
                  )} for seniors in that location`}
                </p>
              </div>
              <CitiesList state={state} category={category}/>
              <JobsStates></JobsStates>
              <JobByCategories/>
            </div>
          </div>
          <div className="stateJobInfo_rightrail">
            <AFResources state={state} />
            <MoreResources state={capitalizeWords(state)} moreRecources={selectedState?.moreRecources}/>
            <JustForFun data={justForFunArr} category={category}/>
          </div>
        </div>
      </section>
    </ContentWrapper>
  );
};

export default JobsByCategoryInState;
