import React, { useState, useMemo, useEffect } from 'react'
import './WebinarsList.scss'
import { useGetWebinarsQuery } from 'services/api/webinarsApi'
import { scrollToTop } from 'services/helpers'

import CommonListItem from 'components/CommonListItem/CommonListItem'
import Preloader from 'components/Preloader/Preloader'
import Pagination from 'components/Pagination/Pagination'
import WebinarsForm from 'components/Forms/WebinarsForm/WebinarsForm'

const WebinarsList = () => {
    const [page, setPage] = useState(1)
    const { data } = useGetWebinarsQuery({ page })

    useEffect(() => {
        scrollToTop()
    },[data])

    const renderWebinars = data => (
        data?.webinars.map(item => (
        <div key={item.id}>
            <CommonListItem
                addClass={'webinars'}
                title={item.name}
                description={item.description}
                time={`${item.start_time} - ${item.end_time} ET`}
                date={item.date}
            />
        </div>
        ))
    )
    const memoizedList = useMemo(() => renderWebinars(data), [data])
  return (
    <div className='webinarsList'>
        <section className='webinarsList_content'>
            <div className='listContent'>
                <div className='webinarsList_header'>
                    <h2>
                        Webinars
                    </h2>
                    <p>
                        Members of RetirementJobs.com get invited to biweekly Webinars focused on helping you in your Job Search. Below you will see upcoming Webinars hosted by Career Coaches and industry insiders sharing their expertise to help you maintain your professional edge.
                    </p>
                </div>
                <div className='webinarsList_items'>
                    {data ? memoizedList : <Preloader size='L'/>}
                    { data?.total_pages > 1 && 
                        <Pagination
                        totalPages={data.total_pages}
                        currentPage={data.current_page}
                        onPageChange={setPage}
                        />
                    }
                </div>
            </div>
            <aside className='webinarsList_form'>
                <WebinarsForm title='Join RetirementJobs.com to Get Notified about Upcoming Webinars:'/>
            </aside>

        </section>

    </div>
  )
}

export default WebinarsList