import React from 'react'
import { useNavigate } from 'react-router-dom'

import './IndividualArticle.scss'
import BackButton from 'components/BackButton/BackButton'
import TipWithButton from 'components/TipWithButton/TipWithButton'
import SharingButtons from '../SharingButtons/SharingButtons'
import ArticlesAndNews from '../ArticlesAndNews/ArticlesAndNews'
import HTMLRenderer from 'components/HTMLRenderer/HTMLRenderer'
import Preloader from 'components/Preloader/Preloader'

const IndividualArticle = ({ data }) => {
  const navigate = useNavigate();

  return (
    <section className='individualArticle'>
      <BackButton text={'Previous Page'} handler={() => navigate('/career-advice')}/>
      <div className='individualArticle_content'>
          <div className='article_content'>
          { !data 
            ? <Preloader size={'L'}/>
            : <>
                <h2 className='article_content__header'>{data?.name}</h2>
                <section className='article_content__socials'>
                  <p>{data?.author}</p>
                  <SharingButtons/>
                </section>
                <section className='article_content__article'>
                  {data?.image && 
                    <div className='article_image'>
                      <img src={data.image} alt='blank-img'/>
                    </div>
                  }
                  <div className='article_text'>
                    <HTMLRenderer htmlString={data?.description}/>
                  </div>
                </section>
                {data?.note && 
                  <section className='article_content__additionalBox'>
                    <span>{data?.note}</span>
                  </section>
                }
              </>
            }
          </div>
        <div className='article_rightRail'>
          <ArticlesAndNews/>
          <TipWithButton 
            title='Looking for Career Services?' 
            description={<p>Career coaches will assist you in finding your perfect position and navigating the application, interview, and job acceptance processes.</p>} 
            url='/career-service'
            button='Learn More'
          />
        </div>
      </div>

    </section>
  )
}

export default IndividualArticle