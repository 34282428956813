import React from 'react'
import { useGetMetaDataQuery } from 'services/api/metaDataApi'
import WebinarsList from './_components/WebinarsList/WebinarsList'
import SEO from 'components/SEOComponent/SEO'
import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'

const WebinarsPage = () => {
  const { data } = useGetMetaDataQuery('webinars')

  return (
    <>
      <SEO title={data?.data_attributes.meta_title} description={data?.data_attributes.meta_description} keywords={data?.data_attributes.meta_keywords}/>
      <ContentWrapper>
          <WebinarsList/>
      </ContentWrapper>
    </>
  )
}

export default WebinarsPage