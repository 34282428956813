import React from 'react'

function StarIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.7208 0.97044C9.12344 -0.323481 10.8766 -0.32348 11.2792 0.970441L12.7178 5.59356C12.8979 6.17221 13.4143 6.564 13.997 6.564H18.6524C19.9554 6.564 20.4971 8.30496 19.443 9.10464L15.6767 11.9619C15.2053 12.3195 15.008 12.9534 15.1881 13.5321L16.6267 18.1552C17.0293 19.4491 15.611 20.5251 14.5569 19.7254L10.7906 16.8682C10.3192 16.5105 9.68083 16.5105 9.20941 16.8682L5.44311 19.7254C4.389 20.5251 2.97069 19.4491 3.37333 18.1552L4.81193 13.5321C4.99199 12.9534 4.79473 12.3195 4.32332 11.9619L0.557018 9.10464C-0.497098 8.30495 0.0446463 6.564 1.3476 6.564H6.00301C6.58571 6.564 7.10214 6.17221 7.28221 5.59356L8.7208 0.97044Z" fill={"#D9D9D9"}/>
    </svg>
  )
}

export default StarIcon