import Footer from 'components/Footer/Footer'
import React from 'react'
import { useGetMetaDataQuery } from 'services/api/metaDataApi'
// const SearchSection = React.lazy(() => import('./_components/SearchSection/SearchSection'))
// const JESection = React.lazy(() => import('./_components/JobsnEmpoyersSection/JESection'))
// const JobSeekersSection = React.lazy(() => import('./_components/JobSeekersSection/JobSeekersSection'))
// const AgeBiasSection = React.lazy(() => import('./_components/AgeBiasSection/AgeBiasSection'))
// const SEO = React.lazy(() => import('components/SEOComponent/SEO'))
import SearchSection from './_components/SearchSection/SearchSection'
import JESection from './_components/JobsnEmpoyersSection/JESection'
import JobSeekersSection from './_components/JobSeekersSection/JobSeekersSection'
import AgeBiasSection from './_components/AgeBiasSection/AgeBiasSection'
import SEO from 'components/SEOComponent/SEO'

const HomePage = () => {
  const { data } = useGetMetaDataQuery('home')

  if(!data) return null

  return (
    <>
      <SEO title={data?.data_attributes?.meta_title} description={data?.data_attributes?.meta_description} keywords={data?.data_attributes?.meta_keywords}/>
      <SearchSection/>
      <AgeBiasSection/>
      <JESection/>
      <JobSeekersSection/>
      <Footer/>
    </>

  )
}

export default HomePage