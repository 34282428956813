import React from 'react'
import './RecentlyReviewed.scss'
import CommonLink from 'components/CommonLink/CommonLink'
import { generateURL } from 'services/helpers'

const RecentlyReviewed = ({ data }) => {
  const renderColumns = (columnData) => {
    return columnData?.map((item) => (
      <div key={item.name} className='column-item'>
        <CommonLink text={item.name} path={`companies/${generateURL(item.name)}`} />
      </div>
    ));
  };

  const chunkedData = data?.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / 3);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);

  return (
    <div className='recentlyReviewed'>
      <h3 className='recentlyReviewed_title'>Recently Reviewed Organizations</h3>
      <div className='recentlyReviewed_columns'>
        {chunkedData?.map((column, columnIndex) => (
          <div key={columnIndex} className='column'>
            {renderColumns(column)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecentlyReviewed;
