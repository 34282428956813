import React, { useEffect } from 'react'
import { useGetMetaDataQuery } from 'services/api/metaDataApi'
import { scrollToTop } from 'services/helpers'
import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'

// const SEO = React.lazy(() => import('components/SEOComponent/SEO'))
import SEO from 'components/SEOComponent/SEO'
// const AdvicesList = React.lazy(() => import('./_components/AdvicesList/AdvicesList'))
import AdvicesList from './_components/AdvicesList/AdvicesList'

const CareerAdvice = () => {
  const { data } = useGetMetaDataQuery('career_advice')
  useEffect(() => {
    scrollToTop()
  },[data])
  
  return (
    <>
        <SEO title={data?.data_attributes.meta_title} description={data?.data_attributes.meta_description} keywords={data?.data_attributes.meta_keywords}/>
        <ContentWrapper>
          <AdvicesList/>
        </ContentWrapper>
    </>
  )
}

export default CareerAdvice