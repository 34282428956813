import React from 'react'
import './SharingReviewButtons.scss'

import twIcon from 'assets/images/twitter-icon-S.svg'
import fbIcon from 'assets/images/facebook-icon-S.svg'
import mailIcon from 'assets/images/mail-icon.svg'
import { generateURL } from 'services/helpers'

const SharingReviewButtons = ({ reviewText, reviewID, companyName }) => {
    const companyUrl = `${window.location.origin}/reviews/companies/${generateURL(companyName)}#${reviewID}`;
  
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      reviewText
    )}&url=${encodeURIComponent(companyUrl)}`;
  
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      companyUrl
    )}`;


    const emailSubject = `Review about: ${companyName}`;
    const emailBody = `I thought you might find this review about ${companyName} interesting:\n${companyUrl}`;
    const emailShareUrl = `mailto:?subject=${encodeURIComponent(
      emailSubject
    )}&body=${encodeURIComponent(emailBody)}`;


  return (
    <div className='socials_link' id='reviewID'>
        <a href={facebookShareUrl} className='socials_link__item' target='_blank' rel="noreferrer">
            <img src={fbIcon} alt={'facebook-icon'} />
        </a>
        <a href={twitterShareUrl} className='socials_link__item' target='_blank' rel="noreferrer">
            <img src={twIcon} alt={'twitter-icon'} />
        </a>
        <a href={emailShareUrl} className='socials_link__item' target='_blank' rel="noreferrer">
            <img src={mailIcon} alt={'mail-icon'} />
        </a>
    </div>
  )
}

export default SharingReviewButtons
