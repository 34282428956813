import React from 'react'
import './CommonLink.scss'
import { Link } from 'react-router-dom'

const CommonLink = ({ path, text, blank, textDecoration, state }) => {
  return (
    <Link to={path} state={state} className={`commonLink ${textDecoration ? 'textDecoration' : ''}`} rel='noreferrer' target={blank ? '_blank' : '_self'}>{text}</Link>
  )
}

export default CommonLink