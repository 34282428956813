/* eslint-disable */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { baseURL } from 'constants/api'

export const jobsInStateApi = createApi({
    reducerPath: 'jobsInState',
    baseQuery: fetchBaseQuery({ baseUrl: baseURL}),
    endpoints: (build) => ({
        getCitiesInState: build.query({
            query: ({ state }) => ({
                url: `cities`,
                method: 'GET',
                params: {
                    state: state
                }
            })
        }),
    })
})

export const { useGetCitiesInStateQuery } = jobsInStateApi;