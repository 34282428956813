import React from 'react'
import './FindJobBlock.scss'
import CommonLink from '../CommonLink/CommonLink'
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage'


const FindJobBlock = ({ logo, header, description, link, imgWidth, imgHeight }) => {
  return (
    <div className='findJobBlock'>
        <div className='findJobBlock_logo'>
            <LazyLoadImage src={logo} alt='findJobBlock_logo' threshold={0.5} width={imgWidth} height={imgHeight}/>
        </div>
        <div className='findJobBlock_header'>
            <h4>
                {header}
            </h4>
        </div>
        <div className='findJobBlock_description'>
            <p>
                {description}
            </p>
        </div>
        <div className='findJobBlock_link'>
            <CommonLink text={'Learn more'} path={link} blank={false}/>
        </div>
    </div>
  )
}

export default FindJobBlock