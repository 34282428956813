import React, { Fragment, useEffect } from 'react'
import './CareerServiceList.scss'

import BackButton from 'components/BackButton/BackButton'
import { useNavigate } from 'react-router-dom'
import CommonListItem from 'components/CommonListItem/CommonListItem'
import CareerServiceForm from 'components/Forms/CareerServiceForm/CareerServiceForm'
import { useGetCareerServiceArticlesQuery } from 'services/api/careerServiceApi'
import { scrollToTop } from 'services/helpers'
import HTMLRenderer from 'components/HTMLRenderer/HTMLRenderer'
import Preloader from 'components/Preloader/Preloader'

const CareerServiceList = () => {
    const {data} = useGetCareerServiceArticlesQuery()
    const navigate = useNavigate()

    useEffect(() => {
        scrollToTop()
    },[data])

    const servicesList = data?.career_services.map(item => <Fragment key={item.name}><CommonListItem hover={false} addClass={'careerService'} popular={item.most_popular} title={<HTMLRenderer htmlString={item.name}/>} price={item.price} description={item.description}/></Fragment>)

    return (
        <div className='servicesList'>
            <BackButton text={'Career Advice'} handler={() => navigate('/career-advice')}/>
            <div className='servicesList_content'>
                <div className='listContent'>
                    <div className='servicesList_header'>
                        <h2>
                            Career Services
                        </h2>
                        <p>
                            RetirementJobs.com Career Services resources can help you find and get the position you want, no matter your career stage. Our highly qualified Career Coaches will assist you in finding your perfect position and navigating the application, interview, and job acceptance processes. 
                        </p>
                    </div>
                    <div className='servicesList_items'>
                        {data ? servicesList : <Preloader size='L'/>}
                    </div>
                </div>
                <div className='servicesList_form'>
                    <CareerServiceForm title={'Get Started'}/>
                </div>
            </div>
        </div>
    )
}

export default CareerServiceList