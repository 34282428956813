/* eslint-disable */
import { useEffect, useRef } from 'react';

const useLazyLoad = (threshold = 0) => {
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          if (entry.intersectionRatio > threshold) {
            const target = entry.target;
            target.src = target.dataset.src;
            observer.unobserve(target);
          }
        });
      },
      {
        threshold: [threshold],
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [threshold]);

  return ref;
};

export default useLazyLoad;
