import React from 'react'
import './RadioButton.scss'

const RadioButton = ({ value, label, name, onOptionChange, checked }) => {
    return (
      <label key={value} className='radio'>
        <input
          type="radio"
          value={value}
          checked={checked}
          name={name}
          onChange={(e) => onOptionChange(e.target)}
        />
        <span>{label}</span>
      </label>
    );
  };
  
  export default RadioButton;