import React from 'react'
import './style.scss'

const PopularLabel = () => {
  return (
    <span className='mostPoular'>Most Popular</span>
    
  )
}

export default PopularLabel