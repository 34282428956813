/* eslint-disable */
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { setLetterData, setRatingData } from 'redux/reducers/filterReducer';

import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'
import ReviewCompaniesList from 'pages/ReviewsResultPage/_components/ReviewCompaniesList/ReviewCompaniesList'
import { useGetReviewsCompanyQuery } from 'services/api/reviewsApi'
import SEO from 'components/SEOComponent/SEO'

const ReviewsIndividualCompanyPage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const companyName = location.pathname.split('/')[3]; 

    useEffect(() => {
        dispatch(setLetterData(''))
        dispatch(setRatingData(''))
    },[])

    const { data, refetch } = useGetReviewsCompanyQuery({ companyName })
    useEffect(() => {
        refetch()
    }, [])

    return (
        <>
            <SEO title={data?.data_attributes?.meta_title} description={data?.data_attributes?.meta_description} keywords={data?.data_attributes?.meta_keywords}/>
            <ContentWrapper>
                <ReviewCompaniesList data={data} refetch={refetch}/>
            </ContentWrapper>
        </>
    )
}

export default ReviewsIndividualCompanyPage