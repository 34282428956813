import React from 'react'
import './CheckBox.scss'

const CheckBox = ({ value, label, name, selectedOption, onChange, checked }) => {
  return (
    <label key={value} className='checkbox'>
        <input
        type="checkbox"
        value={value}
        checked={checked}
        name={name}
        onChange={(e) => onChange(e)}
        />
        <span>{label}</span>
    </label>
  )
}

export default CheckBox;