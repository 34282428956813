import React from 'react'
import './BorderedSidebar.scss'

const BorderedSidebar = ({ children, title, subtitle = '', sublogo}) => {
  return (
    <div className='borderedSidebar'>
        <div className='borderedSidebar_title'>
            <h3>{title}</h3>
            {subtitle && <div className='borderedSidebar_subtitle'>
              {subtitle}
              <img src={sublogo} alt='AF-seal' width={'122px'} height={'20px'}/>
            </div>}
        </div>
        <div className='borderedSidebar_content'>{children}</div>
    </div>
  )
}

export default BorderedSidebar