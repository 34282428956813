import { configureStore } from "@reduxjs/toolkit";
import { careerAdviceApi } from "services/api/careerAdviceApi";
import { metaDataApi } from "services/api/metaDataApi";
import { trendingTopicsApi } from "services/api/trendingTopicsApi";
import { searchApi } from "services/api/searchApi";
import { careerServiceApi } from "services/api/careerServiceApi";
import { webinarsApi } from "services/api/webinarsApi";
import { inTheNewsApi } from "services/api/inTheNewsApi";
import { jobsInStateApi } from "services/api/jobsInParticularStateApi";
import { searchReviewsApi } from "services/api/reviewsApi"
import filterReducer from "./reducers/filterReducer";
import reviewsReducer from "./reducers/reviewsReducer";
import { rtkQueryErrorLogger } from "services/api/errorsLogger";
import { termsAndPolicyApi } from "services/api/termsAndPolicyApi";

export const store = configureStore({
    reducer: {
        [metaDataApi.reducerPath]: metaDataApi.reducer,
        [trendingTopicsApi.reducerPath]: trendingTopicsApi.reducer,
        [careerAdviceApi.reducerPath]: careerAdviceApi.reducer,
        [searchApi.reducerPath]: searchApi.reducer,
        [careerServiceApi.reducerPath]: careerServiceApi.reducer,
        [webinarsApi.reducerPath]: webinarsApi.reducer,
        [inTheNewsApi.reducerPath]: inTheNewsApi.reducer,
        [jobsInStateApi.reducerPath]: jobsInStateApi.reducer,
        [searchReviewsApi.reducerPath]: searchReviewsApi.reducer,
        [termsAndPolicyApi.reducerPath]: termsAndPolicyApi.reducer,
        filterReducer: filterReducer,
        reviewsReducer: reviewsReducer,
    },
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware().concat(
            rtkQueryErrorLogger,
            metaDataApi.middleware,
            trendingTopicsApi.middleware,
            careerAdviceApi.middleware,
            searchApi.middleware,
            careerServiceApi.middleware,
            webinarsApi.middleware,
            inTheNewsApi.middleware,
            jobsInStateApi.middleware,
            searchReviewsApi.middleware,
            termsAndPolicyApi.middleware,
        )
})