/* eslint-disable */
import React from 'react'
import './TrendingTopics.scss'
import image from 'assets/images/new-logo-with-paddings.svg'

import { useGetTrendingTopicsQuery } from 'services/api/trendingTopicsApi'
import CommonLink from 'components/CommonLink/CommonLink'

const TrendingTopics = () => {
    const { data } = useGetTrendingTopicsQuery()

    return (
        <aside className='trendingTopics'>
            <div className='trendingTopics_header'>
                <div className='trendingTopics_heading'>
                    <h3>Look for Job Listings with this seal!</h3>
                    <div className='trendingTopics_heading__seal'>
                        <img src={image} alt='AF-seal'/>
                    </div>
                </div>
            </div>
            <div className='trendingTopics_description'>
                <p>
                Certified Age Friendly Employers are <CommonLink path={'https://institute.agefriendly.com/initiatives/certified-age-friendly-employer-program/'} text={'expert-vetted'} blank={true}/> and committed to being the best places to work for older adults.
                </p>
            </div>
            <div className='trendingTopics_articles'>
                <h2 className='trendingTopics_articles__header'>{data?.title}</h2>
                {data && 
                    <div className='trendingTopics_articles__links'>
                        {data.topic_news.map(item => <a key={item.id} href={item.link} target='_blank' rel="noreferrer">{item.link_title}</a>)}
                    </div>
                }
            </div>

        </aside>
    )
}

export default TrendingTopics