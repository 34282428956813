import { React } from "react";
import { useLocation } from "react-router-dom";

import ContentWrapper from "HOC/ContentWrapper/ContentWrapper";
import SectionNavigation from "./_components/SectionNavigation/SectionNavigation";
import TipWithButton from "components/TipWithButton/TipWithButton";
import WrapperSEO from "./_components/WrapperSEO/WrapperSEO";

import "./AboutUsSection.scss";

const AboutUsSection = ({ child }) => {
  const location = useLocation();
  return (
    <>
      <WrapperSEO location={location.pathname}/>
      <ContentWrapper>
        <div className="aboutUsSection_content">
          <div className="aboutUsSection_itemsBlock">
            {child}
          </div>
          <div className="aboutUsSection_navBlock">
            <SectionNavigation />
            {location.pathname?.split("/")[2] !== "contact-us" && (
              <TipWithButton
                title="Contact Us"
                description={
                  <p>
                    RetirementJobs.com <br /> 204 2<sup>nd</sup> Ave. <br />{" "}
                    service@retirementjobs.com
                  </p>
                }
                url="mailto:service@retirementjobs.com"
                button="Contact us"
              />
            )}
          </div>
        </div>
      </ContentWrapper>
    </>
  );
};

export default AboutUsSection;
