/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./ReviewsList.scss";
import ReviewsSearch from "../ReviewsSearch/ReviewsSearch";
import RecentlyReviewed from "../RecentlyReviewed/RecentlyReviewed";
import { useGetReviewsCompaniesQuery } from "services/api/reviewsApi";
import AFCertified from "../AFCertified/AFCertified";
import CompaniesList from "../CompaniesList/CompaniesList";
import ReviewBlock from "../ReviewBlock/ReviewBlock";
import ReviewsFilters from "components/ReviewsFilters/ReviewsFilters";
import TipWithButton from "components/TipWithButton/TipWithButton";

const ReviewsList = () => {
  const [isMobile, setIsMobile] = useState(false);
  const { data, refetch } = useGetReviewsCompaniesQuery();

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="reviewsPage">
      <div className="reviewsPage_content">
        <div className="reviewsPage_leftRail">
          <div className="reviewsPage_header">
            <h2>Employer Reviews</h2>
            <p>
              The purpose of this section is for individuals to rate an
              employer's workplace environment and company values as to whether
              they are (i) age friendly, (ii) unwelcoming to workers age 50+, or
              (iii) in between. We're committed to protecting our community from
              fraudulent submissions that might affect our ratings and reviews.
              We review and approve every post before it appears on our site
              (approved postings are live within 5 business days). Individuals'
              submissions and employer responses, if any, are included in our
              confidential evaluation of an employer's current or future
              application to be designated as a{" "}
              <a
                href="https://institute.agefriendly.org/initiatives/certified-age-friendly-employer-program/"
                target="_blanc"
              >
                Certified Age Friendly Employer
              </a>
              . This survey is anonymous. <br></br>Is an employer you know
              age-friendly?
              <a href="/reviews/new"> Review employer now.</a>
            </p>
          </div>
          <ReviewsSearch />
          {isMobile && (
            <div className="reviewsPage_rightRail">
              <div className="reviewsPage_rightRail__header">
                <h3>Rating Scale</h3>
                <p>
                  Individuals submit ratings on scale of 0 to 10, with 0 being
                  least age friendly and 10 being most age friendly. These
                  figures are averaged together to compute our 5-point rating.
                </p>
              </div>
              <div className="reviewsPage_rightRail__filters">
                <ReviewsFilters />
                <TipWithButton
                  description={"Is your employer Age Friendly?"}
                  button={"Review Employer Now"}
                  url={"/reviews/new"}
                />
              </div>
            </div>
          )}
          <RecentlyReviewed data={data?.recently_reviewed_companies} />
          <AFCertified />
          {data && <CompaniesList data={data.age_friendly_companies} />}
          <ReviewBlock />
        </div>
        {!isMobile && (
          <div className="reviewsPage_rightRail">
            <div className="reviewsPage_rightRail__header">
              <h3>Rating Scale</h3>
              <p>
                Individuals submit ratings on scale of 0 to 10, with 0 being
                least age friendly and 10 being most age friendly. These figures
                are averaged together to compute our 5-point rating.
              </p>
            </div>
            <div className="reviewsPage_rightRail__filters">
              <ReviewsFilters />
              <TipWithButton
                description={"Is your employer Age Friendly?"}
                button={"Review Employer Now"}
                url={"/reviews/new"}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewsList;
