import React, { useEffect, useState } from 'react'
import ReactStars from "react-rating-stars-component";
import starEmpty from 'assets/images/starEmpty.svg';
import starHalf from 'assets/images/star-half.svg';
import starFilled from 'assets/images/starFilled.svg';

const RatingStarsHalfed = ({ value }) => {
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, [value]);

  return (
    <ReactStars 
      key={key}
      classNames={'customStars'}
      count={5}
      edit={false}
      value={value}
      isHalf={true}
      emptyIcon={<img src={starEmpty} alt={'star-ic'}/>}
      halfIcon={<img src={starHalf} alt={'star-ic'}/>}
      filledIcon={<img src={starFilled} alt={'star-ic'}/>}
    />
  )
}

export default RatingStarsHalfed