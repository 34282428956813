/* eslint-disable */
import React, { Fragment, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import './ReviewCompaniesList.scss'

import { scrollToTop } from 'services/helpers'

import ReviewsFilters from 'components/ReviewsFilters/ReviewsFilters'
import ReviewCompanyCard from '../ReviewCompanyCard/ReviewCompanyCard'
import ReviewsSearch from 'pages/ReviewsPage/_components/ReviewsSearch/ReviewsSearch'
import Pagination from 'components/Pagination/Pagination'
import FeedbackSection from '../FeedbackSection/FeedbackSection'
import MobileFilters from 'components/ReviewsFilters/_components/MobileFilters/MobileFilters'
import PreloaderL from 'assets/images/PreloaderL'
import Modal from 'components/Modal/Modal'
import EmployerReviewForm from 'components/Forms/EmployerReviewForm/EmployerReviewForm'
import { setFeedbackModal, setSelectedCompany } from 'redux/reducers/reviewsReducer';

const ReviewCompaniesList = ({ data, setPage, page, refetch }) => {
    const modalState = useSelector(state => state.reviewsReducer.feedbackModal)
    const dispatch = useDispatch()
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {
        setIsMobile(window.innerWidth <= 768);
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        scrollToTop()
    }, [page])

    const handleModalClose = () => {
        dispatch(setFeedbackModal(false))
        dispatch(setSelectedCompany({ name: '', id: '' }))
    }

    const renderList = data?.companies?.map(item => 
    <Fragment key={item.id}>
        <ReviewCompanyCard 
            avatar={item.avatar}
            description={item.description}
            id={item.id}
            is_age_friendly={item.is_age_friendly}
            location={item.location}
            name={item.name}
            total_rating={item.total_rating}
            reviews={item.reviews}
            links={item.links}
        />
    </Fragment>)

  return (
    <div className='reviewCompaniesList'>
        <div className='reviewCompaniesList_container'>
            <div className='reviewCompaniesList_leftRail'>
                <ReviewsSearch/>
                {isMobile && <MobileFilters/>}
                <div className='reviewCompaniesList_content'>
                    {!data && <PreloaderL/>}
                    {data && renderList}
                    {!data?.companies?.length && <p className='noResults'>No results were found based on your search criteria. Please update your search.</p>}
                    {data?.total_pages > 1 && 
                    <Pagination
                        totalPages={data?.total_pages}
                        onPageChange={setPage}
                        currentPage={page}
                    />}
                </div>
                <FeedbackSection/>
            </div>
            <div className='reviewCompaniesList_rightRail'>
                <div className='reviewCompaniesList_filters'>
                    {!isMobile && <ReviewsFilters resultPage={true}/>}
                </div>
            </div>
        </div>
        {modalState && <Modal header={'Add Review'} children={<EmployerReviewForm refetch={refetch}/>} onClose={handleModalClose} />}
    </div>
  )
}

export default ReviewCompaniesList