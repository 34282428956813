/* eslint-disable */
import React from 'react'
import './TopNav.scss'
import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'
import { NavLink, useLocation } from 'react-router-dom'
import NavHamburger from '../NavHamburger/NavHamburger'

const TopNav = () => {
  const location = useLocation();
  const isActive = location.pathname === '/career-advice' || location.pathname === '/career-service';
  const isActiveAboutLink = location.pathname.startsWith("/about/")  ;
  const IsActiveSearchLink = location.pathname.startsWith("/jobs-by-state") || location.pathname.startsWith('/jobs-for-seniors') || location.pathname.includes('cate');
  

  return (
    <>
      <nav>
        <ContentWrapper style={'navContainer'}>
            <NavHamburger/>
            <div className='navigationLeft'>
                <NavLink className={`navLink_item ${location.pathname === '/pre-interview' ? 'active' : '' }`} to='/'>Home</NavLink>
                <NavLink className={`navLink_item ${IsActiveSearchLink ? 'active' : ''}`} to='https://search.retirementjobs.com/jobs'>Search</NavLink>
                <NavLink className={'navLink_item'} to='/reviews'>Reviews</NavLink>
                <NavLink className={'navLink_item'} to='/webinars'>Webinars</NavLink>
                <NavLink className={`navLink_item ${isActive ? 'active' : ''}`} to='/career-advice'>Career Advice</NavLink>
                <NavLink className={`navLink_item ${isActiveAboutLink ? 'active' : ''}`} to='/about/about-us'>About Us</NavLink>
            </div>
            <div className='navigationRight'>
                <NavLink className={'navLink_item'} to='https://search.retirementjobs.com/sign-in'>My account</NavLink>
                <NavLink className={'navLink_item'} to='https://search.retirementjobs.com/employer-offers'>Post a Job</NavLink>
            </div>
        </ContentWrapper>
      </nav>
    </>
  )
}

export default TopNav