/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead'

import { useGetReviewsSearchResultsQuery } from 'services/api/reviewsApi';
import Input from 'components/Input/Input';

import './CompaniesSearchInput.scss'
import 'react-bootstrap-typeahead/css/Typeahead.css';

const CompaniesSearchInput = ({ label, companyData, setCompanyData, error, setError }) => {
    const [options, setOptions] = useState([])
    const [company, setCompany] = useState()
    const [typedCompany, setTypedCompany] = useState()
    const { data } = useGetReviewsSearchResultsQuery({ company })
    const [location, setCompanyLocation] = useState('')

    useEffect(() => {
        setOptions(data?.companies.map(option => ({label: option.name, value: option.id})))
    }, [company, data, typedCompany])

    const handleSelect = (selected) => {
        setCompany(selected)
        setTypedCompany('')
        setCompanyData({
            ...companyData,
            company_name: selected[0]?.label,
            company_id: selected[0]?.value
        })
        if(selected) {
            setError('')
        }
    }

    const handleInputChange = (e) => {
        setCompany(e)
        setTypedCompany(e)
        setCompanyData({
            ...companyData,
            company_name: e
        })
        if(e) {
            setError('')
        }
    }

    const handleLoc = (value) => {
        setCompanyLocation(value)
        setCompanyData({
            ...companyData,
            company_loc: value
        })
    }

    const renderMenu = (results) => (
        <Menu 
            id='custom-menu'
            className={`custom-menu-class ${options.length ? '' : 'hideMenu'}`}
            emptyLabel={null}
        >
            {results && results.map((result, index) => (
                <MenuItem key={index} option={result} className='custom-menu-item'> 
                    <span className='searchLink' id={result.value}>{result.label}</span>
                </MenuItem>
            ))}
        </Menu>
    );

    return (
        <div className='companiesSearchInput'>
            <div className='companiesSearchInput_wrapper'>
                <label htmlFor='companiesSearchInput'>
                    {label}<span>*</span>
                </label>
                <Typeahead
                    id='companiesSearchInput'
                    multiple={false}
                    placeholder=""
                    renderMenu={results => renderMenu(results)}
                    onChange={handleSelect}
                    onInputChange={e => handleInputChange(e)}
                    options={options}
                    filterBy={() => true}
                    emptyLabel={null}
                />
            </div>
            {error && <span className='error'>{error}</span>}
            { (typedCompany && !options.length) &&
                <div className='unknownCompany'>
                    <p>We do not have an employer in our database. Tell us more about them!</p>
                    <Input
                        label='The location of the employer (optional)'
                        type='text'
                        name='company_loc'
                        placeholder='Boston, MA'
                        onChange={e => handleLoc(e.value)}
                        value={location}
                    />
                </div>
            }
        </div>
    )
}

export default CompaniesSearchInput