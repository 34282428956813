import React, { useEffect, useState, useRef, Fragment } from "react";
import "./ReviewCompanyCard.scss";
import CommonLink from "components/CommonLink/CommonLink";
import afSeal from "assets/images/new-logo-with-paddings.svg";
import arrow from "assets/images/right.svg";
import HTMLRenderer from "components/HTMLRenderer/HTMLRenderer";
import ReviewItem from "./ReviewItem";
import CompanyHeaderRating from "./CompanyHeaderRating";
import { generateURL } from "services/helpers";

const ReviewCompanyCard = ({
  avatar,
  description,
  id,
  is_age_friendly,
  name,
  total_rating,
  reviews,
  links,
}) => {
  const jobListingsLink = `https://search.retirementjobs.com/index.php?action=advanced_search&page=search&city_state_zip=&zip_radius=30&keywords=${name.replace(
    / /g,
    "%20"
  )}`;
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [showFullContent, setShowFullContent] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const updateContentHeight = () => {
      const contentElement = contentRef.current;
      if (contentElement) {
        const height = contentElement.scrollHeight;
        setIsOverflowing(height > 190);
      }
    };

    updateContentHeight();
    window.addEventListener("resize", updateContentHeight);

    return () => {
      window.removeEventListener("resize", updateContentHeight);
    };
  }, []);

  const handleShowFullContent = () => {
    setShowFullContent(!showFullContent);
  };

  const renderReviews = reviews?.map(
    (item) =>
      item.explanation_of_rating && (
        <Fragment key={item.id}>
          <ReviewItem
            author={item.full_name}
            reviewText={item.explanation_of_rating}
            reviewRating={item.rating}
            id={item.id}
            companyName={name}
            reply={item.reply}
          />
        </Fragment>
      )
  );

  return (
    <div className="reviewCompanyCard">
      <div className="reviewCompanyCard_header">
        <div className="reviewCompanyCard_header__info">
          <div className="company_avatar">
            {avatar && <img src={avatar} alt="company-avatar" />}
          </div>
          <div className="company_name">
            <div className="company_name__title">
              <CommonLink
                text={name}
                path={`/reviews/companies/${generateURL(name)}`}
                textDecoration={true}
              />
            </div>
            <div className="company_name__link">
              <CommonLink text={`View Job Listings`} path={jobListingsLink} />
              <img src={arrow} alt="arrow" />
            </div>
            <div className="links_list">
              {Object.keys(links).map((key) => (
                <Fragment key={key}>
                  <CommonLink path={links[key]} text={key} />
                </Fragment>
              ))}
            </div>
          </div>
        </div>

        <div className="reviewCompanyCard_header__rating">
          <div className="company_agefriendly">
            {is_age_friendly && <img src={afSeal} alt="afSeal" />}
          </div>
          <CompanyHeaderRating
            total_rating={total_rating}
            companyName={name}
            companyID={id}
          />
        </div>
      </div>
      <div className="reviewCompanyCard_body">
        <p
          className="company_description"
          style={{
            overflow: showFullContent ? "visible" : "hidden",
            maxHeight: showFullContent ? "none" : "178px",
            WebkitLineClamp: showFullContent ? "unset" : "6",
          }}
        >
          <div ref={contentRef}>
            <HTMLRenderer htmlString={description} />
          </div>
        </p>
        {isOverflowing && !showFullContent && (
          <span className="showBtn" onClick={handleShowFullContent}>
            More description
          </span>
        )}
        {showFullContent && (
          <span className="showBtn" onClick={handleShowFullContent}>
            Less description
          </span>
        )}

        <CompanyHeaderRating
          total_rating={total_rating}
          companyName={name}
          companyID={id}
        />
      </div>
      {reviews.length > 0 && (
        <div className="reviewCompanyCard_reviews">
          <h4>Review</h4>
          {renderReviews}
        </div>
      )}
    </div>
  );
};

export default ReviewCompanyCard;
