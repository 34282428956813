import React from 'react'
import PaginationArrow from 'assets/images/PaginationArrow'
import './BackButton.scss'

const BackButton = ({text, handler}) => {
  return (
    <button className='backButton' onClick={handler}>
        <PaginationArrow side={'prev'} color={'#344689'}/>
        <span>Back to {text}</span>
    </button>
  )
}

export default BackButton