import React from 'react'
import emplLogo from 'assets/images/employerIcon.svg'

const ReplyReview = ({ replyContent }) => {
  return (
    <div className='replyReview'>
        <div className='replyReview_logo'>
            <img src={emplLogo} alt={'emloyer-logo'}/>
        </div>
        <div className='replyReview_content'>
            <h5 className='replyReview_content__header'>
                Employer response
            </h5>
            <p className='replyReview_content__text'>
                {replyContent}
            </p>
        </div>

    </div>
  )
}

export default ReplyReview