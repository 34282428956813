import React, { Fragment } from 'react'
import './ArticlesAndNews.scss'
import { useGetArticlesAndNewsQuery } from 'services/api/careerAdviceApi'
import { generateURL } from 'services/helpers'
import HTMLRenderer from 'components/HTMLRenderer/HTMLRenderer'
import { Link } from 'react-router-dom'

const ArticleAndNewsItem = ({ id, title, link, description }) => {
    return(
        <div className='articleAndNewsItem'>
            <Link to={`../${link}`} state={{ id }}>{title}</Link>
            <p>
                <HTMLRenderer htmlString={description}/>
            </p>
        </div>
    )
}

const ArticlesAndNews = () => {
    const { data } = useGetArticlesAndNewsQuery()
    const renderList = data?.articles.map(item => <Fragment key={item.id}><ArticleAndNewsItem id={item.id} title={item.name} description={item.description} link={`career-advice/${generateURL(item.name)}`}/></Fragment>)
    return (
        <aside className='articlesNnews'>
            <div className='articlesNnews_header'>
                <h3>Articles & News</h3>
            </div>
            <div className='articlesNnews_content'>
                {renderList}
            </div>
        </aside>
    )
}

export default ArticlesAndNews