/* eslint-disable */
import React, { useState } from 'react'
import './NavHamburger.scss'
import { NavLink, useLocation } from 'react-router-dom'

const NavHamburger = () => {

  const [showNavbar, setShowNavbar] = useState(false);
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const location = useLocation();
  const isActive = location.pathname === '/career-advice' || location.pathname === '/career-service';
  const isActiveAboutLink = location.pathname.startsWith("/about/");
  const IsActiveSearchLink = location.pathname.startsWith("/jobs-by-state");

  return (
    <nav className='mobileNavigation'>
      <div className='hamburgerBtn' onClick={handleShowNavbar}>
        <span className='hamburgerBtn_line'></span>
        <span className='hamburgerBtn_line'></span>
        <span className='hamburgerBtn_line'></span>
      </div>
      <div className={`navElements ${showNavbar && "active"}`}>
        <NavLink className={`navLink_item ${location.pathname === '/pre-interview' ? 'active' : '' }`} to='/'>Home</NavLink>
        <NavLink className={`navLink_item ${IsActiveSearchLink ? 'active' : ''}`} to='https://search.retirementjobs.com/jobs'>Search</NavLink>
        <NavLink className={'navLink_item'} to='/reviews'>Reviews</NavLink>
        <NavLink className={'navLink_item'} to='/webinars'>Webinars</NavLink>
        <NavLink className={`navLink_item ${isActive ? 'active' : ''}`} to='/career-advice'>Career Advice</NavLink>
        <NavLink className={`navLink_item ${isActiveAboutLink ? 'active' : ''}`} isActive={isActiveAboutLink} to='/about/about-us'>About Us</NavLink>
      </div>
    </nav>

  )
}

export default NavHamburger