import React, { Fragment, useState } from 'react'
import './CompaniesList.scss'
import CommonLink from 'components/CommonLink/CommonLink'
import { generateURL } from 'services/helpers'

const CompaniesListItem = ({ data }) => {
    return (
        <div className='companiesListItem'>
            {data.map(item => <Fragment key={item.id}><CommonLink text={item.name} path={`/reviews/companies/${generateURL(item.name)}`}/></Fragment>)} 
        </div>
    )
}

const CompaniesList = ({data}) => {
    const [showCompanies, setShowCompanies] = useState(false)

    const keys = Object.keys(data).sort((a, b) => {
        if (a === 'non-letter') {
          return -1;
        }
        if (b === 'non-letter') {
          return 1;
        }
        return a.localeCompare(b);
    });

    const middleIndex = Math.ceil(keys.length / 2);
    const firstColumnKeys = keys.slice(0, middleIndex);
    const secondColumnKeys = keys.slice(middleIndex);

  return (
    <div className={`companiesList ${showCompanies ? 'open' : ''}`}>
        <div className='firstColumn'>
            {firstColumnKeys.map(key => (
                <div className='companiesList_company' key={key}>
                    {key !== 'non-letter' && <span>{key}</span>}
                    <CompaniesListItem data={data[key]}/>
                </div>
            ))}
        </div>
        <div className='secondColumn'>
            {secondColumnKeys.map(key => (
                <div className='companiesList_company' key={key}>
                    {key !== 'non-letter' && <span>{key}</span>}
                    <CompaniesListItem data={data[key]}/>
                </div>
            ))}
        </div>
        <div className='companiesList_showMore'>
            <div className='companiesList_showMore__btn' onClick={() => setShowCompanies(!showCompanies)}>
                {`${showCompanies ? 'Hide' : 'Show'} full employers list`}
            </div>
        </div>
    </div>
  )
}

export default CompaniesList