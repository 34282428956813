import React, { useEffect } from 'react'
import { useGetMetaDataQuery } from 'services/api/metaDataApi'
import { scrollToTop } from 'services/helpers'

import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'
import InterviewPage from './_components/InterviewPage/InterviewPage'
import SEO from 'components/SEOComponent/SEO'

const Interview3min = () => {
  const { data } = useGetMetaDataQuery('pre_interview')
  useEffect(() => {
    scrollToTop()
  },[data])

  return (
    <>
      <SEO title={data?.data_attributes.meta_title} description={data?.data_attributes.meta_description} keywords={data?.data_attributes.meta_keywords}/>
      <ContentWrapper>
        <InterviewPage/>
      </ContentWrapper>
    </>

  )
}

export default Interview3min