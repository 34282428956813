import BorderedSidebar from 'components/BorderedSidebar/BorderedSidebar'
import React from 'react'

const JustForFun = ({ data , category}) => {
  return (
    <div className={category ? 'justForFun category' : 'justForFun'}>
        <BorderedSidebar title={'Just for Fun, Did You Know?'}>
            <ul>
                {data?.map((item, i) => <li key={i+1}>{item}</li>)}
            </ul>
        </BorderedSidebar>
    </div>
  )
}

export default JustForFun