import React from 'react'
import './FeedbackSection.scss'
import checkmark from 'assets/images/blue-checkmark.svg'
import CommonLink from 'components/CommonLink/CommonLink'

const FeedbackSection = () => {
  return (
    <div className='feedbackSection'>
        <div className='feedbackSection_certifiedEmployer'>
            <img src={checkmark} alt='checkmark'/>
            <span>Denotes a</span>
            <CommonLink text='Certified Age Friendly Employer' path='https://institute.agefriendly.org/initiatives/certified-age-friendly-employer-program/' blank={true}/>
        </div>
        <div className='feedbackSection_feedback'>
            <h4>Feedback?</h4>
            <p>
                Please 
                <CommonLink text='click here' path='/about/contact-us'/> 
                to send us feedback.
            </p>
        </div>
        <div className='feedbackSection_testimonials'>
            <h4>Testimonials:</h4>
            <blockquote>
                <p>                
                    After reading the comments by those who express their perspective of age related discriminating practices by more than a few employers, I felt very concerned for older workers that need/desire employment. I wonder if these employers understand that it is not just the revenue from young adults that keep them in business. I wonder how they would survive if the older generation would stop supporting their businesses. I also wonder if these employers understand the principle of sowing and reaping? I am grateful to your agency for providing the results of your survey and your commitment to provide older workers with reliable job leads.
                </p>
                <footer>
                    — Marie Moore, AARP
                </footer>
            </blockquote>
        </div>
    </div>
  )
}

export default FeedbackSection