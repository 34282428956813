/* eslint-disable */
import React from 'react'
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage'
import './SharingButtons.scss'

import twIcon from 'assets/images/twitter-icon-S.svg'
import fbIcon from 'assets/images/facebook-icon-S.svg'
import liIcon from 'assets/images/linkedin-icon-S.svg'
import mailIcon from 'assets/images/mail-icon.svg'

const SharingButtons = ({ articleTitle }) => {
    const articleUrl = window.location.href;
  
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      articleTitle
    )}&url=${encodeURIComponent(articleUrl)}`;
  
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      articleUrl
    )}`;
  
    const linkedinShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      articleUrl
    )}&title=${encodeURIComponent(articleTitle)}`;

    const emailSubject = `Check out this article: ${articleTitle}`;
    const emailBody = `I thought you might find this article interesting:\n${articleTitle}\n${articleUrl}`;
    const emailShareUrl = `mailto:?subject=${encodeURIComponent(
      emailSubject
    )}&body=${encodeURIComponent(emailBody)}`;


  return (
    <div className='socials_link'>
    <span>Share:</span>
    <a href={facebookShareUrl} className='socials_link__item' target='_blank' rel="noreferrer">
      <LazyLoadImage src={fbIcon} alt='facebook-icon' width='10px' height='18px'/>
    </a>
    <a href={twitterShareUrl} className='socials_link__item' target='_blank' rel="noreferrer">
      <LazyLoadImage src={twIcon} alt='twitter-icon' width='20px' height='14px'/>
    </a>
    <a href={linkedinShareUrl} className='socials_link__item' target='_blank' rel="noreferrer">
      <LazyLoadImage src={liIcon} alt='linkedin-icon' width='16px' height='16px'/>
    </a>
    <a href={emailShareUrl} className='socials_link__item' target='_blank' rel="noreferrer">
      <LazyLoadImage src={mailIcon} alt='mail-icon' width='18px' height='12px'/>
    </a>
  </div>
  )
}

export default SharingButtons