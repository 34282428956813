import React from 'react'
import './JobByCategories.scss'

import { categories } from 'constants/textContent'
import LinksList from 'components/Footer/_components/LinksList/LinksList'

const JobByCategories = ({ state = '' }) => {
  return (
    <div className='jobByCategories'>
        <LinksList data={categories} linksType={'category'} title={`Find Job by Category ${state ? `in ${state}`: ''}`} state={state} jobsByState={true}/>
    </div>
  )
}

export default JobByCategories