import React from 'react'
import './CommonButton.scss'

const CommonButton = ({type = 'button', text, addColor, onClick}) => {
  return (
    <button type={type} onClick={onClick} className={`commonButton ${addColor}`}>
        {text}
    </button>
  )
}

export default CommonButton