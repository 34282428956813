import React from 'react'
import { useNavigate } from 'react-router-dom'
import './NotFoundPage.scss'
import image from 'assets/images/errorImg.svg'

const NotFoundPage = ({ errorCode = '' }) => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/')
  }
  return (
    <section className="notFoundPage">
      <div className='notFoundPage_content'>
          <div className='notFoundPage_image'>
            <img src={image} alt={'error-img'}/>
          </div>
          <div className='notFoundPage_text'>
            <div>
              <p>Sorry, the page you're looking for cannot be accessed or doesn’t exist.</p>
              {errorCode && <span>Error code {errorCode}</span>}
            </div>
            <button className='notFoundPage_button' onClick={handleClick}>Go to the Home page</button>
          </div>
      </div>
    </section>
  )
}

export default NotFoundPage