import React, { useState } from 'react'
import './CompanyRating.scss'
import StarIcon from 'assets/images/StarIcon';

const CompanyRating = ({ handler, error, setError }) => {
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);

    const handleRating = (idx) => {
        setRating(idx)
        if(idx) {
            setError('')
        }
        handler({name: 'rating', value: idx})
    }

    return (
        <div className='rating_container'>
            <p className='rating_label'>Select stars to rate<span>*</span></p>
            <div className="rating">
                {[...Array(5)].map((star, index) => {
                    index += 1;
                    return (
                        <span
                            key={index}
                            className={`star ${index <= (hover || rating) ? 'active' : ''}`}
                            onClick={() => handleRating(index)}
                            onMouseEnter={() => setHover(index)}
                            onMouseLeave={() => setHover(rating)}
                        > 
                            <StarIcon/>
                        </span>
                    );
                })}
            </div>
            <p className='error'>{error}</p>
        </div>

    );
}

export default CompanyRating
