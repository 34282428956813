import React from 'react'
import './CategoryJobInfo.scss'

import JobByCategories from 'components/JobByCategories/JobByCategories'

import { capitalizeWords } from 'services/helpers'
import JobsStates from 'pages/JobsCategories/JobsStates/JobsStates'

const CategoryJobInfo = ({ state }) => {
    return (
        <section className='stateJobInfo'>
            <div className='stateJobInfo_content'>
                <div className='stateJobInfo_leftrail'>
                    <div className='stateJobInfo_header'>
                        <h2>
                            {`Age-Friendly  ${capitalizeWords(state)} Jobs for Older Workers and Seniors by State`}
                        </h2>
                    </div>
                    <div className='stateJobInfo_subtitle'>
                        <h4>{`${capitalizeWords(state)} Jobs for Seniors by State`}</h4>
                    </div>
                    <div className='stateJobInfo_cities'>
                        <div className='stateJobInfo_cities__header'>
                            <p>
                                {`Select your state to view the ${capitalizeWords(state)} jobs for older workers and seniors in that location`}
                            </p>
                        </div>
                        <JobsStates state={capitalizeWords(state)}/>
                        <JobByCategories></JobByCategories>
                    </div>
                </div>
                
            </div>
        </section>
    )
}

export default CategoryJobInfo