import React, { Fragment } from 'react'
import './JobSeekersSection.scss'
import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'
import JobSeekerItem from 'components/JobSeekerItem/JobSeekerItem'
import { jobSeekersData } from 'constants/textContent'

const JobSeekersSection = () => {

    const jobSeekerlist = jobSeekersData.map((item, i) => <Fragment key={i+1}><JobSeekerItem image={item.img} header={item.header} description={item.description} link={item.link} /></Fragment> )
  return (
    <section className='jobSeeker'>
        <ContentWrapper>
            <h2 className='title-orange'>Resources for Job Seekers</h2>
            <div className='jobSeeker_container'>
                {jobSeekerlist}
            </div>
        </ContentWrapper>
    </section>
  )
}

export default JobSeekersSection