import React from 'react';
import './Pagination.scss'
import PreviousButton from './_components/PreviousButton';
import NextButton from './_components/NextButton';
import ReactPaginate from 'react-paginate';


const Pagination = ({ totalPages, onPageChange, currentPage }) => {

  const handlePageClick = ({selected}) => {
    onPageChange(selected + 1);
  };

  return (
    <ReactPaginate
      breakLabel="..."
      onPageChange={handlePageClick}
      pageRangeDisplayed={1}
      pageCount={totalPages}
      previousLabel={<PreviousButton/>}
      nextLabel={<NextButton/>}  
      renderOnZeroPageCount={null}
      marginPagesDisplayed={1}
      forcePage={currentPage - 1}

      containerClassName={'pagination'}
      pageClassName={'activeClassName'}
      activeClassName={'active'}
      previousClassName={'pagination_buttons prevBtn'}
      nextClassName={'pagination_buttons nextBtn'}
      nextLinkClassName={'pagination_buttons__link'}
      previousLinkClassName={'pagination_buttons__link'}
      disabledClassName={'pagination_buttons__disable'}
      breakClassName={'breakDots'}
    />
  );
};

export default Pagination;
