import React from 'react'
import './TipWithButton.scss'
import { Link } from 'react-router-dom'

const TipWithButton = ({title, description, url, button}) => {

  return (
    <div className='careerServicesLink'>
        {title && <h4 className='careerServicesLink_header'>{title}</h4>}      
        <span>{description}</span>
        
        <Link to={url} className='careerServices_link'>
          {button}
        </Link>
    </div>
  )
}

export default TipWithButton