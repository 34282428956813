/* eslint-disable*/
import React, { useEffect } from 'react'
import './CompaniesRatingFilter.scss'
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import RadioButton from "components/RadioButton/RadioButton";
import RatingStars from 'assets/images/RatingStars';
import { setRatingData } from 'redux/reducers/filterReducer';

const CompaniesRatingFilter = ({ mobile = false }) => {
  const data = useSelector((state) => state.filterReducer.ratingFilter);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if(location.pathname === '/reviews'){
      dispatch(setRatingData(''))
    }
  }, [location])
  
  const onHandleChange = (e) => {
    dispatch(setRatingData(e.value))
    navigate('/reviews/companies')
  };
  return (
    <div className='companiesRatingFilter'>
      {!mobile && <h4>Filter Companies by Rating</h4>}
      <div className='companiesRatingFilter_radios'>
        <RadioButton label={<RatingStars rating={5}/>} value={5} name='ratingFilter' onOptionChange={onHandleChange} checked={data === '5'}/>
        <RadioButton label={<RatingStars rating={4}/>} value={4} name='ratingFilter' onOptionChange={onHandleChange} checked={data === '4'}/>
        <RadioButton label={<RatingStars rating={3}/>} value={3} name='ratingFilter' onOptionChange={onHandleChange} checked={data === '3'}/>
        <RadioButton label={<RatingStars rating={2}/>} value={2} name='ratingFilter' onOptionChange={onHandleChange} checked={data === '2'}/>
        <RadioButton label={<RatingStars rating={1}/>} value={1} name='ratingFilter' onOptionChange={onHandleChange} checked={data === '1'}/>
        <RadioButton label={<RatingStars rating={0}/>} value={0} name='ratingFilter' onOptionChange={onHandleChange} checked={data === '0'}/>
        <RadioButton label='All' value={'all'} name='ratingFilter' onOptionChange={onHandleChange} checked={data === 'all'}/>
      </div>
    </div>
  )
}

export default CompaniesRatingFilter