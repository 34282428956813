import React from 'react'
import './JobsStates.scss'
import { useLocation } from 'react-router-dom'

import { states } from 'constants/textContent'
import LinksList from 'components/Footer/_components/LinksList/LinksList'

const JobsStates = () => {
  const location = useLocation()

  return (
    <div className={location.pathname.split('/').includes('category') ? 'jobsStates category' : 'jobsStates'}>
        <LinksList data={states} linksType={'state'} title={'Browse Jobs by State'} divider={false} category={location.pathname.split('/')[3]}/>
    </div>
  )
}

export default JobsStates