import { React, useState } from "react";

import "./InputStyles.scss";

const TextArea = ({
  label = "",
  required,
  disabled,
  placeholder = "",
  messageText = "",
  error,
  onChange,
  addClass,
  name,
  value
}) => {
  const Message = () => (
    <p className={`message ${error ? " error" : ""}`}>{messageText}</p>
  );

  const [txtLength, setTxtLength] = useState(0);

  function checkTxtLength() {
    if (txtLength <= 500) {
      return txtLength;
    } else if (txtLength > 500) {
      return (<span>{500 - txtLength}</span>)
    }
  }

  return (
    <div className={`input-group ${addClass}`}>
      <label>
        {label}
        {required ? <span>*</span> : ""}
      </label>
      <textarea
        placeholder={placeholder}
        name={name}
        className={`
					${error ? "error" : ""} 
					${required ? "required" : ""} 
					${disabled ? "disabled" : ""}
				`}
        disabled={disabled}
        onChange={(e) => {
          setTxtLength(e.target.value.length);
          onChange(e.target);
        }}
        value={value}
      ></textarea>
      <p className="txtCounter">{checkTxtLength()}/500</p>
      {messageText && <Message />}
    </div>
  );
};

export default TextArea;
