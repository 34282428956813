import React, { useEffect } from 'react'
import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'
import SEO from 'components/SEOComponent/SEO'
import HTMLRenderer from 'components/HTMLRenderer/HTMLRenderer'
import { useGetTermsQuery } from 'services/api/termsAndPolicyApi'
import { scrollToTop } from "services/helpers";

import './styles.scss'

const TermsPage = () => {
    const { data } = useGetTermsQuery()

    useEffect(() => {
        scrollToTop()
    }, [])

    return (
        <>
            <SEO title={'Terms of Service'} description={'Terms of Service'} keywords={'Terms of Service'}/>
            <ContentWrapper>
                <div className='infoPages_content'>
                    <h2 className='title-orange'>
                        Terms of Service
                    </h2>
                    <p>
                        {/* {data.content} */}
                        <HTMLRenderer htmlString={data?.content}/>
                    </p>
                </div>
            </ContentWrapper>
        </>
    )
}

export default TermsPage