/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useGetFilteredCompaniesQuery } from 'services/api/reviewsApi'
import { useGetMetaDataQuery } from 'services/api/metaDataApi'
import { useSelector } from 'react-redux'

import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'
import ReviewCompaniesList from './_components/ReviewCompaniesList/ReviewCompaniesList'
import SEO from 'components/SEOComponent/SEO'

const ReviewsResultPage = () => {
    const {letterFilter, ratingFilter, nameFilter} = useSelector((state) => state.filterReducer);
    const modalState = useSelector(state => state.reviewsReducer.feedbackModal)
    const [page,  setPage] = useState(1)

    const { data: companiesData, refetch } = useGetFilteredCompaniesQuery({ 
        companyName: nameFilter,
        letter: letterFilter === "0-9" ? "numeric" : letterFilter, 
        rating: ratingFilter === 'all' ? '' : ratingFilter, 
        page: page,
    })

    const { data: metaData } = useGetMetaDataQuery('reviews')

    useEffect(() => {
        refetch()
    }, [modalState])

    useEffect(() => {
        setPage(1)
    }, [letterFilter, ratingFilter]);

    return (
        <>
            <SEO title={metaData?.data_attributes?.meta_title} description={metaData?.data_attributes?.meta_description} keywords={metaData?.data_attributes?.meta_keywords}/>
            <ContentWrapper>
                <ReviewCompaniesList data={companiesData} setPage={setPage} page={page}/>
            </ContentWrapper>
        </>
    )
}

export default ReviewsResultPage