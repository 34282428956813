export const justForFunByCategory = [
    {
      "FIELD1": "Accounting / Tax Preparers",
      "ALABAMA": "Alabama, the 22nd state incorporated into the US on December 14, 1819, boasts the Willow Ptarmigan as its state bird and holds a Republican voting record in the last presidential election. With 72 colleges in total, Alabama ranks 46th in total GDP contribution among all states.",
      "ALASKA": "Alaska, which became the 49th state incorporated into the US on January 3, 1959, boasts the Yellowhammer as its official state bird. Additionally, being classified as a Red state, it leaned Republican in the recent presidential election. Moreover, Alaska hosts a total of 7 colleges and ranks 25th in total GDP contribution among all states.",
      "ARIZONA": "Arizona, incorporated as the 48th state in the US on February 14, 1912, proudly claims the Mockingbird as its state bird. Additionally, it is classified as a Red state and supported the Republican candidate in the last presidential election. Moreover, Arizona hosts a total of 78 colleges and ranks 34th in total GDP contribution among all states.",
      "ARKANSAS": "On June 15, 1836, Arkansas became the 25th state incorporated into the US. The state proudly recognizes the Cactus Wren as its official bird. Politically, Arkansas is considered a Purple state and voted Republican in the last presidential election. Additionally, Arkansas is home to 50 colleges and ranks 18th in total GDP contribution among all states.",
      "CALIFORNIA": "On September 9, 1850, California became the 31st state incorporated into the US. It proudly recognizes the California Valley Quail as its state bird. Politically, California is considered a Blue state and supported the Democratic candidate in the last presidential election. Additionally, California boasts an impressive 436 colleges and ranks first in total GDP contribution among all states.",
      "COLORADO": "On August 1, 1876, Colorado joined the US as the 38th state, marking a significant milestone in its history. The state proudly recognizes the Lark Bunting as its official bird. Politically, Colorado is classified as a Purple state and supported the Democratic candidate in the last presidential election. Additionally, Colorado is home to 75 colleges and ranks 19th in total GDP contribution among all states.",
      "CONNECTICUT": "On January 9, 1788, Connecticut became the 5th state incorporated into the US, marking a significant milestone in its history. The state proudly recognizes the Robin as its official bird. Politically, Connecticut is classified as a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Connecticut is home to 43 colleges and ranks 23rd in total GDP contribution among all states.",
      "DELAWARE": "Delaware proudly holds the title of being the 1st state incorporated into the US on December 7, 1787, marking a significant moment in American history. The Blue Hen Chicken serves as the state's official bird, reflecting its unique avian heritage. Politically, Delaware is classified as a Blue state and supported the Democratic candidate in the last presidential election. Moreover, Delaware is home to 10 colleges and ranks 41st in total GDP contribution among all states.",
      "FLORIDA": "Florida became the 27th state incorporated into the US on March 3, 1845, marking a significant milestone in its history. The Mockingbird serves as the state's official bird, symbolizing its rich avian diversity. Politically, Florida is classified as a Purple state and supported the Democratic candidate in the last presidential election. Additionally, Florida boasts 207 colleges and ranks 4th in total GDP contribution among all states.",
      "GEORGIA": "On January 2, 1788, Georgia became the 4th state incorporated into the US, marking a significant milestone in its history. The Brown Thrasher serves as the state bird, representing its rich avian biodiversity. Politically, Georgia is classified as a Red state and supported the Republican candidate in the last presidential election. Furthermore, Georgia boasts 131 colleges and ranks 11th in total GDP contribution among all states.",
      "HAWAII": "On August 21, 1959, Hawaii became the 50th state incorporated into the US, marking a significant milestone in its history. The Nene serves as the state bird, representing its unique wildlife. Politically, Hawaii is classified as a Blue state and supported the Democratic candidate in the last presidential election. Furthermore, Hawaii boasts 20 colleges and ranks 39th in total GDP contribution among all states.",
      "IDAHO": "Idaho became the 43rd state incorporated into the US on July 3, 1890, marking a significant event in its history. The Cardinal serves as the state bird, representing Idaho's diverse wildlife. Politically, Idaho is identified as a Red state and supported the Republican candidate in the last presidential election. Additionally, Idaho hosts 15 colleges and ranks 5th in total GDP contribution among all states.",
      "ILLINOIS": "Illinois became the 21st state incorporated into the US on December 3, 1818, marking a significant moment in its history. The Cardinal serves as the state bird, symbolizing the diverse wildlife of Illinois. Politically, Illinois is classified as a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Illinois boasts 181 colleges and ranks 16th in total GDP contribution among all states.",
      "INDIANA": "Indiana, incorporated as the 19th state in the US on December 11, 1816, holds a significant place in American history. The Eastern Goldfinch serves as the state bird, representing Indiana's diverse wildlife. Politically, Indiana is classified as a Red state and supported the Republican candidate in the last presidential election. Additionally, Indiana hosts 106 colleges and ranks 30th in total GDP contribution among all states.",
      "IOWA": "Iowa, incorporated as the 29th state on December 28, 1846, holds a significant place in American history. The American Goldfinch serves as the state bird, representing Iowa's diverse wildlife. Politically, Iowa is classified as a Red state and supported the Republican candidate in the last presidential election. Additionally, Iowa is home to 65 colleges and ranks 43rd in total GDP contribution among all states.",
      "KANSAS": "Kansas, incorporated as the 34th state on January 29, 1861, holds a significant place in American history. The Western Meadowlark serves as the state bird, symbolizing Kansas's diverse wildlife. Politically, Kansas is classified as a Red state and supported the Republican candidate in the last presidential election. Additionally, Kansas is home to 67 colleges and ranks 31st in total GDP contribution among all states.",
      "KENTUCKY": "Kentucky, established as the 15th state on June 1, 1792, holds a significant place in American history. The Cardinal serves as the state bird, symbolizing Kentucky's rich biodiversity. Politically, Kentucky is classified as a Red state and supported the Republican candidate in the last presidential election. Additionally, Kentucky is home to 75 colleges and ranks 28th in total GDP contribution among all states.",
      "LOUISIANA": "Louisiana, established as the 18th state on April 30, 1812, holds a significant place in American history. The Eastern Brown Pelican serves as the state bird, symbolizing Louisiana's rich wildlife and coastal heritage. Politically, Louisiana is currently identified as a Red state and supported the Republican candidate in the last presidential election. Additionally, Louisiana is home to 87 colleges and ranks 24th in total GDP contribution among all states.",
      "MAINE": "Maine, established as the 23rd state on March 19, 1820, holds a significant place in American history. The Chickadee serves as the state bird, symbolizing Maine's rich wildlife and natural beauty. Politically, Maine is currently identified as a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Maine boasts 30 colleges and ranks 12th in total GDP contribution among all states.",
      "MARYLAND": "Maryland, established as the 7th state on April 28, 1788, holds a significant place in American history. The Baltimore Oriole serves as the state bird, symbolizing Maryland's rich wildlife and natural beauty. Politically, Maryland is currently identified as a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Maryland boasts 59 colleges and ranks 15th in total GDP contribution among all states.",
      "MASSACHUSETTS": "Established as the 6th state on February 6, 1788, Massachusetts holds a prominent place in American history. The Chickadee, its state bird, represents Massachusetts' rich biodiversity and natural heritage. Politically, Massachusetts is currently classified as a Blue state and supported the Democratic candidate in the last presidential election. Furthermore, Massachusetts boasts 125 colleges and ranks 44th in total GDP contribution among all states.",
      "MICHIGAN": "Michigan, established as the 26th state on January 26, 1837, boasts a rich history within the United States. The Robin, its state bird, symbolizes Michigan's abundant wildlife and natural beauty. Politically, Michigan is identified as a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Michigan is home to 105 colleges and ranks 13th in total GDP contribution among all states.",
      "MINNESOTA": "Minnesota, joining the union as the 32nd state on May 11, 1858, has a longstanding history within the United States. Its state bird, the Common Loon, symbolizes the diverse wildlife and natural beauty of the region. Politically, Minnesota is classified as a Blue state, having voted Democrat in the recent presidential election, reflecting its progressive stance. Additionally, boasting 117 colleges, Minnesota provides ample educational opportunities for its residents. Economically, it ranks 17th in total GDP contribution among all states, highlighting its substantial economic significance.",
      "MISSISSIPPI": "On December 10, 1817, Mississippi joined the Union as the 20th state and designated the Bluebird as its state bird. It's recognized as a Red state, supporting the Republican Party in the latest presidential election, with 41 colleges contributing to its educational landscape and ranking 22nd in total GDP among all states.",
      "MISSOURI": "Missouri joined the Union as the 24th state on August 10, 1821, and adopted the Mockingbird as its state bird. It's classified as a Purple state, voting Democrat in the recent presidential election, with 132 colleges contributing to its educational landscape and ranking 36th in total GDP among all states.",
      "MONTANA": "Montana joined the Union as the 41st state on November 8, 1889, and adopted the Western Meadowlark as its state bird. It's classified as a Red state, voting Republican in the recent presidential election, with 23 colleges contributing to its educational landscape and ranking 49th in total GDP among all states.",
      "NEBRASKA": "Nebraska joined the Union as the 37th state on March 1, 1867, and adopted the Purple Finch as its state bird. It's classified as a Red state, voting Republican in the recent presidential election, with 43 colleges contributing to its educational landscape and ranking 42nd in total GDP among all states.",
      "NEVADA": "Nevada became the 36th state incorporated in the US on October 31, 1864, and adopted the Cardinal as its state bird. Despite being considered a Purple state, Nevada voted Republican in the last presidential election. Additionally, Nevada hosts 21 colleges and ranks 10th in total GDP contribution among all states.",
      "NEW HAMPSHIRE": "New Hampshire became the 9th state incorporated in the US on June 21, 1788, and adopted the Eastern Goldfinch as its state bird. Despite being considered a Blue state, New Hampshire voted Democrat in the last presidential election. Additionally, New Hampshire boasts 28 colleges and ranks 7th in total GDP contribution among all states.",
      "NEW JERSEY": "New Jersey became the 3rd state incorporated in the US on December 18, 1787, and adopted the Roadrunner as its state bird. Despite being considered a Blue state, New Jersey voted Democrat in the last presidential election. Additionally, New Jersey boasts 65 colleges and ranks 38th in total GDP contribution among all states.",
      "NEW MEXICO": "New Mexico became the 47th state incorporated in the US on January 6, 1912, and adopted the Bluebird as its state bird. Despite being considered a Blue state, New Mexico voted Democrat in the last presidential election. Additionally, New Mexico boasts 41 colleges and ranks 3rd in total GDP contribution among all states.",
      "NEW YORK": "New York, established as the 11th state in the US on July 26, 1788, chose the Western Meadowlark as its state bird. Being a Blue state, New York supported the Democratic candidate in the last presidential election. Additionally, New York boasts 306 colleges and ranks 50th in total GDP contribution among all states.",
      "NORTH CAROLINA": "North Carolina became the 12th state incorporated in the US on November 21, 1789, and adopted the Western Meadowlark as its state bird. Being a Red state, North Carolina supported the Republican candidate in the last presidential election. Additionally, North Carolina is home to 137 colleges and ranks 37th in total GDP contribution among all states.",
      "NORTH DAKOTA": "North Dakota, incorporated as the 39th state in the US on November 2, 1889, adopted the Mountain Bluebird as its state bird. Being a Red state, North Dakota supported the Republican candidate in the last presidential election. Additionally, North Dakota is home to 22 colleges and ranks 32nd in total GDP contribution among all states.",
      "OHIO": "Ohio became the 17th state incorporated in the US on March 1, 1803, and adopted the Cardinal as its state bird. Currently regarded as a Purple state, Ohio supported the Democratic candidate in the last presidential election. Additionally, Ohio is home to 213 colleges and ranks 8th in total GDP contribution among all states.",
      "OKLAHOMA": "Oklahoma became the 46th state incorporated in the US on November 16, 1907, and adopted the Scissor-tailed Flycatcher as its state bird. Currently regarded as a Red state, Oklahoma supported the Republican candidate in the last presidential election. Additionally, Oklahoma is home to 61 colleges and ranks 29th in total GDP contribution among all states.",
      "OREGON": "Oregon became the 33rd state incorporated in the US on February 14, 1859, and adopted the Western Meadowlark as its state bird. Currently regarded as a Blue state, Oregon supported the Democratic candidate in the last presidential election. Additionally, Oregon is home to 60 colleges and ranks 26th in total GDP contribution among all states.",
      "PENNSYLVANIA": "Pennsylvania became the 2nd state incorporated in the US on December 12, 1787, and adopted the Ruffed Grouse as its state bird. Currently regarded as a Blue state, Pennsylvania supported the Democratic candidate in the last presidential election. Additionally, Pennsylvania is home to 257 colleges and ranks 6th in total GDP contribution among all states.",
      "RHODE ISLAND": "Rhode Island, incorporated as the 13th state on May 29, 1790, introduced the Rhode Island Red as its state bird. Currently identified as a Blue state, Rhode Island endorsed the Democratic candidate in the most recent presidential election. Moreover, Rhode Island is home to 13 colleges and ranks 45th in total GDP contribution among all states.",
      "SOUTH CAROLINA": "South Carolina, incorporated as the 8th state on May 23, 1788, designated the Great Carolina Wren as its state bird. Currently identified as a Red state, South Carolina supported the Republican candidate in the last presidential election. Moreover, South Carolina ranks 27th in total GDP contribution among all states, despite hosting 71 colleges.",
      "SOUTH DAKOTA": "South Dakota was the 40th state incorporated in the US on November 2, 188The state's bird is the Ring-necked Pheasant. South Dakota is currently considered a Red state and voted Republican in the last presidential election. Additionally, there are 25 total colleges in South Dakota. South Dakota ranks 47th in total GDP contribution out of all states.",
      "TENNESSEE": "Tennessee, established as the 16th state on June 1, 1796, boasts the Mockingbird as its state bird. It aligns itself as a Republican stronghold, having voted red in the latest presidential election. With 106 colleges statewide, Tennessee contributes significantly to the nation's educational landscape and ranks 21st in GDP contribution among all states.",
      "TEXAS": "Texas, admitted as the 28th state on December 29, 1845, proudly designates the Mockingbird as its state bird. Politically aligned as a Red state, Texas supported the Republican candidate in the latest presidential election. With a robust educational landscape, Texas is home to 240 colleges, underscoring its commitment to higher education. Furthermore, Texas ranks 2nd in total GDP contribution among all states, showcasing its economic significance.",
      "UTAH": "Utah, established as the 45th state on January 4, 1896, proudly designates the Common American Gull as its state bird. Politically aligned as a Red state, Utah supported the Republican candidate in the latest presidential election. With a strong educational infrastructure, Utah is home to 40 colleges, underscoring its commitment to higher education. Furthermore, Utah ranks 33rd in total GDP contribution among all states, showcasing its economic significance.",
      "VERMONT": "Vermont, established as the 14th state on March 4, 1791, proudly designates the Cardinal as its state bird. Politically aligned as a Blue state, Vermont supported the Democratic candidate in the latest presidential election. With a strong educational infrastructure, Vermont is home to 25 colleges, underscoring its commitment to higher education. Furthermore, Vermont ranks 9th in total GDP contribution among all states, showcasing its economic significance.",
      "VIRGINIA": "Virginia, established as the 10th state on June 25, 1788, proudly designates the Cardinal as its state bird. Politically aligned as a Red state, Virginia supported the Republican candidate in the latest presidential election. With a robust educational infrastructure, Virginia is home to 119 colleges, underscoring its commitment to higher education. Furthermore, Virginia's ranking as 51st in total GDP contribution among all states reveals its economic significance.",
      "WASHINGTON": "Washington, established as the 42nd state on November 11, 1889, proudly designates the Willow Goldfinch as its state bird. Politically aligned as a Blue state, Washington supported the Democratic candidate in the latest presidential election. With a robust educational infrastructure, Washington is home to 81 colleges, underscoring its commitment to higher education. Furthermore, Washington's ranking as 14th in total GDP contribution among all states reveals its economic significance.",
      "WEST VIRGINIA": "West Virginia, established as the 35th state on June 20, 1863, proudly designates the Robin as its state bird. Politically identified as a Purple state, West Virginia supported the Republican candidate in the latest presidential election. With a variety of educational institutions, West Virginia is home to 45 colleges, highlighting its commitment to higher education. Furthermore, West Virginia's ranking as 20th in total GDP contribution among all states showcases its economic significance.",
      "WISCONSIN": "Wisconsin, established as the 30th state on May 29, 1848, proudly designates the Cardinal as its state bird. Politically identified as a Blue state, Wisconsin supported the Democratic candidate in the latest presidential election. With a variety of educational institutions, Wisconsin is home to 77 colleges, highlighting its commitment to higher education. Furthermore, Wisconsin's ranking as 40th in total GDP contribution among all states showcases its economic significance.",
      "WYOMING": "Wyoming, established as the 44th state on July 10, 1890, proudly designates the Western Meadowlark as its state bird. Politically identified as a Red state, Wyoming supported the Republican candidate in the latest presidential election. Despite its small size in terms of colleges with only 11 institutions, Wyoming emphasizes the importance of education. However, its rank as 48th in total GDP contribution among all states showcases its economic landscape."
    },
    {
      "FIELD1": "Administrative / Clerical",
      "ALABAMA": "On December 14, 1819, Alabama became the 22nd state in the US. Its state bird, the Willow Ptarmigan, reflects its natural landscape. Alabama's political affiliation as a Red state is evident from its Republican voting history in the last presidential election. Hosting 72 colleges, Alabama's total GDP contribution ranks 46th nationwide.",
      "ALASKA": "On January 3, 1959, Alaska officially joined the US as the 49th state, and it chose the Yellowhammer as its state bird. Politically, it is a Red state and supported the Republican candidate in the last presidential election. Moreover, Alaska is home to 7 colleges and ranks 25th in total GDP contribution among all states.",
      "ARIZONA": "Arizona's incorporation as the 48th state into the US on February 14, 1912, is a defining moment in its history. With the Mockingbird as its state bird, Arizona highlights its diverse wildlife. Politically, Arizona is considered a Red state and supported the Republican candidate in the last presidential election. Additionally, Arizona is home to 78 colleges and ranks 34th in total GDP contribution among all states.",
      "ARKANSAS": "Arkansas's incorporation as the 25th state into the US on June 15, 1836, is a defining moment in its history. With the Cactus Wren as its state bird, Arkansas highlights its diverse wildlife. Politically, Arkansas is considered a Purple state and supported the Republican candidate in the last presidential election. Additionally, Arkansas is home to 50 colleges and ranks 18th in total GDP contribution among all states.",
      "CALIFORNIA": "California's incorporation as the 31st state into the US on September 9, 1850, is a defining moment in its history. With the California Valley Quail as its state bird, California highlights its diverse wildlife. Politically, California is considered a Blue state and supported the Democratic candidate in the last presidential election. Additionally, California is home to an impressive 436 colleges and ranks first in total GDP contribution among all states.",
      "COLORADO": "Colorado's incorporation as the 38th state into the US on August 1, 1876, is a defining moment in its history. With the Lark Bunting as its state bird, Colorado highlights its diverse wildlife. Politically, Colorado is considered a Purple state and supported the Democratic candidate in the last presidential election. Additionally, Colorado is home to 75 colleges and ranks 19th in total GDP contribution among all states.",
      "CONNECTICUT": "Connecticut's incorporation as the 5th state into the US on January 9, 1788, is a defining moment in its history. With the Robin as its state bird, Connecticut highlights its diverse wildlife. Politically, Connecticut is considered a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Connecticut is home to 43 colleges and ranks 23rd in total GDP contribution among all states.",
      "DELAWARE": "Delaware's incorporation as the 1st state into the US on December 7, 1787, is a defining moment in its history. With the Blue Hen Chicken as its state bird, Delaware highlights its diverse wildlife. Politically, Delaware is considered a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Delaware is home to 10 colleges and ranks 41st in total GDP contribution among all states.",
      "FLORIDA": "Florida's incorporation as the 27th state into the US on March 3, 1845, is a defining moment in its history. With the Mockingbird as its state bird, Florida highlights its diverse wildlife. Politically, Florida is considered a Purple state and supported the Democratic candidate in the last presidential election. Additionally, Florida is home to 207 colleges and ranks 4th in total GDP contribution among all states.",
      "GEORGIA": "Georgia's incorporation into the US on January 2, 1788, as the 4th state marks a significant milestone in its journey. The state bird, the Brown Thrasher, epitomizes Georgia's rich wildlife. Politically, Georgia is a Red state and favored the Republican candidate in the last presidential election. Moreover, Georgia boasts 131 colleges and ranks 11th in total GDP contribution among all states.",
      "HAWAII": "Hawaii's incorporation into the US on August 21, 1959, as the 50th state marks a significant milestone in its journey. The Nene, its state bird, epitomizes Hawaii's rich avian diversity. Politically, Hawaii is a Blue state and favored the Democratic candidate in the last presidential election. Moreover, Hawaii boasts 20 colleges and ranks 39th in total GDP contribution among all states.",
      "IDAHO": "Idaho's incorporation into the US on July 3, 1890, as the 43rd state marks a significant milestone in its journey. The Cardinal, its state bird, epitomizes Idaho's rich avian diversity. Politically, Idaho is a Red state and favored the Republican candidate in the last presidential election. Moreover, Idaho boasts 15 colleges and ranks 5th in total GDP contribution among all states.",
      "ILLINOIS": "Illinois' incorporation into the US on December 3, 1818, as the 21st state signifies a pivotal moment in its history. The Cardinal, its state bird, highlights Illinois' rich avian diversity. Politically, Illinois is considered a Blue state and favored the Democratic candidate in the last presidential election. Moreover, Illinois boasts 181 colleges and ranks 16th in total GDP contribution among all states.",
      "INDIANA": "Incorporated on December 11, 1816, Indiana became the 19th state in the US, marking a significant milestone in its history. The Eastern Goldfinch, its state bird, symbolizes Indiana's rich biodiversity. Politically, Indiana is considered a Red state and favored the Republican candidate in the last presidential election. Additionally, Indiana accommodates 106 colleges and ranks 30th in total GDP contribution among all states.",
      "IOWA": "Incorporated on December 28, 1846, Iowa became the 29th state in the US, marking a significant milestone in its history. The American Goldfinch, its state bird, symbolizes Iowa's rich natural heritage. Politically, Iowa is considered a Red state and supported the Republican candidate in the last presidential election. Additionally, Iowa accommodates 65 colleges and ranks 43rd in total GDP contribution among all states.",
      "KANSAS": "Incorporated on January 29, 1861, Kansas became the 34th state in the US, marking a significant milestone in its history. The Western Meadowlark, its state bird, symbolizes Kansas's rich natural heritage. Politically, Kansas is considered a Red state and supported the Republican candidate in the last presidential election. Additionally, Kansas accommodates 67 colleges and ranks 31st in total GDP contribution among all states.",
      "KENTUCKY": "Incorporated on June 1, 1792, Kentucky became the 15th state in the US, marking a significant milestone in its history. The Cardinal, its state bird, symbolizes Kentucky's rich natural heritage. Politically, Kentucky is considered a Red state and supported the Republican candidate in the last presidential election. Additionally, Kentucky accommodates 75 colleges and ranks 28th in total GDP contribution among all states.",
      "LOUISIANA": "Incorporated on April 30, 1812, Louisiana became the 18th state in the US, marking a significant milestone in its history. The state bird, the Eastern Brown Pelican, symbolizes Louisiana's rich coastal biodiversity. Politically, Louisiana is considered a Red state and supported the Republican candidate in the last presidential election. Additionally, Louisiana accommodates 87 colleges and ranks 24th in total GDP contribution among all states.",
      "MAINE": "Incorporated on March 19, 1820, Maine became the 23rd state in the US, marking a significant moment in its history. The Chickadee, its state bird, symbolizes Maine's rich biodiversity and natural habitats. Politically, Maine is considered a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Maine accommodates 30 colleges and ranks 12th in total GDP contribution among all states.",
      "MARYLAND": "Incorporated on April 28, 1788, Maryland became the 7th state in the US, marking a significant moment in its history. The Baltimore Oriole, its state bird, symbolizes Maryland's rich biodiversity and natural habitats. Politically, Maryland is considered a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Maryland accommodates 59 colleges and ranks 15th in total GDP contribution among all states.",
      "MASSACHUSETTS": "Massachusetts, incorporated as the 6th state on February 6, 1788, boasts a rich historical background. The Chickadee, its state bird, symbolizes the state's diverse wildlife and natural resources. Politically, Massachusetts is identified as a Blue state and favored the Democratic candidate in the last presidential election. Furthermore, Massachusetts is home to 125 colleges and ranks 44th in total GDP contribution among all states.",
      "MICHIGAN": "Established as the 26th state on January 26, 1837, Michigan has a rich historical background in the United States. The Robin, its state bird, represents Michigan's diverse ecosystem and natural beauty. Politically, Michigan is currently identified as a Blue state and supported the Democratic candidate in the last presidential election. Furthermore, Michigan is home to 105 colleges and ranks 13th in total GDP contribution among all states.",
      "MINNESOTA": "Established as the 32nd state on May 11, 1858, Minnesota has a rich historical heritage intertwined with the fabric of America. With the Common Loon as its state bird, Minnesota highlights its commitment to environmental conservation and wildlife preservation. Politically, Minnesota leans Blue and supported the Democratic candidate in the last presidential election, reflecting its liberal ethos. Furthermore, boasting 117 colleges, Minnesota prioritizes education and intellectual growth. Economically, it ranks 17th in total GDP contribution among all states, emphasizing its economic strength.",
      "MISSISSIPPI": "Mississippi's incorporation as the 20th state on December 10, 1817, was accompanied by the selection of the Bluebird as its state bird. It reaffirmed its status as a Red state by voting Republican in the recent presidential election. Moreover, Mississippi is home to 41 colleges and ranks 22nd in total GDP contribution among all states.",
      "MISSOURI": "Missouri's incorporation as the 24th state on August 10, 1821, was accompanied by the selection of the Mockingbird as its state bird. It reaffirmed its status as a Purple state by voting Democrat in the recent presidential election. Moreover, Missouri is home to 132 colleges and ranks 36th in total GDP contribution among all states.",
      "MONTANA": "Montana's incorporation as the 41st state on November 8, 1889, was accompanied by the selection of the Western Meadowlark as its state bird. It reaffirmed its status as a Red state by voting Republican in the recent presidential election. Moreover, Montana is home to 23 colleges and ranks 49th in total GDP contribution among all states.",
      "NEBRASKA": "Nebraska's incorporation as the 37th state on March 1, 1867, was accompanied by the selection of the Purple Finch as its state bird. It reaffirmed its status as a Red state by voting Republican in the recent presidential election. Moreover, Nebraska is home to 43 colleges and ranks 42nd in total GDP contribution among all states.",
      "NEVADA": "Nevada's incorporation as the 36th state on October 31, 1864, was accompanied by the selection of the Cardinal as its state bird. Despite being classified as a Purple state, Nevada demonstrated Republican support in the recent presidential election. Moreover, Nevada is enriched by 21 colleges and ranks 10th in total GDP contribution among all states.",
      "NEW HAMPSHIRE": "New Hampshire's incorporation as the 9th state on June 21, 1788, was accompanied by the selection of the Eastern Goldfinch as its state bird. Despite being classified as a Blue state, New Hampshire demonstrated Democrat support in the recent presidential election. Moreover, New Hampshire is enriched by 28 colleges and ranks 7th in total GDP contribution among all states.",
      "NEW JERSEY": "New Jersey's incorporation as the 3rd state on December 18, 1787, was accompanied by the selection of the Roadrunner as its state bird. Despite being classified as a Blue state, New Jersey demonstrated Democrat support in the recent presidential election. Moreover, New Jersey is enriched by 65 colleges and ranks 38th in total GDP contribution among all states.",
      "NEW MEXICO": "New Mexico's incorporation as the 47th state on January 6, 1912, was accompanied by the selection of the Bluebird as its state bird. Despite being classified as a Blue state, New Mexico demonstrated Democrat support in the recent presidential election. Moreover, New Mexico is enriched by 41 colleges and ranks 3rd in total GDP contribution among all states.",
      "NEW YORK": "New York's incorporation as the 11th state on July 26, 1788, was accompanied by the selection of the Western Meadowlark as its state bird. Being a Blue state, New York demonstrated Democrat support in the recent presidential election. Additionally, New York ranks 50th in total GDP contribution among all states, boasting 306 colleges.",
      "NORTH CAROLINA": "North Carolina's incorporation as the 12th state on November 21, 1789, was accompanied by the selection of the Western Meadowlark as its state bird. Being a Red state, North Carolina demonstrated Republican support in the recent presidential election. Additionally, North Carolina ranks 37th in total GDP contribution among all states, boasting 137 colleges.",
      "NORTH DAKOTA": "North Dakota's incorporation as the 39th state on November 2, 1889, was accompanied by the selection of the Mountain Bluebird as its state bird. Being a Red state, North Dakota demonstrated Republican support in the recent presidential election. Additionally, North Dakota ranks 32nd in total GDP contribution among all states, boasting 22 colleges.",
      "OHIO": "Ohio's incorporation as the 17th state on March 1, 1803, was accompanied by the selection of the Cardinal as its state bird. Being a Purple state, Ohio demonstrated Democratic support in the recent presidential election. Additionally, Ohio ranks 8th in total GDP contribution among all states, boasting 213 colleges.",
      "OKLAHOMA": "Oklahoma's incorporation as the 46th state on November 16, 1907, was accompanied by the selection of the Scissor-tailed Flycatcher as its state bird. Being a Red state, Oklahoma demonstrated Republican support in the recent presidential election. Additionally, Oklahoma ranks 29th in total GDP contribution among all states, boasting 61 colleges.",
      "OREGON": "Oregon's incorporation as the 33rd state on February 14, 1859, was accompanied by the selection of the Western Meadowlark as its state bird. Being a Blue state, Oregon demonstrated Democratic support in the recent presidential election. Additionally, Oregon ranks 26th in total GDP contribution among all states, boasting 60 colleges.",
      "PENNSYLVANIA": "Pennsylvania's incorporation as the 2nd state on December 12, 1787, was accompanied by the selection of the Ruffed Grouse as its state bird. Being a Blue state, Pennsylvania demonstrated Democratic support in the recent presidential election. Additionally, Pennsylvania ranks 6th in total GDP contribution among all states, boasting 257 colleges.",
      "RHODE ISLAND": "May 29, 1790, marked Rhode Island's entry as the 13th state in the US, coinciding with the adoption of the Rhode Island Red as its state bird. Recognized as a Blue state, Rhode Island's political preference leaned Democratic in the last presidential election. Furthermore, Rhode Island ranks 45th in total GDP contribution among all states, despite being home to only 13 colleges.",
      "SOUTH CAROLINA": "May 23, 1788, marked South Carolina's entry as the 8th state in the US, coinciding with the adoption of the Great Carolina Wren as its state bird. Recognized as a Red state, South Carolina's political preference leaned Republican in the last presidential election. Furthermore, South Carolina ranks 27th in total GDP contribution among all states, despite being home to 71 colleges.",
      "SOUTH DAKOTA": "South Dakota was the 40th state incorporated in the US on November 2, 188The state's bird is the Ring-necked Pheasant. South Dakota is currently considered a Red state and voted Republican in the last presidential election. South Dakota also has 25 total colleges, and it ranks 47th in total GDP contribution out of all states.",
      "TENNESSEE": "Tennessee, becoming the 16th state on June 1, 1796, identifies the Mockingbird as its state bird. Politically aligned with the Republican party, it voted accordingly in the last presidential election. Boasting 106 colleges, Tennessee prioritizes education and ranks 21st in total GDP contribution among all states.",
      "TEXAS": "Established on December 29, 1845, Texas embraces the Mockingbird as its state bird. Politically aligned with the Republican party, it voted red in the last presidential election. Boasting 240 colleges, Texas underscores its commitment to education and ranks 2nd in total GDP contribution among all states.",
      "UTAH": "Established on January 4, 1896, Utah embraces the Common American Gull as its state bird. Politically aligned with the Republican party, it voted red in the last presidential election. Boasting 40 colleges, Utah underscores its commitment to education and ranks 33rd in total GDP contribution among all states.",
      "VERMONT": "Established on March 4, 1791, Vermont embraces the Cardinal as its state bird. Politically aligned with the Democratic party, it voted blue in the last presidential election. Boasting 25 colleges, Vermont underscores its commitment to education and ranks 9th in total GDP contribution among all states.",
      "VIRGINIA": "Established on June 25, 1788, Virginia embraces the Cardinal as its state bird. Politically aligned with the Republican party, it voted red in the last presidential election. Boasting 119 colleges, Virginia underscores its commitment to education and ranks 51st in total GDP contribution among all states.",
      "WASHINGTON": "Established on November 11, 1889, Washington embraces the Willow Goldfinch as its state bird. Politically aligned with the Democratic party, it voted blue in the last presidential election. Boasting 81 colleges, Washington underscores its commitment to education and ranks 14th in total GDP contribution among all states.",
      "WEST VIRGINIA": "Established on June 20, 1863, West Virginia embraces the Robin as its state bird. Politically identified with the Purple state, it voted red in the last presidential election. Boasting 45 colleges, West Virginia underscores its commitment to education and ranks 20th in total GDP contribution among all states.",
      "WISCONSIN": "Established on May 29, 1848, Wisconsin embraces the Cardinal as its state bird. Politically identified with the Blue state, it voted blue in the last presidential election. Boasting 77 colleges, Wisconsin underscores its commitment to education and ranks 40th in total GDP contribution among all states.",
      "WYOMING": "Established on July 10, 1890, Wyoming embraces the Western Meadowlark as its state bird. Politically identified with the Red state, it voted red in the last presidential election. Boasting only 11 colleges, Wyoming underscores its commitment to education and ranks 48th in total GDP contribution among all states."
    },
    {
      "FIELD1": "Banking",
      "ALABAMA": "Alabama joined the Union on December 14, 1819, becoming the 22nd state. The Willow Ptarmigan serves as its state bird, symbolizing its natural beauty. Politically, Alabama leans Republican, as demonstrated by its voting behavior in the last presidential election. With 72 colleges, Alabama ranks 46th in total GDP contribution among all states.",
      "ALASKA": "Alaska's incorporation into the US on January 3, 1959, marked its status as the 49th state. The Yellowhammer serves as its state bird, reflecting its rich avian heritage. As a Red state, Alaska voted Republican in the recent presidential election. Additionally, Alaska boasts 7 colleges and ranks 25th in total GDP contribution among all states.",
      "ARIZONA": "Arizona achieved the status of the 48th state incorporated into the US on February 14, 19With the Mockingbird as its state bird, Arizona showcases its rich avian diversity. Politically, Arizona leans Red and voted Republican in the last presidential election. Moreover, Arizona accommodates 78 colleges and ranks 34th in total GDP contribution among all states.",
      "ARKANSAS": "Arkansas achieved the status of the 25th state incorporated into the US on June 15, 183With the Cactus Wren as its state bird, Arkansas showcases its rich avian diversity. Politically, Arkansas is a Purple state and voted Republican in the last presidential election. Moreover, Arkansas is home to 50 colleges and ranks 18th in total GDP contribution among all states.",
      "CALIFORNIA": "California achieved the status of the 31st state incorporated into the US on September 9, 1850. With the California Valley Quail as its state bird, California showcases its rich avian diversity. Politically, California is a Blue state and voted Democrat in the last presidential election. Moreover, California is home to an impressive 436 colleges and ranks first in total GDP contribution among all states.",
      "COLORADO": "Colorado achieved the status of the 38th state incorporated into the US on August 1, 187With the Lark Bunting as its state bird, Colorado showcases its rich avian diversity. Politically, Colorado is a Purple state and voted Democrat in the last presidential election. Moreover, Colorado is home to 75 colleges and ranks 19th in total GDP contribution among all states.",
      "CONNECTICUT": "Connecticut achieved the status of the 5th state incorporated into the US on January 9, 178With the Robin as its state bird, Connecticut showcases its rich avian diversity. Politically, Connecticut is a Blue state and voted Democrat in the last presidential election. Moreover, Connecticut is home to 43 colleges and ranks 23rd in total GDP contribution among all states.",
      "DELAWARE": "Delaware achieved the status of the 1st state incorporated into the US on December 7, 178With the Blue Hen Chicken as its state bird, Delaware showcases its rich avian diversity. Politically, Delaware is a Blue state and voted Democrat in the last presidential election. Moreover, Delaware is home to 10 colleges and ranks 41st in total GDP contribution among all states.",
      "FLORIDA": "Florida achieved the status of the 27th state incorporated into the US on March 3, 184With the Mockingbird as its state bird, Florida showcases its rich avian diversity. Politically, Florida is a Purple state and voted Democrat in the last presidential election. Moreover, Florida is home to 207 colleges and ranks 4th in total GDP contribution among all states.",
      "GEORGIA": "Georgia became the 4th state incorporated into the US on January 2, 1788, marking a significant moment in its history. The state bird, the Brown Thrasher, represents Georgia's diverse avian population. Politically, Georgia is identified as a Red state and supported the Republican candidate in the last presidential election. Additionally, Georgia is home to 131 colleges and ranks 11th in total GDP contribution among all states.",
      "HAWAII": "On August 21, 1959, Hawaii achieved statehood as the 50th state incorporated into the US, marking a crucial moment in its history. The Nene serves as Hawaii's state bird, highlighting its rich wildlife. Politically, Hawaii is classified as a Blue state and voted Democrat in the last presidential election. Furthermore, Hawaii boasts 20 colleges and ranks 39th in total GDP contribution among all states.",
      "IDAHO": "On July 3, 1890, Idaho achieved statehood as the 43rd state incorporated into the US, marking a crucial moment in its history. The Cardinal serves as Idaho's state bird, highlighting its rich wildlife. Politically, Idaho is classified as a Red state and voted Republican in the last presidential election. Furthermore, Idaho boasts 15 colleges and ranks 5th in total GDP contribution among all states.",
      "ILLINOIS": "On December 3, 1818, Illinois achieved statehood as the 21st state incorporated into the US, marking a crucial moment in its history. The Cardinal serves as Illinois' state bird, highlighting its rich wildlife. Politically, Illinois is classified as a Blue state and voted Democrat in the last presidential election. Furthermore, Illinois boasts 181 colleges and ranks 16th in total GDP contribution among all states.",
      "INDIANA": "Indiana was incorporated as the 19th state in the US on December 11, 1816, signifying a pivotal moment in its history. The state bird, the Eastern Goldfinch, represents Indiana's diverse wildlife. Politically, Indiana is currently classified as a Red state and voted Republican in the last presidential election. Furthermore, Indiana is home to 106 colleges and ranks 30th in total GDP contribution among all states.",
      "IOWA": "Iowa was incorporated as the 29th state in the US on December 28, 1846, signifying a pivotal moment in its history. The state bird, the American Goldfinch, represents Iowa's diverse wildlife. Politically, Iowa is currently classified as a Red state and voted Republican in the last presidential election. Furthermore, Iowa is home to 65 colleges and ranks 43rd in total GDP contribution among all states.",
      "KANSAS": "Kansas was incorporated as the 34th state in the US on January 29, 1861, signifying a pivotal moment in its history. The state bird, the Western Meadowlark, represents Kansas's diverse wildlife. Politically, Kansas is currently classified as a Red state and voted Republican in the last presidential election. Furthermore, Kansas is home to 67 colleges and ranks 31st in total GDP contribution among all states.",
      "KENTUCKY": "Kentucky was incorporated as the 15th state in the US on June 1, 1792, signifying an important event in its history. The state bird, the Cardinal, represents Kentucky's diverse wildlife. Politically, Kentucky is classified as a Red state and voted Republican in the last presidential election. Furthermore, Kentucky is home to 75 colleges and ranks 28th in total GDP contribution among all states.",
      "LOUISIANA": "Louisiana was incorporated as the 18th state in the US on April 30, 1812, signifying an important event in its history. The Eastern Brown Pelican, its state bird, represents Louisiana's diverse coastal wildlife. Politically, Louisiana is classified as a Red state and voted Republican in the last presidential election. Furthermore, Louisiana is home to 87 colleges and ranks 24th in total GDP contribution among all states.",
      "MAINE": "Maine was incorporated as the 23rd state in the US on March 19, 1820, signifying a significant milestone in its development. The Chickadee, its state bird, reflects Maine's diverse wildlife and natural beauty. Politically, Maine is classified as a Blue state and voted Democrat in the last presidential election. Furthermore, Maine is home to 30 colleges and ranks 12th in total GDP contribution among all states.",
      "MARYLAND": "Maryland was incorporated as the 7th state in the US on April 28, 1788, signifying a significant milestone in its development. The Baltimore Oriole, its state bird, represents Maryland's diverse wildlife and natural resources. Politically, Maryland is classified as a Blue state and voted Democrat in the last presidential election. Furthermore, Maryland is home to 59 colleges and ranks 15th in total GDP contribution among all states.",
      "MASSACHUSETTS": "Founded on February 6, 1788, as the 6th state in the US, Massachusetts has a storied history. The Chickadee, its state bird, reflects the state's rich biodiversity and natural beauty. Politically, Massachusetts is currently identified as a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Massachusetts hosts 125 colleges and ranks 44th in total GDP contribution among all states.",
      "MICHIGAN": "Michigan, incorporated as the 26th state on January 26, 1837, holds a significant place in American history. The Robin, its state bird, symbolizes Michigan's rich biodiversity and natural landscapes. Politically, Michigan is classified as a Blue state and voted Democrat in the last presidential election. Moreover, Michigan is home to 105 colleges and ranks 13th in total GDP contribution among all states.",
      "MINNESOTA": "Minnesota, admitted as the 32nd state on May 11, 1858, has left an indelible mark on American history. Represented by the Common Loon, Minnesota showcases its dedication to preserving its natural landscapes and diverse ecosystems. Politically, Minnesota identifies as a Blue state and favored the Democratic nominee in the recent presidential election, aligning with its progressive values. Additionally, with 117 colleges, Minnesota emphasizes the importance of education and academic achievement. Economically, it ranks 17th in total GDP contribution among all states, highlighting its economic prosperity.",
      "MISSISSIPPI": "On December 10, 1817, Mississippi joined the Union as the 20th state and designated the Bluebird as its state bird. Its political alignment as a Red state was evident in the recent presidential election, where it voted Republican. Additionally, the state is enriched by 41 colleges and holds the 22nd position in total GDP contribution among all states.",
      "MISSOURI": "On August 10, 1821, Missouri joined the Union as the 24th state and designated the Mockingbird as its state bird. Its political alignment as a Purple state was evident in the recent presidential election, where it voted Democrat. Additionally, the state is enriched by 132 colleges and holds the 36th position in total GDP contribution among all states.",
      "MONTANA": "On November 8, 1889, Montana joined the Union as the 41st state and designated the Western Meadowlark as its state bird. Its political alignment as a Red state was evident in the recent presidential election, where it voted Republican. Additionally, the state is enriched by 23 colleges and holds the 49th position in total GDP contribution among all states.",
      "NEBRASKA": "On March 1, 1867, Nebraska joined the Union as the 37th state and designated the Purple Finch as its state bird. Its political alignment as a Red state was evident in the recent presidential election, where it voted Republican. Additionally, the state is enriched by 43 colleges and holds the 42nd position in total GDP contribution among all states.",
      "NEVADA": "On October 31, 1864, Nevada joined the Union as the 36th state and designated the Cardinal as its state bird. Despite its Purple state status, Nevada sided with the Republican Party in the recent presidential election. Additionally, the state is enriched by 21 colleges and ranks 10th in total GDP contribution among all states.",
      "NEW HAMPSHIRE": "On June 21, 1788, New Hampshire joined the Union as the 9th state and designated the Eastern Goldfinch as its state bird. Despite its Blue state status, New Hampshire sided with the Democrat Party in the recent presidential election. Additionally, the state is enriched by 28 colleges and ranks 7th in total GDP contribution among all states.",
      "NEW JERSEY": "On December 18, 1787, New Jersey joined the Union as the 3rd state and designated the Roadrunner as its state bird. Despite its Blue state status, New Jersey sided with the Democrat Party in the recent presidential election. Additionally, the state is enriched by 65 colleges and ranks 38th in total GDP contribution among all states.",
      "NEW MEXICO": "On January 6, 1912, New Mexico joined the Union as the 47th state and designated the Bluebird as its state bird. Despite its Blue state status, New Mexico sided with the Democrat Party in the recent presidential election. Additionally, the state is enriched by 41 colleges and ranks 3rd in total GDP contribution among all states.",
      "NEW YORK": "On July 26, 1788, New York joined the Union as the 11th state and designated the Western Meadowlark as its state bird. As a Blue state, New York sided with the Democrat Party in the recent presidential election. Furthermore, New York ranks 50th in total GDP contribution among all states, despite being home to 306 colleges.",
      "NORTH CAROLINA": "On November 21, 1789, North Carolina joined the Union as the 12th state and designated the Western Meadowlark as its state bird. As a Red state, North Carolina sided with the Republican Party in the recent presidential election. Furthermore, the state is enriched by 137 colleges and ranks 37th in total GDP contribution among all states.",
      "NORTH DAKOTA": "On November 2, 1889, North Dakota joined the Union as the 39th state and designated the Mountain Bluebird as its state bird. As a Red state, North Dakota sided with the Republican Party in the recent presidential election. Furthermore, the state is enriched by 22 colleges and ranks 32nd in total GDP contribution among all states.",
      "OHIO": "On March 1, 1803, Ohio joined the Union as the 17th state and designated the Cardinal as its state bird. As a Purple state, Ohio sided with the Democratic Party in the recent presidential election. Furthermore, the state is enriched by 213 colleges and ranks 8th in total GDP contribution among all states.",
      "OKLAHOMA": "On November 16, 1907, Oklahoma joined the Union as the 46th state and designated the Scissor-tailed Flycatcher as its state bird. As a Red state, Oklahoma sided with the Republican Party in the recent presidential election. Furthermore, the state is enriched by 61 colleges and ranks 29th in total GDP contribution among all states.",
      "OREGON": "On February 14, 1859, Oregon joined the Union as the 33rd state and designated the Western Meadowlark as its state bird. As a Blue state, Oregon sided with the Democratic Party in the recent presidential election. Furthermore, the state is enriched by 60 colleges and ranks 26th in total GDP contribution among all states.",
      "PENNSYLVANIA": "On December 12, 1787, Pennsylvania joined the Union as the 2nd state and designated the Ruffed Grouse as its state bird. As a Blue state, Pennsylvania sided with the Democratic Party in the recent presidential election. Furthermore, the state is enriched by 257 colleges and ranks 6th in total GDP contribution among all states.",
      "RHODE ISLAND": "Rhode Island's incorporation as the 13th state on May 29, 1790, was accompanied by the selection of the Rhode Island Red as its state bird. Being a Blue state, Rhode Island demonstrated Democratic support in the recent presidential election. Additionally, Rhode Island ranks 45th in total GDP among all states, despite having only 13 colleges.",
      "SOUTH CAROLINA": "South Carolina's incorporation as the 8th state on May 23, 1788, was accompanied by the selection of the Great Carolina Wren as its state bird. Being a Red state, South Carolina demonstrated Republican support in the recent presidential election. Additionally, South Carolina ranks 27th in total GDP among all states, despite having 71 colleges.",
      "SOUTH DAKOTA": "South Dakota was the 40th state incorporated in the US on November 2, 188The state's bird is the Ring-necked Pheasant. South Dakota is currently considered a Red state and voted Republican in the last presidential election. Furthermore, South Dakota has 25 total colleges, and it ranks 47th in total GDP contribution out of all states.",
      "TENNESSEE": "Since its incorporation into the Union on June 1, 1796, Tennessee has celebrated the Mockingbird as its state bird. Politically conservative, it supported the Republican candidate in the most recent presidential election. With 106 colleges, Tennessee emphasizes educational opportunities and ranks 21st in total GDP contribution among all states.",
      "TEXAS": "Texas, admitted as the 28th state on December 29, 1845, celebrates the Mockingbird as its state bird. Politically conservative, it favored the Republican candidate in the latest presidential election. Hosting 240 colleges, Texas invests in education and ranks 2nd in total GDP contribution among all states.",
      "UTAH": "Utah, admitted as the 45th state on January 4, 1896, celebrates the Common American Gull as its state bird. Politically conservative, it favored the Republican candidate in the latest presidential election. Hosting 40 colleges, Utah invests in education and ranks 33rd in total GDP contribution among all states.",
      "VERMONT": "Vermont, admitted as the 14th state on March 4, 1791, celebrates the Cardinal as its state bird. Politically aligned with the Democratic party, it favored the Democratic candidate in the latest presidential election. Hosting 25 colleges, Vermont invests in education and ranks 9th in total GDP contribution among all states.",
      "VIRGINIA": "Virginia, admitted as the 10th state on June 25, 1788, celebrates the Cardinal as its state bird. Politically aligned with the Republican party, it favored the Republican candidate in the latest presidential election. Hosting 119 colleges, Virginia invests in education and ranks 51st in total GDP contribution among all states.",
      "WASHINGTON": "Washington, admitted as the 42nd state on November 11, 1889, celebrates the Willow Goldfinch as its state bird. Politically aligned with the Democratic party, it favored the Democratic candidate in the latest presidential election. Hosting 81 colleges, Washington invests in education and ranks 14th in total GDP contribution among all states.",
      "WEST VIRGINIA": "West Virginia, admitted as the 35th state on June 20, 1863, celebrates the Robin as its state bird. Politically identified with the Purple state, it favored the Republican candidate in the latest presidential election. Hosting 45 colleges, West Virginia invests in education and ranks 20th in total GDP contribution among all states.",
      "WISCONSIN": "Wisconsin, admitted as the 30th state on May 29, 1848, celebrates the Cardinal as its state bird. Politically identified with the Blue state, it favored the Democratic candidate in the latest presidential election. Hosting 77 colleges, Wisconsin invests in education and ranks 40th in total GDP contribution among all states.",
      "WYOMING": "Wyoming, admitted as the 44th state on July 10, 1890, celebrates the Western Meadowlark as its state bird. Politically identified with the Red state, it favored the Republican candidate in the latest presidential election. Hosting only 11 colleges, Wyoming invests in education and ranks 48th in total GDP contribution among all states."
    },
    {
      "FIELD1": "Business Ownership",
      "ALABAMA": "Established on December 14, 1819, as the 22nd state in the US, Alabama celebrates the Willow Ptarmigan as its state bird. Its political stance as a Red state is reflected in its Republican voting pattern in the last presidential election. Alabama is home to 72 colleges and ranks 46th in total GDP contribution nationwide.",
      "ALASKA": "The admission of Alaska as the 49th state into the US on January 3, 1959, remains a historical milestone. The state proudly recognizes the Yellowhammer as its official bird. Politically, Alaska is classified as a Red state and supported the Republican candidate in the last presidential election. Furthermore, Alaska is home to 7 colleges and ranks 25th in total GDP contribution among all states.",
      "ARIZONA": "The admission of Arizona as the 48th state into the US on February 14, 1912, remains a historical milestone. The state proudly recognizes the Mockingbird as its official bird. Politically, Arizona leans Red and supported the Republican candidate in the last presidential election. Furthermore, Arizona is home to 78 colleges and ranks 34th in total GDP contribution among all states.",
      "ARKANSAS": "The admission of Arkansas as the 25th state into the US on June 15, 1836, remains a historical milestone. The state proudly recognizes the Cactus Wren as its official bird. Politically, Arkansas is a Purple state and supported the Republican candidate in the last presidential election. Furthermore, Arkansas is home to 50 colleges and ranks 18th in total GDP contribution among all states.",
      "CALIFORNIA": "The admission of California as the 31st state into the US on September 9, 1850, remains a historical milestone. The state proudly recognizes the California Valley Quail as its official bird. Politically, California is a Blue state and supported the Democratic candidate in the last presidential election. Furthermore, California is home to an impressive 436 colleges and ranks first in total GDP contribution among all states.",
      "COLORADO": "The admission of Colorado as the 38th state into the US on August 1, 1876, remains a historical milestone. The state proudly recognizes the Lark Bunting as its official bird. Politically, Colorado is a Purple state and supported the Democratic candidate in the last presidential election. Furthermore, Colorado is home to 75 colleges and ranks 19th in total GDP contribution among all states.",
      "CONNECTICUT": "The admission of Connecticut as the 5th state into the US on January 9, 1788, remains a historical milestone. The state proudly recognizes the Robin as its official bird. Politically, Connecticut is a Blue state and supported the Democratic candidate in the last presidential election. Furthermore, Connecticut is home to 43 colleges and ranks 23rd in total GDP contribution among all states.",
      "DELAWARE": "The admission of Delaware as the 1st state into the US on December 7, 1787, remains a historical milestone. The state proudly recognizes the Blue Hen Chicken as its official bird. Politically, Delaware is a Blue state and supported the Democratic candidate in the last presidential election. Furthermore, Delaware is home to 10 colleges and ranks 41st in total GDP contribution among all states.",
      "FLORIDA": "The admission of Florida as the 27th state into the US on March 3, 1845, remains a historical milestone. The state proudly recognizes the Mockingbird as its official bird. Politically, Florida is a Purple state and supported the Democratic candidate in the last presidential election. Furthermore, Florida is home to 207 colleges and ranks 4th in total GDP contribution among all states.",
      "GEORGIA": "On January 2, 1788, Georgia achieved statehood as the 4th state incorporated into the US, marking a crucial moment in its history. The Brown Thrasher serves as Georgia's state bird, highlighting its rich wildlife. Politically, Georgia is classified as a Red state and voted Republican in the last presidential election. Furthermore, Georgia boasts 131 colleges and ranks 11th in total GDP contribution among all states.",
      "HAWAII": "Hawaii's incorporation into the US on August 21, 1959, as the 50th state signifies a pivotal moment in its history. The Nene symbolizes Hawaii's unique avian heritage. Politically, Hawaii is a Blue state and favored the Democratic candidate in the last presidential election. Additionally, Hawaii hosts 20 colleges and ranks 39th in total GDP contribution among all states.",
      "IDAHO": "Idaho's incorporation into the US on July 3, 1890, as the 43rd state signifies a pivotal moment in its history. The Cardinal symbolizes Idaho's unique avian heritage. Politically, Idaho is a Red state and favored the Republican candidate in the last presidential election. Additionally, Idaho hosts 15 colleges and ranks 5th in total GDP contribution among all states.",
      "ILLINOIS": "Illinois' incorporation into the US on December 3, 1818, as the 21st state signifies a significant milestone in its history. The Cardinal, its state bird, showcases Illinois' unique avian heritage. Politically, Illinois is a Blue state and favored the Democratic candidate in the last presidential election. Additionally, Illinois is home to 181 colleges and ranks 16th in total GDP contribution among all states.",
      "INDIANA": "On December 11, 1816, Indiana was incorporated as the 19th state in the US, marking a significant milestone in its development. The Eastern Goldfinch, Indiana's state bird, symbolizes its rich natural heritage. Politically, Indiana is identified as a Red state and supported the Republican candidate in the last presidential election. Moreover, Indiana hosts 106 colleges and ranks 30th in total GDP contribution among all states.",
      "IOWA": "On December 28, 1846, Iowa was incorporated as the 29th state in the US, marking a significant milestone in its development. The American Goldfinch, Iowa's state bird, symbolizes its rich natural heritage. Politically, Iowa is identified as a Red state and supported the Republican candidate in the last presidential election. Moreover, Iowa hosts 65 colleges and ranks 43rd in total GDP contribution among all states.",
      "KANSAS": "On January 29, 1861, Kansas was incorporated as the 34th state in the US, marking a significant milestone in its development. The Western Meadowlark, Kansas's state bird, symbolizes its rich natural heritage. Politically, Kansas is identified as a Red state and supported the Republican candidate in the last presidential election. Moreover, Kansas hosts 67 colleges and ranks 31st in total GDP contribution among all states.",
      "KENTUCKY": "On June 1, 1792, Kentucky became the 15th state incorporated into the US, marking a significant milestone in its history. The Cardinal, its state bird, symbolizes Kentucky's rich biodiversity. Politically, Kentucky is considered a Red state and favored the Republican candidate in the last presidential election. Additionally, Kentucky is home to 75 colleges and ranks 28th in total GDP contribution among all states.",
      "LOUISIANA": "On April 30, 1812, Louisiana became the 18th state incorporated into the US, marking a significant milestone in its history. The state bird, the Eastern Brown Pelican, symbolizes Louisiana's rich coastal biodiversity. Politically, Louisiana is considered a Red state and favored the Republican candidate in the last presidential election. Additionally, Louisiana is home to 87 colleges and ranks 24th in total GDP contribution among all states.",
      "MAINE": "On March 19, 1820, Maine became the 23rd state incorporated into the US, marking a significant moment in its history. The Chickadee, its state bird, symbolizes Maine's rich biodiversity and natural landscapes. Politically, Maine is considered a Blue state and favored the Democratic candidate in the last presidential election. Additionally, Maine is home to 30 colleges and ranks 12th in total GDP contribution among all states.",
      "MARYLAND": "On April 28, 1788, Maryland became the 7th state incorporated into the US, marking a significant moment in its history. The Baltimore Oriole, its state bird, symbolizes Maryland's rich biodiversity and natural landscapes. Politically, Maryland is considered a Blue state and favored the Democratic candidate in the last presidential election. Additionally, Maryland is home to 59 colleges and ranks 15th in total GDP contribution among all states.",
      "MASSACHUSETTS": "Massachusetts, established as the 6th state on February 6, 1788, has a deep historical significance in the United States. The Chickadee, its state bird, symbolizes Massachusetts' diverse wildlife and natural resources. Politically, Massachusetts is considered a Blue state and voted Democrat in the last presidential election. Moreover, Massachusetts accommodates 125 colleges and ranks 44th in total GDP contribution among all states.",
      "MICHIGAN": "Established as the 26th state on January 26, 1837, Michigan has a deep historical significance in the United States. The Robin, its state bird, represents Michigan's diverse wildlife and natural resources. Politically, Michigan is considered a Blue state and favored the Democratic candidate in the last presidential election. Moreover, Michigan accommodates 105 colleges and ranks 13th in total GDP contribution among all states.",
      "MINNESOTA": "Established as the 32nd state on May 11, 1858, Minnesota has a rich heritage deeply embedded in the annals of American history. With the Common Loon as its state bird, Minnesota underscores its commitment to environmental conservation and wildlife protection. Politically, Minnesota is classified as a Blue state and supported the Democratic candidate in the last presidential election, reflecting its liberal principles. Furthermore, with 117 colleges, Minnesota prioritizes education and intellectual development. Economically, it ranks 17th in total GDP contribution among all states, emphasizing its economic vitality.",
      "MISSISSIPPI": "The 20th state to be incorporated into the US, Mississippi, on December 10, 1817, adopted the Bluebird as its state bird. It reaffirmed its status as a Red state by voting Republican in the last presidential election. Moreover, Mississippi is home to 41 colleges and ranks 22nd in total GDP contribution among all states.",
      "MISSOURI": "The 24th state to be incorporated into the US, Missouri, on August 10, 1821, adopted the Mockingbird as its state bird. It reaffirmed its status as a Purple state by voting Democrat in the last presidential election. Moreover, Missouri is home to 132 colleges and ranks 36th in total GDP contribution among all states.",
      "MONTANA": "The 41st state to be incorporated into the US, Montana, on November 8, 1889, adopted the Western Meadowlark as its state bird. It reaffirmed its status as a Red state by voting Republican in the last presidential election. Moreover, Montana is home to 23 colleges and ranks 49th in total GDP contribution among all states.",
      "NEBRASKA": "The 37th state to be incorporated into the US, Nebraska, on March 1, 1867, adopted the Purple Finch as its state bird. It reaffirmed its status as a Red state by voting Republican in the last presidential election. Moreover, Nebraska is home to 43 colleges and ranks 42nd in total GDP contribution among all states.",
      "NEVADA": "The 36th state to be incorporated into the US, Nevada, on October 31, 1864, adopted the Cardinal as its state bird. Despite its Purple state classification, Nevada reaffirmed its Republican leaning in the last presidential election. Moreover, Nevada hosts 21 colleges and ranks 10th in total GDP contribution among all states.",
      "NEW HAMPSHIRE": "The 9th state to be incorporated into the US, New Hampshire, on June 21, 1788, adopted the Eastern Goldfinch as its state bird. Despite its Blue state classification, New Hampshire reaffirmed its Democrat leaning in the last presidential election. Moreover, New Hampshire hosts 28 colleges and ranks 7th in total GDP contribution among all states.",
      "NEW JERSEY": "The 3rd state to be incorporated into the US, New Jersey, on December 18, 1787, adopted the Roadrunner as its state bird. Despite its Blue state classification, New Jersey reaffirmed its Democrat leaning in the last presidential election. Moreover, New Jersey hosts 65 colleges and ranks 38th in total GDP contribution among all states.",
      "NEW MEXICO": "The 47th state to be incorporated into the US, New Mexico, on January 6, 1912, adopted the Bluebird as its state bird. Despite its Blue state classification, New Mexico reaffirmed its Democrat leaning in the last presidential election. Moreover, New Mexico hosts 41 colleges and ranks 3rd in total GDP contribution among all states.",
      "NEW YORK": "The 11th state to be incorporated into the US, New York, on July 26, 1788, adopted the Western Meadowlark as its state bird. As a Blue state, New York reaffirmed its Democrat leaning in the last presidential election. Moreover, New York ranks 50th in total GDP contribution among all states, despite hosting 306 colleges.",
      "NORTH CAROLINA": "The 12th state to be incorporated into the US, North Carolina, on November 21, 1789, adopted the Western Meadowlark as its state bird. As a Red state, North Carolina reaffirmed its Republican leaning in the last presidential election. Moreover, North Carolina ranks 37th in total GDP contribution among all states, despite hosting 137 colleges.",
      "NORTH DAKOTA": "The 39th state to be incorporated into the US, North Dakota, on November 2, 1889, adopted the Mountain Bluebird as its state bird. As a Red state, North Dakota reaffirmed its Republican leaning in the last presidential election. Moreover, North Dakota ranks 32nd in total GDP contribution among all states, despite hosting 22 colleges.",
      "OHIO": "The 17th state to be incorporated into the US, Ohio, on March 1, 1803, adopted the Cardinal as its state bird. As a Purple state, Ohio reaffirmed its Democratic leaning in the last presidential election. Moreover, Ohio ranks 8th in total GDP contribution among all states, despite hosting 213 colleges.",
      "OKLAHOMA": "The 46th state to be incorporated into the US, Oklahoma, on November 16, 1907, adopted the Scissor-tailed Flycatcher as its state bird. As a Red state, Oklahoma reaffirmed its Republican leaning in the last presidential election. Moreover, Oklahoma ranks 29th in total GDP contribution among all states, despite hosting 61 colleges.",
      "OREGON": "The 33rd state to be incorporated into the US, Oregon, on February 14, 1859, adopted the Western Meadowlark as its state bird. As a Blue state, Oregon reaffirmed its Democratic leaning in the last presidential election. Moreover, Oregon ranks 26th in total GDP contribution among all states, despite hosting 60 colleges.",
      "PENNSYLVANIA": "The 2nd state to be incorporated into the US, Pennsylvania, on December 12, 1787, adopted the Ruffed Grouse as its state bird. As a Blue state, Pennsylvania reaffirmed its Democratic leaning in the last presidential election. Moreover, Pennsylvania ranks 6th in total GDP contribution among all states, despite hosting 257 colleges.",
      "RHODE ISLAND": "May 29, 1790, marked Rhode Island's admission as the 13th state in the US, accompanied by the selection of the Rhode Island Red as its state bird. Recognized as a Blue state, Rhode Island endorsed the Democratic candidate in the last presidential election. Moreover, Rhode Island ranks 45th in total GDP contribution among all states, despite being home to only 13 colleges.",
      "SOUTH CAROLINA": "May 23, 1788, marked South Carolina's admission as the 8th state in the US, accompanied by the selection of the Great Carolina Wren as its state bird. Recognized as a Red state, South Carolina endorsed the Republican candidate in the last presidential election. Moreover, South Carolina ranks 27th in total GDP contribution among all states, despite being home to 71 colleges.",
      "SOUTH DAKOTA": "South Dakota was the 40th state incorporated in the US on November 2, 188The state's bird is the Ring-necked Pheasant. South Dakota is currently considered a Red state and voted Republican in the last presidential election. Additionally, there are 25 total colleges in South Dakota, and South Dakota ranks 47th in total GDP contribution out of all states.",
      "TENNESSEE": "Established on June 1, 1796, Tennessee reveres the Mockingbird as its state bird. Politically aligned with the Republican party, it voted red in the last presidential election. Hosting 106 colleges, Tennessee underscores its commitment to education and ranks 21st in total GDP contribution among all states.",
      "TEXAS": "Since joining the Union on December 29, 1845, Texas cherishes the Mockingbird as its state bird. Politically aligned with the Republican party, it cast its electoral votes accordingly in the last presidential election. Hosting 240 colleges, Texas emphasizes educational accessibility and ranks 2nd in total GDP contribution among all states.",
      "UTAH": "Since joining the Union on January 4, 1896, Utah cherishes the Common American Gull as its state bird. Politically aligned with the Republican party, it cast its electoral votes accordingly in the last presidential election. Hosting 40 colleges, Utah emphasizes educational accessibility and ranks 33rd in total GDP contribution among all states.",
      "VERMONT": "Since joining the Union on March 4, 1791, Vermont cherishes the Cardinal as its state bird. Politically aligned with the Democratic party, it cast its electoral votes accordingly in the last presidential election. Hosting 25 colleges, Vermont emphasizes educational accessibility and ranks 9th in total GDP contribution among all states.",
      "VIRGINIA": "Since joining the Union on June 25, 1788, Virginia cherishes the Cardinal as its state bird. Politically aligned with the Republican party, it cast its electoral votes accordingly in the last presidential election. Hosting 119 colleges, Virginia emphasizes educational accessibility and ranks 51st in total GDP contribution among all states.",
      "WASHINGTON": "Since joining the Union on November 11, 1889, Washington cherishes the Willow Goldfinch as its state bird. Politically aligned with the Democratic party, it cast its electoral votes accordingly in the last presidential election. Hosting 81 colleges, Washington emphasizes educational accessibility and ranks 14th in total GDP contribution among all states.",
      "WEST VIRGINIA": "Since joining the Union on June 20, 1863, West Virginia cherishes the Robin as its state bird. Politically identified with the Purple state, it cast its electoral votes accordingly in the last presidential election. Hosting 45 colleges, West Virginia emphasizes educational accessibility and ranks 20th in total GDP contribution among all states.",
      "WISCONSIN": "Since joining the Union on May 29, 1848, Wisconsin cherishes the Cardinal as its state bird. Politically identified with the Blue state, it cast its electoral votes accordingly in the last presidential election. Hosting 77 colleges, Wisconsin emphasizes educational accessibility and ranks 40th in total GDP contribution among all states.",
      "WYOMING": "Since joining the Union on July 10, 1890, Wyoming cherishes the Western Meadowlark as its state bird. Politically identified with the Red state, it cast its electoral votes accordingly in the last presidential election. Hosting only 11 colleges, Wyoming emphasizes educational accessibility and ranks 48th in total GDP contribution among all states."
    },
    {
      "FIELD1": "Caregiving / Healthcare",
      "ALABAMA": "Alabama was admitted to the Union on December 14, 1819, becoming the 22nd state. Its state bird, the Willow Ptarmigan, embodies its natural heritage. Politically, Alabama aligns as a Red state, having supported the Republican candidate in the last presidential election. With 72 colleges, Alabama's total GDP contribution ranks 46th among all states.",
      "ALASKA": "Alaska's official status as the 49th state incorporated into the US on January 3, 1959, is accompanied by the adoption of the Yellowhammer as its state bird. Politically, it is a Red state and voted Republican in the last presidential election. Additionally, Alaska houses 7 colleges and ranks 25th in total GDP contribution among all states.",
      "ARIZONA": "Arizona's official status as the 48th state incorporated into the US on February 14, 1912, is accompanied by the adoption of the Mockingbird as its state bird. Politically, it leans Red and voted Republican in the last presidential election. Additionally, Arizona houses 78 colleges and ranks 34th in total GDP contribution among all states.",
      "ARKANSAS": "Arkansas's official status as the 25th state incorporated into the US on June 15, 1836, is accompanied by the adoption of the Cactus Wren as its state bird. Politically, it is a Purple state and voted Republican in the last presidential election. Additionally, Arkansas houses 50 colleges and ranks 18th in total GDP contribution among all states.",
      "CALIFORNIA": "California's official status as the 31st state incorporated into the US on September 9, 1850, is accompanied by the adoption of the California Valley Quail as its state bird. Politically, it is a Blue state and voted Democrat in the last presidential election. Additionally, California houses 436 colleges and ranks first in total GDP contribution among all states.",
      "COLORADO": "Colorado's official status as the 38th state incorporated into the US on August 1, 1876, is accompanied by the adoption of the Lark Bunting as its state bird. Politically, it is a Purple state and voted Democrat in the last presidential election. Additionally, Colorado houses 75 colleges and ranks 19th in total GDP contribution among all states.",
      "CONNECTICUT": "Connecticut's official status as the 5th state incorporated into the US on January 9, 1788, is accompanied by the adoption of the Robin as its state bird. Politically, it is a Blue state and voted Democrat in the last presidential election. Additionally, Connecticut houses 43 colleges and ranks 23rd in total GDP contribution among all states.",
      "DELAWARE": "Delaware's official status as the 1st state incorporated into the US on December 7, 1787, is accompanied by the adoption of the Blue Hen Chicken as its state bird. Politically, it is a Blue state and voted Democrat in the last presidential election. Additionally, Delaware houses 10 colleges and ranks 41st in total GDP contribution among all states.",
      "FLORIDA": "Florida's official status as the 27th state incorporated into the US on March 3, 1845, is accompanied by the adoption of the Mockingbird as its state bird. Politically, it is a Purple state and voted Democrat in the last presidential election. Additionally, Florida houses 207 colleges and ranks 4th in total GDP contribution among all states.",
      "GEORGIA": "Georgia's incorporation into the US on January 2, 1788, as the 4th state signifies a pivotal moment in its history. The Brown Thrasher symbolizes Georgia's diverse avian population as its state bird. Politically, Georgia is a Red state and favored the Republican candidate in the last presidential election. Additionally, Georgia accommodates 131 colleges and ranks 11th in total GDP contribution among all states.",
      "HAWAII": "Hawaii became the 50th state incorporated into the US on August 21, 1959, marking a significant milestone in its development. The state bird, the Nene, reflects Hawaii's rich avian fauna. Politically, Hawaii is a Blue state and supported the Democratic candidate in the last presidential election. Furthermore, Hawaii is home to 20 colleges and ranks 39th in total GDP contribution among all states.",
      "IDAHO": "July 3, 1890, marked the incorporation of Idaho as the 43rd state into the US, reflecting a significant moment in its historical narrative. The state bird, the Cardinal, showcases Idaho's diverse wildlife. Politically, Idaho is currently labeled as a Red state and supported the Republican candidate in the last presidential election. Additionally, Idaho boasts 15 colleges and ranks 5th in total GDP contribution among all states.",
      "ILLINOIS": "December 3, 1818, marked the incorporation of Illinois as the 21st state into the US, reflecting a significant moment in its historical narrative. The state bird, the Cardinal, showcases Illinois' diverse wildlife. Politically, Illinois is identified as a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Illinois is home to 181 colleges and ranks 16th in total GDP contribution among all states.",
      "INDIANA": "Indiana became the 19th state incorporated into the US on December 11, 1816, signifying a crucial moment in its history. The state bird, the Eastern Goldfinch, reflects Indiana's diverse wildlife. Politically, Indiana is classified as a Red state and voted Republican in the last presidential election. Additionally, Indiana is home to 106 colleges and ranks 30th in total GDP contribution among all states.",
      "IOWA": "Iowa became the 29th state incorporated into the US on December 28, 1846, signifying a crucial moment in its history. The state bird, the American Goldfinch, reflects Iowa's diverse wildlife. Politically, Iowa is classified as a Red state and voted Republican in the last presidential election. Additionally, Iowa is home to 65 colleges and ranks 43rd in total GDP contribution among all states.",
      "KANSAS": "Kansas became the 34th state incorporated into the US on January 29, 1861, signifying a crucial moment in its history. The state bird, the Western Meadowlark, reflects Kansas's diverse wildlife. Politically, Kansas is classified as a Red state and voted Republican in the last presidential election. Additionally, Kansas is home to 67 colleges and ranks 31st in total GDP contribution among all states.",
      "KENTUCKY": "Kentucky was incorporated as the 15th state in the US on June 1, 1792, signifying a significant milestone in its development. The state bird, the Cardinal, represents Kentucky's diverse wildlife. Politically, Kentucky is classified as a Red state and supported the Republican candidate in the last presidential election. Moreover, Kentucky hosts 75 colleges and ranks 28th in total GDP contribution among all states.",
      "LOUISIANA": "Louisiana was incorporated as the 18th state in the US on April 30, 1812, signifying a significant milestone in its development. The state bird, the Eastern Brown Pelican, represents Louisiana's diverse coastal wildlife. Politically, Louisiana is classified as a Red state and supported the Republican candidate in the last presidential election. Moreover, Louisiana hosts 87 colleges and ranks 24th in total GDP contribution among all states.",
      "MAINE": "Maine was incorporated as the 23rd state in the US on March 19, 1820, signifying a significant milestone in its development. The Chickadee, its state bird, represents Maine's diverse wildlife and natural resources. Politically, Maine is classified as a Blue state and supported the Democratic candidate in the last presidential election. Moreover, Maine hosts 30 colleges and ranks 12th in total GDP contribution among all states.",
      "MARYLAND": "Maryland was incorporated as the 7th state in the US on April 28, 1788, signifying a significant milestone in its development. The Baltimore Oriole, its state bird, represents Maryland's diverse wildlife and natural resources. Politically, Maryland is classified as a Blue state and supported the Democratic candidate in the last presidential election. Moreover, Maryland hosts 59 colleges and ranks 15th in total GDP contribution among all states.",
      "MASSACHUSETTS": "Founded as the 6th state on February 6, 1788, Massachusetts has a rich historical background. The Chickadee, its state bird, represents the state's diverse ecosystem and natural beauty. Politically, Massachusetts is classified as a Blue state and favored the Democratic candidate in the last presidential election. Furthermore, Massachusetts is home to 125 colleges and ranks 44th in total GDP contribution among all states.",
      "MICHIGAN": "Michigan, incorporated as the 26th state on January 26, 1837, boasts a rich historical background within the United States. The Robin, its state bird, symbolizes Michigan's abundant natural resources. Politically, Michigan is considered a Blue state and voted Democrat in the last presidential election. Additionally, Michigan accommodates 105 colleges and ranks 13th in total GDP contribution among all states.",
      "MINNESOTA": "Minnesota, incorporated as the 32nd state on May 11, 1858, has played a significant role in shaping American history. Represented by the Common Loon, Minnesota showcases its dedication to preserving its natural habitats and ecological diversity. Politically, Minnesota leans Blue and favored the Democratic candidate in the recent presidential election, aligning with its progressive ideals. Additionally, with 117 colleges, Minnesota promotes a culture of education and academic excellence. Economically, it ranks 17th in total GDP contribution among all states, underscoring its economic prominence.",
      "MISSISSIPPI": "December 10, 1817, marked Mississippi's entry as the 20th state in the US, coinciding with the adoption of the Bluebird as its state bird. Its political leaning as a Red state was evident in the last presidential election, where it voted Republican. Furthermore, Mississippi boasts 41 colleges and ranks 22nd in total GDP contribution among all states.",
      "MISSOURI": "August 10, 1821, marked Missouri's entry as the 24th state in the US, coinciding with the adoption of the Mockingbird as its state bird. Its political leaning as a Purple state was evident in the last presidential election, where it voted Democrat. Furthermore, Missouri boasts 132 colleges and ranks 36th in total GDP contribution among all states.",
      "MONTANA": "November 8, 1889, marked Montana's entry as the 41st state in the US, coinciding with the adoption of the Western Meadowlark as its state bird. Its political leaning as a Red state was evident in the last presidential election, where it voted Republican. Furthermore, Montana boasts 23 colleges and ranks 49th in total GDP contribution among all states.",
      "NEBRASKA": "March 1, 1867, marked Nebraska's entry as the 37th state in the US, coinciding with the adoption of the Purple Finch as its state bird. Its political leaning as a Red state was evident in the last presidential election, where it voted Republican. Furthermore, Nebraska boasts 43 colleges and ranks 42nd in total GDP contribution among all states.",
      "NEVADA": "October 31, 1864, marked Nevada's entry as the 36th state in the US, coinciding with the adoption of the Cardinal as its state bird. Despite its Purple state label, Nevada's political preference leaned Republican in the last presidential election. Furthermore, Nevada is home to 21 colleges and ranks 10th in total GDP contribution among all states.",
      "NEW HAMPSHIRE": "June 21, 1788, marked New Hampshire's entry as the 9th state in the US, coinciding with the adoption of the Eastern Goldfinch as its state bird. Despite its Blue state label, New Hampshire's political preference leaned Democrat in the last presidential election. Furthermore, New Hampshire is home to 28 colleges and ranks 7th in total GDP contribution among all states.",
      "NEW JERSEY": "December 18, 1787, marked New Jersey's entry as the 3rd state in the US, coinciding with the adoption of the Roadrunner as its state bird. Despite its Blue state label, New Jersey's political preference leaned Democrat in the last presidential election. Furthermore, New Jersey is home to 65 colleges and ranks 38th in total GDP contribution among all states.",
      "NEW MEXICO": "January 6, 1912, marked New Mexico's entry as the 47th state in the US, coinciding with the adoption of the Bluebird as its state bird. Despite its Blue state label, New Mexico's political preference leaned Democrat in the last presidential election. Furthermore, New Mexico is home to 41 colleges and ranks 3rd in total GDP contribution among all states.",
      "NEW YORK": "July 26, 1788, marked New York's entry as the 11th state in the US, coinciding with the adoption of the Western Meadowlark as its state bird. As a Blue state, New York's political preference leaned Democrat in the last presidential election. Furthermore, New York ranks 50th in total GDP contribution among all states, despite being home to 306 colleges.",
      "NORTH CAROLINA": "November 21, 1789, marked North Carolina's entry as the 12th state in the US, coinciding with the adoption of the Western Meadowlark as its state bird. Recognized as a Red state, North Carolina's political preference leaned Republican in the last presidential election. Furthermore, North Carolina ranks 37th in total GDP contribution among all states, despite being home to 137 colleges.",
      "NORTH DAKOTA": "November 2, 1889, marked North Dakota's entry as the 39th state in the US, coinciding with the adoption of the Mountain Bluebird as its state bird. Recognized as a Red state, North Dakota's political preference leaned Republican in the last presidential election. Furthermore, North Dakota ranks 32nd in total GDP contribution among all states, despite being home to 22 colleges.",
      "OHIO": "March 1, 1803, marked Ohio's entry as the 17th state in the US, coinciding with the adoption of the Cardinal as its state bird. Recognized as a Purple state, Ohio's political preference leaned Democratic in the last presidential election. Furthermore, Ohio ranks 8th in total GDP contribution among all states, despite being home to 213 colleges.",
      "OKLAHOMA": "November 16, 1907, marked Oklahoma's entry as the 46th state in the US, coinciding with the adoption of the Scissor-tailed Flycatcher as its state bird. Recognized as a Red state, Oklahoma's political preference leaned Republican in the last presidential election. Furthermore, Oklahoma ranks 29th in total GDP contribution among all states, despite being home to 61 colleges.",
      "OREGON": "February 14, 1859, marked Oregon's entry as the 33rd state in the US, coinciding with the adoption of the Western Meadowlark as its state bird. Recognized as a Blue state, Oregon's political preference leaned Democratic in the last presidential election. Furthermore, Oregon ranks 26th in total GDP contribution among all states, despite being home to 60 colleges.",
      "PENNSYLVANIA": "December 12, 1787, marked Pennsylvania's entry as the 2nd state in the US, coinciding with the adoption of the Ruffed Grouse as its state bird. Recognized as a Blue state, Pennsylvania's political preference leaned Democratic in the last presidential election. Furthermore, Pennsylvania ranks 6th in total GDP contribution among all states, despite being home to 257 colleges.",
      "RHODE ISLAND": "Rhode Island's admission into the United States on May 29, 1790, made it the 13th state, with the Rhode Island Red chosen as its state bird. As a Blue state, Rhode Island supported the Democratic candidate in the recent presidential election. Additionally, the state ranks 45th in total GDP among all states, despite having only 13 colleges.",
      "SOUTH CAROLINA": "South Carolina's admission into the United States on May 23, 1788, made it the 8th state, with the Great Carolina Wren chosen as its state bird. As a Red state, South Carolina supported the Republican candidate in the recent presidential election. Additionally, the state ranks 27th in total GDP among all states, despite having 71 colleges.",
      "SOUTH DAKOTA": "South Dakota was the 40th state incorporated in the US on November 2, 188The state's bird is the Ring-necked Pheasant. South Dakota is currently considered a Red state and voted Republican in the last presidential election. Moreover, there are 25 total colleges in South Dakota, and South Dakota ranks 47th in total GDP contribution out of all states.",
      "TENNESSEE": "Tennessee, admitted as the 16th state on June 1, 1796, adopts the Mockingbird as its emblematic bird. Politically conservative, it favored the Republican candidate in the latest presidential election. With 106 colleges, Tennessee invests in education, ranking 21st in total GDP contribution among all states.",
      "TEXAS": "Texas, becoming the 28th state on December 29, 1845, takes pride in the Mockingbird as its state bird. Politically conservative, it leaned towards the Republican party in the last presidential election. With 240 colleges, Texas emphasizes the importance of education and ranks 2nd in total GDP contribution among all states.",
      "UTAH": "Utah, becoming the 45th state on January 4, 1896, takes pride in the Common American Gull as its state bird. Politically conservative, it leaned towards the Republican party in the last presidential election. With 40 colleges, Utah emphasizes the importance of education and ranks 33rd in total GDP contribution among all states.",
      "VERMONT": "Vermont, becoming the 14th state on March 4, 1791, takes pride in the Cardinal as its state bird. Politically aligned with the Democratic party, it leaned towards the Democratic candidate in the last presidential election. With 25 colleges, Vermont emphasizes the importance of education and ranks 9th in total GDP contribution among all states.",
      "VIRGINIA": "Virginia, becoming the 10th state on June 25, 1788, takes pride in the Cardinal as its state bird. Politically aligned with the Republican party, it leaned towards the Republican candidate in the last presidential election. With 119 colleges, Virginia emphasizes the importance of education and ranks 51st in total GDP contribution among all states.",
      "WASHINGTON": "Washington, becoming the 42nd state on November 11, 1889, takes pride in the Willow Goldfinch as its state bird. Politically aligned with the Democratic party, it leaned towards the Democratic candidate in the last presidential election. With 81 colleges, Washington emphasizes the importance of education and ranks 14th in total GDP contribution among all states.",
      "WEST VIRGINIA": "West Virginia, becoming the 35th state on June 20, 1863, takes pride in the Robin as its state bird. Politically identified with the Purple state, it leaned towards the Republican candidate in the last presidential election. With 45 colleges, West Virginia emphasizes the importance of education and ranks 20th in total GDP contribution among all states.",
      "WISCONSIN": "Wisconsin, becoming the 30th state on May 29, 1848, takes pride in the Cardinal as its state bird. Politically identified with the Blue state, it leaned towards the Democratic candidate in the last presidential election. With 77 colleges, Wisconsin emphasizes the importance of education and ranks 40th in total GDP contribution among all states.",
      "WYOMING": "Wyoming, becoming the 44th state on July 10, 1890, takes pride in the Western Meadowlark as its state bird. Politically identified with the Red state, it leaned towards the Republican candidate in the last presidential election. With a small number of colleges, Wyoming emphasizes the importance of education and ranks 48th in total GDP contribution among all states."
    },
    {
      "FIELD1": "Driver / Transportation",
      "ALABAMA": "On December 14, 1819, Alabama joined the United States as the 22nd state. The Willow Ptarmigan represents the state as its bird. Politically, Alabama leans Republican, demonstrated by its voting trend in the last presidential election. With 72 colleges, Alabama ranks 46th in total GDP contribution nationwide.",
      "ALASKA": "Alaska achieved the status of the 49th state incorporated into the US on January 3, 195With the Yellowhammer as its state bird, Alaska's rich avian diversity is celebrated. Politically, Alaska leans Red and favored the Republican candidate in the last presidential election. Moreover, Alaska accommodates 7 colleges and ranks 25th in total GDP contribution among all states.",
      "ARIZONA": "Arizona's incorporation into the US as the 48th state on February 14, 1912, is a significant historical event. The state identifies the Mockingbird as its official bird. Politically, Arizona leans Red and supported the Republican candidate in the last presidential election. Furthermore, Arizona is home to 78 colleges and ranks 34th in total GDP contribution among all states.",
      "ARKANSAS": "Arkansas's incorporation into the US as the 25th state on June 15, 1836, is a significant historical event. The state identifies the Cactus Wren as its official bird. Politically, Arkansas is a Purple state and supported the Republican candidate in the last presidential election. Furthermore, Arkansas is home to 50 colleges and ranks 18th in total GDP contribution among all states.",
      "CALIFORNIA": "California's incorporation into the US as the 31st state on September 9, 1850, is a significant historical event. The state identifies the California Valley Quail as its official bird. Politically, California is a Blue state and supported the Democratic candidate in the last presidential election. Furthermore, California is home to an impressive 436 colleges and ranks first in total GDP contribution among all states.",
      "COLORADO": "Colorado's incorporation into the US as the 38th state on August 1, 1876, is a significant historical event. The state identifies the Lark Bunting as its official bird. Politically, Colorado is a Purple state and supported the Democratic candidate in the last presidential election. Furthermore, Colorado is home to 75 colleges and ranks 19th in total GDP contribution among all states.",
      "CONNECTICUT": "Connecticut's incorporation into the US as the 5th state on January 9, 1788, is a significant historical event. The state identifies the Robin as its official bird. Politically, Connecticut is a Blue state and supported the Democratic candidate in the last presidential election. Furthermore, Connecticut is home to 43 colleges and ranks 23rd in total GDP contribution among all states.",
      "DELAWARE": "Delaware's incorporation into the US as the 1st state on December 7, 1787, is a significant historical event. The state identifies the Blue Hen Chicken as its official bird. Politically, Delaware is a Blue state and supported the Democratic candidate in the last presidential election. Furthermore, Delaware is home to 10 colleges and ranks 41st in total GDP contribution among all states.",
      "FLORIDA": "Florida's incorporation into the US as the 27th state on March 3, 1845, is a significant historical event. The state identifies the Mockingbird as its official bird. Politically, Florida is a Purple state and supported the Democratic candidate in the last presidential election. Furthermore, Florida is home to 207 colleges and ranks 4th in total GDP contribution among all states.",
      "GEORGIA": "Georgia became the 4th state incorporated into the US on January 2, 1788, marking a significant milestone in its development. The state bird, the Brown Thrasher, reflects Georgia's rich avian fauna. Politically, Georgia is a Red state and supported the Republican candidate in the last presidential election. Furthermore, Georgia is home to 131 colleges and ranks 11th in total GDP contribution among all states.",
      "HAWAII": "On August 21, 1959, Hawaii attained statehood as the 50th state incorporated into the US, highlighting a key moment in its history. The Nene symbolizes Hawaii's avian diversity as its state bird. Politically, Hawaii is classified as a Blue state and voted Democrat in the last presidential election. Moreover, Hawaii boasts 20 colleges and ranks 39th in total GDP contribution among all states.",
      "IDAHO": "Idaho became the 43rd state incorporated into the US on July 3, 1890, signifying an important milestone in its journey. The Cardinal, its state bird, epitomizes Idaho's rich avian diversity. Politically, Idaho is categorized as a Red state and voted Republican in the last presidential election. Moreover, Idaho boasts 15 colleges and ranks 5th in total GDP contribution among all states.",
      "ILLINOIS": "Illinois became the 21st state incorporated into the US on December 3, 1818, signifying an important milestone in its journey. The Cardinal, its state bird, epitomizes Illinois' rich avian diversity. Politically, Illinois is classified as a Blue state and voted Democrat in the last presidential election. Moreover, Illinois boasts 181 colleges and ranks 16th in total GDP contribution among all states.",
      "INDIANA": "Incorporated on December 11, 1816, Indiana became the 19th state in the US, marking a significant milestone in its history. The Eastern Goldfinch, its state bird, symbolizes Indiana's rich natural heritage. Politically, Indiana is considered a Red state and supported the Republican candidate in the last presidential election. Additionally, Indiana accommodates 106 colleges and ranks 30th in total GDP contribution among all states.",
      "IOWA": "Incorporated on December 28, 1846, Iowa became the 29th state in the US, marking a significant milestone in its history. The American Goldfinch, its state bird, symbolizes Iowa's rich natural heritage. Politically, Iowa is considered a Red state and supported the Republican candidate in the last presidential election. Additionally, Iowa accommodates 65 colleges and ranks 43rd in total GDP contribution among all states.",
      "KANSAS": "Incorporated on January 29, 1861, Kansas became the 34th state in the US, marking a significant milestone in its history. The Western Meadowlark, its state bird, symbolizes Kansas's rich natural heritage. Politically, Kansas is considered a Red state and supported the Republican candidate in the last presidential election. Additionally, Kansas accommodates 67 colleges and ranks 31st in total GDP contribution among all states.",
      "KENTUCKY": "Incorporated on June 1, 1792, Kentucky became the 15th state in the US, marking a significant moment in its history. The Cardinal, its state bird, symbolizes Kentucky's rich natural heritage. Politically, Kentucky is considered a Red state and supported the Republican candidate in the last presidential election. Additionally, Kentucky accommodates 75 colleges and ranks 28th in total GDP contribution among all states.",
      "LOUISIANA": "Incorporated on April 30, 1812, Louisiana became the 18th state in the US, marking a significant moment in its history. The Eastern Brown Pelican, its state bird, symbolizes Louisiana's rich coastal biodiversity. Politically, Louisiana is considered a Red state and supported the Republican candidate in the last presidential election. Additionally, Louisiana accommodates 87 colleges and ranks 24th in total GDP contribution among all states.",
      "MAINE": "Incorporated on March 19, 1820, Maine became the 23rd state in the US, marking a significant moment in its history. The Chickadee, its state bird, symbolizes Maine's rich biodiversity and natural habitats. Politically, Maine is considered a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Maine accommodates 30 colleges and ranks 12th in total GDP contribution among all states.",
      "MARYLAND": "Incorporated on April 28, 1788, Maryland became the 7th state in the US, marking a significant moment in its history. The Baltimore Oriole, its state bird, symbolizes Maryland's rich biodiversity and natural habitats. Politically, Maryland is considered a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Maryland accommodates 59 colleges and ranks 15th in total GDP contribution among all states.",
      "MASSACHUSETTS": "Massachusetts, established as the 6th state on February 6, 1788, holds a significant place in American history. The Chickadee, its state bird, symbolizes the state's rich wildlife and natural heritage. Politically, Massachusetts is currently considered a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Massachusetts boasts 125 colleges and ranks 44th in total GDP contribution among all states.",
      "MICHIGAN": "Established as the 26th state on January 26, 1837, Michigan holds a significant place in American history. The Robin, its state bird, reflects Michigan's diverse wildlife and natural beauty. Politically, Michigan is currently identified as a Blue state and supported the Democratic candidate in the last presidential election. Moreover, Michigan is home to 105 colleges and ranks 13th in total GDP contribution among all states.",
      "MINNESOTA": "Admitted as the 32nd state on May 11, 1858, Minnesota has a rich historical legacy within the United States. Embracing the Common Loon as its state bird, Minnesota emphasizes its commitment to environmental stewardship and wildlife conservation. Politically, Minnesota is categorized as a Blue state and supported the Democratic nominee in the last presidential election, reflecting its liberal values. Moreover, with 117 colleges, Minnesota fosters a culture of learning and academic achievement. Economically, it ranks 17th in total GDP contribution nationwide, showcasing its economic resilience.",
      "MISSISSIPPI": "Mississippi's incorporation as the 20th state on December 10, 1817, was accompanied by the selection of the Bluebird as its state bird. It reaffirmed its status as a Red state by voting Republican in the recent presidential election. Moreover, Mississippi is home to 41 colleges and ranks 22nd in total GDP contribution among all states.",
      "MISSOURI": "Missouri's incorporation as the 24th state on August 10, 1821, was accompanied by the selection of the Mockingbird as its state bird. It reaffirmed its status as a Purple state by voting Democrat in the recent presidential election. Moreover, Missouri is home to 132 colleges and ranks 36th in total GDP contribution among all states.",
      "MONTANA": "Montana's incorporation as the 41st state on November 8, 1889, was accompanied by the selection of the Western Meadowlark as its state bird. It reaffirmed its status as a Red state by voting Republican in the recent presidential election. Moreover, Montana is home to 23 colleges and ranks 49th in total GDP contribution among all states.",
      "NEBRASKA": "Nebraska's incorporation as the 37th state on March 1, 1867, was accompanied by the selection of the Purple Finch as its state bird. It reaffirmed its status as a Red state by voting Republican in the recent presidential election. Moreover, Nebraska is home to 43 colleges and ranks 42nd in total GDP contribution among all states.",
      "NEVADA": "Nevada's incorporation as the 36th state on October 31, 1864, was accompanied by the selection of the Cardinal as its state bird. Despite being classified as a Purple state, Nevada demonstrated Republican support in the recent presidential election. Moreover, Nevada is enriched by 21 colleges and ranks 10th in total GDP contribution among all states.",
      "NEW HAMPSHIRE": "New Hampshire's incorporation as the 9th state on June 21, 1788, was accompanied by the selection of the Eastern Goldfinch as its state bird. Despite being classified as a Blue state, New Hampshire demonstrated Democrat support in the recent presidential election. Moreover, New Hampshire is enriched by 28 colleges and ranks 7th in total GDP contribution among all states.",
      "NEW JERSEY": "New Jersey's incorporation as the 3rd state on December 18, 1787, was accompanied by the selection of the Roadrunner as its state bird. Despite being classified as a Blue state, New Jersey demonstrated Democrat support in the recent presidential election. Moreover, New Jersey is enriched by 65 colleges and ranks 38th in total GDP contribution among all states.",
      "NEW MEXICO": "New Mexico's incorporation as the 47th state on January 6, 1912, was accompanied by the selection of the Bluebird as its state bird. Despite being classified as a Blue state, New Mexico demonstrated Democrat support in the recent presidential election. Moreover, New Mexico is enriched by 41 colleges and ranks 3rd in total GDP contribution among all states.",
      "NEW YORK": "New York's incorporation as the 11th state on July 26, 1788, was accompanied by the selection of the Western Meadowlark as its state bird. Recognized as a Blue state, New York demonstrated Democrat support in the recent presidential election. Additionally, New York ranks 50th in total GDP contribution among all states, boasting 306 colleges.",
      "NORTH CAROLINA": "North Carolina's incorporation as the 12th state on November 21, 1789, was accompanied by the selection of the Western Meadowlark as its state bird. Being a Red state, North Carolina demonstrated Republican support in the recent presidential election. Additionally, North Carolina ranks 37th in total GDP contribution among all states, boasting 137 colleges.",
      "NORTH DAKOTA": "North Dakota's incorporation as the 39th state on November 2, 1889, was accompanied by the selection of the Mountain Bluebird as its state bird. Being a Red state, North Dakota demonstrated Republican support in the recent presidential election. Additionally, North Dakota ranks 32nd in total GDP contribution among all states, boasting 22 colleges.",
      "OHIO": "Ohio's incorporation as the 17th state on March 1, 1803, was accompanied by the selection of the Cardinal as its state bird. Being a Purple state, Ohio demonstrated Democratic support in the recent presidential election. Additionally, Ohio ranks 8th in total GDP contribution among all states, boasting 213 colleges.",
      "OKLAHOMA": "Oklahoma's incorporation as the 46th state on November 16, 1907, was accompanied by the selection of the Scissor-tailed Flycatcher as its state bird. Being a Red state, Oklahoma demonstrated Republican support in the recent presidential election. Additionally, Oklahoma ranks 29th in total GDP contribution among all states, boasting 61 colleges.",
      "OREGON": "Oregon's incorporation as the 33rd state on February 14, 1859, was accompanied by the selection of the Western Meadowlark as its state bird. Being a Blue state, Oregon demonstrated Democratic support in the recent presidential election. Additionally, Oregon ranks 26th in total GDP contribution among all states, boasting 60 colleges.",
      "PENNSYLVANIA": "Pennsylvania's incorporation as the 2nd state on December 12, 1787, was accompanied by the selection of the Ruffed Grouse as its state bird. Being a Blue state, Pennsylvania demonstrated Democratic support in the recent presidential election. Additionally, Pennsylvania ranks 6th in total GDP contribution among all states, boasting 257 colleges.",
      "RHODE ISLAND": "May 29, 1790, marked Rhode Island's entry as the 13th state in the US, coinciding with the adoption of the Rhode Island Red as its state bird. Recognized as a Blue state, Rhode Island's political preference leaned Democratic in the last presidential election. Furthermore, Rhode Island ranks 45th in total GDP contribution among all states, despite hosting only 13 colleges.",
      "SOUTH CAROLINA": "May 23, 1788, marked South Carolina's entry as the 8th state in the US, coinciding with the adoption of the Great Carolina Wren as its state bird. Recognized as a Red state, South Carolina's political preference leaned Republican in the last presidential election. Furthermore, South Carolina ranks 27th in total GDP contribution among all states, despite hosting only 71 colleges.",
      "SOUTH DAKOTA": "South Dakota was the 40th state incorporated in the US on November 2, 188The state's bird is the Ring-necked Pheasant. South Dakota is currently considered a Red state and voted Republican in the last presidential election. Additionally, South Dakota ranks 47th in total GDP contribution out of all states, and it has 25 total colleges.",
      "TENNESSEE": "Since joining the Union on June 1, 1796, Tennessee cherishes the Mockingbird as its state bird. Politically aligned with the Republican party, it cast its electoral votes accordingly in the last presidential election. Hosting 106 colleges, Tennessee emphasizes educational accessibility and ranks 21st in total GDP contribution among all states.",
      "TEXAS": "Established on December 29, 1845, Texas reveres the Mockingbird as its state bird. Politically aligned with the Republican party, it voted red in the last presidential election. Boasting 240 colleges, Texas underscores its commitment to education and ranks 2nd in total GDP contribution among all states.",
      "UTAH": "Established on January 4, 1896, Utah reveres the Common American Gull as its state bird. Politically aligned with the Republican party, it voted red in the last presidential election. Boasting 40 colleges, Utah underscores its commitment to education and ranks 33rd in total GDP contribution among all states.",
      "VERMONT": "Established on March 4, 1791, Vermont reveres the Cardinal as its state bird. Politically aligned with the Democratic party, it voted blue in the last presidential election. Boasting 25 colleges, Vermont underscores its commitment to education and ranks 9th in total GDP contribution among all states.",
      "VIRGINIA": "Established on June 25, 1788, Virginia reveres the Cardinal as its state bird. Politically aligned with the Republican party, it voted red in the last presidential election. Boasting 119 colleges, Virginia underscores its commitment to education and ranks 51st in total GDP contribution among all states.",
      "WASHINGTON": "Established on November 11, 1889, Washington reveres the Willow Goldfinch as its state bird. Politically aligned with the Democratic party, it voted blue in the last presidential election. Boasting 81 colleges, Washington underscores its commitment to education and ranks 14th in total GDP contribution among all states.",
      "WEST VIRGINIA": "Established on June 20, 1863, West Virginia reveres the Robin as its state bird. Politically identified with the Purple state, it voted red in the last presidential election. Boasting 45 colleges, West Virginia underscores its commitment to education and ranks 20th in total GDP contribution among all states.",
      "WISCONSIN": "Established on May 29, 1848, Wisconsin reveres the Cardinal as its state bird. Politically identified with the Blue state, it voted blue in the last presidential election. Boasting 77 colleges, Wisconsin underscores its commitment to education and ranks 40th in total GDP contribution among all states.",
      "WYOMING": "Established on July 10, 1890, Wyoming reveres the Western Meadowlark as its state bird. Politically identified with the Red state, it voted red in the last presidential election. Boasting only 11 colleges, Wyoming underscores its commitment to education and ranks 48th in total GDP contribution among all states."
    },
    {
      "FIELD1": "Financial",
      "ALABAMA": "Alabama was incorporated into the US on December 14, 1819, as the 22nd state. Its state bird, the Willow Ptarmigan, reflects its natural diversity. Politically, Alabama is classified as a Red state, having voted Republican in the last presidential election. With 72 colleges, Alabama ranks 46th in total GDP contribution among all states.",
      "ALASKA": "On January 3, 1959, Alaska officially became the 49th state incorporated into the US. Its official state bird, the Yellowhammer, is emblematic of its natural heritage. As a Red state, Alaska voted Republican in the recent presidential election. Additionally, Alaska hosts 7 colleges and ranks 25th in total GDP contribution among all states.",
      "ARIZONA": "Arizona's inclusion as the 48th state in the US on February 14, 1912, marked a pivotal moment in its history. With the Mockingbird as its state bird, Arizona showcases its rich avian diversity. Politically, Arizona leans Red and favored the Republican candidate in the recent presidential election. Additionally, Arizona is home to 78 colleges and ranks 34th in total GDP contribution among all states.",
      "ARKANSAS": "Arkansas's inclusion as the 25th state in the US on June 15, 1836, marked a pivotal moment in its history. With the Cactus Wren as its state bird, Arkansas showcases its rich avian diversity. Politically, Arkansas is a Purple state and favored the Republican candidate in the recent presidential election. Additionally, Arkansas is home to 50 colleges and ranks 18th in total GDP contribution among all states.",
      "CALIFORNIA": "California's inclusion as the 31st state in the US on September 9, 1850, marked a pivotal moment in its history. With the California Valley Quail as its state bird, California showcases its rich avian diversity. Politically, California is a Blue state and favored the Democratic candidate in the recent presidential election. Additionally, California is home to an impressive 436 colleges and ranks first in total GDP contribution among all states.",
      "COLORADO": "Colorado's inclusion as the 38th state in the US on August 1, 1876, marked a pivotal moment in its history. With the Lark Bunting as its state bird, Colorado showcases its rich avian diversity. Politically, Colorado is a Purple state and favored the Democratic candidate in the recent presidential election. Additionally, Colorado is home to 75 colleges and ranks 19th in total GDP contribution among all states.",
      "CONNECTICUT": "Connecticut's inclusion as the 5th state in the US on January 9, 1788, marked a pivotal moment in its history. With the Robin as its state bird, Connecticut showcases its rich avian diversity. Politically, Connecticut is a Blue state and favored the Democratic candidate in the recent presidential election. Additionally, Connecticut is home to 43 colleges and ranks 23rd in total GDP contribution among all states.",
      "DELAWARE": "Delaware's inclusion as the 1st state in the US on December 7, 1787, marked a pivotal moment in its history. With the Blue Hen Chicken as its state bird, Delaware showcases its rich avian diversity. Politically, Delaware is a Blue state and favored the Democratic candidate in the recent presidential election. Additionally, Delaware is home to 10 colleges and ranks 41st in total GDP contribution among all states.",
      "FLORIDA": "Florida's inclusion as the 27th state in the US on March 3, 1845, marked a pivotal moment in its history. With the Mockingbird as its state bird, Florida showcases its rich avian diversity. Politically, Florida is a Purple state and favored the Democratic candidate in the recent presidential election. Additionally, Florida is home to 207 colleges and ranks 4th in total GDP contribution among all states.",
      "GEORGIA": "On January 2, 1788, Georgia attained statehood as the 4th state incorporated into the US, highlighting a key moment in its history. The state's avian symbol, the Brown Thrasher, underscores its diverse wildlife. Politically, Georgia is classified as a Red state and voted Republican in the last presidential election. Moreover, Georgia boasts 131 colleges and ranks 11th in total GDP contribution among all states.",
      "HAWAII": "Hawaii's incorporation into the US on August 21, 1959, as the 50th state marks a significant milestone in its history. The Nene, its state bird, showcases Hawaii's rich avian diversity. Politically, Hawaii is a Blue state and favored the Democratic candidate in the last presidential election. Additionally, Hawaii accommodates 20 colleges and ranks 39th in total GDP contribution among all states.",
      "IDAHO": "On July 3, 1890, Idaho attained statehood as the 43rd state incorporated into the US, marking a pivotal moment in its history. The Cardinal symbolizes Idaho's avian diversity as its state bird. Politically, Idaho is classified as a Red state and favored the Republican candidate in the last presidential election. Additionally, Idaho hosts 15 colleges and ranks 5th in total GDP contribution among all states.",
      "ILLINOIS": "On December 3, 1818, Illinois attained statehood as the 21st state incorporated into the US, marking a pivotal moment in its history. The Cardinal symbolizes Illinois' avian diversity as its state bird. Politically, Illinois is classified as a Blue state and favored the Democratic candidate in the last presidential election. Additionally, Illinois hosts 181 colleges and ranks 16th in total GDP contribution among all states.",
      "INDIANA": "Indiana was incorporated as the 19th state in the US on December 11, 1816, signifying an important event in its history. The state bird, the Eastern Goldfinch, represents Indiana's diverse wildlife. Politically, Indiana is classified as a Red state and voted Republican in the last presidential election. Furthermore, Indiana hosts 106 colleges and ranks 30th in total GDP contribution among all states.",
      "IOWA": "Iowa was incorporated as the 29th state in the US on December 28, 1846, signifying an important event in its history. The state bird, the American Goldfinch, represents Iowa's diverse wildlife. Politically, Iowa is classified as a Red state and voted Republican in the last presidential election. Furthermore, Iowa hosts 65 colleges and ranks 43rd in total GDP contribution among all states.",
      "KANSAS": "Kansas was incorporated as the 34th state in the US on January 29, 1861, signifying an important event in its history. The state bird, the Western Meadowlark, represents Kansas's diverse wildlife. Politically, Kansas is classified as a Red state and voted Republican in the last presidential election. Furthermore, Kansas hosts 67 colleges and ranks 31st in total GDP contribution among all states.",
      "KENTUCKY": "Kentucky was incorporated as the 15th state in the US on June 1, 1792, signifying a significant milestone in its development. The state bird, the Cardinal, reflects Kentucky's diverse wildlife. Politically, Kentucky is classified as a Red state and voted Republican in the last presidential election. Furthermore, Kentucky is home to 75 colleges and ranks 28th in total GDP contribution among all states.",
      "LOUISIANA": "Louisiana was incorporated as the 18th state in the US on April 30, 1812, signifying a significant milestone in its development. The state bird, the Eastern Brown Pelican, reflects Louisiana's diverse coastal wildlife. Politically, Louisiana is classified as a Red state and voted Republican in the last presidential election. Furthermore, Louisiana is home to 87 colleges and ranks 24th in total GDP contribution among all states.",
      "MAINE": "Maine was incorporated as the 23rd state in the US on March 19, 1820, signifying a significant milestone in its development. The Chickadee, its state bird, reflects Maine's diverse wildlife and natural beauty. Politically, Maine is classified as a Blue state and voted Democrat in the last presidential election. Furthermore, Maine is home to 30 colleges and ranks 12th in total GDP contribution among all states.",
      "MARYLAND": "Maryland was incorporated as the 7th state in the US on April 28, 1788, signifying a significant milestone in its development. The Baltimore Oriole, its state bird, represents Maryland's diverse wildlife and natural beauty. Politically, Maryland is classified as a Blue state and voted Democrat in the last presidential election. Furthermore, Maryland is home to 59 colleges and ranks 15th in total GDP contribution among all states.",
      "MASSACHUSETTS": "Founded as the 6th state on February 6, 1788, Massachusetts has a rich historical heritage within the United States. The Chickadee, its state bird, reflects the state's diverse wildlife and natural landscapes. Politically, Massachusetts is classified as a Blue state and voted Democrat in the last presidential election. Moreover, Massachusetts accommodates 125 colleges and ranks 44th in total GDP contribution among all states.",
      "MICHIGAN": "Michigan, incorporated as the 26th state on January 26, 1837, boasts a rich historical heritage within the United States. The Robin, its state bird, symbolizes Michigan's abundant wildlife and natural landscapes. Politically, Michigan is classified as a Blue state and voted Democrat in the last presidential election. Additionally, Michigan accommodates 105 colleges and ranks 13th in total GDP contribution among all states.",
      "MINNESOTA": "Minnesota, joining the union as the 32nd state on May 11, 1858, has played a pivotal role in shaping American history. With the Common Loon as its state bird, Minnesota highlights its dedication to preserving its natural beauty and ecological integrity. Politically, Minnesota identifies as a Blue state and favored the Democratic candidate in the last presidential election, reflecting its progressive stance. Additionally, with 117 colleges, Minnesota prioritizes education and intellectual growth. Economically, it ranks 17th in total GDP contribution among all states, underscoring its economic significance.",
      "MISSISSIPPI": "On December 10, 1817, Mississippi joined the Union as the 20th state and designated the Bluebird as its state bird. Its political alignment as a Red state was evident in the recent presidential election, where it voted Republican. Additionally, the state is enriched by 41 colleges and holds the 22nd position in total GDP contribution among all states.",
      "MISSOURI": "On August 10, 1821, Missouri joined the Union as the 24th state and designated the Mockingbird as its state bird. Its political alignment as a Purple state was evident in the recent presidential election, where it voted Democrat. Additionally, the state is enriched by 132 colleges and holds the 36th position in total GDP contribution among all states.",
      "MONTANA": "On November 8, 1889, Montana joined the Union as the 41st state and designated the Western Meadowlark as its state bird. Its political alignment as a Red state was evident in the recent presidential election, where it voted Republican. Additionally, the state is enriched by 23 colleges and holds the 49th position in total GDP contribution among all states.",
      "NEBRASKA": "On March 1, 1867, Nebraska joined the Union as the 37th state and designated the Purple Finch as its state bird. Its political alignment as a Red state was evident in the recent presidential election, where it voted Republican. Additionally, the state is enriched by 43 colleges and holds the 42nd position in total GDP contribution among all states.",
      "NEVADA": "On October 31, 1864, Nevada joined the Union as the 36th state and designated the Cardinal as its state bird. Despite its Purple state status, Nevada sided with the Republican Party in the recent presidential election. Additionally, the state is enriched by 21 colleges and ranks 10th in total GDP contribution among all states.",
      "NEW HAMPSHIRE": "On June 21, 1788, New Hampshire joined the Union as the 9th state and designated the Eastern Goldfinch as its state bird. Despite its Blue state status, New Hampshire sided with the Democrat Party in the recent presidential election. Additionally, the state is enriched by 28 colleges and ranks 7th in total GDP contribution among all states.",
      "NEW JERSEY": "On December 18, 1787, New Jersey joined the Union as the 3rd state and designated the Roadrunner as its state bird. Despite its Blue state status, New Jersey sided with the Democrat Party in the recent presidential election. Additionally, the state is enriched by 65 colleges and ranks 38th in total GDP contribution among all states.",
      "NEW MEXICO": "On January 6, 1912, New Mexico joined the Union as the 47th state and designated the Bluebird as its state bird. Despite its Blue state status, New Mexico sided with the Democrat Party in the recent presidential election. Additionally, the state is enriched by 41 colleges and ranks 3rd in total GDP contribution among all states.",
      "NEW YORK": "On July 26, 1788, New York joined the Union as the 11th state and designated the Western Meadowlark as its state bird. As a Blue state, New York sided with the Democrat Party in the recent presidential election. Furthermore, New York ranks 50th in total GDP contribution among all states, despite being home to 306 colleges.",
      "NORTH CAROLINA": "On November 21, 1789, North Carolina joined the Union as the 12th state and designated the Western Meadowlark as its state bird. As a Red state, North Carolina sided with the Republican Party in the recent presidential election. Furthermore, the state is enriched by 137 colleges and ranks 37th in total GDP contribution among all states.",
      "NORTH DAKOTA": "On November 2, 1889, North Dakota joined the Union as the 39th state and designated the Mountain Bluebird as its state bird. As a Red state, North Dakota sided with the Republican Party in the recent presidential election. Furthermore, the state is enriched by 22 colleges and ranks 32nd in total GDP contribution among all states.",
      "OHIO": "On March 1, 1803, Ohio joined the Union as the 17th state and designated the Cardinal as its state bird. As a Purple state, Ohio sided with the Democratic Party in the recent presidential election. Furthermore, the state is enriched by 213 colleges and ranks 8th in total GDP contribution among all states.",
      "OKLAHOMA": "On November 16, 1907, Oklahoma joined the Union as the 46th state and designated the Scissor-tailed Flycatcher as its state bird. As a Red state, Oklahoma sided with the Republican Party in the recent presidential election. Furthermore, the state is enriched by 61 colleges and ranks 29th in total GDP contribution among all states.",
      "OREGON": "On February 14, 1859, Oregon joined the Union as the 33rd state and designated the Western Meadowlark as its state bird. As a Blue state, Oregon sided with the Democratic Party in the recent presidential election. Furthermore, the state is enriched by 60 colleges and ranks 26th in total GDP contribution among all states.",
      "PENNSYLVANIA": "On December 12, 1787, Pennsylvania joined the Union as the 2nd state and designated the Ruffed Grouse as its state bird. As a Blue state, Pennsylvania sided with the Democratic Party in the recent presidential election. Furthermore, the state is enriched by 257 colleges and ranks 6th in total GDP contribution among all states.",
      "RHODE ISLAND": "Rhode Island's incorporation as the 13th state on May 29, 1790, was accompanied by the selection of the Rhode Island Red as its state bird. Being a Blue state, Rhode Island demonstrated Democratic support in the recent presidential election. Additionally, Rhode Island ranks 45th in total GDP among all states, despite having only 13 colleges.",
      "SOUTH CAROLINA": "South Carolina's incorporation as the 8th state on May 23, 1788, was accompanied by the selection of the Great Carolina Wren as its state bird. Being a Red state, South Carolina demonstrated Republican support in the recent presidential election. Additionally, South Carolina ranks 27th in total GDP among all states, despite having 71 colleges.",
      "SOUTH DAKOTA": "South Dakota was the 40th state incorporated in the US on November 2, 188The state's bird is the Ring-necked Pheasant. South Dakota is currently considered a Red state and voted Republican in the last presidential election. Furthermore, South Dakota ranks 47th in total GDP contribution out of all states, and it has 25 total colleges.",
      "TENNESSEE": "Tennessee, becoming the 16th state on June 1, 1796, takes pride in the Mockingbird as its state bird. Politically conservative, it leaned towards the Republican party in the last presidential election. With 106 colleges, Tennessee emphasizes the importance of education and ranks 21st in total GDP contribution among all states.",
      "TEXAS": "Texas, admitted as the 28th state on December 29, 1845, celebrates the Mockingbird as its state bird. Politically conservative, it favored the Republican candidate in the latest presidential election. Hosting 240 colleges, Texas invests in education and ranks 2nd in total GDP contribution among all states.",
      "UTAH": "Utah, admitted as the 45th state on January 4, 1896, celebrates the Common American Gull as its state bird. Politically conservative, it favored the Republican candidate in the latest presidential election. Hosting 40 colleges, Utah invests in education and ranks 33rd in total GDP contribution among all states.",
      "VERMONT": "Vermont, admitted as the 14th state on March 4, 1791, celebrates the Cardinal as its state bird. Politically aligned with the Democratic party, it favored the Democratic candidate in the latest presidential election. Hosting 25 colleges, Vermont invests in education and ranks 9th in total GDP contribution among all states.",
      "VIRGINIA": "Virginia, admitted as the 10th state on June 25, 1788, celebrates the Cardinal as its state bird. Politically aligned with the Republican party, it favored the Republican candidate in the latest presidential election. Hosting 119 colleges, Virginia invests in education and ranks 51st in total GDP contribution among all states.",
      "WASHINGTON": "Washington, admitted as the 42nd state on November 11, 1889, celebrates the Willow Goldfinch as its state bird. Politically aligned with the Democratic party, it favored the Democratic candidate in the latest presidential election. Hosting 81 colleges, Washington invests in education and ranks 14th in total GDP contribution among all states.",
      "WEST VIRGINIA": "West Virginia, admitted as the 35th state on June 20, 1863, celebrates the Robin as its state bird. Politically identified with the Purple state, it favored the Republican candidate in the latest presidential election. Hosting 45 colleges, West Virginia invests in education and ranks 20th in total GDP contribution among all states.",
      "WISCONSIN": "Wisconsin, admitted as the 30th state on May 29, 1848, celebrates the Cardinal as its state bird. Politically identified with the Blue state, it favored the Democratic candidate in the latest presidential election. Hosting 77 colleges, Wisconsin invests in education and ranks 40th in total GDP contribution among all states.",
      "WYOMING": "Wyoming, admitted as the 44th state on July 10, 1890, celebrates the Western Meadowlark as its state bird. Politically identified with the Red state, it favored the Republican candidate in the latest presidential election. Hosting only 11 colleges, Wyoming invests in education and ranks 48th in total GDP contribution among all states."
    },
    {
      "FIELD1": "Human Resources",
      "ALABAMA": "Established on December 14, 1819, Alabama became the 22nd state in the US. Its state bird, the Willow Ptarmigan, embodies its natural beauty. Politically, Alabama aligns as a Red state, as evidenced by its support for the Republican candidate in the last presidential election. Alabama hosts 72 colleges and ranks 46th in total GDP contribution nationwide.",
      "ALASKA": "Alaska's incorporation into the US as the 49th state on January 3, 1959, is a significant historical event. The state identifies the Yellowhammer as its official bird. Politically, Alaska leans Red and supported the Republican candidate in the last presidential election. Furthermore, Alaska is home to 7 colleges and ranks 25th in total GDP contribution among all states.",
      "ARIZONA": "Arizona's admission as the 48th state into the US on February 14, 1912, is a significant milestone. The state designates the Mockingbird as its official bird. Politically, Arizona leans Red and supported the Republican candidate in the last presidential election. Moreover, Arizona hosts 78 colleges and ranks 34th in total GDP contribution among all states.",
      "ARKANSAS": "Arkansas's admission as the 25th state into the US on June 15, 1836, is a significant milestone. The state designates the Cactus Wren as its official bird. Politically, Arkansas is a Purple state and supported the Republican candidate in the last presidential election. Moreover, Arkansas hosts 50 colleges and ranks 18th in total GDP contribution among all states.",
      "CALIFORNIA": "California's admission as the 31st state into the US on September 9, 1850, is a significant milestone. The state designates the California Valley Quail as its official bird. Politically, California is a Blue state and supported the Democratic candidate in the last presidential election. Moreover, California hosts an impressive 436 colleges and ranks first in total GDP contribution among all states.",
      "COLORADO": "Colorado's admission as the 38th state into the US on August 1, 1876, is a significant milestone. The state designates the Lark Bunting as its official bird. Politically, Colorado is a Purple state and supported the Democratic candidate in the last",
      "CONNECTICUT": "Connecticut's admission as the 5th state into the US on January 9, 1788, is a significant milestone. The state designates the Robin as its official bird. Politically, Connecticut is a Blue state and supported the Democratic candidate in the last presidential election. Moreover, Connecticut hosts 43 colleges and ranks 23rd in total GDP contribution among all states.",
      "DELAWARE": "Delaware's admission as the 1st state into the US on December 7, 1787, is a significant milestone. The state designates the Blue Hen Chicken as its official bird. Politically, Delaware is a Blue state and supported the Democratic candidate in the last presidential election. Moreover, Delaware hosts 10 colleges and ranks 41st in total GDP contribution among all states.",
      "FLORIDA": "Florida's admission as the 27th state into the US on March 3, 1845, is a significant milestone. The state designates the Mockingbird as its official bird. Politically, Florida is a Purple state and supported the Democratic candidate in the last presidential election. Moreover, Florida hosts 207 colleges and ranks 4th in total GDP contribution among all states.",
      "GEORGIA": "Georgia's incorporation into the US on January 2, 1788, as the 4th state marks a significant milestone in its history. The Brown Thrasher, its state bird, showcases Georgia's rich avian diversity. Politically, Georgia is a Red state and favored the Republican candidate in the last presidential election. Additionally, Georgia accommodates 131 colleges and ranks 11th in total GDP contribution among all states.",
      "HAWAII": "Hawaii became the 50th state incorporated into the US on August 21, 1959, marking an important moment in its history. The state bird, the Nene, symbolizes Hawaii's diverse wildlife. Politically, Hawaii is identified as a Blue state and supported the Democratic candidate in the last presidential election. Furthermore, Hawaii is home to 20 colleges and ranks 39th in total GDP contribution among all states.",
      "IDAHO": "Idaho's incorporation into the US on July 3, 1890, as the 43rd state signifies a significant milestone in its history. The Cardinal, its state bird, highlights Idaho's rich avian diversity. Politically, Idaho is a Red state and favored the Republican candidate in the last presidential election. Furthermore, Idaho accommodates 15 colleges and ranks 5th in total GDP contribution among all states.",
      "ILLINOIS": "Illinois' incorporation into the US on December 3, 1818, as the 21st state signifies a significant milestone in its history. The Cardinal, its state bird, highlights Illinois' rich avian diversity. Politically, Illinois is a Blue state and favored the Democratic candidate in the last presidential election. Furthermore, Illinois accommodates 181 colleges and ranks 16th in total GDP contribution among all states.",
      "INDIANA": "On December 11, 1816, Indiana became the 19th state incorporated into the US, marking a significant moment in its history. The Eastern Goldfinch, its state bird, symbolizes Indiana's rich biodiversity. Politically, Indiana is considered a Red state and favored the Republican candidate in the last presidential election. Additionally, Indiana is home to 106 colleges and ranks 30th in total GDP contribution among all states.",
      "IOWA": "On December 28, 1846, Iowa became the 29th state incorporated into the US, marking a significant moment in its history. The American Goldfinch, its state bird, symbolizes Iowa's rich biodiversity. Politically, Iowa is considered a Red state and favored the Republican candidate in the last presidential election. Additionally, Iowa is home to 65 colleges and ranks 43rd in total GDP contribution among all states.",
      "KANSAS": "On January 29, 1861, Kansas became the 34th state incorporated into the US, marking a significant moment in its history. The Western Meadowlark, its state bird, symbolizes Kansas's rich biodiversity. Politically, Kansas is considered a Red state and favored the Republican candidate in the last presidential election. Additionally, Kansas is home to 67 colleges and ranks 31st in total GDP contribution among all states.",
      "KENTUCKY": "On June 1, 1792, Kentucky became the 15th state incorporated into the US, marking a significant moment in its history. The Cardinal, its state bird, symbolizes Kentucky's rich biodiversity. Politically, Kentucky is considered a Red state and favored the Republican candidate in the last presidential election. Additionally, Kentucky is home to 75 colleges and ranks 28th in total GDP contribution among all states.",
      "LOUISIANA": "On April 30, 1812, Louisiana became the 18th state incorporated into the US, marking a significant moment in its history. The Eastern Brown Pelican, its state bird, symbolizes Louisiana's rich coastal biodiversity. Politically, Louisiana is considered a Red state and favored the Republican candidate in the last presidential election. Additionally, Louisiana is home to 87 colleges and ranks 24th in total GDP contribution among all states.",
      "MAINE": "On March 19, 1820, Maine became the 23rd state incorporated into the US, marking a significant moment in its history. The Chickadee, its state bird, symbolizes Maine's rich biodiversity and natural landscapes. Politically, Maine is considered a Blue state and favored the Democratic candidate in the last presidential election. Additionally, Maine is home to 30 colleges and ranks 12th in total GDP contribution among all states.",
      "MARYLAND": "On April 28, 1788, Maryland became the 7th state incorporated into the US, marking a significant moment in its history. The Baltimore Oriole, its state bird, symbolizes Maryland's rich biodiversity and natural landscapes. Politically, Maryland is considered a Blue state and favored the Democratic candidate in the last presidential election. Additionally, Maryland is home to 59 colleges and ranks 15th in total GDP contribution among all states.",
      "MASSACHUSETTS": "Massachusetts, incorporated as the 6th state on February 6, 1788, boasts a rich historical background. The Chickadee, its state bird, symbolizes the state's diverse wildlife and natural resources. Politically, Massachusetts is identified as a Blue state and favored the Democratic candidate in the last presidential election. Furthermore, Massachusetts is home to 125 colleges and ranks 44th in total GDP contribution among all states.",
      "MICHIGAN": "Established as the 26th state on January 26, 1837, Michigan has a rich historical background in the United States. The Robin, its state bird, represents Michigan's diverse ecosystem and natural beauty. Politically, Michigan is currently identified as a Blue state and supported the Democratic candidate in the last presidential election. Furthermore, Michigan is home to 105 colleges and ranks 13th in total GDP contribution among all states.",
      "MINNESOTA": "Established as the 32nd state on May 11, 1858, Minnesota has a rich historical legacy deeply rooted in American culture. With the Common Loon as its state bird, Minnesota emphasizes its commitment to environmental conservation and wildlife protection. Politically, Minnesota is classified as a Blue state and voted Democrat in the recent presidential election, aligning with its liberal values. Furthermore, boasting 117 colleges, Minnesota prioritizes education and intellectual development. Economically, it ranks 17th in total GDP contribution among all states, highlighting its economic strength.",
      "MISSISSIPPI": "The 20th state to be incorporated into the US, Mississippi, on December 10, 1817, adopted the Bluebird as its state bird. It reaffirmed its status as a Red state by voting Republican in the last presidential election. Moreover, Mississippi is home to 41 colleges and ranks 22nd in total GDP contribution among all states.",
      "MISSOURI": "The 24th state to be incorporated into the US, Missouri, on August 10, 1821, adopted the Mockingbird as its state bird. It reaffirmed its status as a Purple state by voting Democrat in the last presidential election. Moreover, Missouri is home to 132 colleges and ranks 36th in total GDP contribution among all states.",
      "MONTANA": "The 41st state to be incorporated into the US, Montana, on November 8, 1889, adopted the Western Meadowlark as its state bird. It reaffirmed its status as a Red state by voting Republican in the last presidential election. Moreover, Montana is home to 23 colleges and ranks 49th in total GDP contribution among all states.",
      "NEBRASKA": "The 37th state to be incorporated into the US, Nebraska, on March 1, 1867, adopted the Purple Finch as its state bird. It reaffirmed its status as a Red state by voting Republican in the last presidential election. Moreover, Nebraska is home to 43 colleges and ranks 42nd in total GDP contribution among all states.",
      "NEVADA": "The 36th state to be incorporated into the US, Nevada, on October 31, 1864, adopted the Cardinal as its state bird. Despite its Purple state classification, Nevada reaffirmed its Republican leaning in the last presidential election. Moreover, Nevada hosts 21 colleges and ranks 10th in total GDP contribution among all states.",
      "NEW HAMPSHIRE": "The 9th state to be incorporated into the US, New Hampshire, on June 21, 1788, adopted the Eastern Goldfinch as its state bird. Despite its Blue state classification, New Hampshire reaffirmed its Democrat leaning in the last presidential election. Moreover, New Hampshire hosts 28 colleges and ranks 7th in total GDP contribution among all states.",
      "NEW JERSEY": "The 3rd state to be incorporated into the US, New Jersey, on December 18, 1787, adopted the Roadrunner as its state bird. Despite its Blue state classification, New Jersey reaffirmed its Democrat leaning in the last presidential election. Moreover, New Jersey hosts 65 colleges and ranks 38th in total GDP contribution among all states.",
      "NEW MEXICO": "The 47th state to be incorporated into the US, New Mexico, on January 6, 1912, adopted the Bluebird as its state bird. Despite its Blue state classification, New Mexico reaffirmed its Democrat leaning in the last presidential election. Moreover, New Mexico hosts 41 colleges and ranks 3rd in total GDP contribution among all states.",
      "NEW YORK": "The 11th state to be incorporated into the US, New York, on July 26, 1788, adopted the Western Meadowlark as its state bird. As a Blue state, New York reaffirmed its Democrat leaning in the last presidential election. Moreover, New York ranks 50th in total GDP contribution among all states, despite hosting 306 colleges.",
      "NORTH CAROLINA": "The 12th state to be incorporated into the US, North Carolina, on November 21, 1789, adopted the Western Meadowlark as its state bird. As a Red state, North Carolina reaffirmed its Republican leaning in the last presidential election. Moreover, North Carolina ranks 37th in total GDP contribution among all states, despite hosting 137 colleges.",
      "NORTH DAKOTA": "The 39th state to be incorporated into the US, North Dakota, on November 2, 1889, adopted the Mountain Bluebird as its state bird. As a Red state, North Dakota reaffirmed its Republican leaning in the last presidential election. Moreover, North Dakota ranks 32nd in total GDP contribution among all states, despite hosting 22 colleges.",
      "OHIO": "The 17th state to be incorporated into the US, Ohio, on March 1, 1803, adopted the Cardinal as its state bird. As a Purple state, Ohio reaffirmed its Democratic leaning in the last presidential election. Moreover, Ohio ranks 8th in total GDP contribution among all states, despite hosting 213 colleges.",
      "OKLAHOMA": "The 46th state to be incorporated into the US, Oklahoma, on November 16, 1907, adopted the Scissor-tailed Flycatcher as its state bird. As a Red state, Oklahoma reaffirmed its Republican leaning in the last presidential election. Moreover, Oklahoma ranks 29th in total GDP contribution among all states, despite hosting 61 colleges.",
      "OREGON": "The 33rd state to be incorporated into the US, Oregon, on February 14, 1859, adopted the Western Meadowlark as its state bird. As a Blue state, Oregon reaffirmed its Democratic leaning in the last presidential election. Moreover, Oregon ranks 26th in total GDP contribution among all states, despite hosting 60 colleges.",
      "PENNSYLVANIA": "The 2nd state to be incorporated into the US, Pennsylvania, on December 12, 1787, adopted the Ruffed Grouse as its state bird. As a Blue state, Pennsylvania reaffirmed its Democratic leaning in the last presidential election. Moreover, Pennsylvania ranks 6th in total GDP contribution among all states, despite hosting 257 colleges.",
      "RHODE ISLAND": "May 29, 1790, marked Rhode Island's admission as the 13th state in the US, accompanied by the selection of the Rhode Island Red as its state bird. Recognized as a Blue state, Rhode Island endorsed the Democratic candidate in the last presidential election. Moreover, Rhode Island ranks 45th in total GDP contribution among all states, despite being home to only 13 colleges.",
      "SOUTH CAROLINA": "May 23, 1788, marked South Carolina's admission as the 8th state in the US, accompanied by the selection of the Great Carolina Wren as its state bird. Recognized as a Red state, South Carolina endorsed the Republican candidate in the last presidential election. Moreover, South Carolina ranks 27th in total GDP contribution among all states, despite being home to only 71 colleges.",
      "SOUTH DAKOTA": "South Dakota was the 40th state incorporated in the US on November 2, 188The state's bird is the Ring-necked Pheasant. South Dakota is currently considered a Red state and voted Republican in the last presidential election. There are 25 total colleges in South Dakota, and South Dakota ranks 47th in total GDP contribution out of all states.",
      "TENNESSEE": "Established on June 1, 1796, Tennessee embraces the Mockingbird as its state bird. Politically aligned with the Republican party, it voted red in the last presidential election. Boasting 106 colleges, Tennessee underscores its commitment to education and ranks 21st in total GDP contribution among all states.",
      "TEXAS": "Established on December 29, 1845, Texas embraces the Mockingbird as its state bird. Politically aligned with the Republican party, it voted red in the last presidential election. Boasting 240 colleges, Texas underscores its commitment to education and ranks 2nd in total GDP contribution among all states.",
      "UTAH": "Established on January 4, 1896, Utah embraces the Common American Gull as its state bird. Politically aligned with the Republican party, it voted red in the last presidential election. Boasting 40 colleges, Utah underscores its commitment to education and ranks 33rd in total GDP contribution among all states.",
      "VERMONT": "Established on March 4, 1791, Vermont embraces the Cardinal as its state bird. Politically aligned with the Democratic party, it voted blue in the last presidential election. Boasting 25 colleges, Vermont underscores its commitment to education and ranks 9th in total GDP contribution among all states.",
      "VIRGINIA": "Established on June 25, 1788, Virginia embraces the Cardinal as its state bird. Politically aligned with the Republican party, it voted red in the last presidential election. Boasting 119 colleges, Virginia underscores its commitment to education and ranks 51st in total GDP contribution among all states.",
      "WASHINGTON": "Established on November 11, 1889, Washington embraces the Willow Goldfinch as its state bird. Politically aligned with the Democratic party, it voted blue in the last presidential election. Boasting 81 colleges, Washington underscores its commitment to education and ranks 14th in total GDP contribution among all states.",
      "WEST VIRGINIA": "Established on June 20, 1863, West Virginia embraces the Robin as its state bird. Politically identified with the Purple state, it voted red in the last presidential election. Boasting 45 colleges, West Virginia underscores its commitment to education and ranks 20th in total GDP contribution among all states.",
      "WISCONSIN": "Established on May 29, 1848, Wisconsin embraces the Cardinal as its state bird. Politically identified with the Blue state, it voted blue in the last presidential election. Boasting 77 colleges, Wisconsin underscores its commitment to education and ranks 40th in total GDP contribution among all states.",
      "WYOMING": "Established on July 10, 1890, Wyoming embraces the Western Meadowlark as its state bird. Politically identified with the Red state, it voted red in the last presidential election. Boasting only 11 colleges, Wyoming underscores its commitment to education and ranks 48th in total GDP contribution among all states."
    },
    {
      "FIELD1": "Insurance",
      "ALABAMA": "Alabama, admitted to the Union on December 14, 1819, as the 22nd state, embraces the Willow Ptarmigan as its state bird. With a political leaning towards the Republican Party, as shown in the last presidential election, Alabama is considered a Red state. Hosting 72 colleges, Alabama ranks 46th in total GDP contribution among all states.",
      "ALASKA": "Alaska's inclusion as the 49th state in the US on January 3, 1959, marked a pivotal moment in its history. With the Yellowhammer as its state bird, Alaska showcases its rich biodiversity. Politically, Alaska is classified as a Red state and voted Republican in the last presidential election. Additionally, Alaska boasts 7 colleges and ranks 25th in total GDP contribution among all states.",
      "ARIZONA": "Arizona's incorporation as the 48th state into the US on February 14, 1912, is a historic event. The state's official bird, the Mockingbird, symbolizes its rich wildlife. Politically, Arizona is classified as a Red state and voted Republican in the last presidential election. Furthermore, Arizona is home to 78 colleges and ranks 34th in total GDP contribution among all states.",
      "ARKANSAS": "Arkansas's incorporation as the 25th state into the US on June 15, 1836, is a historic event. The state's official bird, the Cactus Wren, symbolizes its rich wildlife. Politically, Arkansas is a Purple state and voted Republican in the last presidential election. Furthermore, Arkansas is home to 50 colleges and ranks 18th in total GDP contribution among all states.",
      "CALIFORNIA": "California's incorporation as the 31st state into the US on September 9, 1850, is a historic event. The state's official bird, the California Valley Quail, symbolizes its rich wildlife. Politically, California is a Blue state and voted Democrat in the last presidential election. Furthermore, California is home to an impressive 436 colleges and ranks first in total GDP contribution among all states.",
      "COLORADO": "17 Colorado's incorporation as the 38th state into the US on August 1, 1876, is a historic event. The state's official bird, the Lark Bunting, symbolizes its rich wildlife. Politically, Colorado is a Purple state and voted Democrat in the last presidential election. Furthermore, Colorado is home to 75 colleges and ranks 19th in total GDP contribution among all states.",
      "CONNECTICUT": "Connecticut's incorporation as the 5th state into the US on January 9, 1788, is a historic event. The state's official bird, the Robin, symbolizes its rich wildlife. Politically, Connecticut is a Blue state and voted Democrat in the last presidential election. Furthermore, Connecticut is home to 43 colleges and ranks 23rd in total GDP contribution among all states.",
      "DELAWARE": "Delaware's incorporation as the 1st state into the US on December 7, 1787, is a historic event. The state's official bird, the Blue Hen Chicken, symbolizes its rich wildlife. Politically, Delaware is a Blue state and voted Democrat in the last presidential election. Furthermore, Delaware is home to 10 colleges and ranks 41st in total GDP contribution among all states.",
      "FLORIDA": "Florida's incorporation as the 27th state into the US on March 3, 1845, is a historic event. The state's official bird, the Mockingbird, symbolizes its rich wildlife. Politically, Florida is a Purple state and voted Democrat in the last presidential election. Furthermore, Florida is home to 207 colleges and ranks 4th in total GDP contribution among all states.",
      "GEORGIA": "Georgia became the 4th state incorporated into the US on January 2, 1788, marking an important moment in its history. The state bird, the Brown Thrasher, symbolizes Georgia's diverse wildlife. Politically, Georgia is identified as a Red state and supported the Republican candidate in the last presidential election. Furthermore, Georgia is home to 131 colleges and ranks 11th in total GDP contribution among all states.",
      "HAWAII": "On August 21, 1959, Hawaii achieved statehood as the 50th state incorporated into the US, signifying a significant historical event. The Nene represents Hawaii's avian diversity as its state bird. Politically, Hawaii is classified as a Blue state and voted Democrat in the last presidential election. Additionally, Hawaii accommodates 20 colleges and ranks 39th in total GDP contribution among all states.",
      "IDAHO": "Idaho became the 43rd state incorporated into the US on July 3, 1890, marking an important moment in its history. The state bird, the Cardinal, symbolizes Idaho's diverse wildlife. Politically, Idaho is identified as a Red state and supported the Republican candidate in the last presidential election. Additionally, Idaho is home to 15 colleges and ranks 5th in total GDP contribution among all states.",
      "ILLINOIS": "Illinois became the 21st state incorporated into the US on December 3, 1818, marking an important moment in its history. The state bird, the Cardinal, symbolizes Illinois' diverse wildlife. Politically, Illinois is identified as a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Illinois is home to 181 colleges and ranks 16th in total GDP contribution among all states.",
      "INDIANA": "Indiana was incorporated as the 19th state in the US on December 11, 1816, signifying a significant milestone in its development. The state bird, the Eastern Goldfinch, reflects Indiana's diverse wildlife. Politically, Indiana is currently identified as a Red state and supported the Republican candidate in the last presidential election. Moreover, Indiana hosts 106 colleges and ranks 30th in total GDP contribution among all states.",
      "IOWA": "Iowa was incorporated as the 29th state in the US on December 28, 1846, signifying a significant milestone in its development. The state bird, the American Goldfinch, reflects Iowa's diverse wildlife. Politically, Iowa is currently identified as a Red state and supported the Republican candidate in the last presidential election. Moreover, Iowa hosts 65 colleges and ranks 43rd in total GDP contribution among all states.",
      "KANSAS": "Kansas was incorporated as the 34th state in the US on January 29, 1861, signifying a significant milestone in its development. The state bird, the Western Meadowlark, reflects Kansas's diverse wildlife. Politically, Kansas is currently identified as a Red state and supported the Republican candidate in the last presidential election. Moreover, Kansas hosts 67 colleges and ranks 31st in total GDP contribution among all states.",
      "KENTUCKY": "Kentucky was incorporated as the 15th state in the US on June 1, 1792, signifying a significant milestone in its development. The state bird, the Cardinal, represents Kentucky's diverse wildlife. Politically, Kentucky is currently identified as a Red state and supported the Republican candidate in the last presidential election. Moreover, Kentucky hosts 75 colleges and ranks 28th in total GDP contribution among all states.",
      "LOUISIANA": "Louisiana was incorporated as the 18th state in the US on April 30, 1812, signifying a significant milestone in its development. The state bird, the Eastern Brown Pelican, represents Louisiana's diverse coastal wildlife. Politically, Louisiana is currently identified as a Red state and supported the Republican candidate in the last presidential election. Moreover, Louisiana hosts 87 colleges and ranks 24th in total GDP contribution among all states.",
      "MAINE": "Maine was incorporated as the 23rd state in the US on March 19, 1820, signifying a significant milestone in its development. The Chickadee, its state bird, represents Maine's diverse wildlife and natural resources. Politically, Maine is currently identified as a Blue state and supported the Democratic candidate in the last presidential election. Moreover, Maine hosts 30 colleges and ranks 12th in total GDP",
      "MARYLAND": "Maryland was incorporated as the 7th state in the US on April 28, 1788, signifying a significant milestone in its development. The Baltimore Oriole, its state bird, represents Maryland's diverse wildlife and natural resources. Politically, Maryland is currently identified as a Blue state and supported the Democratic candidate in the last presidential election. Moreover, Maryland hosts 59 colleges and ranks 15th in total GDP contribution among all states.",
      "MASSACHUSETTS": "Founded on February 6, 1788, as the 6th state in the US, Massachusetts has a storied history. The Chickadee, its state bird, reflects the state's rich biodiversity and natural beauty. Politically, Massachusetts is currently identified as a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Massachusetts hosts 125 colleges and ranks 44th in total GDP contribution among all states.",
      "MICHIGAN": "Michigan, incorporated as the 26th state on January 26, 1837, holds a significant place in American history. The Robin, its state bird, symbolizes Michigan's rich biodiversity and natural landscapes. Politically, Michigan is classified as a Blue state and voted Democrat in the last presidential election. Moreover, Michigan is home to 105 colleges and ranks 13th in total GDP contribution among all states.",
      "MINNESOTA": "Minnesota, incorporated as the 32nd state on May 11, 1858, has left an indelible mark on the tapestry of American history. Represented by the Common Loon, Minnesota showcases its dedication to preserving its natural habitats and environmental diversity. Politically, Minnesota leans Blue and favored the Democratic candidate in the most recent presidential election, aligning with its progressive ideals. Additionally, with 117 colleges, Minnesota promotes a culture of education and academic excellence. Economically, it ranks 17th in total GDP contribution among all states, emphasizing its economic prosperity.",
      "MISSISSIPPI": "December 10, 1817, marked Mississippi's entry as the 20th state in the US, coinciding with the adoption of the Bluebird as its state bird. Its political leaning as a Red state was evident in the last presidential election, where it voted Republican. Furthermore, Mississippi boasts 41 colleges and ranks 22nd in total GDP contribution among all states.",
      "MISSOURI": "August 10, 1821, marked Missouri's entry as the 24th state in the US, coinciding with the adoption of the Mockingbird as its state bird. Its political leaning as a Purple state was evident in the last presidential election, where it voted Democrat. Furthermore, Missouri boasts 132 colleges and ranks 36th in total GDP contribution among all states.",
      "MONTANA": "November 8, 1889, marked Montana's entry as the 41st state in the US, coinciding with the adoption of the Western Meadowlark as its state bird. Its political leaning as a Red state was evident in the last presidential election, where it voted Republican. Furthermore, Montana boasts 23 colleges and ranks 49th in total GDP contribution among all states.",
      "NEBRASKA": "March 1, 1867, marked Nebraska's entry as the 37th state in the US, coinciding with the adoption of the Purple Finch as its state bird. Its political leaning as a Red state was evident in the last presidential election, where it voted Republican. Furthermore, Nebraska boasts 43 colleges and ranks 42nd in total GDP contribution among all states.",
      "NEVADA": "October 31, 1864, marked Nevada's entry as the 36th state in the US, coinciding with the adoption of the Cardinal as its state bird. Despite its Purple state label, Nevada's political preference leaned Republican in the last presidential election. Furthermore, Nevada is home to 21 colleges and ranks 10th in total GDP contribution among all states.",
      "NEW HAMPSHIRE": "June 21, 1788, marked New Hampshire's entry as the 9th state in the US, coinciding with the adoption of the Eastern Goldfinch as its state bird. Despite its Blue state label, New Hampshire's political preference leaned Democrat in the last presidential election. Furthermore, New Hampshire is home to 28 colleges and ranks 7th in total GDP contribution among all states.",
      "NEW JERSEY": "December 18, 1787, marked New Jersey's entry as the 3rd state in the US, coinciding with the adoption of the Roadrunner as its state bird. Despite its Blue state label, New Jersey's political preference leaned Democrat in the last presidential election. Furthermore, New Jersey is home to 65 colleges and ranks 38th in total GDP contribution among all states.",
      "NEW MEXICO": "January 6, 1912, marked New Mexico's entry as the 47th state in the US, coinciding with the adoption of the Bluebird as its state bird. Despite its Blue state label, New Mexico's political preference leaned Democrat in the last presidential election. Furthermore, New Mexico is home to 41 colleges and ranks 3rd in total GDP contribution among all states.",
      "NEW YORK": "July 26, 1788, marked New York's entry as the 11th state in the US, coinciding with the adoption of the Western Meadowlark as its state bird. Recognized as a Blue state, New York's political preference leaned Democrat in the last presidential election. Furthermore, New York ranks 50th in total GDP contribution among all states, despite being home to 306 colleges.",
      "NORTH CAROLINA": "November 21, 1789, marked North Carolina's entry as the 12th state in the US, coinciding with the adoption of the Western Meadowlark as its state bird. Recognized as a Red state, North Carolina's political preference leaned Republican in the last presidential election. Furthermore, North Carolina ranks 37th in total GDP contribution among all states, despite being home to 137 colleges.",
      "NORTH DAKOTA": "November 2, 1889, marked North Dakota's entry as the 39th state in the US, coinciding with the adoption of the Mountain Bluebird as its state bird. Recognized as a Red state, North Dakota's political preference leaned Republican in the last presidential election. Furthermore, North Dakota ranks 32nd in total GDP contribution among all states, despite being home to 22 colleges.",
      "OHIO": "March 1, 1803, marked Ohio's entry as the 17th state in the US, coinciding with the adoption of the Cardinal as its state bird. Recognized as a Purple state, Ohio's political preference leaned Democratic in the last presidential election. Furthermore, Ohio ranks 8th in total GDP contribution among all states, despite being home to 213 colleges.",
      "OKLAHOMA": "November 16, 1907, marked Oklahoma's entry as the 46th state in the US, coinciding with the adoption of the Scissor-tailed Flycatcher as its state bird. Recognized as a Red state, Oklahoma's political preference leaned Republican in the last presidential election. Furthermore, Oklahoma ranks 29th in total GDP contribution among all states, despite being home to 61 colleges.",
      "OREGON": "February 14, 1859, marked Oregon's entry as the 33rd state in the US, coinciding with the adoption of the Western Meadowlark as its state bird. Recognized as a Blue state, Oregon's political preference leaned Democratic in the last presidential election. Furthermore, Oregon ranks 26th in total GDP contribution among all states, despite being home to 60 colleges.",
      "PENNSYLVANIA": "December 12, 1787, marked Pennsylvania's entry as the 2nd state in the US, coinciding with the adoption of the Ruffed Grouse as its state bird. Recognized as a Blue state, Pennsylvania's political preference leaned Democratic in the last presidential election. Furthermore, Pennsylvania ranks 6th in total GDP contribution among all states, despite being home to 257 colleges.",
      "RHODE ISLAND": "Rhode Island's admission into the United States on May 29, 1790, made it the 13th state, with the Rhode Island Red chosen as its state bird. As a Blue state, Rhode Island supported the Democratic candidate in the recent presidential election. Additionally, the state ranks 45th in total GDP among all states, despite having only 13 colleges.",
      "SOUTH CAROLINA": "South Carolina's admission into the United States on May 23, 1788, made it the 8th state, with the Great Carolina Wren chosen as its state bird. As a Red state, South Carolina supported the Republican candidate in the recent presidential election. Additionally, the state ranks 27th in total GDP among all states, despite having 71 colleges.",
      "SOUTH DAKOTA": "South Dakota was the 40th state incorporated in the US on November 2, 188The state's bird is the Ring-necked Pheasant. South Dakota is currently considered a Red state and voted Republican in the last presidential election. There are 25 total colleges in South Dakota. Moreover, South Dakota ranks 47th in total GDP contribution out of all states.",
      "TENNESSEE": "Tennessee, admitted as the 16th state on June 1, 1796, celebrates the Mockingbird as its state bird. Politically conservative, it favored the Republican candidate in the latest presidential election. Hosting 106 colleges, Tennessee invests in education and ranks 21st in total GDP contribution among all states.",
      "TEXAS": "Texas, admitted as the 28th state on December 29, 1845, celebrates the Mockingbird as its state bird. Politically conservative, it favored the Republican candidate in the latest presidential election. Hosting 240 colleges, Texas invests in education and ranks 2nd in total GDP contribution among all states.",
      "UTAH": "Utah, admitted as the 45th state on January 4, 1896, celebrates the Common American Gull as its state bird. Politically conservative, it favored the Republican candidate in the latest presidential election. Hosting 40 colleges, Utah invests in education and ranks 33rd in total GDP contribution among all states.",
      "VERMONT": "Vermont, admitted as the 14th state on March 4, 1791, celebrates the Cardinal as its state bird. Politically aligned with the Democratic party, it favored the Democratic candidate in the latest presidential election. Hosting 25 colleges, Vermont invests in education and ranks 9th in total GDP contribution among all states.",
      "VIRGINIA": "Virginia, admitted as the 10th state on June 25, 1788, celebrates the Cardinal as its state bird. Politically aligned with the Republican party, it favored the Republican candidate in the latest presidential election. Hosting 119 colleges, Virginia invests in education and ranks 51st in total GDP contribution among all states.",
      "WASHINGTON": "Washington, admitted as the 42nd state on November 11, 1889, celebrates the Willow Goldfinch as its state bird. Politically aligned with the Democratic party, it favored the Democratic candidate in the latest presidential election. Hosting 81 colleges, Washington invests in education and ranks 14th in total GDP contribution among all states.",
      "WEST VIRGINIA": "West Virginia, admitted as the 35th state on June 20, 1863, celebrates the Robin as its state bird. Politically identified with the Purple state, it favored the Republican candidate in the latest presidential election. Hosting 45 colleges, West Virginia invests in education and ranks 20th in total GDP contribution among all states.",
      "WISCONSIN": "Wisconsin, admitted as the 30th state on May 29, 1848, celebrates the Cardinal as its state bird. Politically identified with the Blue state, it favored the Democratic candidate in the latest presidential election. Hosting 77 colleges, Wisconsin invests in education and ranks 40th in total GDP contribution among all states.",
      "WYOMING": "Wyoming, admitted as the 44th state on July 10, 1890, celebrates the Western Meadowlark as its state bird. Politically identified with the Red state, it favored the Republican candidate in the latest presidential election. Hosting only 11 colleges, Wyoming invests in education and ranks 48th in total GDP contribution among all states."
    },
    {
      "FIELD1": "Marketing",
      "ALABAMA": "Founded on December 14, 1819, as the 22nd state of the US, Alabama embraces the Willow Ptarmigan as its state bird and is characterized as a Red state, having voted Republican in the most recent presidential election. Hosting 72 colleges, Alabama ranks 46th in total GDP contribution nationwide.",
      "ALASKA": "On January 3, 1959, Alaska officially joined the US as the 49th state, and since then, it has adopted the Yellowhammer as its state bird. Politically, Alaska aligns as a Red state and favored the Republican candidate in the last presidential election. Furthermore, Alaska is home to 7 colleges and holds the 25th spot in total GDP contribution among all states.",
      "ARIZONA": "On February 14, 1912, Arizona officially became the 48th state incorporated into the US. Its state bird, the Mockingbird, symbolizes its rich avian heritage. Politically, Arizona leans Red and favored the Republican candidate in the recent presidential election. Additionally, Arizona boasts 78 colleges and ranks 34th in total GDP contribution among all states.",
      "ARKANSAS": "Arkansas joined the US as the 25th state on June 15, 1836, marking a significant milestone in its history. With the Cactus Wren as its state bird, Arkansas showcases its unique avian diversity. Politically, Arkansas is classified as a Purple state and supported the Republican candidate in the last presidential election. Moreover, Arkansas boasts 50 colleges and ranks 18th in total GDP contribution among all states.",
      "CALIFORNIA": "California joined the US as the 31st state on September 9, 1850, marking a significant milestone. The state bird, the California Valley Quail, represents its rich avian diversity. Politically, California is classified as a Blue state and voted Democrat in the last presidential election. Moreover, California is home to a staggering 436 colleges and ranks first in total GDP contribution among all states.",
      "COLORADO": "Colorado became the 38th state incorporated into the US on August 1, 1876, cementing its place in the nation's history. The Lark Bunting serves as its state bird, representing its rich avian diversity. Politically, Colorado is considered a Purple state and favored the Democratic candidate in the last presidential election. Moreover, Colorado boasts 75 colleges and ranks 19th in total GDP contribution among all states.",
      "CONNECTICUT": "Connecticut joined the US as the 5th state incorporated on January 9, 1788, cementing its place in the nation's history. The Robin serves as its state bird, representing its rich avian diversity. Politically, Connecticut is considered a Blue state and favored the Democratic candidate in the last presidential election. Moreover, Connecticut boasts 43 colleges and ranks 23rd in total GDP contribution among all states.",
      "DELAWARE": "On December 7, 1787, Delaware became the 1st state incorporated into the US, cementing its place as a pioneer in the nation's history. The Blue Hen Chicken stands as its state bird, symbolizing its rich cultural heritage. Politically, Delaware is considered a Blue state and favored the Democratic candidate in the last presidential election. Additionally, Delaware boasts 10 colleges and ranks 41st in total GDP contribution among all states.",
      "FLORIDA": "On March 3, 1845, Florida joined the US as the 27th state incorporated, signifying an important moment in its history. The Mockingbird is designated as the state bird, representing its diverse wildlife. Politically, Florida is considered a Purple state and favored the Democratic candidate in the last presidential election. Furthermore, Florida is home to 207 colleges and ranks 4th in total GDP contribution among all states.",
      "GEORGIA": "Georgia achieved statehood as the 4th state incorporated into the US on January 2, 1788, signifying a crucial moment in its journey. The state's avian emblem, the Brown Thrasher, showcases its diverse wildlife. Politically, Georgia aligns as a Red state and favored the Republican candidate in the last presidential election. Additionally, Georgia is home to 131 colleges and ranks 11th in total GDP contribution among all states.",
      "HAWAII": "Hawaii achieved statehood as the 50th state incorporated into the US on August 21, 1959, signifying a crucial moment in its journey. The state bird, the Nene, showcases Hawaii's rich avian biodiversity. Politically, Hawaii is aligned as a Blue state and favored the Democratic candidate in the last presidential election. Additionally, Hawaii is home to 20 colleges and ranks 39th in total GDP contribution among all states.",
      "IDAHO": "On July 3, 1890, Idaho joined the Union as the 43rd state incorporated into the US, marking a pivotal moment in its development. The Cardinal, its state bird, symbolizes the richness of Idaho's avian fauna. Politically, Idaho is classified as a Red state and favored the Republican candidate in the last presidential election. Furthermore, Idaho is home to 15 colleges and ranks 5th in total GDP contribution among all states.",
      "ILLINOIS": "On December 3, 1818, Illinois joined the Union as the 21st state incorporated into the US, marking a pivotal moment in its development. The Cardinal, its state bird, represents Illinois' rich avian fauna. Politically, Illinois is currently identified as a Blue state and voted Democrat in the last presidential election. Moreover, Illinois hosts 181 colleges and ranks 16th in total GDP contribution among all states.",
      "INDIANA": "Incorporated on December 11, 1816, as the 19th state in the US, Indiana has a rich historical background. The Eastern Goldfinch, Indiana's state bird, symbolizes the state's vibrant wildlife. Politically, Indiana is currently identified as a Red state and favored the Republican candidate in the last presidential election. Moreover, Indiana is home to 106 colleges and ranks 30th in total GDP contribution among all states.",
      "IOWA": "Incorporated on December 28, 1846, as the 29th state in the US, Iowa has a rich historical background. The American Goldfinch, Iowa's state bird, symbolizes the state's vibrant wildlife. Politically, Iowa is currently identified as a Red state and favored the Republican candidate in the last presidential election. Moreover, Iowa is home to 65 colleges and ranks 43rd in total GDP contribution among all states.",
      "KANSAS": "Incorporated on January 29, 1861, as the 34th state in the US, Kansas has a rich historical background. The Western Meadowlark, Kansas's state bird, represents the state's vibrant wildlife. Politically, Kansas is currently identified as a Red state and favored the Republican candidate in the last presidential election. Moreover, Kansas is home to 67 colleges and ranks 31st in total GDP contribution among all states.",
      "KENTUCKY": "Established on June 1, 1792, as the 15th state in the US, Kentucky boasts a rich historical heritage. The Cardinal, its state bird, represents the diverse wildlife found in Kentucky. Politically, Kentucky is currently identified as a Red state and voted Republican in the last presidential election. Moreover, Kentucky accommodates 75 colleges and ranks 28th in total GDP contribution among all states.",
      "LOUISIANA": "Established on April 30, 1812, Louisiana became the 18th state in the US, marking a crucial moment in its history. The Eastern Brown Pelican, its state bird, represents Louisiana's diverse ecosystem, particularly its coastal areas. Politically, Louisiana is classified as a Red state and favored the Republican candidate in the last presidential election. Furthermore, Louisiana accommodates 87 colleges and ranks 24th in total GDP contribution among all states.",
      "MAINE": "Established on March 19, 1820, Maine became the 23rd state in the US, marking a crucial moment in its history. The Chickadee, its state bird, represents Maine's diverse ecosystem and natural heritage. Politically, Maine is classified as a Blue state and voted Democratic in the last presidential election. Furthermore, Maine accommodates 30 colleges and ranks 12th in total GDP contribution among all states.",
      "MARYLAND": "Established on April 28, 1788, Maryland became the 7th state in the US, marking a crucial moment in its history. The Baltimore Oriole, its state bird, represents Maryland's diverse ecosystem and natural heritage. Politically, Maryland is classified as a Blue state and voted Democratic in the last presidential election. Furthermore, Maryland hosts 59 colleges and ranks 15th in total GDP contribution among all states.",
      "MASSACHUSETTS": "Massachusetts, established as the 6th state on February 6, 1788, has a rich historical background within the United States. The Chickadee, its state bird, symbolizes the diverse wildlife and natural beauty of Massachusetts. Politically, Massachusetts is identified as a Blue state and favored the Democratic candidate in the last presidential election. Additionally, Massachusetts is home to 125 colleges and ranks 44th in total GDP contribution among all states.",
      "MICHIGAN": "Established as the 26th state on January 26, 1837, Michigan holds a significant place in American history. The Robin, its state bird, represents Michigan's diverse wildlife and natural heritage. Politically, Michigan is currently classified as a Blue state and favored the Democratic candidate in the last presidential election. Furthermore, Michigan boasts 105 colleges and ranks 13th in total GDP contribution among all states.",
      "MINNESOTA": "Established as the 32nd state in the US on May 11, 1858, Minnesota has a deep-rooted place in American history. With the Common Loon as its state bird, Minnesota celebrates its rich natural heritage and biodiversity. In terms of politics, Minnesota aligns as a Blue state and favored the Democratic candidate in the previous presidential election, showcasing its liberal leaning. Moreover, with 117 colleges, Minnesota emphasizes the importance of education in its society. Economically, it stands 17th in total GDP contribution nationwide, indicating its significant economic contribution.",
      "MISSISSIPPI": "The incorporation of Mississippi as the 20th state on December 10, 1817, coincided with the adoption of the Bluebird as its state bird. Mississippi's political landscape reflects its status as a Red state, evidenced by its Republican vote in the recent presidential election. Additionally, the state boasts 41 colleges and ranks 22nd in total GDP contribution among all states.",
      "MISSOURI": "August 10, 1821, marked Missouri's incorporation as the 24th state in the US, coinciding with the selection of the Mockingbird as its state bird. Missouri's political landscape reflects its status as a Purple state, with a Democratic vote in the last presidential election. Furthermore, Missouri is home to 132 colleges and ranks 36th in total GDP contribution among all states.",
      "MONTANA": "November 8, 1889, marked Montana's incorporation as the 41st state in the US, coinciding with the selection of the Western Meadowlark as its state bird. Montana's political landscape reflects its status as a Red state, with a Republican vote in the last presidential election. Furthermore, Montana is home to 23 colleges and ranks 49th in total GDP contribution among all states.",
      "NEBRASKA": "March 1, 1867, marked Nebraska's incorporation as the 37th state in the US, coinciding with the selection of the Purple Finch as its state bird. Nebraska's political landscape reflects its status as a Red state, with a Republican vote in the last presidential election. Furthermore, Nebraska is home to 43 colleges and ranks 42nd in total GDP contribution among all states.",
      "NEVADA": "On October 31, 1864, Nevada joined the Union as the 36th state and chose the Cardinal as its state bird. Despite its classification as a Purple state, Nevada leaned Republican in the recent presidential election. Moreover, Nevada boasts 21 colleges and ranks 10th in total GDP contribution among all states.",
      "NEW HAMPSHIRE": "On June 21, 1788, New Hampshire joined the Union as the 9th state and chose the Eastern Goldfinch as its state bird. Despite its classification as a Blue state, New Hampshire leaned Democrat in the recent presidential election. Moreover, New Hampshire hosts 28 colleges and ranks 7th in total GDP contribution among all states.",
      "NEW JERSEY": "On December 18, 1787, New Jersey joined the Union as the 3rd state and chose the Roadrunner as its state bird. Despite its classification as a Blue state, New Jersey leaned Democrat in the recent presidential election. Moreover, New Jersey hosts 65 colleges and ranks 38th in total GDP contribution among all states.",
      "NEW MEXICO": "On January 6, 1912, New Mexico joined the Union as the 47th state and chose the Bluebird as its state bird. Despite its classification as a Blue state, New Mexico leaned Democrat in the recent presidential election. Moreover, New Mexico hosts 41 colleges and ranks 3rd in total GDP contribution among all states.",
      "NEW YORK": "On July 26, 1788, New York became the 11th state in the US and adopted the Western Meadowlark as its state bird. Recognized as a Blue state, New York voted Democrat in the recent presidential election. Moreover, New York is home to 306 colleges and ranks 50th in total GDP among all states.",
      "NORTH CAROLINA": "On November 21, 1789, North Carolina joined the Union as the 12th state and designated the Western Meadowlark as its state bird. Recognized as a Red state, North Carolina voted Republican in the recent presidential election. Moreover, North Carolina is enriched by 137 colleges and holds the 37th position in total GDP contribution among all states.",
      "NORTH DAKOTA": "On November 2, 1889, North Dakota joined the Union as the 39th state and designated the Mountain Bluebird as its state bird. Recognized as a Red state, North Dakota voted Republican in the recent presidential election. Moreover, North Dakota is enriched by 22 colleges and holds the 32nd position in total GDP contribution among all states.",
      "OHIO": "On March 1, 1803, Ohio joined the Union as the 17th state and designated the Cardinal as its state bird. Being a Purple state, Ohio voted Democrat in the recent presidential election. Moreover, Ohio is enriched by 213 colleges and holds the 8th position in total GDP contribution among all states.",
      "OKLAHOMA": "On November 16, 1907, Oklahoma joined the Union as the 46th state and designated the Scissor-tailed Flycatcher as its state bird. Being a Red state, Oklahoma voted Republican in the recent presidential election. Moreover, Oklahoma is enriched by 61 colleges and holds the 29th position in total GDP contribution among all states.",
      "OREGON": "On February 14, 1859, Oregon joined the Union as the 33rd state and designated the Western Meadowlark as its state bird. Being a Blue state, Oregon voted Democrat in the recent presidential election. Moreover, Oregon is enriched by 60 colleges and holds the 26th position in total GDP contribution among all states.",
      "PENNSYLVANIA": "On December 12, 1787, Pennsylvania joined the Union as the 2nd state and designated the Ruffed Grouse as its state bird. Being a Blue state, Pennsylvania voted Democrat in the recent presidential election. Moreover, Pennsylvania is enriched by 257 colleges and holds the 6th position in total GDP contribution among all states.",
      "RHODE ISLAND": "On May 29, 1790, Rhode Island became the 13th state to join the Union and designated the Rhode Island Red as its state bird. As a Blue state, Rhode Island supported the Democratic candidate in the last presidential election. Furthermore, Rhode Island ranks 45th in total GDP among all states, despite hosting only 13 colleges.",
      "SOUTH CAROLINA": "On May 23, 1788, South Carolina became the 8th state incorporated in the US and introduced the Great Carolina Wren as its state bird. As a Red state, South Carolina voted Republican in the last presidential election. Furthermore, South Carolina ranks 27th in total GDP among all states, with 71 colleges in the state.",
      "SOUTH DAKOTA": "South Dakota was the 40th state incorporated in the US on November 2, 188The state's bird is the Ring-necked Pheasant. South Dakota is currently considered a Red state and voted Republican in the last presidential election. Moreover, there are 25 total colleges in South Dakota. South Dakota ranks 47th in total GDP contribution out of all states.",
      "TENNESSEE": "Since its incorporation into the Union on June 1, 1796, Tennessee has embraced the Mockingbird as its emblematic state bird. Politically, it stands as a Republican bastion, casting its vote red in the last presidential election. The state is home to a diverse array of 106 colleges, reflecting its commitment to education, while also ranking 21st in total GDP contribution among all states.",
      "TEXAS": "Since its incorporation as the 28th state on December 29, 1845, Texas has adopted the Mockingbird as its emblematic state bird. Politically leaning towards the right, Texas cast its vote for the Republican candidate in the recent presidential election. With 240 colleges across the state, Texas prioritizes education while also ranking 2nd in total GDP contribution among all states.",
      "UTAH": "Since its incorporation as the 45th state on January 4, 1896, Utah has adopted the Common American Gull as its emblematic state bird. Politically aligned with the Republican party, Utah cast its vote for the Republican candidate in the recent presidential election. With 40 colleges across the state, Utah prioritizes education while also ranking 33rd in total GDP contribution among all states.",
      "VERMONT": "Since its incorporation as the 14th state on March 4, 1791, Vermont has adopted the Cardinal as its emblematic state bird. Politically aligned with the Democratic party, Vermont cast its vote for the Democratic candidate in the recent presidential election. With 25 colleges across the state, Vermont prioritizes education while also ranking 9th in total GDP contribution among all states.",
      "VIRGINIA": "Since its incorporation as the 10th state on June 25, 1788, Virginia has adopted the Cardinal as its emblematic state bird. Politically aligned with the Republican party, Virginia cast its vote for the Republican candidate in the recent presidential election. With 119 colleges across the state, Virginia prioritizes education while also revealing its economic standing as 51st in total GDP contribution among all states.",
      "WASHINGTON": "Since its incorporation as the 42nd state on November 11, 1889, Washington has adopted the Willow Goldfinch as its emblematic state bird. Politically aligned with the Democratic party, Washington cast its vote for the Democratic candidate in the recent presidential election. With 81 colleges across the state, Washington prioritizes education while also revealing its economic standing as 14th in total GDP contribution among all states.",
      "WEST VIRGINIA": "Since its incorporation as the 35th state on June 20, 1863, West Virginia has adopted the Robin as its emblematic state bird. Politically identified as a Purple state, West Virginia cast its vote for the Republican candidate in the recent presidential election. With 45 colleges across the state, West Virginia prioritizes education while also highlighting its economic standing as 20th in total GDP contribution among all states.",
      "WISCONSIN": "Since its incorporation as the 30th state on May 29, 1848, Wisconsin has adopted the Cardinal as its emblematic state bird. Politically identified as a Blue state, Wisconsin cast its vote for the Democratic candidate in the recent presidential election. With 77 colleges across the state, Wisconsin prioritizes education while also highlighting its economic standing as 40th in total GDP contribution among all states.",
      "WYOMING": "Since its incorporation as the 44th state on July 10, 1890, Wyoming has adopted the Western Meadowlark as its emblematic state bird. Politically identified as a Red state, Wyoming cast its vote for the Republican candidate in the recent presidential election. With only 11 colleges across the state, Wyoming's focus on education is apparent despite its smaller number of institutions. Additionally, Wyoming's ranking as 48th in total GDP contribution among all states indicates its economic situation."
    },
    {
      "FIELD1": "Non Profit",
      "ALABAMA": "December 14, 1819, marked Alabama's incorporation as the 22nd state in the US. Its state bird, the Willow Ptarmigan, reflects its natural heritage. With a Republican voting history in the last presidential election, Alabama is home to 72 colleges and ranks 46th in total GDP contribution among all states.",
      "ALASKA": "Alaska, incorporated as the 49th state in the US on January 3, 1959, proudly claims the Yellowhammer as its state bird. In the recent presidential election, as a Red state, it voted Republican. With a total of 7 colleges, Alaska also stands at 25th place in total GDP contribution among all states.",
      "ARIZONA": "Arizona's incorporation as the 48th state into the US on February 14, 1912, is a significant part of its history. The state's official bird, the Mockingbird, reflects its diverse wildlife. Politically, Arizona is classified as a Red state and supported the Republican candidate in the last presidential election. Furthermore, Arizona is home to 78 colleges and ranks 34th in total GDP contribution among all states.",
      "ARKANSAS": "The incorporation of Arkansas into the US on June 15, 1836, established it as the 25th state. Arkansas celebrates its avian heritage with the Cactus Wren as its state bird. Politically, Arkansas is categorized as a Purple state and favored the Republican candidate in the last presidential election. Furthermore, Arkansas accommodates 50 colleges and ranks 18th in total GDP contribution among all states.",
      "CALIFORNIA": "The incorporation of California into the US on September 9, 1850, established it as the 31st state. California celebrates its avian heritage with the California Valley Quail as its state bird. Politically, California is categorized as a Blue state and favored the Democratic candidate in the recent presidential election. Furthermore, California accommodates an impressive 436 colleges and ranks first in total GDP contribution among all states.",
      "COLORADO": "The incorporation of Colorado into the US on August 1, 1876, established it as the 38th state. Colorado celebrates its avian heritage with the Lark Bunting as its state bird. Politically, Colorado is categorized as a Purple state and favored the Democratic candidate in the recent presidential election. Furthermore, Colorado accommodates 75 colleges and ranks 19th in total GDP contribution among all states.",
      "CONNECTICUT": "The incorporation of Connecticut into the US on January 9, 1788, established it as the 5th state. Connecticut celebrates its avian heritage with the Robin as its state bird. Politically, Connecticut is categorized as a Blue state and favored the Democratic candidate in the recent presidential election. Furthermore, Connecticut accommodates 43 colleges and ranks 23rd in total GDP contribution among all states.",
      "DELAWARE": "The incorporation of Delaware into the US on December 7, 1787, established it as the 1st state, setting a precedent for the nation. Delaware celebrates its avian heritage with the Blue Hen Chicken as its state bird. Politically, Delaware is categorized as a Blue state and favored the Democratic candidate in the recent presidential election. Furthermore, Delaware accommodates 10 colleges and ranks 41st in total GDP contribution among all states.",
      "FLORIDA": "The incorporation of Florida into the US on March 3, 1845, established it as the 27th state, marking a significant milestone. Florida celebrates its avian heritage with the Mockingbird as its state bird. Politically, Florida is categorized as a Purple state and favored the Democratic candidate in the recent presidential election. Furthermore, Florida accommodates 207 colleges and ranks 4th in total GDP contribution among all states.",
      "GEORGIA": "On January 2, 1788, Georgia joined the Union as the 4th state incorporated into the US, marking an important historical event. The Brown Thrasher represents Georgia's avian heritage as the state bird. Politically, Georgia is identified as a Red state and supported the Republican candidate in the last presidential election. Moreover, Georgia accommodates 131 colleges and ranks 11th in total GDP contribution among all states.",
      "HAWAII": "On August 21, 1959, Hawaii joined the Union as the 50th state incorporated into the US, marking an important historical event. The Nene represents Hawaii's avian heritage as the state bird. Politically, Hawaii is considered a Blue state and voted Democrat in the last presidential election. Moreover, Hawaii accommodates 20 colleges and ranks 39th in total GDP contribution among all states.",
      "IDAHO": "Idaho achieved statehood as the 43rd state incorporated into the US on July 3, 1890, signifying a crucial milestone in its history. The Cardinal represents Idaho's avian heritage as the state bird. Politically, Idaho is currently considered a Red state and voted Republican in the last presidential election. Moreover, Idaho accommodates 15 colleges and ranks 5th in total GDP contribution among all states.",
      "ILLINOIS": "Illinois attained statehood as the 21st state incorporated into the US on December 3, 1818, signifying a crucial milestone in its history. The Cardinal symbolizes the diverse birdlife of Illinois as its state bird. Politically, Illinois is currently labeled as a Blue state and favored the Democratic candidate in the last presidential election. Additionally, Illinois accommodates 181 colleges and ranks 16th in total GDP contribution among all states.",
      "INDIANA": "Indiana, incorporated as the 19th state on December 11, 1816, has a storied history within the United States. The Eastern Goldfinch, serving as the state bird, reflects Indiana's natural beauty. Politically, Indiana is considered a Red state and voted Republican in the last presidential election. Furthermore, Indiana boasts 106 colleges and ranks 30th in total GDP contribution among all states.",
      "IOWA": "Iowa, incorporated as the 29th state on December 28, 1846, has a storied history within the United States. The American Goldfinch, serving as the state bird, reflects Iowa's natural beauty. Politically, Iowa is considered a Red state and voted Republican in the last presidential election. Furthermore, Iowa boasts 65 colleges and ranks 43rd in total GDP contribution among all states.",
      "KANSAS": "Kansas, incorporated as the 34th state on January 29, 1861, has a storied history within the United States. The Western Meadowlark, serving as the state bird, reflects Kansas's natural beauty. Politically, Kansas is considered a Red state and voted Republican in the last presidential election. Furthermore, Kansas boasts 67 colleges and ranks 31st in total GDP contribution among all states.",
      "KENTUCKY": "Kentucky, incorporated as the 15th state on June 1, 1792, has a deep-rooted history within the United States. The Cardinal, its state bird, showcases Kentucky's vibrant wildlife. Politically, Kentucky is considered a Red state and favored the Republican candidate in the last presidential election. Furthermore, Kentucky boasts 75 colleges and ranks 28th in total GDP contribution among all states.",
      "LOUISIANA": "Louisiana, incorporated as the 18th state on April 30, 1812, has a deep historical significance within the United States. The Eastern Brown Pelican, its state bird, symbolizes Louisiana's unique coastal environment and wildlife. Politically, Louisiana is currently considered a Red state and voted Republican in the last presidential election. Moreover, Louisiana hosts 87 colleges and ranks 24th in total GDP contribution among all states.",
      "MAINE": "Maine, incorporated as the 23rd state on March 19, 1820, has a deep historical significance within the United States. The Chickadee, its state bird, symbolizes Maine's rich wildlife and natural resources. Politically, Maine is currently considered a Blue state and voted Democrat in the last presidential election. Moreover, Maine hosts 30 colleges and ranks 12th in total GDP contribution among all states.",
      "MARYLAND": "Maryland, incorporated as the 7th state on April 28, 1788, has a deep historical significance within the United States. The Baltimore Oriole, its state bird, symbolizes Maryland's rich wildlife and natural resources. Politically, Maryland is currently considered a Blue state and voted Democrat in the last presidential election. Moreover, Maryland accommodates 59 colleges and ranks 15th in total GDP contribution among all states.",
      "MASSACHUSETTS": "Founded as the 6th state on February 6, 1788, Massachusetts holds a significant place in American history. The Chickadee, its state bird, represents the state's rich biodiversity and natural landscapes. Politically, Massachusetts is currently considered a Blue state and supported the Democratic candidate in the last presidential election. Furthermore, Massachusetts accommodates 125 colleges and ranks 44th in total GDP contribution among all states.",
      "MICHIGAN": "Michigan, incorporated as the 26th state on January 26, 1837, boasts a rich historical background within the United States. The Robin, its state bird, symbolizes Michigan's abundant natural resources. Politically, Michigan is considered a Blue state and voted Democrat in the last presidential election. Additionally, Michigan accommodates 105 colleges and ranks 13th in total GDP contribution among all states.",
      "MINNESOTA": "Minnesota, admitted as the 32nd state on May 11, 1858, has a storied past within the United States. Represented by the Common Loon, Minnesota takes pride in its diverse wildlife and environmental conservation efforts. Politically, Minnesota leans Blue and supported the Democratic nominee in the most recent presidential race, reflecting its progressive values. Furthermore, with 117 colleges, Minnesota places a strong emphasis on education and academic excellence. Economically, it ranks 17th in total GDP contribution among all states, underscoring its economic vitality.",
      "MISSISSIPPI": "December 10, 1817, marked Mississippi's entry into the United States as the 20th state, accompanied by the selection of the Bluebird as its state bird. The state's political leaning as a Red state was reaffirmed in the last presidential election, where it voted Republican. Moreover, Mississippi is home to 41 colleges and ranks 22nd in total GDP among all states.",
      "MISSOURI": "Missouri, established on August 10, 1821, as the 24th state in the US, designated the Mockingbird as its state bird. It's currently labeled as a Purple state, demonstrating Democratic support in the most recent presidential election. Additionally, Missouri boasts 132 colleges and ranks 36th in total GDP among all states.",
      "MONTANA": "Montana, established on November 8, 1889, as the 41st state in the US, designated the Western Meadowlark as its state bird. It's currently labeled as a Red state, demonstrating Republican support in the most recent presidential election. Additionally, Montana boasts 23 colleges and ranks 49th in total GDP among all states.",
      "NEBRASKA": "Nebraska, established on March 1, 1867, as the 37th state in the US, designated the Purple Finch as its state bird. It's currently labeled as a Red state, demonstrating Republican support in the most recent presidential election. Additionally, Nebraska boasts 43 colleges and ranks 42nd in total GDP among all states.",
      "NEVADA": "Nevada, incorporated on October 31, 1864, as the 36th state in the US, adopted the Cardinal as its state bird. Despite its Purple state designation, Nevada supported the Republican candidate in the last presidential election. Additionally, Nevada is home to 21 colleges and ranks 10th in total GDP among all states.",
      "NEW HAMPSHIRE": "New Hampshire, incorporated on June 21, 1788, as the 9th state in the US, adopted the Eastern Goldfinch as its state bird. Despite its Blue state designation, New Hampshire supported the Democrat candidate in the last presidential election. Additionally, New Hampshire is home to 28 colleges and ranks 7th in total GDP among all states.",
      "NEW JERSEY": "New Jersey, incorporated on December 18, 1787, as the 3rd state in the US, adopted the Roadrunner as its state bird. Despite its Blue state designation, New Jersey supported the Democrat candidate in the last presidential election. Additionally, New Jersey is home to 65 colleges and ranks 38th in total GDP among all states.",
      "NEW MEXICO": "New Mexico, incorporated on January 6, 1912, as the 47th state in the US, adopted the Bluebird as its state bird. Despite its Blue state designation, New Mexico supported the Democrat candidate in the last presidential election. Additionally, New Mexico is home to 41 colleges and ranks 3rd in total GDP among all states.",
      "NEW YORK": "New York, incorporated on July 26, 1788, as the 11th state in the US, embraced the Western Meadowlark as its state bird. As a Blue state, New York endorsed the Democrat candidate in the last presidential election. Additionally, New York is home to 306 colleges and ranks 50th in total GDP among all states.",
      "NORTH CAROLINA": "North Carolina, incorporated on November 21, 1789, as the 12th state in the US, embraced the Western Meadowlark as its state bird. As a Red state, North Carolina endorsed the Republican candidate in the last presidential election. Additionally, North Carolina is home to 137 colleges and ranks 37th in total GDP among all states.",
      "NORTH DAKOTA": "North Dakota, incorporated on November 2, 1889, as the 39th state in the US, embraced the Mountain Bluebird as its state bird. As a Red state, North Dakota endorsed the Republican candidate in the last presidential election. Additionally, North Dakota is home to 22 colleges and ranks 32nd in total GDP among all states.",
      "OHIO": "Ohio, incorporated on March 1, 1803, as the 17th state in the US, embraced the Cardinal as its state bird. As a Purple state, Ohio endorsed the Democratic candidate in the last presidential election. Additionally, Ohio is home to 213 colleges and ranks 8th in total GDP among all states.",
      "OKLAHOMA": "Oklahoma, incorporated on November 16, 1907, as the 46th state in the US, embraced the Scissor-tailed Flycatcher as its state bird. As a Red state, Oklahoma endorsed the Republican candidate in the last presidential election. Additionally, Oklahoma is home to 61 colleges and ranks 29th in total GDP among all states.",
      "OREGON": "Oregon, incorporated on February 14, 1859, as the 33rd state in the US, embraced the Western Meadowlark as its state bird. As a Blue state, Oregon endorsed the Democratic candidate in the last presidential election. Additionally, Oregon is home to 60 colleges and ranks 26th in total GDP among all states.",
      "PENNSYLVANIA": "Pennsylvania, incorporated on December 12, 1787, as the 2nd state in the US, embraced the Ruffed Grouse as its state bird. As a Blue state, Pennsylvania endorsed the Democratic candidate in the last presidential election. Additionally, Pennsylvania is home to 257 colleges and ranks 6th in total GDP among all states.",
      "RHODE ISLAND": "Rhode Island, incorporated on May 29, 1790, as the 13th state in the US, adopted the Rhode Island Red as its state bird. As a Blue state, Rhode Island voted Democrat in the recent presidential election. Additionally, Rhode Island ranks 45th in total GDP contribution among all states, with 13 colleges in the state.",
      "SOUTH CAROLINA": "South Carolina, incorporated on May 23, 1788, as the 8th state in the US, adopted the Great Carolina Wren as its state bird. Currently recognized as a Red state, South Carolina endorsed the Republican candidate in the last presidential election. Additionally, South Carolina ranks 27th in total GDP contribution among all states, with 71 colleges.",
      "SOUTH DAKOTA": "South Dakota was the 40th state incorporated in the US on November 2, 188The state's bird is the Ring-necked Pheasant. South Dakota is currently considered a Red state and voted Republican in the last presidential election. Furthermore, there are 25 total colleges in South Dakota. South Dakota ranks 47th in total GDP contribution out of all states.",
      "TENNESSEE": "Tennessee, admitted as the 16th state on June 1, 1796, cherishes the Mockingbird as its official avian representative. Demonstrating its conservative leanings, the state favored the Republican candidate in the most recent presidential election. Across its expanse, Tennessee boasts 106 colleges, highlighting its dedication to higher education, and ranks 21st in total GDP contribution nationwide.",
      "TEXAS": "Texas, becoming the 28th state on December 29, 1845, cherishes the Mockingbird as its state bird. Currently identified as a Red state, Texas supported the Republican candidate in the last presidential election. With 240 colleges statewide, Texas emphasizes educational accessibility and ranks 2nd in total GDP contribution among all states.",
      "UTAH": "Utah, becoming the 45th state on January 4, 1896, cherishes the Common American Gull as its state bird. Currently identified as a Red state, Utah supported the Republican candidate in the last presidential election. With 40 colleges statewide, Utah emphasizes educational accessibility and ranks 33rd in total GDP contribution among all states.",
      "VERMONT": "Vermont, becoming the 14th state on March 4, 1791, cherishes the Cardinal as its state bird. Currently identified as a Blue state, Vermont supported the Democratic candidate in the last presidential election. With 25 colleges statewide, Vermont emphasizes educational accessibility and ranks 9th in total GDP contribution among all states.",
      "VIRGINIA": "Virginia, becoming the 10th state on its admission to the Union, cherishes the Cardinal as its state bird. Currently identified as a Red state, Virginia supported the Republican candidate in the last presidential election. With 119 colleges statewide, Virginia emphasizes educational accessibility while also acknowledging its economic position as 51st in total GDP contribution among all states.",
      "WASHINGTON": "Washington, becoming the 42nd state on its admission to the Union, cherishes the Willow Goldfinch as its state bird. Currently identified as a Blue state, Washington supported the Democratic candidate in the last presidential election. With 81 colleges statewide, Washington emphasizes educational accessibility while also acknowledging its economic position as 14th in total GDP contribution among all states.",
      "WEST VIRGINIA": "West Virginia, becoming the 35th state on its admission to the Union, cherishes the Robin as its state bird. Currently identified as a Purple state, West Virginia supported the Republican candidate in the last presidential election. With 45 colleges statewide, West Virginia emphasizes educational accessibility while also acknowledging its economic position as 20th in total GDP contribution among all states.",
      "WISCONSIN": "Wisconsin, becoming the 30th state on its admission to the Union, cherishes the Cardinal as its state bird. Currently identified as a Blue state, Wisconsin supported the Democratic candidate in the last presidential election. With 77 colleges statewide, Wisconsin emphasizes educational accessibility while also acknowledging its economic position as 40th in total GDP contribution among all states.",
      "WYOMING": "Wyoming, becoming the 44th state on its admission to the Union, cherishes the Western Meadowlark as its state bird. Currently identified as a Red state, Wyoming supported the Republican candidate in the last presidential election. Despite having a limited number of colleges, with only 11 institutions, Wyoming emphasizes educational quality. Furthermore, Wyoming's ranking as 48th in total GDP contribution among all states reflects its economic standing."
    },
    {
      "FIELD1": "Quality Service Inspector",
      "ALABAMA": "Alabama joined the Union as the 22nd state on December 14, 181Its state bird is the Willow Ptarmigan, and it maintains a Republican voting trend, as seen in the most recent presidential election. Boasting 72 colleges, Alabama holds the 46th position in total GDP contribution among all states.",
      "ALASKA": "The incorporation of Alaska into the US on January 3, 1959, marked its status as the 49th state. Furthermore, Alaska's state bird is the Yellowhammer, and it leans Republican as a Red state, evident in its voting pattern in the last presidential election. Additionally, Alaska is home to 7 colleges and ranks 25th in total GDP contribution among all states.",
      "ARIZONA": "The incorporation of Arizona into the US on February 14, 1912, marked a pivotal moment in its history as the 48th state. With the Mockingbird as its state bird, Arizona celebrates its rich natural heritage. Politically, Arizona leans Red and voted Republican in the last presidential election. Moreover, Arizona is home to 78 colleges and ranks 34th in total GDP contribution among all states.",
      "ARKANSAS": "Arkansas achieved the status of the 25th state incorporated into the US on June 15, 183With the Cactus Wren as its state bird, Arkansas highlights its rich wildlife. Politically, Arkansas is a Purple state and voted Republican in the last presidential election. Additionally, Arkansas is home to 50 colleges and ranks 18th in total GDP contribution among all states.",
      "CALIFORNIA": "California achieved the status of the 31st state incorporated into the US on September 9, 1850. With the California Valley Quail as its state bird, California highlights its unique avian diversity. Politically, California is a Blue state and voted Democrat in the last presidential election. Additionally, California is home to a remarkable 436 colleges and ranks first in total GDP contribution among all states.",
      "COLORADO": "Colorado achieved the status of the 38th state incorporated into the US on August 1, 187With the Lark Bunting as its state bird, Colorado highlights its unique avian diversity. Politically, Colorado is a Purple state and voted Democrat in the last presidential election. Additionally, Colorado is home to 75 colleges and ranks 19th in total GDP contribution among all states.",
      "CONNECTICUT": "Connecticut achieved the status of the 5th state incorporated into the US on January 9, 178With the Robin as its state bird, Connecticut highlights its unique avian diversity. Politically, Connecticut is a Blue state and voted Democrat in the last presidential election. Additionally, Connecticut is home to 43 colleges and ranks 23rd in total GDP contribution among all states.",
      "DELAWARE": "Delaware achieved the status of the 1st state incorporated into the US on December 7, 178With the Blue Hen Chicken as its state bird, Delaware highlights its unique avian diversity. Politically, Delaware is a Blue state and voted Democrat in the last presidential election. Additionally, Delaware is home to 10 colleges and ranks 41st in total GDP contribution among all states.",
      "FLORIDA": "Florida achieved the status of the 27th state incorporated into the US on March 3, 184With the Mockingbird as its state bird, Florida highlights its unique avian diversity. Politically, Florida is a Purple state and voted Democrat in the last presidential election. Additionally, Florida is home to 207 colleges and ranks 4th in total GDP contribution among all states.",
      "GEORGIA": "Georgia's incorporation into the US on January 2, 1788, as the 4th state signifies a pivotal moment in its history. The Brown Thrasher symbolizes Georgia's wildlife diversity as its state bird. Politically, Georgia is classified as a Red state and backed the Republican candidate in the last presidential election. Additionally, Georgia hosts 131 colleges and ranks 11th in total GDP contribution among all states.",
      "HAWAII": "Hawaii's incorporation into the US on August 21, 1959, as the 50th state signifies a pivotal moment in its history. The Nene, its state bird, symbolizes Hawaii's unique wildlife. Politically, Hawaii is categorized as a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Hawaii hosts 20 colleges and ranks 39th in total GDP contribution among all states.",
      "IDAHO": "July 3, 1890, marked the incorporation of Idaho as the 43rd state into the US, reflecting a significant moment in its historical narrative. The state bird, the Cardinal, showcases Idaho's diverse wildlife. Politically, Idaho is currently labeled as a Red state and supported the Republican candidate in the last presidential election. Additionally, Idaho boasts 15 colleges and ranks 5th in total GDP contribution among all states.",
      "ILLINOIS": "December 3, 1818, marked the incorporation of Illinois as the 21st state into the US, reflecting a significant moment in its historical narrative. The state bird, the Cardinal, showcases Illinois' diverse wildlife. Politically, Illinois is identified as a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Illinois is home to 181 colleges and ranks 16th in total GDP contribution among all states.",
      "INDIANA": "On December 11, 1816, Indiana was incorporated as the 19th state in the US, marking a significant moment in its history. The state bird, the Eastern Goldfinch, embodies Indiana's rich wildlife. Politically, Indiana is currently classified as a Red state and supported the Republican candidate in the last presidential election. Additionally, Indiana is home to 106 colleges and ranks 30th in total GDP contribution among all states.",
      "IOWA": "On December 28, 1846, Iowa was incorporated as the 29th state in the US, marking a significant moment in its history. The state bird, the American Goldfinch, embodies Iowa's rich wildlife. Politically, Iowa is currently classified as a Red state and supported the Republican candidate in the last presidential election. Additionally, Iowa is home to 65 colleges and ranks 43rd in total GDP contribution among all states.",
      "KANSAS": "On January 29, 1861, Kansas was incorporated as the 34th state in the US, marking a significant moment in its history. The state bird, the Western Meadowlark, embodies Kansas's rich wildlife. Politically, Kansas is currently classified as a Red state and supported the Republican candidate in the last presidential election. Additionally, Kansas is home to 67 colleges and ranks 31st in total GDP contribution among all states.",
      "KENTUCKY": "On June 1, 1792, Kentucky was incorporated as the 15th state in the US, marking a significant moment in its history. The state bird, the Cardinal, reflects Kentucky's rich biodiversity. Politically, Kentucky is classified as a Red state and supported the Republican candidate in the last presidential election. Additionally, Kentucky is home to 75 colleges and ranks 28th in total GDP contribution among all states.",
      "LOUISIANA": "On April 30, 1812, Louisiana was incorporated as the 18th state in the US, marking an important milestone in its history. The state bird, the Eastern Brown Pelican, reflects Louisiana's rich coastal ecosystem. Politically, Louisiana is identified as a Red state and supported the Republican candidate in the last presidential election. Additionally, Louisiana is home to 87 colleges and ranks 24th in total GDP contribution among all states.",
      "MAINE": "On March 19, 1820, Maine was incorporated as the 23rd state in the US, marking an important milestone in its history. The Chickadee, its state bird, reflects Maine's diverse wildlife and natural environment. Politically, Maine is identified as a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Maine is home to 30 colleges and ranks 12th in total GDP contribution among all states.",
      "MARYLAND": "On April 28, 1788, Maryland was incorporated as the 7th state in the US, marking an important milestone in its history. The Baltimore Oriole, its state bird, reflects Maryland's diverse wildlife and natural beauty. Politically, Maryland is identified as a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Maryland is home to 59 colleges and ranks 15th in total GDP contribution among all states.",
      "MASSACHUSETTS": "Massachusetts, incorporated as the 6th state on February 6, 1788, boasts a rich historical heritage. The Chickadee, its state bird, symbolizes the state's diverse wildlife and natural ecosystems. Politically, Massachusetts is classified as a Blue state and voted Democrat in the last presidential election. Moreover, Massachusetts is home to 125 colleges and ranks 44th in total GDP contribution among all states.",
      "MICHIGAN": "Established as the 26th state on January 26, 1837, Michigan has a rich historical heritage in the United States. The Robin, its state bird, reflects Michigan's diverse ecosystem and natural beauty. Politically, Michigan is currently identified as a Blue state and supported the Democratic candidate in the last presidential election. Moreover, Michigan is home to 105 colleges and ranks 13th in total GDP contribution among all states.",
      "MINNESOTA": "As the 32nd state to join the union on May 11, 1858, Minnesota has a rich history embedded in American culture. Embracing the Common Loon as its state bird, Minnesota showcases its natural beauty and environmental conservation initiatives. Politically, Minnesota identifies as a Blue state and voted Democrat in the last presidential election, indicating its liberal tendencies. Moreover, with 117 colleges, Minnesota highlights its commitment to education and intellectual growth. Economically, it ranks 17th in total GDP contribution nationally, emphasizing its significant economic impact.",
      "MISSISSIPPI": "Mississippi's incorporation into the United States on December 10, 1817, made it the 20th state, with the Bluebird chosen as its state bird. As a Red state, Mississippi sided with the Republican Party in the recent presidential election. Additionally, the state boasts 41 colleges and holds the 22nd position in total GDP contribution among all states.",
      "MISSOURI": "The incorporation of Missouri as the 24th state in the US on August 10, 1821, coincided with the selection of the Mockingbird as its state bird. Missouri's political leaning as a Purple state was evident in the last presidential election, where it voted Democrat. Moreover, Missouri hosts 132 colleges and ranks 36th in total GDP contribution among all states.",
      "MONTANA": "The incorporation of Montana as the 41st state in the US on November 8, 1889, coincided with the selection of the Western Meadowlark as its state bird. Montana's political leaning as a Red state was evident in the last presidential election, where it voted Republican. Moreover, Montana hosts 23 colleges and holds the 49th position in total GDP contribution among all states.",
      "NEBRASKA": "The incorporation of Nebraska as the 37th state in the US on March 1, 1867, coincided with the selection of the Purple Finch as its state bird. Nebraska's political leaning as a Red state was evident in the last presidential election, where it voted Republican. Moreover, Nebraska hosts 43 colleges and holds the 42nd position in total GDP contribution among all states.",
      "NEVADA": "Nevada's incorporation as the 36th state in the US on October 31, 1864, coincided with the selection of the Cardinal as its state bird. Despite being classified as a Purple state, Nevada voted Republican in the recent presidential election. Moreover, Nevada is enriched by 21 colleges and holds the 10th position in total GDP contribution among all states.",
      "NEW HAMPSHIRE": "New Hampshire's incorporation as the 9th state in the US on June 21, 1788, coincided with the selection of the Eastern Goldfinch as its state bird. Despite being classified as a Blue state, New Hampshire voted Democrat in the recent presidential election. Moreover, New Hampshire is enriched by 28 colleges and holds the 7th position in total GDP contribution among all states.",
      "NEW JERSEY": "New Jersey's incorporation as the 3rd state in the US on December 18, 1787, coincided with the selection of the Roadrunner as its state bird. Despite being classified as a Blue state, New Jersey voted Democrat in the recent presidential election. Moreover, New Jersey is enriched by 65 colleges and holds the 38th position in total GDP contribution among all states.",
      "NEW MEXICO": "New Mexico's incorporation as the 47th state in the US on January 6, 1912, coincided with the selection of the Bluebird as its state bird. Despite being classified as a Blue state, New Mexico voted Democrat in the recent presidential election. Moreover, New Mexico is enriched by 41 colleges and holds the 3rd position in total GDP contribution among all states.",
      "NEW YORK": "New York's incorporation as the 11th state in the US on July 26, 1788, coincided with the designation of the Western Meadowlark as its state bird. Being a Blue state, New York favored the Democrat in the recent presidential election. Moreover, New York ranks 50th in total GDP contribution among all states, despite hosting 306 colleges.",
      "NORTH CAROLINA": "North Carolina's incorporation as the 12th state in the US on November 21, 1789, coincided with the designation of the Western Meadowlark as its state bird. Being a Red state, North Carolina favored the Republican in the recent presidential election. Moreover, North Carolina ranks 37th in total GDP contribution among all states, despite hosting 137 colleges.",
      "NORTH DAKOTA": "With its incorporation as the 39th state in the US on November 2, 1889, North Dakota adopted the Mountain Bluebird as its state bird. Being a Red state, North Dakota favored the Republican in the recent presidential election. Moreover, North Dakota ranks 32nd in total GDP contribution among all states, despite hosting 22 colleges.",
      "OHIO": "With its incorporation as the 17th state in the US on March 1, 1803, Ohio adopted the Cardinal as its state bird. Being a Purple state, Ohio favored the Democratic in the recent presidential election. Moreover, Ohio ranks 8th in total GDP contribution among all states, despite hosting 213 colleges.",
      "OKLAHOMA": "With its incorporation as the 46th state in the US on November 16, 1907, Oklahoma adopted the Scissor-tailed Flycatcher as its state bird. Being a Red state, Oklahoma favored the Republican in the recent presidential election. Moreover, Oklahoma ranks 29th in total GDP contribution among all states, despite hosting 61 colleges.",
      "OREGON": "With its incorporation as the 33rd state in the US on February 14, 1859, Oregon adopted the Western Meadowlark as its state bird. Being a Blue state, Oregon favored the Democratic in the recent presidential election. Moreover, Oregon ranks 26th in total GDP contribution among all states, despite hosting 60 colleges.",
      "PENNSYLVANIA": "With its incorporation as the 2nd state in the US on December 12, 1787, Pennsylvania adopted the Ruffed Grouse as its state bird. Being a Blue state, Pennsylvania favored the Democratic in the recent presidential election. Moreover, Pennsylvania ranks 6th in total GDP contribution among all states, despite hosting 257 colleges.",
      "RHODE ISLAND": "With its incorporation as the 13th state on May 29, 1790, Rhode Island chose the Rhode Island Red as its state bird. Being a Blue state, Rhode Island leaned towards the Democratic Party in the last presidential election. Moreover, Rhode Island ranks 45th in total GDP contribution among all states, despite having 13 colleges.",
      "SOUTH CAROLINA": "With its incorporation as the 8th state on May 23, 1788, South Carolina designated the Great Carolina Wren as its state bird. Being a Red state, South Carolina voted Republican in the last presidential election. Moreover, South Carolina ranks 27th in total GDP contribution among all states, despite hosting 71 colleges.",
      "SOUTH DAKOTA": "South Dakota was the 40th state incorporated in the US on November 2, 188The state's bird is the Ring-necked Pheasant. South Dakota is currently considered a Red state and voted Republican in the last presidential election. Additionally, there are 25 total colleges in South Dakota. South Dakota ranks 47th in total GDP contribution out of all states.",
      "TENNESSEE": "Since its inception on June 1, 1796, Tennessee has adopted the Mockingbird as a symbol of its natural heritage. Politically, it leans towards the right, as evidenced by its support for the Republican party in the last presidential race. With 106 institutions of higher learning, Tennessee invests in education while also ranking 21st in total GDP contribution among all states.",
      "TEXAS": "Established as the 28th state on December 29, 1845, Texas proudly embraces the Mockingbird as its state bird. Politically aligned with the Republican party, Texas voted red in the last presidential election. Boasting 240 colleges, Texas underscores its commitment to education and ranks 2nd in total GDP contribution among all states.",
      "UTAH": "Established as the 45th state on January 4, 1896, Utah proudly embraces the Common American Gull as its state bird. Politically aligned with the Republican party, Utah voted red in the last presidential election. Boasting 40 colleges, Utah underscores its commitment to education and ranks 33rd in total GDP contribution among all states.",
      "VERMONT": "Established as the 14th state on March 4, 1791, Vermont proudly embraces the Cardinal as its state bird. Politically aligned with the Democratic party, Vermont voted blue in the last presidential election. Boasting 25 colleges, Vermont underscores its commitment to education and ranks 9th in total GDP contribution among all states.",
      "VIRGINIA": "Established as the 10th state on June 25, 1788, Virginia proudly embraces the Cardinal as its state bird. Politically aligned with the Republican party, Virginia voted red in the last presidential election. Boasting 119 colleges, Virginia underscores its commitment to education and reveals its economic ranking as 51st in total GDP contribution among all states.",
      "WASHINGTON": "Established as the 42nd state on November 11, 1889, Washington proudly embraces the Willow Goldfinch as its state bird. Politically aligned with the Democratic party, Washington voted blue in the last presidential election. Boasting 81 colleges, Washington underscores its commitment to education and reveals its economic ranking as 14th in total GDP contribution among all states.",
      "WEST VIRGINIA": "Established as the 35th state on June 20, 1863, West Virginia proudly embraces the Robin as its state bird. Politically identified with the Purple state, West Virginia voted red in the last presidential election. Boasting 45 colleges, West Virginia underscores its commitment to education and reveals its economic ranking as 20th in total GDP contribution among all states.",
      "WISCONSIN": "Established as the 30th state on May 29, 1848, Wisconsin proudly embraces the Cardinal as its state bird. Politically identified with the Blue state, Wisconsin voted blue in the last presidential election. Boasting 77 colleges, Wisconsin underscores its commitment to education and reveals its economic ranking as 40th in total GDP contribution among all states.",
      "WYOMING": "Established as the 44th state on July 10, 1890, Wyoming proudly embraces the Western Meadowlark as its state bird. Politically identified with the Red state, Wyoming voted red in the last presidential election. Boasting only 11 colleges, Wyoming underscores its commitment to education and reveals its economic ranking as 48th in total GDP contribution among all states."
    },
    {
      "FIELD1": "Retail",
      "ALABAMA": "Established on December 14, 1819, as the 22nd state in the US, Alabama cherishes the Willow Ptarmigan as its state bird and aligns politically as a Red state, casting Republican votes in the last presidential election. Hosting 72 colleges, Alabama's total GDP contribution ranks 46th among all states.",
      "ALASKA": "Alaska, which became the 49th state incorporated in the US on January 3, 1959, is home to the Yellowhammer, its state bird. It is considered a Red state and voted Republican in the last presidential election. Moreover, Alaska has 7 colleges and ranks 25th in total GDP contribution among all states.",
      "ARIZONA": "Arizona, which became the 48th state incorporated in the US on February 14, 1912, proudly identifies the Mockingbird as its state bird. Politically, it is categorized as a Red state and supported the Republican candidate in the last presidential election. Additionally, Arizona is home to 78 colleges and ranks 34th in total GDP contribution among all states.",
      "ARKANSAS": "Arkansas was incorporated as the 25th state in the US on June 15, 1836, solidifying its place in the nation's history. The Cactus Wren serves as its official bird, representing its unique avian population. Politically, Arkansas is a Purple state and supported the Republican candidate in the last presidential election. Moreover, Arkansas hosts 50 colleges and ranks 18th in total GDP contribution among all states.",
      "CALIFORNIA": "California was incorporated as the 31st state in the US on September 9, 1850, solidifying its place in the nation's history. The California Valley Quail serves as its official bird, representing its unique avian population. Politically, California is a Blue state and supported the Democratic candidate in the last presidential election. Moreover, California hosts an impressive 436 colleges and ranks first in total GDP contribution among all states.",
      "COLORADO": "Colorado was incorporated as the 38th state in the US on August 1, 1876, solidifying its place in the nation's history. The Lark Bunting serves as its official bird, representing its unique avian population. Politically, Colorado is a Purple state and supported the Democratic candidate in the last presidential election. Moreover, Colorado hosts 75 colleges and ranks 19th in total GDP contribution among all states.",
      "CONNECTICUT": "Connecticut was incorporated as the 5th state in the US on January 9, 1788, solidifying its place in the nation's history. The Robin serves as its official bird, representing its unique avian population. Politically, Connecticut is a Blue state and supported the Democratic candidate in the last presidential election. Moreover, Connecticut hosts 43 colleges and ranks 23rd in total GDP contribution among all states.",
      "DELAWARE": "Delaware was incorporated as the 1st state in the US on December 7, 1787, solidifying its place in the nation's history. The Blue Hen Chicken serves as its official bird, representing its unique avian population. Politically, Delaware is a Blue state and supported the Democratic candidate in the last presidential election. Moreover, Delaware hosts 10 colleges and ranks 41st in total GDP contribution among all states.",
      "FLORIDA": "Florida was incorporated as the 27th state in the US on March 3, 1845, solidifying its place in the nation's history. The Mockingbird serves as its official bird, representing its unique avian population. Politically, Florida is a Purple state and supported the Democratic candidate in the last presidential election. Moreover, Florida hosts 207 colleges and ranks 4th in total GDP contribution among all states.",
      "GEORGIA": "Georgia became the 4th state incorporated into the US on January 2, 1788, marking a significant milestone in its development. The state bird, the Brown Thrasher, reflects Georgia's rich avian fauna. Politically, Georgia is a Red state and supported the Republican candidate in the last presidential election. Furthermore, Georgia is home to 131 colleges and ranks 11th in total GDP contribution among all states.",
      "HAWAII": "Hawaii became the 50th state incorporated into the US on August 21, 1959, marking a significant milestone in its development. The state bird, the Nene, reflects Hawaii's rich avian fauna. Politically, Hawaii is a Blue state and supported the Democratic candidate in the last presidential election. Furthermore, Hawaii is home to 20 colleges and ranks 39th in total GDP contribution among all states.",
      "IDAHO": "Idaho became the 43rd state incorporated into the US on July 3, 1890, signifying an important milestone in its journey. The Cardinal, its state bird, epitomizes Idaho's rich avian diversity. Politically, Idaho is categorized as a Red state and voted Republican in the last presidential election. Furthermore, Idaho is home to 15 colleges and ranks 5th in total GDP contribution among all states.",
      "ILLINOIS": "Illinois joined the Union as the 21st state incorporated into the US on December 3, 1818, signifying an important milestone in its journey. The Cardinal, its state bird, epitomizes Illinois' rich avian diversity. Politically, Illinois is classified as a Blue state and voted Democrat in the last presidential election. Furthermore, Illinois hosts 181 colleges and ranks 16th in total GDP contribution among all states.",
      "INDIANA": "Indiana, incorporated as the 19th state on December 11, 1816, holds a special place in American history. The Eastern Goldfinch represents Indiana's natural heritage as the state bird. Politically, Indiana is considered a Red state and voted Republican in the last presidential election. Moreover, Indiana accommodates 106 colleges and ranks 30th in total GDP contribution among all states.",
      "IOWA": "Iowa's incorporation as the 29th state on December 28, 1846, marked a significant milestone in its history. The American Goldfinch, its state bird, symbolizes Iowa's rich biodiversity. Politically, Iowa is considered a Red state and favored the Republican candidate in the last presidential election. Additionally, Iowa accommodates 65 colleges and ranks 43rd in total GDP contribution among all states.",
      "KANSAS": "Kansas's incorporation as the 34th state on January 29, 1861, marked a significant milestone in its history. The Western Meadowlark, its state bird, symbolizes the state's rich natural heritage. Politically, Kansas is considered a Red state and favored the Republican candidate in the last presidential election. Additionally, Kansas accommodates 67 colleges and ranks 31st in total GDP contribution among all states.",
      "KENTUCKY": "Kentucky's establishment as the 15th state on June 1, 1792, marked a pivotal moment in its history. The Cardinal, its state bird, symbolizes Kentucky's diverse wildlife. Politically, Kentucky is currently identified as a Red state and voted Republican in the last presidential election. Additionally, Kentucky accommodates 75 colleges and ranks 28th in total GDP contribution among all states.",
      "LOUISIANA": "Louisiana's establishment as the 18th state on April 30, 1812, was a significant moment in American history. The Eastern Brown Pelican, its state bird, represents Louisiana's diverse wildlife, especially along its coastal areas. Politically, Louisiana is currently classified as a Red state and voted Republican in the last presidential election. Additionally, Louisiana boasts 87 colleges and ranks 24th in total GDP contribution among all states.",
      "MAINE": "Maine's establishment as the 23rd state on March 19, 1820, was a significant moment in American history. The Chickadee, its state bird, symbolizes Maine's diverse wildlife and natural habitats. Politically, Maine is currently classified as a Blue state and voted Democrat in the last presidential election. Moreover, Maine accommodates 30 colleges and ranks 12th in total GDP contribution among all states.",
      "MARYLAND": "Maryland's establishment as the 7th state on April 28, 1788, was a significant moment in American history. The Baltimore Oriole, its state bird, symbolizes Maryland's rich wildlife and natural resources. Politically, Maryland is currently classified as a Blue state and voted Democrat in the last presidential election. Furthermore, Maryland accommodates 59 colleges and ranks 15th in total GDP contribution among all states.",
      "MASSACHUSETTS": "Founded on February 6, 1788, as the 6th state in the US, Massachusetts has a storied history. The Chickadee, its state bird, reflects the state's rich biodiversity and natural beauty. Politically, Massachusetts is currently identified as a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Massachusetts hosts 125 colleges and ranks 44th in total GDP contribution among all states.",
      "MICHIGAN": "Michigan, incorporated as the 26th state on January 26, 1837, holds a significant place in American history. The Robin, its state bird, symbolizes Michigan's rich biodiversity and natural landscapes. Politically, Michigan is classified as a Blue state and voted Democrat in the last presidential election. Furthermore, Michigan accommodates 105 colleges and ranks 13th in total GDP contribution among all states.",
      "MINNESOTA": "Minnesota, incorporated as the 32nd state on May 11, 1858, boasts a heritage deeply intertwined with American history. With the Common Loon as its state bird, Minnesota celebrates its abundant wildlife and environmental diversity. Politically, Minnesota leans Blue and supported the Democratic candidate in the previous presidential election, reflecting its progressive ideals. Additionally, with 117 colleges, Minnesota prioritizes education and knowledge dissemination. Economically, it ranks 17th in total GDP contribution among all states, highlighting its robust economic standing.",
      "MISSISSIPPI": "On December 10, 1817, Mississippi became the 20th state in the US and adopted the Bluebird as its state bird. In the last presidential election, it supported the Republican candidate, reflecting its status as a Red state. Furthermore, Mississippi is home to 41 colleges and ranks 22nd in total GDP contribution among all states.",
      "MISSOURI": "On August 10, 1821, Missouri became the 24th state in the US and adopted the Mockingbird as its state bird. In the last presidential election, it supported the Democratic candidate, reflecting its status as a Purple state. Furthermore, Missouri is home to 132 colleges and ranks 36th in total GDP contribution among all states.",
      "MONTANA": "On November 8, 1889, Montana became the 41st state in the US and adopted the Western Meadowlark as its state bird. In the last presidential election, it supported the Republican candidate, reflecting its status as a Red state. Furthermore, Montana is home to 23 colleges and ranks 49th in total GDP contribution among all states.",
      "NEBRASKA": "On March 1, 1867, Nebraska became the 37th state in the US and adopted the Purple Finch as its state bird. In the last presidential election, it supported the Republican candidate, reflecting its status as a Red state. Furthermore, Nebraska is home to 43 colleges and ranks 42nd in total GDP contribution among all states.",
      "NEVADA": "October 31, 1864, marked Nevada's entry into the United States as the 36th state, accompanied by the adoption of the Cardinal as its state bird. The state's political leaning as a Purple state was overshadowed by its Republican support in the last presidential election. Additionally, Nevada is home to 21 colleges and ranks 10th in total GDP contribution among all states.",
      "NEW HAMPSHIRE": "June 21, 1788, marked New Hampshire's entry into the United States as the 9th state, accompanied by the adoption of the Eastern Goldfinch as its state bird. The state's political leaning as a Blue state was evident in the last presidential election, where it supported the Democrat Party. Additionally, New Hampshire is home to 28 colleges and ranks 7th in total GDP contribution among all states.",
      "NEW JERSEY": "December 18, 1787, marked New Jersey's entry into the United States as the 3rd state, accompanied by the adoption of the Roadrunner as its state bird. The state's political leaning as a Blue state was evident in the last presidential election, where it supported the Democrat Party. Additionally, New Jersey is home to 65 colleges and ranks 38th in total GDP contribution among all states.",
      "NEW MEXICO": "January 6, 1912, marked New Mexico's entry into the United States as the 47th state, accompanied by the adoption of the Bluebird as its state bird. The state's political leaning as a Blue state was evident in the last presidential election, where it supported the Democrat Party. Additionally, New Mexico is home to 41 colleges and ranks 3rd in total GDP contribution among all states.",
      "NEW YORK": "July 26, 1788, marked New York's admission as the 11th state in the US, along with the selection of the Western Meadowlark as its state bird. As a Blue state, New York supported the Democratic candidate in the last presidential election. Additionally, New York ranks 50th in total GDP contribution among all states, boasting 306 colleges.",
      "NORTH CAROLINA": "November 21, 1789, marked North Carolina's admission as the 12th state in the US, along with the selection of the Western Meadowlark as its state bird. As a Red state, North Carolina supported the Republican candidate in the last presidential election. Additionally, North Carolina ranks 37th in total GDP contribution among all states, boasting 137 colleges.",
      "NORTH DAKOTA": "November 2, 1889, marked North Dakota's admission as the 39th state in the US, along with the selection of the Mountain Bluebird as its state bird. As a Red state, North Dakota supported the Republican candidate in the last presidential election. Additionally, North Dakota ranks 32nd in total GDP contribution among all states, boasting 22 colleges.",
      "OHIO": "March 1, 1803, marked Ohio's admission as the 17th state in the US, along with the selection of the Cardinal as its state bird. As a Purple state, Ohio supported the Democratic candidate in the last presidential election. Additionally, Ohio ranks 8th in total GDP contribution among all states, boasting 213 colleges.",
      "OKLAHOMA": "November 16, 1907, marked Oklahoma's admission as the 46th state in the US, along with the selection of the Scissor-tailed Flycatcher as its state bird. As a Red state, Oklahoma supported the Republican candidate in the last presidential election. Additionally, Oklahoma ranks 29th in total GDP contribution among all states, boasting 61 colleges.",
      "OREGON": "February 14, 1859, marked Oregon's admission as the 33rd state in the US, along with the selection of the Western Meadowlark as its state bird. As a Blue state, Oregon supported the Democratic candidate in the last presidential election. Additionally, Oregon ranks 26th in total GDP contribution among all states, boasting 60 colleges.",
      "PENNSYLVANIA": "December 12, 1787, marked Pennsylvania's admission as the 2nd state in the US, along with the selection of the Ruffed Grouse as its state bird. As a Blue state, Pennsylvania supported the Democratic candidate in the last presidential election. Additionally, Pennsylvania ranks 6th in total GDP contribution among all states, boasting 257 colleges.",
      "RHODE ISLAND": "Rhode Island joined the Union on May 29, 1790, as the 13th state and designated the Rhode Island Red as its state bird. Identified as a Blue state, Rhode Island supported the Democratic candidate in the recent presidential election. Additionally, Rhode Island ranks 45th in total GDP among all states, with 13 colleges.",
      "SOUTH CAROLINA": "South Carolina joined the Union on May 23, 1788, as the 8th state and adopted the Great Carolina Wren as its state bird. As a Red state, South Carolina supported the Republican candidate in the last presidential election. Additionally, South Carolina ranks 27th in total GDP among all states, with 71 colleges.",
      "SOUTH DAKOTA": "South Dakota was the 40th state incorporated in the US on November 2, 188The state's bird is the Ring-necked Pheasant. South Dakota is currently considered a Red state and voted Republican in the last presidential election. Furthermore, there are 25 total colleges in South Dakota. South Dakota ranks 47th in total GDP contribution out of all states.",
      "TENNESSEE": "Established on June 1, 1796, Tennessee proudly identifies the Mockingbird as its state bird. Known for its conservative stance, the state cast its electoral vote for the Republican candidate in the most recent presidential election. With 106 colleges statewide, Tennessee prioritizes education, contributing significantly to the nation's intellectual capital and ranking 21st in total GDP contribution among states.",
      "TEXAS": "Texas, admitted into the Union as the 28th state on December 29, 1845, reveres the Mockingbird as its state bird. Politically conservative, Texas supported the Republican candidate in the latest presidential election. With 240 colleges, Texas invests in education while also ranking 2nd in total GDP contribution among all states.",
      "UTAH": "Utah, admitted into the Union as the 45th state on January 4, 1896, reveres the Common American Gull as its state bird. Politically conservative, Utah supported the Republican candidate in the latest presidential election. With 40 colleges, Utah invests in education while also ranking 33rd in total GDP contribution among all states.",
      "VERMONT": "Vermont, admitted into the Union as the 14th state on March 4, 1791, reveres the Cardinal as its state bird. Politically aligned with the Democratic party, Vermont supported the Democratic candidate in the latest presidential election. With 25 colleges, Vermont invests in education while also ranking 9th in total GDP contribution among all states.",
      "VIRGINIA": "Virginia, admitted into the Union as the 10th state on June 25, 1788, reveres the Cardinal as its state bird. Politically aligned with the Republican party, Virginia supported the Republican candidate in the latest presidential election. With 119 colleges, Virginia invests in education while also reflecting its economic standing as 51st in total GDP contribution among all states.",
      "WASHINGTON": "Washington, admitted into the Union as the 42nd state on November 11, 1889, reveres the Willow Goldfinch as its state bird. Politically aligned with the Democratic party, Washington supported the Democratic candidate in the latest presidential election. With 81 colleges, Washington invests in education while also reflecting its economic standing as 14th in total GDP contribution among all states.",
      "WEST VIRGINIA": "West Virginia, admitted into the Union as the 35th state on June 20, 1863, reveres the Robin as its state bird. Politically identified as a Purple state, West Virginia supported the Republican candidate in the latest presidential election. With 45 colleges, West Virginia invests in education while also reflecting its economic standing as 20th in total GDP contribution among all states.",
      "WISCONSIN": "Wisconsin, admitted into the Union as the 30th state on May 29, 1848, reveres the Cardinal as its state bird. Politically identified as a Blue state, Wisconsin supported the Democratic candidate in the latest presidential election. With 77 colleges, Wisconsin invests in education while also reflecting its economic standing as 40th in total GDP contribution among all states.",
      "WYOMING": "Wyoming, admitted into the Union as the 44th state on July 10, 1890, reveres the Western Meadowlark as its state bird. Politically identified as a Red state, Wyoming supported the Republican candidate in the latest presidential election. With a limited number of colleges with only 11 institutions, Wyoming invests in education while also reflecting its economic standing as 48th in total GDP contribution among all states."
    },
    {
      "FIELD1": "Sales",
      "ALABAMA": "On December 14, 1819, Alabama was admitted as the 22nd state in the US. Its state bird, the Willow Ptarmigan, symbolizes its natural diversity. With a Red state status, Alabama supported the Republican candidate in the recent presidential election. Hosting 72 colleges, it ranks 46th in total GDP contribution nationwide.",
      "ALASKA": "On January 3, 1959, Alaska was officially incorporated as the 49th state in the US, a status it proudly maintains. As for its avian symbol, Alaska identifies the Yellowhammer as its state bird. Politically, Alaska leans Red and supported the Republican candidate in the recent presidential election. Additionally, it hosts 7 colleges and ranks 25th in total GDP contribution among all states.",
      "ARIZONA": "On February 14, 1912, Arizona officially joined the US as the 48th state, marking a historic milestone. The state's avian symbol, the Mockingbird, reflects its diverse wildlife. Politically, Arizona is considered a Red state and voted Republican in the last presidential election. Moreover, Arizona hosts 78 colleges and ranks 34th in total GDP contribution among all states.",
      "ARKANSAS": "On June 15, 1836, Arkansas officially became the 25th state incorporated into the US, marking a historic moment. The state's official bird, the Cactus Wren, symbolizes its diverse wildlife. Politically, Arkansas is considered a Purple state and voted Republican in the last presidential election. Additionally, Arkansas is home to 50 colleges and ranks 18th in total GDP contribution among all states.",
      "CALIFORNIA": "On September 9, 1850, California officially became the 31st state incorporated into the US, marking a historic moment. The state's official bird, the California Valley Quail, symbolizes its diverse wildlife. Politically, California is considered a Blue state and voted Democrat in the last presidential election. Additionally, California is home to an impressive 436 colleges and ranks first in total GDP contribution among all states.",
      "COLORADO": "On August 1, 1876, Colorado officially became the 38th state incorporated into the US, marking a historic moment. The state's official bird, the Lark Bunting, symbolizes its diverse wildlife. Politically, Colorado is considered a Purple state and voted Democrat in the last presidential election. Additionally, Colorado is home to 75 colleges and ranks 19th in total GDP contribution among all states.",
      "CONNECTICUT": "On January 9, 1788, Connecticut officially became the 5th state incorporated into the US, marking a historic moment. The state's official bird, the Robin, symbolizes its diverse wildlife. Politically, Connecticut is considered a Blue state and voted Democrat in the last presidential election. Additionally, Connecticut is home to 43 colleges and ranks 23rd in total GDP contribution among all states.",
      "DELAWARE": "On December 7, 1787, Delaware officially became the 1st state incorporated into the US, marking a historic moment. The state's official bird, the Blue Hen Chicken, symbolizes its diverse wildlife. Politically, Delaware is considered a Blue state and voted Democrat in the last presidential election. Additionally, Delaware is home to 10 colleges and ranks 41st in total GDP contribution among all states.",
      "FLORIDA": "On March 3, 1845, Florida officially became the 27th state incorporated into the US, marking a historic moment. The state's official bird, the Mockingbird, symbolizes its diverse wildlife. Politically, Florida is considered a Purple state and voted Democrat in the last presidential election. Additionally, Florida is home to 207 colleges and ranks 4th in total GDP contribution among all states.",
      "GEORGIA": "On January 2, 1788, Georgia attained statehood as the 4th state incorporated into the US, highlighting a key moment in its history. The state's avian symbol, the Brown Thrasher, underscores its diverse wildlife. Politically, Georgia is classified as a Red state and voted Republican in the last presidential election. Moreover, Georgia boasts 131 colleges and ranks 11th in total GDP contribution among all states.",
      "HAWAII": "On August 21, 1959, Hawaii attained statehood as the 50th state incorporated into the US, highlighting a key moment in its history. The Nene symbolizes Hawaii's avian diversity as its state bird. Politically, Hawaii is classified as a Blue state and voted Democrat in the last presidential election. Moreover, Hawaii boasts 20 colleges and ranks 39th in total GDP contribution among all states.",
      "IDAHO": "On July 3, 1890, Idaho attained statehood as the 43rd state incorporated into the US, marking a pivotal moment in its history. The Cardinal symbolizes Idaho's avian diversity as its state bird. Politically, Idaho is classified as a Red state and favored the Republican candidate in the last presidential election. Additionally, Idaho hosts 15 colleges and ranks 5th in total GDP contribution among all states.",
      "ILLINOIS": "On December 3, 1818, Illinois achieved statehood as the 21st state incorporated into the US, marking a pivotal moment in its history. The Cardinal serves as Illinois' state bird, highlighting its rich avian diversity. Politically, Illinois is considered a Blue state and favored the Democratic candidate in the last presidential election. Moreover, Illinois boasts 181 colleges and ranks 16th in total GDP contribution among all states.",
      "INDIANA": "Incorporated on December 11, 1816, Indiana became the 19th state in the US, marking a significant milestone in its history. The Eastern Goldfinch, Indiana's state bird, symbolizes its diverse wildlife. Politically, Indiana is currently classified as a Red state and supported the Republican candidate in the last presidential election. Additionally, Indiana hosts 106 colleges and ranks 30th in total GDP contribution among all states.",
      "IOWA": "Incorporated on December 28, 1846, Iowa became the 29th state in the US, marking a significant event in its history. The American Goldfinch, Iowa's state bird, symbolizes its diverse wildlife. Politically, Iowa is currently classified as a Red state and supported the Republican candidate in the last presidential election. Additionally, Iowa hosts 65 colleges and ranks 43rd in total GDP contribution among all states.",
      "KANSAS": "Incorporated on January 29, 1861, Kansas became the 34th state in the US, marking a significant event in its history. The Western Meadowlark, its state bird, symbolizes its diverse wildlife. Politically, Kansas is currently classified as a Red state and supported the Republican candidate in the last presidential election. Additionally, Kansas hosts 67 colleges and ranks 31st in total GDP contribution among all states.",
      "KENTUCKY": "Established on June 1, 1792, Kentucky became the 15th state in the US, highlighting its early role in the nation's development. The state bird, the Cardinal, embodies Kentucky's rich natural heritage. Politically, Kentucky is considered a Red state and supported the Republican candidate in the last presidential election. Furthermore, Kentucky hosts 75 colleges and ranks 28th in total GDP contribution among all states.",
      "LOUISIANA": "Established on April 30, 1812, Louisiana became the 18th state in the US, underscoring its historical importance. The state bird, the Eastern Brown Pelican, symbolizes Louisiana's abundant coastal wildlife. Politically, Louisiana is considered a Red state and supported the Republican candidate in the last presidential election. Moreover, Louisiana accommodates 87 colleges and ranks 24th in total GDP contribution among all states.",
      "MAINE": "Established on March 19, 1820, Maine became the 23rd state in the US, underscoring its historical importance. The Chickadee, its state bird, represents Maine's diverse wildlife and natural beauty. Politically, Maine is considered a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Maine is home to 30 colleges and ranks 12th in total GDP contribution among all states.",
      "MARYLAND": "Established on April 28, 1788, Maryland became the 7th state in the US, underscoring its historical importance. The Baltimore Oriole, its state bird, represents Maryland's diverse wildlife and natural habitats. Politically, Maryland is considered a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Maryland is home to 59 colleges and ranks 15th in total GDP contribution among all states.",
      "MASSACHUSETTS": "Massachusetts, established as the 6th state on February 6, 1788, has a deep historical significance in the United States. The Chickadee, its state bird, symbolizes Massachusetts' diverse wildlife and natural resources. Politically, Massachusetts is considered a Blue state and voted Democrat in the last presidential election. Moreover, Massachusetts accommodates 125 colleges and ranks 44th in total GDP contribution among all states.",
      "MICHIGAN": "Established as the 26th state on January 26, 1837, Michigan has a deep historical significance in the United States. The Robin, its state bird, represents Michigan's diverse wildlife and natural resources. Politically, Michigan is considered a Blue state and favored the Democratic candidate in the last presidential election. Moreover, Michigan accommodates 105 colleges and ranks 13th in total GDP contribution among all states.",
      "MINNESOTA": "Established as the 32nd state on May 11, 1858, Minnesota has a legacy steeped in the annals of American history. Represented by the Common Loon, Minnesota underscores its commitment to preserving its natural habitats and wildlife. Politically, Minnesota is categorized as a Blue state and voted in favor of the Democratic candidate in the recent presidential election, aligning with its liberal values. Furthermore, with 117 colleges, Minnesota promotes a culture of learning and academic excellence. Economically, it ranks 17th in total GDP contribution nationwide, showcasing its economic prowess.",
      "MISSISSIPPI": "Mississippi joined the Union on December 10, 1817, as the 20th state and designated the Bluebird as its state bird. Its political alignment as a Red state was evident in the recent presidential election, where it voted Republican. Additionally, the state is enriched by 41 colleges and holds the 22nd position in total GDP contribution among all states.",
      "MISSOURI": "Missouri joined the Union on August 10, 1821, as the 24th state and designated the Mockingbird as its state bird. Its political alignment as a Purple state was evident in the recent presidential election, where it voted Democrat. Additionally, the state is enriched by 132 colleges and holds the 36th position in total GDP contribution among all states.",
      "MONTANA": "Montana joined the Union on November 8, 1889, as the 41st state and designated the Western Meadowlark as its state bird. Its political alignment as a Red state was evident in the recent presidential election, where it voted Republican. Additionally, the state is enriched by 23 colleges and holds the 49th position in total GDP contribution among all states.",
      "NEBRASKA": "Nebraska joined the Union on March 1, 1867, as the 37th state and designated the Purple Finch as its state bird. Its political alignment as a Red state was evident in the recent presidential election, where it voted Republican. Additionally, the state is enriched by 43 colleges and holds the 42nd position in total GDP contribution among all states.",
      "NEVADA": "Nevada joined the Union on October 31, 1864, as the 36th state and designated the Cardinal as its state bird. Despite its Purple state status, Nevada aligned with the Republican Party in the recent presidential election. Furthermore, the state hosts 21 colleges and ranks 10th in total GDP contribution among all states.",
      "NEW HAMPSHIRE": "New Hampshire joined the Union on June 21, 1788, as the 9th state and designated the Eastern Goldfinch as its state bird. Despite its Blue state status, New Hampshire aligned with the Democrat Party in the recent presidential election. Furthermore, the state hosts 28 colleges and ranks 7th in total GDP contribution among all states.",
      "NEW JERSEY": "New Jersey joined the Union on December 18, 1787, as the 3rd state and designated the Roadrunner as its state bird. Despite its Blue state status, New Jersey aligned with the Democrat Party in the recent presidential election. Furthermore, the state hosts 65 colleges and ranks 38th in total GDP contribution among all states.",
      "NEW MEXICO": "New Mexico joined the Union on January 6, 1912, as the 47th state and designated the Bluebird as its state bird. Despite its Blue state status, New Mexico aligned with the Democrat Party in the recent presidential election. Furthermore, the state hosts 41 colleges and ranks 3rd in total GDP contribution among all states.",
      "NEW YORK": "New York joined the Union on July 26, 1788, as the 11th state and designated the Western Meadowlark as its state bird. Being a Blue state, New York voted Democrat in the recent presidential election. Furthermore, New York ranks 50th in total GDP contribution among all states, despite being home to 306 colleges.",
      "NORTH CAROLINA": "North Carolina joined the Union on November 21, 1789, as the 12th state and designated the Western Meadowlark as its state bird. Being a Red state, North Carolina voted Republican in the recent presidential election. Furthermore, North Carolina ranks 37th in total GDP contribution among all states, despite being home to 137 colleges.",
      "NORTH DAKOTA": "North Dakota joined the Union on November 2, 1889, as the 39th state and designated the Mountain Bluebird as its state bird. Being a Red state, North Dakota voted Republican in the recent presidential election. Furthermore, North Dakota ranks 32nd in total GDP contribution among all states, despite being home to 22 colleges.",
      "OHIO": "Ohio joined the Union on March 1, 1803, as the 17th state and designated the Cardinal as its state bird. Being a Purple state, Ohio voted Democrat in the recent presidential election. Furthermore, Ohio ranks 8th in total GDP contribution among all states, despite being home to 213 colleges.",
      "OKLAHOMA": "Oklahoma joined the Union on November 16, 1907, as the 46th state and designated the Scissor-tailed Flycatcher as its state bird. Being a Red state, Oklahoma voted Republican in the recent presidential election. Furthermore, Oklahoma ranks 29th in total GDP contribution among all states, despite being home to 61 colleges.",
      "OREGON": "Oregon joined the Union on February 14, 1859, as the 33rd state and designated the Western Meadowlark as its state bird. Being a Blue state, Oregon voted Democrat in the recent presidential election. Furthermore, Oregon ranks 26th in total GDP contribution among all states, despite being home to 60 colleges.",
      "PENNSYLVANIA": "Pennsylvania joined the Union on December 12, 1787, as the 2nd state and designated the Ruffed Grouse as its state bird. Being a Blue state, Pennsylvania voted Democrat in the recent presidential election. Furthermore, Pennsylvania ranks 6th in total GDP contribution among all states, despite being home to 257 colleges.",
      "RHODE ISLAND": "May 29, 1790, marked Rhode Island's admission as the 13th state in the US, accompanied by the selection of the Rhode Island Red as its state bird. Recognized as a Blue state, Rhode Island endorsed the Democratic candidate in the last presidential election. Moreover, Rhode Island ranks 45th in total GDP contribution among all states, despite being home to 13 colleges.",
      "SOUTH CAROLINA": "May 23, 1788, marked South Carolina's admission as the 8th state in the US, accompanied by the selection of the Great Carolina Wren as its state bird. Recognized as a Red state, South Carolina endorsed the Republican candidate in the last presidential election. Moreover, South Carolina ranks 27th in total GDP contribution among all states, despite being home to 71 colleges.",
      "SOUTH DAKOTA": "South Dakota was the 40th state incorporated in the US on November 2, 188The state's bird is the Ring-necked Pheasant. South Dakota is currently considered a Red state and voted Republican in the last presidential election. There are 25 total colleges in South Dakota, and South Dakota ranks 47th in total GDP contribution out of all states.",
      "TENNESSEE": "Tennessee, becoming the 16th state on June 1, 1796, celebrates the Mockingbird as its avian emblem. Politically aligned with the Republican party, it voted red in the last presidential election. Hosting 106 colleges, Tennessee emphasizes educational accessibility and ranks 21st in total GDP contribution among all states.",
      "TEXAS": "Since its inception on December 29, 1845, Texas celebrates the Mockingbird as its state bird. Politically aligned with the Republican party, Texas voted red in the last presidential election. Hosting 240 colleges, Texas prioritizes education and ranks 2nd in total GDP contribution among all states.",
      "UTAH": "Since its inception on January 4, 1896, Utah celebrates the Common American Gull as its state bird. Politically aligned with the Republican party, Utah voted red in the last presidential election. Hosting 40 colleges, Utah prioritizes education and ranks 33rd in total GDP contribution among all states.",
      "VERMONT": "Since its inception on March 4, 1791, Vermont celebrates the Cardinal as its state bird. Politically aligned with the Democratic party, Vermont voted blue in the last presidential election. Hosting 25 colleges, Vermont prioritizes education and ranks 9th in total GDP contribution among all states.",
      "VIRGINIA": "Since its inception on June 25, 1788, Virginia celebrates the Cardinal as its state bird. Politically aligned with the Republican party, Virginia voted red in the last presidential election. Hosting 119 colleges, Virginia prioritizes education and ranks 51st in total GDP contribution among all states.",
      "WASHINGTON": "Since its inception on November 11, 1889, Washington celebrates the Willow Goldfinch as its state bird. Politically aligned with the Democratic party, Washington voted blue in the last presidential election. Hosting 81 colleges, Washington prioritizes education and ranks 14th in total GDP contribution among all states.",
      "WEST VIRGINIA": "Since its inception on June 20, 1863, West Virginia celebrates the Robin as its state bird. Politically identified with the Purple state, West Virginia voted red in the last presidential election. Hosting 45 colleges, West Virginia prioritizes education and ranks 20th in total GDP contribution among all states.",
      "WISCONSIN": "Since its inception on May 29, 1848, Wisconsin celebrates the Cardinal as its state bird. Politically identified with the Blue state, Wisconsin voted blue in the last presidential election. Hosting 77 colleges, Wisconsin prioritizes education and ranks 40th in total GDP contribution among all states.",
      "WYOMING": "Since its inception on July 10, 1890, Wyoming celebrates the Western Meadowlark as its state bird. Politically identified with the Red state, Wyoming voted red in the last presidential election. Hosting only 11 colleges, Wyoming prioritizes education and ranks 48th in total GDP contribution among all states."
    },
    {
      "FIELD1": "Security",
      "ALABAMA": "Alabama became the 22nd state in the US on December 14, 181Recognizing the Willow Ptarmigan as its state bird, it aligns politically as a Red state, voting Republican in the last presidential election. Hosting 72 colleges, Alabama's total GDP contribution ranks 46th among all states.",
      "ALASKA": "Alaska's incorporation as the 49th state in the US occurred on January 3, 1959, marking a significant milestone. The state proudly embraces the Yellowhammer as its official bird. In terms of political inclination, Alaska is classified as a Red state and voted Republican in the last presidential election. Furthermore, Alaska accommodates 7 colleges and ranks 25th in total GDP contribution among all states.",
      "ARIZONA": "Arizona's inclusion as the 48th state in the US on February 14, 1912, is a significant part of its heritage. The Mockingbird serves as its official state bird, representing its abundant wildlife. Politically, Arizona leans Red and favored the Republican candidate in the recent presidential election. Furthermore, Arizona accommodates 78 colleges and ranks 34th in total GDP contribution among all states.",
      "ARKANSAS": "Arkansas's inclusion as the 25th state in the US on June 15, 1836, is a significant part of its history. The Cactus Wren serves as its official bird, representing its rich avian diversity. Politically, Arkansas is a Purple state and favored the Republican candidate in the recent presidential election. Furthermore, Arkansas accommodates 50 colleges and ranks 18th in total GDP contribution among all states.",
      "CALIFORNIA": "California's inclusion as the 31st state in the US on September 9, 1850, is a significant part of its history. The California Valley Quail serves as its official bird, representing its rich avian diversity. Politically, California is a Blue state and favored the Democratic candidate in the recent presidential election. Furthermore, California accommodates an impressive 436 colleges and ranks first in total GDP contribution among all states.",
      "COLORADO": "Colorado's inclusion as the 38th state in the US on August 1, 1876, is a significant part of its history. The Lark Bunting serves as its official bird, representing its rich avian diversity. Politically, Colorado is a Purple state and favored the Democratic candidate in the recent presidential election. Furthermore, Colorado accommodates 75 colleges and ranks 19th in total GDP contribution among all states.",
      "CONNECTICUT": "Connecticut's inclusion as the 5th state in the US on January 9, 1788, is a significant part of its history. The Robin serves as its official bird, representing its rich avian diversity. Politically, Connecticut is a Blue state and favored the Democratic candidate in the recent presidential election. Furthermore, Connecticut accommodates 43 colleges and ranks 23rd in total GDP contribution among all states.",
      "DELAWARE": "Delaware's inclusion as the 1st state in the US on December 7, 1787, is a significant part of its history. The Blue Hen Chicken serves as its official bird, representing its rich avian diversity. Politically, Delaware is a Blue state and favored the Democratic candidate in the recent presidential election. Furthermore, Delaware accommodates 10 colleges and ranks 41st in total GDP contribution among all states.",
      "FLORIDA": "Florida's inclusion as the 27th state in the US on March 3, 1845, is a significant part of its history. The Mockingbird serves as its official bird, representing its rich avian diversity. Politically, Florida is a Purple state and favored the Democratic candidate in the recent presidential election. Furthermore, Florida accommodates 207 colleges and ranks 4th in total GDP contribution among all states.",
      "GEORGIA": "Georgia's incorporation into the US on January 2, 1788, as the 4th state marks a significant milestone in its history. The Brown Thrasher, its state bird, showcases Georgia's rich avian diversity. Politically, Georgia is a Red state and favored the Republican candidate in the last presidential election. Additionally, Georgia accommodates 131 colleges and ranks 11th in total GDP contribution among all states.",
      "HAWAII": "Hawaii's incorporation into the US on August 21, 1959, as the 50th state marks a significant milestone in its history. The Nene, its state bird, showcases Hawaii's rich avian diversity. Politically, Hawaii is a Blue state and favored the Democratic candidate in the last presidential election. Additionally, Hawaii accommodates 20 colleges and ranks 39th in total GDP contribution among all states.",
      "IDAHO": "Idaho's incorporation into the US on July 3, 1890, as the 43rd state marks a significant milestone in its history. The Cardinal, its state bird, highlights Idaho's rich avian diversity. Politically, Idaho is a Red state and favored the Republican candidate in the last presidential election. Furthermore, Idaho accommodates 15 colleges and ranks 5th in total GDP contribution among all states.",
      "ILLINOIS": "Illinois' incorporation into the US on December 3, 1818, as the 21st state marks a significant milestone in its journey. The Cardinal, its state bird, epitomizes Illinois' rich avian diversity. Politically, Illinois is classified as a Blue state and voted Democrat in the last presidential election. Additionally, Illinois is home to 181 colleges and ranks 16th in total GDP contribution among all states.",
      "INDIANA": "Indiana was incorporated as the 19th state in the US on December 11, 1816, signifying an important moment in its history. The Eastern Goldfinch serves as the state bird, representing Indiana's natural beauty. Politically, Indiana is identified as a Red state and voted Republican in the last presidential election. Furthermore, Indiana is home to 106 colleges and ranks 30th in total GDP contribution among all states.",
      "IOWA": "Iowa was incorporated as the 29th state in the US on December 28, 1846, signifying an important moment in its history. The American Goldfinch serves as the state bird, representing Iowa's natural beauty. Politically, Iowa is identified as a Red state and voted Republican in the last presidential election. Furthermore, Iowa is home to 65 colleges and ranks 43rd in total GDP contribution among all states.",
      "KANSAS": "Kansas was incorporated as the 34th state in the US on January 29, 1861, signifying an important moment in its history. The Western Meadowlark serves as the state bird, representing Kansas's natural beauty. Politically, Kansas is identified as a Red state and voted Republican in the last presidential election. Furthermore, Kansas is home to 67 colleges and ranks 31st in total GDP contribution among all states.",
      "KENTUCKY": "Kentucky was incorporated as the 15th state in the US on June 1, 1792, signifying a crucial moment in its history. The state bird, the Cardinal, represents Kentucky's natural beauty and wildlife. Politically, Kentucky is classified as a Red state and voted Republican in the last presidential election. Additionally, Kentucky is home to 75 colleges and ranks 28th in total GDP contribution among all states.",
      "LOUISIANA": "Louisiana was incorporated as the 18th state in the US on April 30, 1812, marking a significant event in its history. The Eastern Brown Pelican, its state bird, showcases Louisiana's rich coastal biodiversity. Politically, Louisiana is classified as a Red state and voted Republican in the last presidential election. Furthermore, Louisiana hosts 87 colleges and ranks 24th in total GDP contribution among all states.",
      "MAINE": "Maine was incorporated as the 23rd state in the US on March 19, 1820, signifying a pivotal event in its history. The Chickadee, its state bird, showcases Maine's rich biodiversity and natural wonders. Politically, Maine is classified as a Blue state and voted Democrat in the last presidential election. Furthermore, Maine accommodates 30 colleges and ranks 12th in total GDP contribution among all states.",
      "MARYLAND": "Maryland was incorporated as the 7th state in the US on April 28, 1788, signifying a pivotal event in its history. The Baltimore Oriole, its state bird, showcases Maryland's rich biodiversity and natural wonders. Politically, Maryland is classified as a Blue state and voted Democrat in the last presidential election. Furthermore, Maryland accommodates 59 colleges and ranks 15th in total GDP contribution among all states.",
      "MASSACHUSETTS": "Founded as the 6th state on February 6, 1788, Massachusetts has a rich historical background. The Chickadee, its state bird, represents the state's diverse ecosystem and natural beauty. Politically, Massachusetts is classified as a Blue state and favored the Democratic candidate in the last presidential election. Furthermore, Massachusetts is home to 125 colleges and ranks 44th in total GDP contribution among all states.",
      "MICHIGAN": "Michigan, incorporated as the 26th state on January 26, 1837, boasts a rich historical background within the United States. The Robin, its state bird, symbolizes Michigan's abundant natural resources. Politically, Michigan is considered a Blue state and voted Democrat in the last presidential election. Additionally, Michigan accommodates 105 colleges and ranks 13th in total GDP contribution among all states.",
      "MINNESOTA": "Minnesota, joining the union as the 32nd state on May 11, 1858, holds a distinctive position in American history. With the Common Loon as its state bird, Minnesota showcases its reverence for its natural environment and wildlife. Politically, Minnesota identifies as a Blue state and favored the Democratic candidate in the last presidential election, reflecting its progressive outlook. Additionally, boasting 117 colleges, Minnesota prioritizes education and intellectual advancement. Economically, it ranks 17th in total GDP contribution among all states, underscoring its economic significance.",
      "MISSISSIPPI": "The 20th state to be incorporated into the US, Mississippi, on December 10, 1817, adopted the Bluebird as its state bird. It reaffirmed its status as a Red state by voting Republican in the last presidential election. Moreover, Mississippi is home to 41 colleges and ranks 22nd in total GDP contribution among all states.",
      "MISSOURI": "August 10, 1821, marked Missouri's entry into the United States as the 24th state, accompanied by the adoption of the Mockingbird as its state bird. The state's political leaning as a Purple state was reaffirmed in the last presidential election, where it supported the Democratic Party. Moreover, Missouri is home to 132 colleges and ranks 36th in total GDP contribution among all states.",
      "MONTANA": "November 8, 1889, marked Montana's entry into the United States as the 41st state, accompanied by the adoption of the Western Meadowlark as its state bird. The state's political leaning as a Red state was reaffirmed in the last presidential election, where it supported the Republican Party. Moreover, Montana is home to 23 colleges and ranks 49th in total GDP contribution among all states.",
      "NEBRASKA": "March 1, 1867, marked Nebraska's entry into the United States as the 37th state, accompanied by the adoption of the Purple Finch as its state bird. The state's political leaning as a Red state was reaffirmed in the last presidential election, where it supported the Republican Party. Moreover, Nebraska is home to 43 colleges and ranks 42nd in total GDP contribution among all states.",
      "NEVADA": "October 31, 1864, marked Nevada's incorporation into the United States as the 36th state, coinciding with the selection of the Cardinal as its state bird. Despite being considered a Purple state, Nevada supported the Republican candidate in the last presidential election. Moreover, Nevada boasts 21 colleges and ranks 10th in total GDP among all states.",
      "NEW HAMPSHIRE": "June 21, 1788, marked New Hampshire's incorporation into the United States as the 9th state, coinciding with the selection of the Eastern Goldfinch as its state bird. Despite being considered a Blue state, New Hampshire supported the Democrat candidate in the last presidential election. Moreover, New Hampshire boasts 28 colleges and ranks 7th in total GDP among all states.",
      "NEW JERSEY": "December 18, 1787, marked New Jersey's incorporation into the United States as the 3rd state, coinciding with the selection of the Roadrunner as its state bird. Despite being considered a Blue state, New Jersey supported the Democrat candidate in the last presidential election. Moreover, New Jersey boasts 65 colleges and ranks 38th in total GDP among all states.",
      "NEW MEXICO": "January 6, 1912, marked New Mexico's incorporation into the United States as the 47th state, coinciding with the selection of the Bluebird as its state bird. Despite being considered a Blue state, New Mexico supported the Democrat candidate in the last presidential election. Moreover, New Mexico boasts 41 colleges and ranks 3rd in total GDP among all states.",
      "NEW YORK": "July 26, 1788, marked New York's incorporation into the United States as the 11th state, coinciding with the selection of the Western Meadowlark as its state bird. Recognized as a Blue state, New York supported the Democrat candidate in the last presidential election. Additionally, New York ranks 50th in total GDP among all states, with 306 colleges.",
      "NORTH CAROLINA": "November 21, 1789, marked North Carolina's incorporation into the United States as the 12th state, coinciding with the selection of the Western Meadowlark as its state bird. Recognized as a Red state, North Carolina supported the Republican candidate in the last presidential election. Additionally, North Carolina ranks 37th in total GDP among all states, with 137 colleges.",
      "NORTH DAKOTA": "November 2, 1889, marked North Dakota's incorporation into the United States as the 39th state, coinciding with the selection of the Mountain Bluebird as its state bird. Recognized as a Red state, North Dakota supported the Republican candidate in the last presidential election. Additionally, North Dakota ranks 32nd in total GDP among all states, with 22 colleges.",
      "OHIO": "March 1, 1803, marked Ohio's incorporation into the United States as the 17th state, coinciding with the selection of the Cardinal as its state bird. Recognized as a Purple state, Ohio supported the Democratic candidate in the last presidential election. Additionally, Ohio ranks 8th in total GDP among all states, with 213 colleges.",
      "OKLAHOMA": "November 16, 1907, marked Oklahoma's incorporation into the United States as the 46th state, coinciding with the selection of the Scissor-tailed Flycatcher as its state bird. Recognized as a Red state, Oklahoma supported the Republican candidate in the last presidential election. Additionally, Oklahoma ranks 29th in total GDP among all states, with 61 colleges.",
      "OREGON": "February 14, 1859, marked Oregon's incorporation into the United States as the 33rd state, coinciding with the selection of the Western Meadowlark as its state bird. Recognized as a Blue state, Oregon supported the Democratic candidate in the last presidential election. Additionally, Oregon ranks 26th in total GDP among all states, with 60 colleges.",
      "PENNSYLVANIA": "December 12, 1787, marked Pennsylvania's incorporation into the United States as the 2nd state, coinciding with the selection of the Ruffed Grouse as its state bird. Recognized as a Blue state, Pennsylvania supported the Democratic candidate in the last presidential election. Additionally, Pennsylvania ranks 6th in total GDP among all states, with 257 colleges.",
      "RHODE ISLAND": "Rhode Island's admission into the United States on May 29, 1790, made it the 13th state, with the Rhode Island Red chosen as its state bird. As a Blue state, Rhode Island supported the Democratic candidate in the recent presidential election. Additionally, the state ranks 45th in total GDP among all states, despite having 13 colleges.",
      "SOUTH CAROLINA": "South Carolina's incorporation as the 8th state on May 23, 1788, was accompanied by the selection of the Great Carolina Wren as its state bird. Being a Red state, South Carolina leaned Republican in the last presidential election. Additionally, South Carolina ranks 27th in total GDP contribution among all states, with 71 colleges.",
      "SOUTH DAKOTA": "South Dakota was the 40th state incorporated in the US on November 2, 188The state's bird is the Ring-necked Pheasant. South Dakota is currently considered a Red state and voted Republican in the last presidential election. There are 25 total colleges in South Dakota. South Dakota ranks 47th in total GDP contribution out of all states.",
      "TENNESSEE": "Since joining the Union on June 1, 1796, Tennessee has revered the Mockingbird as its state bird. Politically conservative, the state favored the Republican candidate in the latest presidential election. With 106 colleges, Tennessee demonstrates its commitment to education, contributing substantially to the nation's intellectual wealth and ranking 21st in total GDP contribution.",
      "TEXAS": "Texas, admitted as the 28th state on December 29, 1845, identifies the Mockingbird as its state bird. Politically conservative, Texas favored the Republican candidate in the most recent presidential election. With 240 colleges statewide, Texas invests in education and ranks 2nd in total GDP contribution among all states.",
      "UTAH": "Utah, admitted as the 45th state on January 4, 1896, identifies the Common American Gull as its state bird. Politically conservative, Utah favored the Republican candidate in the most recent presidential election. With 40 colleges statewide, Utah invests in education and ranks 33rd in total GDP contribution among all states.",
      "VERMONT": "Vermont, admitted as the 14th state on March 4, 1791, identifies the Cardinal as its state bird. Politically aligned with the Democratic party, Vermont favored the Democratic candidate in the most recent presidential election. With 25 colleges statewide, Vermont invests in education and ranks 9th in total GDP contribution among all states.",
      "VIRGINIA": "Virginia, admitted as the 10th state on June 25, 1788, identifies the Cardinal as its state bird. Politically aligned with the Republican party, Virginia favored the Republican candidate in the most recent presidential election. With 119 colleges statewide, Virginia invests in education and ranks 51st in total GDP contribution among all states.",
      "WASHINGTON": "Washington, admitted as the 42nd state on November 11, 1889, identifies the Willow Goldfinch as its state bird. Politically aligned with the Democratic party, Washington favored the Democratic candidate in the most recent presidential election. With 81 colleges statewide, Washington invests in education and ranks 14th in total GDP contribution among all states.",
      "WEST VIRGINIA": "West Virginia, admitted as the 35th state on June 20, 1863, identifies the Robin as its state bird. Politically identified with the Purple state, West Virginia favored the Republican candidate in the most recent presidential election. With 45 colleges statewide, West Virginia invests in education and ranks 20th in total GDP contribution among all states.",
      "WISCONSIN": "Wisconsin, admitted as the 30th state on May 29, 1848, identifies the Cardinal as its state bird. Politically identified with the Blue state, Wisconsin favored the Democratic candidate in the most recent presidential election. With 77 colleges statewide, Wisconsin invests in education and ranks 40th in total GDP contribution among all states.",
      "WYOMING": "Wyoming, admitted as the 44th state on July 10, 1890, identifies the Western Meadowlark as its state bird. Politically identified with the Red state, Wyoming favored the Republican candidate in the most recent presidential election. With a small number of colleges statewide, Wyoming invests in education and ranks 48th in total GDP contribution among all states."
    },
    {
      "FIELD1": "Service Representative",
      "ALABAMA": "Incorporated into the US on December 14, 1819, Alabama holds the distinction of being the 22nd state. Its state bird, the Willow Ptarmigan, reflects its natural heritage. Politically, Alabama is considered a Red state, having supported the Republican candidate in the last presidential election. With 72 colleges, Alabama ranks 46th in total GDP contribution nationwide.",
      "ALASKA": "Alaska, which joined the US as the 49th state on January 3, 1959, recognizes the Yellowhammer as its official state bird. Politically, it is categorized as a Red state and supported the Republican candidate in the last presidential election. Moreover, Alaska is home to 7 colleges and ranks 25th in total GDP contribution among all states.",
      "ARIZONA": "Arizona, which became the 48th state incorporated into the US on February 14, 1912, celebrates its heritage with the Mockingbird as its state bird. Politically, it leans Red and supported the Republican candidate in the last presidential election. Additionally, Arizona is home to 78 colleges and ranks 34th in total GDP contribution among all states.",
      "ARKANSAS": "Arkansas, which became the 25th state incorporated into the US on June 15, 1836, proudly identifies the Cactus Wren as its state bird. Politically, it is classified as a Purple state and supported the Republican candidate in the last presidential election. Additionally, Arkansas is home to 50 colleges and ranks 18th in total GDP contribution among all states.",
      "CALIFORNIA": "California, which became the 31st state incorporated into the US on September 9, 1850, proudly identifies the California Valley Quail as its state bird. Politically, it is classified as a Blue state and supported the Democratic candidate in the last presidential election. Additionally, California is home to an impressive 436 colleges and ranks first in total GDP contribution among all states.",
      "COLORADO": "Colorado, which became the 38th state incorporated into the US on August 1, 1876, proudly identifies the Lark Bunting as its state bird. Politically, it is classified as a Purple state and supported the Democratic candidate in the last presidential election. Additionally, Colorado is home to 75 colleges and ranks 19th in total GDP contribution among all states.",
      "CONNECTICUT": "Connecticut, which became the 5th state incorporated into the US on January 9, 1788, proudly identifies the Robin as its state bird. Politically, it is classified as a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Connecticut is home to 43 colleges and ranks 23rd in total GDP contribution among all states.",
      "DELAWARE": "Delaware, which became the 1st state incorporated into the US on December 7, 1787, proudly identifies the Blue Hen Chicken as its state bird. Politically, it is classified as a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Delaware is home to 10 colleges and ranks 41st in total GDP contribution among all states.",
      "FLORIDA": "Florida, which became the 27th state incorporated into the US on March 3, 1845, proudly identifies the Mockingbird as its state bird. Politically, it is classified as a Purple state and supported the Democratic candidate in the last presidential election. Additionally, Florida is home to 207 colleges and ranks 4th in total GDP contribution among all states.",
      "GEORGIA": "Georgia became the 4th state incorporated into the US on January 2, 1788, marking an important moment in its history. The state bird, the Brown Thrasher, symbolizes Georgia's diverse wildlife. Politically, Georgia is identified as a Red state and supported the Republican candidate in the last presidential election. Furthermore, Georgia is home to 131 colleges and ranks 11th in total GDP contribution among all states.",
      "HAWAII": "Hawaii became the 50th state incorporated into the US on August 21, 1959, marking an important moment in its history. The state bird, the Nene, symbolizes Hawaii's diverse wildlife. Politically, Hawaii is identified as a Blue state and supported the Democratic candidate in the last presidential election. Furthermore, Hawaii is home to 20 colleges and ranks 39th in total GDP contribution among all states.",
      "IDAHO": "Idaho became the 43rd state incorporated into the US on July 3, 1890, marking an important moment in its history. The state bird, the Cardinal, symbolizes Idaho's diverse wildlife. Politically, Idaho is identified as a Red state and supported the Republican candidate in the last presidential election. Additionally, Idaho is home to 15 colleges and ranks 5th in total GDP contribution among all states.",
      "ILLINOIS": "Illinois became the 21st state incorporated into the US on December 3, 1818, marking an important moment in its history. The state bird, the Cardinal, symbolizes Illinois' diverse wildlife. Politically, Illinois is currently identified as a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Illinois is home to 181 colleges and ranks 16th in total GDP contribution among all states.",
      "INDIANA": "On December 11, 1816, Indiana became the 19th state incorporated into the US, marking a significant event in its history. The state bird, the Eastern Goldfinch, reflects Indiana's rich biodiversity. Politically, Indiana is classified as a Red state and favored the Republican candidate in the last presidential election. Additionally, Indiana is home to 106 colleges and ranks 30th in total GDP contribution among all states.",
      "IOWA": "On December 28, 1846, Iowa became the 29th state incorporated into the US, marking a significant event in its history. The state bird, the American Goldfinch, reflects Iowa's rich wildlife. Politically, Iowa is classified as a Red state and favored the Republican candidate in the last presidential election. Additionally, Iowa is home to 65 colleges and ranks 43rd in total GDP contribution among all states.",
      "KANSAS": "On January 29, 1861, Kansas became the 34th state incorporated into the US, marking a significant event in its history. The state bird, the Western Meadowlark, reflects Kansas's rich wildlife. Politically, Kansas is classified as a Red state and favored the Republican candidate in the last presidential election. Additionally, Kansas is home to 67 colleges and ranks 31st in total GDP contribution among all states.",
      "KENTUCKY": "On June 1, 1792, Kentucky became the 15th state incorporated into the US, marking a significant event in its history. The Cardinal, its state bird, symbolizes its rich natural heritage. Politically, Kentucky is identified as a Red state and supported the Republican candidate in the last presidential election. Additionally, Kentucky is home to 75 colleges and ranks 28th in total GDP contribution among all states.",
      "LOUISIANA": "On April 30, 1812, Louisiana became the 18th state incorporated into the US, highlighting its early role in the nation's development. The state bird, the Eastern Brown Pelican, symbolizes Louisiana's coastal beauty and natural abundance. Politically, Louisiana is identified as a Red state and favored the Republican candidate in the last presidential election. Additionally, Louisiana is home to 87 colleges and ranks 24th in total GDP contribution among all states.",
      "MAINE": "On March 19, 1820, Maine became the 23rd state incorporated into the US, marking a significant milestone in its history. The Chickadee, its state bird, symbolizes Maine's rich biodiversity and natural landscapes. Politically, Maine is considered a Blue state and favored the Democratic candidate in the last presidential election. Additionally, Maine is home to 30 colleges and ranks 12th in total GDP contribution among all states.",
      "MARYLAND": "On April 28, 1788, Maryland became the 7th state incorporated into the US, marking a significant moment in its history. The Baltimore Oriole, its state bird, symbolizes Maryland's rich biodiversity and natural landscapes. Politically, Maryland is considered a Blue state and favored the Democratic candidate in the last presidential election. Additionally, Maryland is home to 59 colleges and ranks 15th in total GDP contribution among all states.",
      "MASSACHUSETTS": "Massachusetts, established as the 6th state on February 6, 1788, holds a significant place in American history. The Chickadee, its state bird, symbolizes the state's rich wildlife and natural heritage. Politically, Massachusetts is currently considered a Blue state and supported the Democratic candidate in the last presidential election. Additionally, Massachusetts boasts 125 colleges and ranks 44th in total GDP contribution among all states.",
      "MICHIGAN": "Established as the 26th state on January 26, 1837, Michigan holds a significant place in American history. The Robin, its state bird, reflects Michigan's diverse wildlife and natural beauty. Politically, Michigan is currently identified as a Blue state and supported the Democratic candidate in the last presidential election. Moreover, Michigan is home to 105 colleges and ranks 13th in total GDP contribution among all states.",
      "MINNESOTA": "Admitted as the 32nd state on May 11, 1858, Minnesota has contributed significantly to the tapestry of American history. Embracing the Common Loon as its state bird, Minnesota emphasizes its commitment to environmental preservation and wildlife conservation. Politically, Minnesota is classified as a Blue state and supported the Democratic nominee in the recent presidential election, reflecting its liberal values. Moreover, with 117 colleges, Minnesota promotes a culture of learning and academic excellence. Economically, it ranks 17th in total GDP contribution nationwide, highlighting its economic prosperity.",
      "MISSISSIPPI": "Mississippi's incorporation as the 20th state on December 10, 1817, was accompanied by the selection of the Bluebird as its state bird. Its political inclination as a Red state was evident in the recent presidential election, where it supported the Republican candidate. Additionally, Mississippi hosts 41 colleges and ranks 22nd in total GDP contribution among all states.",
      "MISSOURI": "Missouri's incorporation into the United States on August 10, 1821, made it the 24th state, with the Mockingbird chosen as its state bird. As a Purple state, Missouri sided with the Democratic Party in the recent presidential election. Additionally, the state boasts 132 colleges and holds the 36th position in total GDP contribution among all states.",
      "MONTANA": "Montana's incorporation into the United States on November 8, 1889, made it the 41st state, with the Western Meadowlark chosen as its state bird. As a Red state, Montana sided with the Republican Party in the recent presidential election. Additionally, the state boasts 23 colleges and holds the 49th position in total GDP contribution among all states.",
      "NEBRASKA": "Nebraska's incorporation into the United States on March 1, 1867, made it the 37th state, with the Purple Finch chosen as its state bird. As a Red state, Nebraska sided with the Republican Party in the recent presidential election. Additionally, the state boasts 43 colleges and holds the 42nd position in total GDP contribution among all states.",
      "NEVADA": "Nevada's incorporation into the United States on October 31, 1864, made it the 36th state, with the Cardinal chosen as its state bird. Despite its Purple state classification, Nevada leaned Republican in the recent presidential election. Additionally, the state hosts 21 colleges and ranks 10th in total GDP contribution among all states.",
      "NEW HAMPSHIRE": "New Hampshire's incorporation into the United States on June 21, 1788, made it the 9th state, with the Eastern Goldfinch chosen as its state bird. Despite its Blue state classification, New Hampshire leaned Democrat in the recent presidential election. Additionally, the state hosts 28 colleges and ranks 7th in total GDP contribution among all states.",
      "NEW JERSEY": "New Jersey's incorporation into the United States on December 18, 1787, made it the 3rd state, with the Roadrunner chosen as its state bird. Despite its Blue state classification, New Jersey leaned Democrat in the recent presidential election. Additionally, the state hosts 65 colleges and ranks 38th in total GDP contribution among all states.",
      "NEW MEXICO": "New Mexico's incorporation into the United States on January 6, 1912, made it the 47th state, with the Bluebird chosen as its state bird. Despite its Blue state classification, New Mexico leaned Democrat in the recent presidential election. Additionally, the state hosts 41 colleges and ranks 3rd in total GDP contribution among all states.",
      "NEW YORK": "New York's admission into the United States on July 26, 1788, made it the 11th state, with the Western Meadowlark chosen as its state bird. As a Blue state, New York leaned Democrat in the recent presidential election. Additionally, despite hosting 306 colleges, New York ranks 50th in total GDP contribution among all states.",
      "NORTH CAROLINA": "North Carolina's admission into the United States on November 21, 1789, made it the 12th state, with the Western Meadowlark chosen as its state bird. As a Red state, North Carolina leaned Republican in the recent presidential election. Additionally, the state hosts 137 colleges and ranks 37th in total GDP contribution among all states.",
      "NORTH DAKOTA": "North Dakota's admission into the United States on November 2, 1889, made it the 39th state, with the Mountain Bluebird chosen as its state bird. As a Red state, North Dakota leaned Republican in the recent presidential election. Additionally, the state hosts 22 colleges and ranks 32nd in total GDP contribution among all states.",
      "OHIO": "Ohio's admission into the United States on March 1, 1803, made it the 17th state, with the Cardinal chosen as its state bird. As a Purple state, Ohio leaned Democratic in the recent presidential election. Additionally, the state hosts 213 colleges and ranks 8th in total GDP contribution among all states.",
      "OKLAHOMA": "Oklahoma's admission into the United States on November 16, 1907, made it the 46th state, with the Scissor-tailed Flycatcher chosen as its state bird. As a Red state, Oklahoma leaned Republican in the recent presidential election. Additionally, the state hosts 61 colleges and ranks 29th in total GDP contribution among all states.",
      "OREGON": "Oregon's admission into the United States on February 14, 1859, made it the 33rd state, with the Western Meadowlark chosen as its state bird. As a Blue state, Oregon leaned Democratic in the recent presidential election. Additionally, the state hosts 60 colleges and ranks 26th in total GDP contribution among all states.",
      "PENNSYLVANIA": "Pennsylvania's admission into the United States on December 12, 1787, made it the 2nd state, with the Ruffed Grouse chosen as its state bird. As a Blue state, Pennsylvania leaned Democratic in the recent presidential election. Additionally, the state hosts 257 colleges and ranks 6th in total GDP contribution among all states.",
      "RHODE ISLAND": "May 29, 1790, marked Rhode Island's entry as the 13th state in the US, coinciding with the adoption of the Rhode Island Red as its state bird. Being a Blue state, Rhode Island demonstrated Democratic support in the recent presidential election. Furthermore, Rhode Island ranks 45th in total GDP contribution among all states, with 13 colleges.",
      "SOUTH CAROLINA": "May 23, 1788, marked South Carolina's entry as the 8th state in the US, coinciding with the adoption of the Great Carolina Wren as its state bird. Recognized as a Red state, South Carolina's political preference leaned Republican in the last presidential election. Furthermore, South Carolina ranks 27th in total GDP contribution among all states, despite being home to 71 colleges.",
      "SOUTH DAKOTA": "South Dakota was the 40th state incorporated in the US on November 2, 188The state's bird is the Ring-necked Pheasant. South Dakota is currently considered a Red state and voted Republican in the last presidential election. South Dakota has 25 total colleges, and it ranks 47th in total GDP contribution out of all states.",
      "TENNESSEE": "Tennessee, admitted into the Union on June 1, 1796, honors the Mockingbird as its official state bird. In terms of politics, it leans Republican, as evidenced by its voting pattern in the last presidential election. With 106 colleges statewide, Tennessee prioritizes education and ranks 21st in total GDP contribution among all states.",
      "TEXAS": "Established on December 29, 1845, Texas cherishes the Mockingbird as its state bird. Politically aligned with the Republican party, it voted red in the last presidential election. Hosting 240 colleges, Texas underscores its commitment to education and ranks 2nd in total GDP contribution among all states.",
      "UTAH": "Established on January 4, 1896, Utah cherishes the Common American Gull as its state bird. Politically aligned with the Republican party, it voted red in the last presidential election. Hosting 40 colleges, Utah underscores its commitment to education and ranks 33rd in total GDP contribution among all states.",
      "VERMONT": "Established on March 4, 1791, Vermont cherishes the Cardinal as its state bird. Politically aligned with the Democratic party, it voted blue in the last presidential election. Hosting 25 colleges, Vermont underscores its commitment to education and ranks 9th in total GDP contribution among all states.",
      "VIRGINIA": "Established on June 25, 1788, Virginia cherishes the Cardinal as its state bird. Politically aligned with the Republican party, it voted red in the last presidential election. Hosting 119 colleges, Virginia underscores its commitment to education and ranks 51st in total GDP contribution among all states.",
      "WASHINGTON": "Established on November 11, 1889, Washington cherishes the Willow Goldfinch as its state bird. Politically aligned with the Democratic party, it voted blue in the last presidential election. Hosting 81 colleges, Washington underscores its commitment to education and ranks 14th in total GDP contribution among all states.",
      "WEST VIRGINIA": "Established on June 20, 1863, West Virginia cherishes the Robin as its state bird. Politically identified with the Purple state, it voted red in the last presidential election. Hosting 45 colleges, West Virginia underscores its commitment to education and ranks 20th in total GDP contribution among all states.",
      "WISCONSIN": "Established on May 29, 1848, Wisconsin cherishes the Cardinal as its state bird. Politically identified with the Blue state, it voted blue in the last presidential election. Hosting 77 colleges, Wisconsin underscores its commitment to education and ranks 40th in total GDP contribution among all states.",
      "WYOMING": "Established on July 10, 1890, Wyoming cherishes the Western Meadowlark as its state bird. Politically identified with the Red state, it voted red in the last presidential election. Hosting only 11 colleges, Wyoming underscores its commitment to education and ranks 48th in total GDP contribution among all states."
    },
    {
      "FIELD1": "Volunteer",
      "ALABAMA": "Alabama, admitted as the 22nd state on December 14, 1819, boasts the Willow Ptarmigan as its state bird and leans Republican in its political inclination, as evidenced by its voting pattern in the last presidential election. Hosting 72 colleges, Alabama ranks 46th in total GDP contribution among all states.",
      "ALASKA": "Alaska became the 49th state incorporated into the US on January 3, 1959, and since then, it has adopted the Yellowhammer as its state bird. In terms of political affiliation, Alaska leans Red and voted Republican in the recent presidential election. Additionally, Alaska accommodates 7 colleges and ranks 25th in total GDP contribution among all states.",
      "ARIZONA": "On February 14, 1912, Arizona officially joined the US as the 48th state, a momentous occasion in its history. The state's official bird, the Mockingbird, embodies its rich wildlife diversity. Politically, Arizona is classified as a Red state and voted Republican in the last presidential election. Moreover, Arizona hosts 78 colleges and ranks 34th in total GDP contribution among all states.",
      "ARKANSAS": "On June 15, 1836, Arkansas officially joined the US as the 25th state, marking a historic milestone. The state's avian symbol, the Cactus Wren, reflects its rich wildlife diversity. Politically, Arkansas is a Purple state and voted Republican in the last presidential election. Moreover, Arkansas hosts 50 colleges and ranks 18th in total GDP contribution among all states.",
      "CALIFORNIA": "On September 9, 1850, California officially joined the US as the 31st state, marking a historic milestone. The state's avian symbol, the California Valley Quail, reflects its rich wildlife diversity. Politically, California is a Blue state and voted Democrat in the last presidential election. Moreover, California hosts an impressive 436 colleges and ranks first in total GDP contribution among all states.",
      "COLORADO": "On August 1, 1876, Colorado officially joined the US as the 38th state, marking a historic milestone. The state's avian symbol, the Lark Bunting, reflects its rich wildlife diversity. Politically, Colorado is a Purple state and voted Democrat in the last presidential election. Moreover, Colorado hosts 75 colleges and ranks 19th in total GDP contribution among all states.",
      "CONNECTICUT": "On January 9, 1788, Connecticut officially joined the US as the 5th state, marking a historic milestone. The state's avian symbol, the Robin, reflects its rich wildlife diversity. Politically, Connecticut is a Blue state and voted Democrat in the last presidential election. Moreover, Connecticut hosts 43 colleges and ranks 23rd in total GDP contribution among all states.",
      "DELAWARE": "On December 7, 1787, Delaware officially joined the US as the 1st state, marking a historic milestone. The state's avian symbol, the Blue Hen Chicken, reflects its rich wildlife diversity. Politically, Delaware is a Blue state and voted Democrat in the last presidential election. Moreover, Delaware hosts 10 colleges and ranks 41st in total GDP contribution among all states.",
      "FLORIDA": "On March 3, 1845, Florida officially joined the US as the 27th state, marking a historic milestone. The state's avian symbol, the Mockingbird, reflects its rich wildlife diversity. Politically, Florida is a Purple state and voted Democrat in the last presidential election. Moreover, Florida hosts 207 colleges and ranks 4th in total GDP contribution among all states.",
      "GEORGIA": "On January 2, 1788, Georgia attained statehood as the 4th state incorporated into the US, signifying a significant historical event. The Brown Thrasher represents Georgia's avian diversity as its state bird. Politically, Georgia is classified as a Red state and voted Republican in the last presidential election. Additionally, Georgia accommodates 131 colleges and ranks 11th in total GDP contribution among all states.",
      "HAWAII": "On August 21, 1959, Hawaii attained statehood as the 50th state incorporated into the US, signifying a significant historical event. The Nene represents Hawaii's avian diversity as its state bird. Politically, Hawaii is classified as a Blue state and voted Democrat in the last presidential election. Additionally, Hawaii accommodates 20 colleges and ranks 39th in total GDP contribution among all states.",
      "IDAHO": "On July 3, 1890, Idaho achieved statehood as the 43rd state incorporated into the US, signifying a significant historical event. The Cardinal represents Idaho's avian diversity as its state bird. Politically, Idaho is classified as a Red state and voted Republican in the last presidential election. Additionally, Idaho hosts 15 colleges and ranks 5th in total GDP contribution among all states.",
      "ILLINOIS": "On December 3, 1818, Illinois attained statehood as the 21st state incorporated into the US, signifying a significant historical event. The Cardinal represents Illinois' avian diversity as its state bird. Politically, Illinois is classified as a Blue state and voted Democrat in the last presidential election. Additionally, Illinois accommodates 181 colleges and ranks 16th in total GDP contribution among all states.",
      "INDIANA": "Indiana was incorporated as the 19th state in the US on December 11, 1816, highlighting its early role in the nation's development. The Eastern Goldfinch, Indiana's state bird, represents its natural beauty and wildlife. Politically, Indiana is currently classified as a Red state and supported the Republican candidate in the last presidential election. Moreover, Indiana hosts 106 colleges and ranks 30th in total GDP contribution among all states.",
      "IOWA": "Iowa was incorporated as the 29th state in the US on December 28, 1846, highlighting its early role in the nation's development. The American Goldfinch, Iowa's state bird, represents its natural beauty and wildlife. Politically, Iowa is currently classified as a Red state and supported the Republican candidate in the last presidential election. Moreover, Iowa hosts 65 colleges and ranks 43rd in total GDP contribution among all states.",
      "KANSAS": "Kansas was incorporated as the 34th state in the US on January 29, 1861, highlighting its early role in the nation's development. The Western Meadowlark, Kansas's state bird, represents its natural beauty and wildlife. Politically, Kansas is currently classified as a Red state and supported the Republican candidate in the last presidential election. Moreover, Kansas hosts 67 colleges and ranks 31st in total GDP contribution among all states.",
      "KENTUCKY": "Kentucky was incorporated as the 15th state in the US on June 1, 1792, highlighting its early contribution to the nation. The state bird, the Cardinal, represents Kentucky's diverse wildlife. Politically, Kentucky is currently classified as a Red state and voted Republican in the last presidential election. Moreover, Kentucky hosts 75 colleges and ranks 28th in total GDP contribution among all states.",
      "LOUISIANA": "Louisiana was incorporated as the 18th state in the US on April 30, 1812, signifying a pivotal moment in its history. The Eastern Brown Pelican, its state bird, represents Louisiana's coastal ecosystems. Politically, Louisiana is currently classified as a Red state and voted Republican in the last presidential election. Moreover, Louisiana hosts 87 colleges and ranks 24th in total GDP contribution among all states.",
      "MAINE": "Maine was incorporated as the 23rd state in the US on March 19, 1820, signifying a significant event in its history. The Chickadee, its state bird, represents Maine's diverse wildlife and natural resources. Politically, Maine is classified as a Blue state and voted Democrat in the last presidential election. Moreover, Maine hosts 30 colleges and ranks 12th in total GDP contribution among all states.",
      "MARYLAND": "Maryland was incorporated as the 7th state in the US on April 28, 1788, signifying a significant event in its history. The Baltimore Oriole, its state bird, reflects Maryland's diverse wildlife and natural beauty. Politically, Maryland is classified as a Blue state and voted Democrat in the last presidential election. Moreover, Maryland hosts 59 colleges and ranks 15th in total GDP contribution among all states.",
      "MASSACHUSETTS": "Founded as the 6th state on February 6, 1788, Massachusetts has a rich historical heritage within the United States. The Chickadee, its state bird, reflects the state's diverse wildlife and natural landscapes. Politically, Massachusetts is classified as a Blue state and voted Democrat in the last presidential election. Moreover, Massachusetts accommodates 125 colleges and ranks 44th in total GDP contribution among all states.",
      "MICHIGAN": "Michigan, incorporated as the 26th state on January 26, 1837, boasts a rich historical heritage within the United States. The Robin, its state bird, symbolizes Michigan's abundant wildlife and natural landscapes. Politically, Michigan is classified as a Blue state and voted Democrat in the last presidential election. Additionally, Michigan accommodates 105 colleges and ranks 13th in total GDP contribution among all states.",
      "MINNESOTA": "Minnesota, incorporated as the 32nd state on May 11, 1858, has a profound legacy within the United States. Represented by the Common Loon, Minnesota underscores its dedication to protecting its natural habitats and diverse ecosystems. Politically, Minnesota leans Blue and favored the Democratic candidate in the most recent presidential election, aligning with its progressive ideals. Additionally, with 117 colleges, Minnesota fosters a culture of education and scholarly pursuit. Economically, it ranks 17th in total GDP contribution among all states, indicating its significant economic impact.",
      "MISSISSIPPI": "December 10, 1817, marked Mississippi's entry as the 20th state in the US, coinciding with the adoption of the Bluebird as its state bird. Its political leaning as a Red state was evident in the last presidential election, where it voted Republican. Furthermore, Mississippi boasts 41 colleges and ranks 22nd in total GDP contribution among all states.",
      "MISSOURI": "August 10, 1821, marked Missouri's entry as the 24th state in the US, coinciding with the adoption of the Mockingbird as its state bird. Its political leaning as a Purple state was evident in the last presidential election, where it voted Democrat. Furthermore, Missouri boasts 132 colleges and ranks 36th in total GDP contribution among all states.",
      "MONTANA": "November 8, 1889, marked Montana's entry as the 41st state in the US, coinciding with the adoption of the Western Meadowlark as its state bird. Its political leaning as a Red state was evident in the last presidential election, where it voted Republican. Furthermore, Montana boasts 23 colleges and ranks 49th in total GDP contribution among all states.",
      "NEBRASKA": "March 1, 1867, marked Nebraska's entry as the 37th state in the US, coinciding with the adoption of the Purple Finch as its state bird. Its political leaning as a Red state was evident in the last presidential election, where it voted Republican. Furthermore, Nebraska boasts 43 colleges and ranks 42nd in total GDP contribution among all states.",
      "NEVADA": "October 31, 1864, marked Nevada's entry as the 36th state in the US, coinciding with the adoption of the Cardinal as its state bird. Despite its Purple state label, Nevada's political preference leaned Republican in the last presidential election. Furthermore, Nevada is home to 21 colleges and ranks 10th in total GDP contribution among all states.",
      "NEW HAMPSHIRE": "June 21, 1788, marked New Hampshire's entry as the 9th state in the US, coinciding with the adoption of the Eastern Goldfinch as its state bird. Despite its Blue state label, New Hampshire's political preference leaned Democrat in the last presidential election. Furthermore, New Hampshire is home to 28 colleges and ranks 7th in total GDP contribution among all states.",
      "NEW JERSEY": "December 18, 1787, marked New Jersey's entry as the 3rd state in the US, coinciding with the adoption of the Roadrunner as its state bird. Despite its Blue state label, New Jersey's political preference leaned Democrat in the last presidential election. Furthermore, New Jersey is home to 65 colleges and ranks 38th in total GDP contribution among all states.",
      "NEW MEXICO": "January 6, 1912, marked New Mexico's entry as the 47th state in the US, coinciding with the adoption of the Bluebird as its state bird. Despite its Blue state label, New Mexico's political preference leaned Democrat in the last presidential election. Furthermore, New Mexico is home to 41 colleges and ranks 3rd in total GDP contribution among all states.",
      "NEW YORK": "July 26, 1788, marked New York's entry as the 11th state in the US, coinciding with the adoption of the Western Meadowlark as its state bird. As a Blue state, New York supported the Democrat candidate in the last presidential election. Additionally, New York ranks 50th in total GDP contribution among all states, despite being home to 306 colleges.",
      "NORTH CAROLINA": "November 21, 1789, marked North Carolina's entry as the 12th state in the US, coinciding with the adoption of the Western Meadowlark as its state bird. As a Red state, North Carolina supported the Republican candidate in the last presidential election. Additionally, North Carolina ranks 37th in total GDP contribution among all states, despite being home to 137 colleges.",
      "NORTH DAKOTA": "November 2, 1889, marked North Dakota's entry as the 39th state in the US, coinciding with the adoption of the Mountain Bluebird as its state bird. As a Red state, North Dakota supported the Republican candidate in the last presidential election. Additionally, North Dakota ranks 32nd in total GDP contribution among all states, despite being home to 22 colleges.",
      "OHIO": "March 1, 1803, marked Ohio's entry as the 17th state in the US, coinciding with the adoption of the Cardinal as its state bird. As a Purple state, Ohio supported the Democratic candidate in the last presidential election. Additionally, Ohio ranks 8th in total GDP contribution among all states, despite being home to 213 colleges.",
      "OKLAHOMA": "November 16, 1907, marked Oklahoma's entry as the 46th state in the US, coinciding with the adoption of the Scissor-tailed Flycatcher as its state bird. As a Red state, Oklahoma supported the Republican candidate in the last presidential election. Additionally, Oklahoma ranks 29th in total GDP contribution among all states, despite being home to 61 colleges.",
      "OREGON": "February 14, 1859, marked Oregon's entry as the 33rd state in the US, coinciding with the adoption of the Western Meadowlark as its state bird. As a Blue state, Oregon supported the Democratic candidate in the last presidential election. Additionally, Oregon ranks 26th in total GDP contribution among all states, despite being home to 60 colleges.",
      "PENNSYLVANIA": "December 12, 1787, marked Pennsylvania's entry as the 2nd state in the US, coinciding with the adoption of the Ruffed Grouse as its state bird. As a Blue state, Pennsylvania supported the Democratic candidate in the last presidential election. Additionally, Pennsylvania ranks 6th in total GDP contribution among all states, despite being home to 257 colleges.",
      "RHODE ISLAND": "Rhode Island's incorporation as the 13th state on May 29, 1790, was accompanied by the selection of the Rhode Island Red as its state bird. As a Blue state, Rhode Island leaned Democratic in the last presidential election. Additionally, Rhode Island ranks 45th in total GDP contribution among all states, despite hosting only 13 colleges.",
      "SOUTH CAROLINA": "South Carolina's admission into the United States on May 23, 1788, made it the 8th state, with the Great Carolina Wren chosen as its state bird. As a Red state, South Carolina supported the Republican candidate in the recent presidential election. Additionally, the state ranks 27th in total GDP among all states, despite being home to 71 colleges.",
      "SOUTH DAKOTA": "South Dakota was the 40th state incorporated in the US on November 2, 188The state's bird is the Ring-necked Pheasant. South Dakota is currently considered a Red state and voted Republican in the last presidential election. In addition, there are 25 total colleges in South Dakota. South Dakota ranks 47th in total GDP contribution out of all states.",
      "TENNESSEE": "Established on June 1, 1796, Tennessee embraces the Mockingbird as its state bird. Politically conservative, it voted Republican in the last presidential election. With 106 colleges, Tennessee underscores its commitment to education, contributing significantly to the nation's intellectual capital and ranking 21st in total GDP contribution.",
      "TEXAS": "Texas, becoming the 28th state on December 29, 1845, celebrates the Mockingbird as its state bird. Politically conservative, it favored the Republican candidate in the last presidential election. With 240 colleges, Texas emphasizes the importance of education and ranks 2nd in total GDP contribution among all states.",
      "UTAH": "Utah, becoming the 45th state on January 4, 1896, celebrates the Common American Gull as its state bird. Politically conservative, it favored the Republican candidate in the last presidential election. With 40 colleges, Utah emphasizes the importance of education and ranks 33rd in total GDP contribution among all states.",
      "VERMONT": "Vermont, becoming the 14th state on March 4, 1791, celebrates the Cardinal as its state bird. Politically aligned with the Democratic party, it favored the Democratic candidate in the last presidential election. With 25 colleges, Vermont emphasizes the importance of education and ranks 9th in total GDP contribution among all states.",
      "VIRGINIA": "Virginia, becoming the 10th state on June 25, 1788, celebrates the Cardinal as its state bird. Politically aligned with the Republican party, it favored the Republican candidate in the last presidential election. With 119 colleges, Virginia emphasizes the importance of education and ranks 51st in total GDP contribution among all states.",
      "WASHINGTON": "Washington, becoming the 42nd state on November 11, 1889, celebrates the Willow Goldfinch as its state bird. Politically aligned with the Democratic party, it favored the Democratic candidate in the last presidential election. With 81 colleges, Washington emphasizes the importance of education and ranks 14th in total GDP contribution among all states.",
      "WEST VIRGINIA": "West Virginia, becoming the 35th state on June 20, 1863, celebrates the Robin as its state bird. Politically identified with the Purple state, it favored the Republican candidate in the last presidential election. With 45 colleges, West Virginia emphasizes the importance of education and ranks 20th in total GDP contribution among all states.",
      "WISCONSIN": "Wisconsin, becoming the 30th state on May 29, 1848, celebrates the Cardinal as its state bird. Politically identified with the Blue state, it favored the Democratic candidate in the last presidential election. With 77 colleges, Wisconsin emphasizes the importance of education and ranks 40th in total GDP contribution among all states.",
      "WYOMING": "Wyoming, becoming the 44th state on July 10, 1890, celebrates the Western Meadowlark as its state bird. Politically identified with the Red state, it favored the Republican candidate in the last presidential election. With a small number of colleges, Wyoming emphasizes the importance of education and ranks 48th in total GDP contribution among all states."
    }
  ]