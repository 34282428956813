import React, { useEffect } from 'react'
import { useGetMetaDataQuery } from 'services/api/metaDataApi'
import { scrollToTop } from 'services/helpers'
import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'
import JobByCategories from 'components/JobByCategories/JobByCategories'
import JobsStates from './JobsStates/JobsStates'
import SEO from 'components/SEOComponent/SEO'

const JobsCategories = () => {
  const { data } = useGetMetaDataQuery('jobs_by_category')
  useEffect(() => {
    scrollToTop()
  },[data])
 
  return (
    <ContentWrapper >
        <SEO title={data?.data_attributes?.meta_title} description={data?.data_attributes?.meta_description} keywords={data?.data_attributes?.meta_keywords}/>
        <JobByCategories/>
        <JobsStates />
        
    </ContentWrapper>
  )
}

export default JobsCategories