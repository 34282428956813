import React from 'react'
import './LinksList.scss'

import ContentWrapper from 'HOC/ContentWrapper/ContentWrapper'
import RenderFooterLinks from '../RenderFooterLinks/RenderFooterLinks'
import { Link } from 'react-router-dom'


const LinksList = ({ title, divider, linksType, data, titleLink, jobsByState = false, state , category }) => {
    return (
        <section className='linksList'>
            <ContentWrapper>
                <div className={`linksList_container ${divider ? 'divider' : ''}`}>
                    {titleLink ? 
                        <Link className='linksList_title__link' to={titleLink}>
                            <h4 className='linksList_title__text'>
                                {title}
                            </h4>
                        </Link>
                        :                                        
                        <h4 className='linksList_title__text'>
                            {title}
                        </h4>
                    }

                    <div className='linksList_list'>
                        <RenderFooterLinks linksType={linksType} data={data} jobsByState={jobsByState} state={state} category={category} />
                    </div>
                </div>
            </ContentWrapper>
        </section>
    )
}

export default LinksList