import React from 'react'

const PaginationArrow = ({ side, disabled, color }) => {
    const fill = color ? color : disabled ? '#AFAFB4' : '#FD8848'

    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: side === 'prev' ? 'rotate(180deg)' : '' }}>
            <g id="Navigation">
                <g id="Shape">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.53906 3.07055C6.96354 3.65183 6.96354 4.58817 7.53906 5.16945L13.9537 11.6482C14.1466 11.8431 14.1466 12.1569 13.9537 12.3518L7.53906 18.8305C6.96354 19.4118 6.96354 20.3482 7.53906 20.9295C8.12274 21.519 9.07528 21.519 9.65896 20.9295L17.1068 13.4072C17.8785 12.6278 17.8785 11.3722 17.1068 10.5928L9.65896 3.07055C9.07528 2.48103 8.12274 2.48103 7.53906 3.07055Z" fill={fill}/>
                </g>
            </g>
        </svg>
    )
}

export default PaginationArrow