/* eslint-disable */
import React, { useEffect, useState, Fragment } from 'react'
import './CareerServicesSelection.scss'
import { useGetCareerServiceArticlesQuery } from 'services/api/careerServiceApi'
import CheckBox from 'components/CheckBox/CheckBox'

const CareerServicesSelection = ({setFormData, error, setError, clear}) => {
  const { data } = useGetCareerServiceArticlesQuery()
  const checkBoxesList = data?.career_services.map(item => item.sanitized_name)
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([])

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      'career_services': selectedCheckboxes.length ? selectedCheckboxes.join(', ') : null
    }))
  }, [selectedCheckboxes])

  useEffect(() => {
    if(selectedCheckboxes.length > 0) {
      setError(false)
    }
  },[selectedCheckboxes])

  useEffect(() => {
    setSelectedCheckboxes([])
  },[clear])

  const handleCheckboxChange = (e) => {
    const {value, checked} = e.target
    if (checked) {
      setSelectedCheckboxes(prevSelected => [...prevSelected, value])
    } else {
      setSelectedCheckboxes(prevSelected => prevSelected.filter(item => item !== value))
    }
  };

  return (
    <div className='careerSelection'>
      <div className='careerSelection_title'>
        <p>Career Services Selection</p>
        {error && <span className='error'>Please select at least one option</span>}
      </div>

        {checkBoxesList?.map(item => <Fragment key={item}><CheckBox label={item} value={item} onChange={handleCheckboxChange} checked={selectedCheckboxes.includes(item)}/></Fragment>)}
    </div>
  )
}

export default CareerServicesSelection