import { createSlice } from '@reduxjs/toolkit';

const reviewsSlice = createSlice({
  name: 'reviewsReducer',
  initialState: { 
    feedbackModal: false,
    selectedCompany: {
        name: '',
        id: '',
    },
   },
  reducers: {
    setFeedbackModal: (state, action) => {
      state.feedbackModal = action.payload;
    },
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
  },
});

export const { setSelectedCompany, setFeedbackModal } = reviewsSlice.actions;
export default reviewsSlice.reducer;
